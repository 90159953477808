/* eslint-disable @typescript-eslint/naming-convention */
import { CommonModule } from '@angular/common';
import { Component, OnInit, isDevMode } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { IonicModule, ModalController } from '@ionic/angular';

import { TranslocoModule } from '@ngneat/transloco';

import { Store } from '@ngrx/store';

import { userActions } from '@hestia/ngx-user';

import { Video } from '../video';

export interface SSNEntryPageData {
  /** Auto Submit SSN */
  autoSubmit?: boolean;
  buttons?: {
    /** Button Label (Local i18n string) */
    label: string | undefined;
    /** Ionicon Name */
    icon: string | undefined;
    /** Card Color  */
    color?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'medium' | 'light';
    /** Handler  */
    handler?: () => void;
  }[];
  videoUrl: string | undefined;
  defaultFaq?: string | undefined;
  /** Frequently Asked Questions */
  faqs?: {
    /** Question (Local i18n string) */
    question?: string;
    /** Answer (Local i18n string) */
    answer?: string;
  }[];
}

@Component({
  standalone: true,
  selector: 'hestia-ssn-entry',
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslocoModule, IonicModule],
  templateUrl: 'ssn-entry.html',
  styles: [``],
})
export class SSNEntryComponent implements OnInit {
  pageData: SSNEntryPageData | undefined;

  showSSN = true;
  digits = new Array(9);

  form = new FormGroup({
    ssn: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
      // Validators.pattern(/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}[-]?\d{4}$/gm),
    ]),
  });

  constructor(public modalController: ModalController, private route: ActivatedRoute, private store: Store) {
    this.route.data.subscribe((data: SSNEntryPageData) => (this.pageData = data));
  }

  ngOnInit(): void {
    return;
  }

  async presentVideo() {
    const modal = await this.modalController.create({
      component: Video,
      componentProps: { videoUrl: this.pageData?.videoUrl },
    });

    modal.present();
  }

  resolveInput(e) {
    const pattern = /^([0-9])$/;
    const result = pattern.test(e.key);

    return result;
  }

  addDigit(d: number) {
    let current = this.form.value.ssn || '';

    if (current.length <= 9) {
      this.form.setValue({ ssn: (current += d) });
    }

    if (this.form.valid && this.pageData?.autoSubmit) {
      this.onSubmit();
    }
  }

  removeDigit() {
    const current = this.form.value.ssn || '';
    this.form.setValue({ ssn: current.slice(0, -1) });
  }

  onSubmit() {
    this.store.dispatch(userActions.initKioskSession({ social_security_number: this.form.value.ssn || '' }));
  }
}
