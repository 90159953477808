import { CarePlanActivityStatus } from '@hestia/ngx-types';

export const anyCarePlanActivitiesBegun = (
  carePlan: fhir4.CarePlan
): boolean => {
  if (!carePlan) {
    return false;
  }
  const begunStatuses: CarePlanActivityStatus[] = [
    'in-progress',
    'on-hold',
    'completed',
  ];
  return (
    carePlan.activity.filter((activity) =>
      begunStatuses.includes(activity.detail.status)
    ).length > 0
  );
};

export const anyCarePlanActivitiesInProgress = (
  carePlan: fhir4.CarePlan
): boolean => {
  if (!carePlan) {
    return false;
  }
  const inProgressStatuses: CarePlanActivityStatus[] = [
    'in-progress',
    'on-hold',
  ];
  return (
    carePlan.activity.filter((activity) =>
      inProgressStatuses.includes(activity.detail.status)
    ).length > 0
  );
};

export const anyCarePlanActivitiesNotFinalized = (
  carePlan: fhir4.CarePlan
): boolean => {
  if (!carePlan) {
    return false;
  }
  const inProgressStatuses: CarePlanActivityStatus[] = [
    'on-hold',
    'not-started',
    'scheduled',
    'in-progress',
    'stopped',
  ];
  return (
    carePlan.activity.filter((activity) =>
      inProgressStatuses.includes(activity.detail.status)
    ).length > 0
  );
};
