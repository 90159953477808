/**  */
export const isValueSetResource = (
  resource: fhir4.Resource
): resource is fhir4.ValueSet => {
  return resource?.resourceType === 'ValueSet';
};

export const isCarePlanResource = (
  resource: fhir4.Resource
): resource is fhir4.CarePlan => {
  return resource?.resourceType === 'CarePlan';
};

export const isQuestionnaireResource = (
  resource: fhir4.Resource
): resource is fhir4.Questionnaire => {
  return resource?.resourceType === 'Questionnaire';
};

export const isPatientResource = (
  resource: fhir4.Resource
): resource is fhir4.Patient => {
  return resource?.resourceType === 'Patient';
};

export const isPractitionerResource = (
  resource: fhir4.Resource
): resource is fhir4.Practitioner => {
  return resource?.resourceType === 'Practitioner';
};

export const isRelatedPersonResource = (
  resource: fhir4.Resource
): resource is fhir4.RelatedPerson => {
  return resource?.resourceType === 'RelatedPerson';
};

export const isQuestionnaireResponseResource = (
  resource: fhir4.Resource
): resource is fhir4.QuestionnaireResponse => {
  return resource?.resourceType === 'QuestionnaireResponse';
};
