import { Action, createReducer, on } from '@ngrx/store';

import * as moduleSettingsActions from './module-settings.actions';

export interface IUserControlledSettingsState {
  enableVirtualKeyboard: boolean;
}

export const initialState: IUserControlledSettingsState = {
  enableVirtualKeyboard: false,
};

export const qnrModuleSettingsFeatureKey = 'questionnaire-module-settings';

const moduleSettingsReducer = createReducer(
  initialState,
  on(
    moduleSettingsActions.resetSettings,
    (_state): IUserControlledSettingsState => initialState
  ),
  on(
    moduleSettingsActions.updateSettings,
    (state, action): IUserControlledSettingsState => ({
      ...state,
      ...action,
    })
  )
);

export function settingsReducer(
  state: IUserControlledSettingsState | undefined,
  action: Action
) {
  return moduleSettingsReducer(state, action);
}
