import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router, UrlTree } from '@angular/router';

import { map, Observable, take } from 'rxjs';

import { HESTIA_USER_MODULE_CONFIG, IHestiaUserModuleConfig } from '../constants';
import { HestiaUserFacade } from '../user.facade';

@Injectable()
export class IsUnauthenticatedRouteGuard implements CanActivateChild {
  constructor(
    private userFacade: HestiaUserFacade,
    private router: Router,
    @Inject(HESTIA_USER_MODULE_CONFIG)
    private hestiaUserModuleConfig: IHestiaUserModuleConfig
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.userFacade.isUserAuthenticated$().pipe(
      take(1),
      map((authenticated) => {
        if (authenticated) {
          return this.router.parseUrl(this.hestiaUserModuleConfig.isUnauthenticatedGuardFailureRoute);
        } else {
          return true;
        }
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(route, state);
  }
}
