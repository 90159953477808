<form [formGroup]="form">
  <div
    *ngFor="
      let widget of widgets;
      let isLast = last;
      let isFirst = first;
      trackBy: trackWidgetById
    "
    class="mb-4"
    hestiaWidget
    [renderStandalone]="widgets.length === 1"
    [config]="widget"
    [form]="form"
    [displayMode]="displayMode"
  ></div>
  <ion-grid *ngIf="!displayMode">
    <ion-row>
      <ion-col [size]="6" [sizeLg]="3">
        <ion-button
          expand="block"
          shape="round"
          [strong]="true"
          [hidden]="(widgets[0].pageNumber ?? null) === 1"
          (click)="previousPage($event)"
        >
          <ion-icon slot="start" name="chevron-back"></ion-icon>
          {{ 'IFQ.PREVIOUS' | transloco }}
        </ion-button>
      </ion-col>
      <ion-col [size]="6" [sizeLg]="3" [offsetLg]="6">
        <ion-button
          expand="block"
          shape="round"
          type="submit"
          [strong]="true"
          class="questionnaire-next-or-submit-btn"
          (click)="submitHandler($event)"
          [disabled]="isSubmitEnabled()"
        >
          {{ submitButtonName | transloco }}
          <ion-icon
            slot="end"
            [name]="
              submitButtonName !== 'Submit' ? 'chevron-forward' : 'paper-plane'
            "
          ></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
