import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { loadModuleTranslations } from '@hestia/ngx-i18n';
import { IHestiaCoreConfig } from '@hestia/ngx-types';

import { CORE_HESTIA_CONFIG, moduleI18nScope } from './constants';
import daDK from './i18n/da-DK.json';
import enGB from './i18n/en-GB.json';
import { CastAsPipe } from './pipes/cast-as.pipe';
import { ReadableCprPipe } from './pipes/readable-cpr.pipe';
import { HestiaCommonService } from './services/common.service';
import { HestiaLayoutService } from './services/layout.service';

const pipes = [ReadableCprPipe, CastAsPipe];

@NgModule({
  declarations: [...pipes],
  imports: [CommonModule, IonicModule, HttpClientModule, TranslocoModule],
  exports: [...pipes],
})
export class HestiaCommonModule {
  constructor(private transloco: TranslocoService) {
    loadModuleTranslations(this.transloco, moduleI18nScope, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'da-DK': daDK,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'en-GB': enGB,
    });
  }
  static forRoot(
    coreHestiaConfig: IHestiaCoreConfig
  ): ModuleWithProviders<HestiaCommonModule> {
    return {
      ngModule: HestiaCommonModule,
      providers: [
        {
          provide: CORE_HESTIA_CONFIG,
          useValue: coreHestiaConfig,
        },
        HestiaCommonService,
        HestiaLayoutService,
      ],
    };
  }
  static forChild(): ModuleWithProviders<HestiaCommonModule> {
    /**
     * We want a singleton instance of LayoutService,
     * therefore we do not provide it when forChild is called
     * (which is done by lazy loaded modules)
     * See https://stackoverflow.com/a/49878339
     */
    return {
      ngModule: HestiaCommonModule,
      providers: [],
    };
  }
}
