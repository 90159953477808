import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { HestiaFhirResourcesService } from '../services/fhir-resources.service';
import { fhirResourceActions } from './fhir-resource.actions';

@Injectable()
export class FhirResourceEffects {
  constructor(
    private actions$: Actions,
    private fhirService: HestiaFhirResourcesService
  ) {}

  clearFhirResources$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fhirResourceActions.clearFhirResources),
        tap((_action) => {
          // We reset the inflight fetch IDs in case of a new login
          this.fhirService.inflightFetchIds = new Set();
        })
      );
    },
    { dispatch: false }
  );
}
