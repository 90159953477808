import { WidgetConfig } from '../../../models/widget.config';

export class SliderWidgetConfig extends WidgetConfig<string> {
  widgetType = 'SliderWidget';
  minValue: number;
  maxValue: number;
  stepSize: number;
  displayMinValue: boolean;
  displayMaxValue: boolean;
  leftLabel: string;
  rightLabel: string;
  displayTicks: boolean;
  tickSize: number;
  displayCurrentValue: boolean;
  sliderOrientation: 'horizontal' | 'vertical';
  constructor(config: Record<string, any> = {}) {
    super(config);
    this.minValue = config.minValue ?? 0;
    this.maxValue = config.maxValue ?? 100;
    this.stepSize = config.stepSize ?? 1;
    this.displayMinValue = config.displayMinValue ?? true;
    this.displayMaxValue = config.displayMaxValue ?? true;
    this.leftLabel = config.leftLabel ?? null;
    this.rightLabel = config.rightLabel ?? null;
    this.displayTicks = config?.displayTicks ?? false;
    this.tickSize = config?.tickSize ?? 1;
    this.displayCurrentValue = config?.displayCurrentValue ?? false;
    this.sliderOrientation = config?.sliderOrientation ?? 'horizontal'; // Default orientation is horizontal
  }
}
