import { HestiaFhirResourceTypeName } from '@hestia/ngx-types';

export const splitRelativeReference = (
  reference: string
): { resourceType: HestiaFhirResourceTypeName; resourceId: string } => {
  const split = reference.split('/'),
    resourceType = split[0] as HestiaFhirResourceTypeName,
    resourceId = split[1];
  return { resourceType, resourceId };
};
