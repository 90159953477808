import {
  Component,
  Input,
  Output,
  ElementRef,
  AfterViewInit,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

export interface ISignaturePad {
  // Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
  toDataURL: (mode: '' | 'image/jpeg' | 'image/svg+xml') => any;
  // Draws signature image from data URL.
  // NOTE: This method does not populate internal data structure that represents drawn signature. Thus, after using #fromDataURL, #toData won't work properly.
  fromDataURL: (data) => void;
  // Returns signature image as an array of point groups
  toData: () => any;
  // Draws signature image from an array of point groups
  fromData: (poinsData: any) => void;

  // Clears the canvas
  clear: () => void;

  // Returns true if canvas is empty, otherwise returns false
  isEmpty: () => boolean;

  // Unbinds all event handlers
  off: () => void;

  // Rebinds all event handlers
  on: () => void;
}
declare const SignaturePad: any;

@Component({
  selector: 'hestia-signing',
  templateUrl: 'signing.html',
})
export class SigningComponent implements AfterViewInit {
  signaturePad: ISignaturePad;
  @ViewChild('canvas') public canvas: ElementRef;
  @Input() public width = 400;
  @Input() public height = 400;
  @Output() public onInit = new EventEmitter();
  @Output() public onEnd = new EventEmitter();
  @Output() public onClear = new EventEmitter();
  constructor(private transloco: TranslocoService) {}
  public ngAfterViewInit() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.signaturePad = new SignaturePad(canvasEl, {
      onEnd: () => {
        this.onEnd.emit();
      },
    }) as ISignaturePad;
    this.signaturePad.on();
    this.onInit.emit(this);
    canvasEl.style.border = 'solid 1px #333';
  }
  clear() {
    this.signaturePad.clear();
    this.onClear.emit(null);
  }
}
