import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { forkJoin, map, mergeMap, Observable, switchMap, take } from 'rxjs';

import { CORE_HESTIA_CONFIG } from '@hestia/ngx-common';
import {
  HestiaAuthEndpoint,
  HestiaFhirResourceTypeName,
  HestiaFhirUserResourceType,
  HestiaUserType,
  IHestiaCoreConfig,
  IHestiaUser,
  IHestiaUserProfile,
} from '@hestia/ngx-types';
import { HestiaUserFacade } from '@hestia/ngx-user';

import { HestiaFhirResourcesService } from './fhir-resources.service';

@Injectable({ providedIn: 'root' })
export class HestiaFhirUsersService {
  apiUrl: string;
  readonly fhirUsersEndpoint = HestiaAuthEndpoint.User;
  constructor(
    private http: HttpClient,
    private fhirService: HestiaFhirResourcesService,
    @Inject(CORE_HESTIA_CONFIG) private hestiaCoreConfig: IHestiaCoreConfig,
    private userFacade: HestiaUserFacade
  ) {
    this.apiUrl = this.hestiaCoreConfig.apiUrl;
  }

  createUser<T = IHestiaUserProfile>(props: IHestiaUser): Observable<T[]> {
    return this.userFacade.userState$.pipe(
      take(1),
      switchMap((userState) => {
        if (!props.organization_uid) {
          props = { ...props, organization_uid: userState.orgUuid };
        }

        return this.http.post<T[]>(`${this.apiUrl}${this.fhirUsersEndpoint}`, props);
      })
    );
  }

  patchUser<T = IHestiaUserProfile>(props: any): Observable<T[]> {
    return this.userFacade.userState$.pipe(
      take(1),
      switchMap((userState) => {
        console.log(props);

        return this.http.patch<T[]>(`${this.apiUrl}@users/${userState.userId}`, props);
      })
    );
  }

  fetchUsers<T = IHestiaUserProfile>(props: {
    userType?: HestiaUserType;
    organizationUuid?: string;
    fhirIds?: string[];
  }): Observable<T[]> {
    return this.userFacade.userState$.pipe(
      take(1),
      switchMap((userState) => {
        const params = {
          'organization_uid:': props.organizationUuid ?? userState.orgUuid,
        };
        if (props.userType) {
          params['user_type'] = props.userType;
        }
        if (props.fhirIds) {
          params['fhir_id'] = props.fhirIds.join(',');
        }
        const options = {
          params: new HttpParams({
            fromObject: params,
          }),
        };
        return this.http.get<T[]>(`${this.apiUrl}${this.fhirUsersEndpoint}`, options);
      })
    );
  }

  fetchUser<T = IHestiaUserProfile>(fhirId): Observable<T> {
    const options = {
      params: new HttpParams({
        fromObject: { fhir_id: fhirId },
      }),
    };
    return this.http.get<IHestiaUserProfile>(`${this.apiUrl}${this.fhirUsersEndpoint}`, options).pipe(
      map((result) => {
        if (Array.isArray(result)) {
          return result[0];
        }
        return result;
      })
    );
  }

  fetchPatientUserProfiles<T = IHestiaUserProfile>(props?: { organizationUuid?: string }): Observable<T[]> {
    return this.fetchUsers<T>({
      userType: HestiaUserType.Patient,
      organizationUuid: props?.organizationUuid,
    });
  }

  fetchHcpUserProfiles<T = IHestiaUserProfile>(props: { organizationUuid: string }): Observable<T[]> {
    return this.fetchUsers<T>({
      userType: HestiaUserType.Hcp,
      organizationUuid: props.organizationUuid,
    });
  }

  fetchRelatedPersonUsersProfiles<T = IHestiaUserProfile>(props: { organizationUuid: string }): Observable<T[]> {
    return this.fetchUsers({
      userType: HestiaUserType.RelatedPerson,
      organizationUuid: props.organizationUuid,
    });
  }

  private userTypeaheadSearch<T>(props: { userType: HestiaFhirUserResourceType; term: string }): Observable<T[]> {
    // TODO: Figure out if we can do a single query instead of two concurrent ones
    const queries: Observable<fhir4.Bundle>[] = [
      this.fhirService.fetchQuery({
        resourceType: props.userType,
        queryParams: { name: props.term },
        addToStore: false,
        resolveReferences: false,
      }),
      this.fhirService.fetchQuery({
        resourceType: props.userType,
        queryParams: { identifier: props.term.replace('-', '') },
        addToStore: false,
        resolveReferences: false,
      }),
    ];
    return forkJoin(queries).pipe(
      map((result) => {
        const users = [];
        result.map((bundle) => {
          if (bundle.total > 0) {
            bundle.entry.map((entry) => users.push(entry.resource));
          }
        });
        return users;
      })
    );
  }

  public patientTypeaheadSearch(term: string): Observable<fhir4.Patient[]> {
    return this.userTypeaheadSearch<fhir4.Patient>({
      term,
      userType: HestiaFhirResourceTypeName.Patient,
    });
  }

  public practitionerTypeaheadSearch(term: string): Observable<fhir4.Practitioner[]> {
    return this.userTypeaheadSearch<fhir4.Practitioner>({
      term,
      userType: HestiaFhirResourceTypeName.Practitioner,
    });
  }

  /** Reworked Stuff 2023 */

  fetchUserById = (id: string) => this.http.get<IHestiaUserProfile[]>(`${this.apiUrl}@fhir-user/${id}`);

  fetchUserSearch = (params: {
    b_start?: number;
    b_size?: number;
    query?: string;
    username?: string;
    user_type?: 'Patient';
    status?: string;
    patientSection?: string;
  }) =>
    this.http
      .get<{ items: IHestiaUserProfile[]; items_total: number }>(`${this.apiUrl}@fhir-user-search`, { params })
      .pipe(map((result) => result));
}
