<ion-list [formGroup]="form">
  <ion-list-header>
    <hestia-widget-group-header [config]="config" [i18n]="i18n"></hestia-widget-group-header>
    <hestia-widget-header [config]="config" [i18n]="i18n"></hestia-widget-header>
  </ion-list-header>

  <ion-grid>
    <ion-row>
      <ion-col
        [size]="3"
        *ngFor="let option of config.options"
        [id]="config.name + '|' + option.code"
        (click)="setFormValue(option.code)"
      >
        <ion-img
          [src]="'assets/img/' + option?.imageUrl"
          style="object-fit: cover"
          [style.filter]="
            value === option.code
              ? 'drop-shadow(0px 5px 5px var(--ion-color-primary))'
              : 'drop-shadow(5px 5px 5px transparent)'
          "
          [style.opacity]="value === option.code ? '1' : '0.75'"
        />
        <ion-text class="imageText" *ngIf="!(option?.imageHideText === true)">
          {{
            i18n({
              translate: option.i18nName,
              fallback: option.display,
              type: 'i18nName'
            })
          }}
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-list>
