import { Component, Input, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';

import { Store } from '@ngrx/store';

import { WidgetBaseComponent } from '../../../models/widget';
import { Events } from '../../../services/events.service';
import { HestiaQnrFormSessionService } from '../../../services/qnr-form-session.service';
import { SelectionWidgetConfig } from './selection-widget.config';

@Component({
  selector: 'hestia-selection-widget',
  templateUrl: './selection-widget.component.html',
  styleUrls: ['./selection-widget.component.scss'],
})
export class SelectionWidgetComponent
  extends WidgetBaseComponent
  implements OnInit, OnDestroy
{
  @Input() config: SelectionWidgetConfig;
  @Input() form: UntypedFormGroup;
  constructor(
    transloco: TranslocoService,
    questionnaireService: HestiaQnrFormSessionService,
    store: Store,
    element: ElementRef,
    events: Events
  ) {
    super(transloco, questionnaireService, store, element, events);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  get isValid() {
    return this.form.controls[this.config.name].valid;
  }
}
