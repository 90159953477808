import { WidgetTypes } from '../../typings';
import { LegacyQnrItemExtBase } from '../extension-base';

export class QuestionnaireItemSvgGroup extends LegacyQnrItemExtBase {
  extensionUrl =
    'http://zitelab.eu/fhir/extensions/questionnaire-item-svg-group';
  supportedWidgets = [WidgetTypes.SvgGroupWidget];
  constructor(config: Record<string, unknown> = {}) {
    super(config);
  }

  parse = (): {
    widgetProperties: {
      isSvgGroup: boolean;
      svgSourceType?: 'string' | 'filename';
      svgSource?: string;
    };
  } => {
    let isSvgGroup = false;
    let svgSourceType: 'string' | 'filename' = null;
    let svgSource: string = null;
    if (this.extensionJson?.valueBoolean) {
      isSvgGroup = this.extensionJson.valueBoolean;
    }
    if (this.extensionJson?.extension) {
      this.extensionJson.extension.forEach((extension) => {
        if (extension.url === 'svgSourceType') {
          svgSourceType = extension.valueCode as 'string' | 'filename';
        }
        if (extension.url === 'svgSource') {
          svgSource = extension.valueString;
        }
      });
    }
    return {
      widgetProperties: {
        isSvgGroup,
        svgSourceType,
        svgSource,
      },
    };
  };
}

export function getSvgGroupExt(item: fhir4.QuestionnaireItem): {
  isSvgGroup: boolean;
  svgSourceType?: 'string' | 'filename';
  svgSource?: string;
} {
  let extensionObj: QuestionnaireItemSvgGroup;
  if (item?.extension && item.type === 'group') {
    // the FHIR resource is already validated at this point
    item.extension.forEach((extension) => {
      if (
        extension.url ===
        'http://zitelab.eu/fhir/extensions/questionnaire-item-svg-group'
      ) {
        extensionObj = new QuestionnaireItemSvgGroup({
          extensionJson: extension,
        });
      }
    });
  }
  return extensionObj !== undefined
    ? extensionObj.parse().widgetProperties
    : { isSvgGroup: false };
}
