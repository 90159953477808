import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { HestiaCommonModule } from '@hestia/ngx-common';
import { loadModuleTranslations } from '@hestia/ngx-i18n';
import { HestiaNgxUserModule } from '@hestia/ngx-user';

import { HestiaMitIdCallbackComponent } from './components/mitid-callback.component/mitid-callback.component';
import { IMitIdModuleConfig, MITID_MODULE_CONFIG, MITID_MODULE_I18N_SCOPE } from './constants';
import daDK from './i18n/da-DK.json';
import enGB from './i18n/en-GB.json';
import { HestiaMitIdService } from './services/mitid.service';

@NgModule({
  imports: [
    CommonModule,
    HestiaCommonModule.forChild(),
    HestiaNgxUserModule,
    IonicModule,
    RouterModule.forChild([]),
    TranslocoModule,
  ],
  exports: [HestiaMitIdCallbackComponent],
  declarations: [HestiaMitIdCallbackComponent],
  providers: [],
})
export class HestiaNgxMitIdModule {
  constructor(private transloco: TranslocoService) {
    loadModuleTranslations(this.transloco, MITID_MODULE_I18N_SCOPE, {
      'da-DK': daDK,
      'en-GB': enGB,
    });
  }
  static forRoot(config: IMitIdModuleConfig): ModuleWithProviders<HestiaNgxMitIdModule> {
    return {
      ngModule: HestiaNgxMitIdModule,
      providers: [
        HestiaMitIdService,
        {
          provide: MITID_MODULE_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
