import { AlertController, IonicModule, ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HestiaUserFacade, userActions } from '@hestia/ngx-user';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { isAfter, isBefore } from 'date-fns';

import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DateFnsModule } from 'ngx-date-fns';
import { Store } from '@ngrx/store';

@Component({
  standalone: true,
  selector: 'hestia-sms-activation',
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslocoModule, IonicModule, DateFnsModule],
  templateUrl: 'sms-activation.html',
  styles: [
    `
      ion-grid {
        padding: 0;
      }
    `,
  ],
})
export class SMSActivationComponent implements OnInit {
  timeLeft = 60;
  interval: NodeJS.Timer | undefined;

  otpPayload: { authToken: string; expires: string } | undefined;

  ssn = new FormControl('', [
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(10),
    // Validators.pattern(/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}[-]?\d{4}$/gm),
  ]);

  activationCode = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]);

  constructor(
    private store: Store,
    private userFacade: HestiaUserFacade,
    private translocoService: TranslocoService,
    public modalCtrl: ModalController,
    private alertController: AlertController
  ) {}

  isDateAfter = (date: string) => isAfter(new Date(date), new Date());

  ngOnInit(): void {
    return;
  }

  requestActivationCode() {
    this.userFacade.oneTimePassword(this.ssn.value as string).subscribe({
      next: (response) => (this.otpPayload = { authToken: response?.auth_token, expires: response?.expires }),
      error: (e) => {
        if (e?.error.error.type.includes('UserNotFound')) {
          this.presentAlert('Error', e?.error.error.message);
        }
      },
    });
  }

  verifyActivationCode() {
    this.userFacade
      .oneTimePasswordConfirm(this.otpPayload?.authToken as string, this.activationCode.value as string)
      .subscribe({
        next: ({ token }) => this.modalCtrl.dismiss({ token }, 'confirm'),
        error: (e) => {
          if (e?.error.error.type.includes('MaxFailedAttemptsLimitCrossed')) {
            this.otpPayload = undefined;
            this.ssn.reset();
          }

          this.presentAlert('hestiaPages.alert.errorOccurred', 'hestiaPages.alert.pleaseTryAgain');
        },
      });
  }

  async presentAlert(header: string, message: string) {
    const alert = await this.alertController.create({
      header: this.translocoService.translate(header),
      message: this.translocoService.translate(message),
      buttons: ['OK'],
    });

    await alert.present();
  }
}
