/* eslint-disable @typescript-eslint/naming-convention */
import { get } from 'lodash-es';

import { Type } from '@angular/core';

import { WidgetBaseComponent } from '../../models/widget';
import { IWidgetConfig, WidgetConfig } from '../../models/widget.config';
import { BooleanWidgetComponent } from './boolean-widget.component/boolean-widget.component';
import { BooleanWidgetConfig } from './boolean-widget.component/boolean-widget.config';
import { CheckboxWidgetComponent } from './checkbox-widget.component/checkbox-widget.component';
import { CheckboxWidgetConfig } from './checkbox-widget.component/checkbox-widget.config';
import { DateTimeWidgetComponent } from './datetime-widget.component/datetime-widget.component';
import { DateTimeWidgetConfig } from './datetime-widget.component/datetime-widget.config';
import { GridGroupWidgetComponent } from './grid-group-widget.component/grid-group-widget.component';
import { GridGroupWidgetConfig } from './grid-group-widget.component/grid-group-widget.config';
import { ImageSelectionWidgetComponent } from './image-selection-widget.component/image-selection-widget.component';
import { ImageSelectionWidgetConfig } from './image-selection-widget.component/image-selection-widget.config';
import { LabelWidgetComponent } from './label-widget.component/label-widget.component';
import { LabelWidgetConfig } from './label-widget.component/label-widget.config';
import { LikertRadioGroupWidgetComponent } from './likert-radio-group-widget.component/likert-radio-group-widget.component';
import { LikertRadioGroupWidgetConfig } from './likert-radio-group-widget.component/likert-radio-group-widget.config';
import { NumberWidgetComponent } from './number-widget.component/number-widget.component';
import { NumberWidgetConfig } from './number-widget.component/number-widget.config';
import { RadioGroupWidgetComponent } from './radio-group-widget.component/radio-group-widget.component';
import { RadioGroupWidgetConfig } from './radio-group-widget.component/radio-group-widget.config';
import { RepeatableGroupWidgetComponent } from './repeatable-group-widget.component/repeatable-group-widget.component';
import { RepeatableGroupWidgetConfig } from './repeatable-group-widget.component/repeatable-group-widget.config';
import { SelectionWidgetComponent } from './selection-widget.component/selection-widget.component';
import { SelectionWidgetConfig } from './selection-widget.component/selection-widget.config';
import { SignWidgetComponent } from './sign-widget.component/sign-widget.component';
import { SignWidgetConfig } from './sign-widget.component/sign-widget.config';
import { SliderWidgetComponent } from './slider-widget.component/slider-widget.component';
import { SliderWidgetConfig } from './slider-widget.component/slider-widget.config';
import { StringWidgetComponent } from './string-widget.component/string-widget.component';
import { StringWidgetConfig } from './string-widget.component/string-widget.config';
import { SvgGroupWidgetComponent } from './svg-group-widget.component/svg-group-widget.component';
import { SvgGroupWidgetConfig } from './svg-group-widget.component/svg-group-widget.config';
import { SvgSliderWidgetComponent } from './svg-slider-widget.component/svg-slider-widget.component';
import { SvgSliderWidgetConfig } from './svg-slider-widget.component/svg-slider-widget.config';
import { TextAreaWidgetComponent } from './textarea-widget.component/textarea-widget.component';
import { TextAreaWidgetConfig } from './textarea-widget.component/textarea-widget.config';
import { LikertRadioGroupWidgetWithImageComponent } from './likert-radio-group-widget-with-image.component/likert-radio-group-widget-with-image.component';
import { LikertRadioGroupWidgetWithImageConfig } from './likert-radio-group-widget-with-image.component/likert-radio-group-widget-with-image.config';

export { SvgSliderWidgetConfig } from './svg-slider-widget.component/svg-slider-widget.config';
export { StringWidgetConfig } from './string-widget.component/string-widget.config';
export { LabelWidgetConfig } from './label-widget.component/label-widget.config';
export { LikertRadioGroupWidgetWithImageConfig } from './likert-radio-group-widget-with-image.component/likert-radio-group-widget-with-image.config';
export { LikertRadioGroupWidgetConfig } from './likert-radio-group-widget.component/likert-radio-group-widget.config';
export { NumberWidgetConfig } from './number-widget.component/number-widget.config';
export { RadioGroupWidgetConfig } from './radio-group-widget.component/radio-group-widget.config';
export { RepeatableGroupWidgetConfig } from './repeatable-group-widget.component/repeatable-group-widget.config';
export { SelectionWidgetConfig } from './selection-widget.component/selection-widget.config';
export { SignWidgetConfig } from './sign-widget.component/sign-widget.config';
export { SliderWidgetConfig } from './slider-widget.component/slider-widget.config';
export { SvgGroupWidgetConfig } from './svg-group-widget.component/svg-group-widget.config';
export { TextAreaWidgetConfig } from './textarea-widget.component/textarea-widget.config';
export { DateTimeWidgetConfig } from './datetime-widget.component/datetime-widget.config';
export { GridGroupWidgetConfig } from './grid-group-widget.component/grid-group-widget.config';
export { BooleanWidgetConfig } from './boolean-widget.component/boolean-widget.config';
export { CheckboxWidgetConfig } from './checkbox-widget.component/checkbox-widget.config';
export { ImageSelectionWidgetConfig } from './image-selection-widget.component/image-selection-widget.config';

export const widgetTypes: { [type: string]: Type<WidgetBaseComponent> } = {
  BooleanWidget: BooleanWidgetComponent,
  CheckboxWidget: CheckboxWidgetComponent,
  DateTimeWidget: DateTimeWidgetComponent,
  ImageSelectionWidget: ImageSelectionWidgetComponent,
  LabelWidget: LabelWidgetComponent,
  NumberWidget: NumberWidgetComponent,
  RadioGroupWidget: RadioGroupWidgetComponent,
  LikertRadioGroupWidgetWithImage: LikertRadioGroupWidgetWithImageComponent,
  LikertRadioGroupWidget: LikertRadioGroupWidgetComponent,
  RepeatableGroupWidget: RepeatableGroupWidgetComponent,
  SelectionWidget: SelectionWidgetComponent,
  SignWidget: SignWidgetComponent,
  StringWidget: StringWidgetComponent,
  TextAreaWidget: TextAreaWidgetComponent,
  SliderWidget: SliderWidgetComponent,
  SvgGroupWidget: SvgGroupWidgetComponent,
  SvgSliderWidget: SvgSliderWidgetComponent,
  GridGroupWidget: GridGroupWidgetComponent,
};

export const widgetConfigMapping: {
  [type: string]: Type<WidgetConfig<unknown>>;
} = {
  BooleanWidget: BooleanWidgetConfig,
  CheckboxWidget: CheckboxWidgetConfig,
  DateTimeWidget: DateTimeWidgetConfig,
  ImageSelectionWidget: ImageSelectionWidgetConfig,
  LabelWidget: LabelWidgetConfig,
  NumberWidget: NumberWidgetConfig,
  RadioGroupWidget: RadioGroupWidgetConfig,
  LikertRadioGroupWidgetWithImage: LikertRadioGroupWidgetWithImageConfig,
  LikertRadioGroupWidget: LikertRadioGroupWidgetConfig,
  RepeatableGroupWidget: RepeatableGroupWidgetConfig,
  SelectionWidget: SelectionWidgetConfig,
  SignWidget: SignWidgetConfig,
  StringWidget: StringWidgetConfig,
  TextAreaWidget: TextAreaWidgetConfig,
  SliderWidget: SliderWidgetConfig,
  SvgGroupWidget: SvgGroupWidgetConfig,
  SvgSliderWidget: SvgSliderWidgetConfig,
  GridGroupWidget: GridGroupWidgetConfig,
};

export const widgetConfigFactory = (
  configProps: IWidgetConfig<unknown>,
  values: Record<string, unknown> = null
): WidgetConfig<unknown> => {
  if (values) {
    const oldVal = configProps.value;
    const newVal = get(values, configProps.name, null);
    configProps = {
      ...configProps,
      value: newVal,
    };
  }
  const configClass = widgetConfigMapping[configProps?.widgetType] ?? null;
  if (configClass) {
    return new configClass(configProps);
  }
};
