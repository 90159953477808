import { WidgetTypes } from '../../typings';
import {
  HestiaExtParentElem,
  IHestiaQnrItemExt,
  IQnrItemExtParsedExt,
  QnrItemType,
} from '../extension-base';

export type QnrItemChoiceListOrientation = 'horizontal' | 'vertical' | 'horizontal-image';

export const QNR_ITEM_CHOICE_ORIENTATION_EXT_URL =
  'http://hl7.org/fhir/StructureDefinition/questionnaire-choiceOrientation';

export interface IWidgetChoiceOrientationProps extends Record<string, unknown> {
  choiceListOrientation: QnrItemChoiceListOrientation;
}

export class QnrItemChoiceOrientationExt
  implements IHestiaQnrItemExt<IWidgetChoiceOrientationProps>
{
  readonly url = QNR_ITEM_CHOICE_ORIENTATION_EXT_URL;
  readonly supportedWidgets = [WidgetTypes.LikertRadioGroupWidget, WidgetTypes.LikertRadioGroupWidgetWithImage];
  readonly supportedItemTypes = [QnrItemType.Choice];
  readonly extendsElem = HestiaExtParentElem.QuestionnaireItem;
  json: fhir4.Extension;
  get configProps(): IQnrItemExtParsedExt<IWidgetChoiceOrientationProps> {
    return this.parse();
  }
  constructor(json: fhir4.Extension) {
    this.json = json;
  }
  parse = (): {
    widgetProperties: IWidgetChoiceOrientationProps;
  } => {
    let orientation: QnrItemChoiceListOrientation = null;
    if (this.json?.valueCode) {
      orientation = this.json.valueCode as QnrItemChoiceListOrientation;
    }

    return {
      widgetProperties: {
        choiceListOrientation: orientation,
      },
    };
  };
}

export function getQnrItemChoiceOrientationExt(
  item: fhir4.QuestionnaireItem
): IWidgetChoiceOrientationProps {
  let extensionObj: QnrItemChoiceOrientationExt;
  if (item?.extension) {
    // the FHIR resource is already validated at this point
    item.extension.forEach((extension) => {
      if (extension.url === QNR_ITEM_CHOICE_ORIENTATION_EXT_URL) {
        extensionObj = new QnrItemChoiceOrientationExt(extension);
      }
    });
  }
  return extensionObj !== undefined
    ? extensionObj.parse().widgetProperties
    : { choiceListOrientation: 'vertical' };
}
