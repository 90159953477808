import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { Events } from '../services/events.service';
/* eslint-disable @angular-eslint/directive-selector */
import Keyboard from 'simple-keyboard';
import { Store } from '@ngrx/store';
import { TranslocoService } from '@ngneat/transloco';
import { VIRTUAL_KEYBOARD_SETTINGS } from '../models/typings';
import { selectModuleSettingsState } from '../store/module-settings/module-settings.selectors';

@Directive({
  selector: '[virtualKeyboard]',
})
export class VirtualKeyboardDirective {
  keyboard: Keyboard;
  inputId: string = null;
  keyboardClass: string;
  keyboardType: 'alphanumerical' | 'numerical' = 'alphanumerical';
  capsLock = false;
  enabled = false;
  private _keyboardValue: string;
  @Input('virtualKeyboard') set keyboardValue(settings: {
    value: string;
    keyboardType: 'alphanumerical' | 'numerical';
    inputId?: string;
  }) {
    this._keyboardValue = settings.value;
    this.keyboardType = settings.keyboardType;
    this.inputId = settings.inputId || null;
    if (this.keyboard !== undefined) {
      this.keyboard.setInput(this._keyboardValue);
    }
    this.events.subscribe('virtualKeyboard:open', (openedKeyboardClass) => {
      if (openedKeyboardClass !== this.keyboardClass && this.keyboardClass !== undefined) {
        console.log(`Close virtual keyboard for class ${this.keyboardClass}, inputId ${this.inputId}`);
        this.handleClose();
      }
    });
  }
  @Output() updateVirtualKeyboardValue: EventEmitter<unknown> = new EventEmitter();
  @HostListener('click', ['$event.target']) onClick(input: HTMLTextAreaElement | HTMLInputElement): void {
    if (this.enabled) {
      if (this.inputId === null) {
        console.log(
          'No inputId provided to virtual keyboard directive. Will try to fall back to constructing CSS class using id attribute from input if available (`${inputId}-virtual-keyboard`), else will attempt to use generic virtual-keyboard class'
        );
      }
      const inputId = this.inputId !== null ? this.inputId : input.getAttribute('id');
      if (inputId === null || inputId === undefined) {
        this.keyboardClass = `virtual-keyboard`; // fallback to generic virtual-keyboard class
      } else {
        this.keyboardClass = `${inputId}-virtual-keyboard`;
      }
      console.log(`Open virtual keyboard for: ${this.keyboardClass}`);
      this.displayKeyboard(this.keyboardClass);
      this.events.publish('virtualKeyboard:open', this.keyboardClass);
    }
  }
  @HostListener('focus', ['$event.target']) onFocus(input: HTMLTextAreaElement | HTMLInputElement): void {
    if (this.enabled) {
      if (this.inputId === null) {
        console.log(
          'No inputId provided to virtual keyboard directive. Will try to fall back to constructing CSS class using id attribute from input if available (`${inputId}-virtual-keyboard`), else will attempt to use generic virtual-keyboard class'
        );
      }
      const inputId = this.inputId !== null ? this.inputId : input.getAttribute('id');
      if (inputId === null || inputId === undefined) {
        this.keyboardClass = `virtual-keyboard`; // fallback to generic virtual-keyboard class
      } else {
        this.keyboardClass = `${inputId}-virtual-keyboard`;
      }
      console.log(`Open virtual keyboard for: ${this.keyboardClass}`);
      this.displayKeyboard(this.keyboardClass);
      this.events.publish('virtualKeyboard:open', this.keyboardClass);
    }
  }

  constructor(public transloco: TranslocoService, public events: Events, public store: Store) {
    this.store.select(selectModuleSettingsState).subscribe((settings) => {
      if (this.enabled !== settings.enableVirtualKeyboard && !settings.enableVirtualKeyboard) {
        this.handleClose();
      }
      this.enabled = settings.enableVirtualKeyboard;
    });
  }

  displayKeyboard(keyboardClass: string): void {
    this.handleClose();
    const curLanguage = this.transloco.getActiveLang();
    const langSettings = VIRTUAL_KEYBOARD_SETTINGS[curLanguage] || VIRTUAL_KEYBOARD_SETTINGS['da-DK'];
    this.keyboard = new Keyboard(`.${keyboardClass}`, {
      layout: langSettings[this.keyboardType],
      mergeDisplay: true,
      display: langSettings.display,
      theme: 'simple-keyboard hg-theme-default hg-layout-default',
      buttonTheme: [
        {
          class: 'closeKeyboardButton',
          buttons: '{close}',
        },
      ],
      buttonAttributes: [
        {
          attribute: 'style',
          value: 'background-color: orange; font-weight: bolder;',
          buttons: '{close}',
        },
      ],
      onChange: (input) => this.onChange(input),
      onKeyPress: (button) => this.onKeyPress(button),
      newLineOnEnter: true,
    });
    this.keyboard.setInput(this._keyboardValue);
  }

  onChange = (input: string) => {
    this.updateVirtualKeyboardValue.emit(input);
  };

  onKeyPress = (button: string) => {
    console.log('Button pressed', button);
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === '{shift}' || button === '{lock}') {
      if (button === '{lock}') {
        this.capsLock = !this.capsLock;
      }
      this.handleShift();
    } else if (button === '{close}') {
      this.handleClose();
    } else {
      const currentLayout = this.keyboard.options.layoutName;
      if (currentLayout === 'shift' && !this.capsLock) {
        this.keyboard.setOptions({
          layoutName: 'default',
        });
      }
    }
  };

  handleClose = () => {
    console.log('this.keyboard: ', this.keyboard, ', keyboardClass: ', this.keyboardClass);
    if (this.keyboard !== undefined) {
      try {
        this.keyboard.destroy();
        this.keyboardClass = undefined;
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleShift = () => {
    const currentLayout = this.keyboard.options.layoutName;
    const shiftToggle = currentLayout === 'default' ? 'shift' : 'default';
    this.keyboard.setOptions({
      layoutName: shiftToggle,
    });
  };
}
