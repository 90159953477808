import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { WidgetConfig } from '../models/widget.config';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  toFormGroup(widgets: WidgetConfig<any>[]) {
    const group: { [key: string]: AbstractControl } = {};
    widgets.forEach((widget) => {
      // First we find relevant validators
      const validators = [...widget.validators];
      if (widget.required) {
        validators.push(Validators.required);
      }
      let defaultValue = widget.defaultValue;
      if (defaultValue === null) {
        defaultValue = '';
      }
      if (widget.controlType !== null) {
        if (widget.controlType === 'FormControl') {
          group[widget.name] = new UntypedFormControl(
            widget.value || defaultValue,
            Validators.compose(validators)
          );
        } else if (widget.controlType === 'FormGroup') {
          let widgetValue: Record<string, unknown>;
          if (widget.value == null) {
            widgetValue = {};
          } else {
            widgetValue = widget.value as Record<string, unknown>;
          }
          group[widget.name] = createFormGroup(widget, widgetValue);
        } else if (widget.controlType === 'FormArray') {
          let widgetValue: Array<Record<string, unknown>>;
          if (widget.value == null) {
            widgetValue = [{}];
          } else {
            widgetValue = widget.value as Array<Record<string, unknown>>;
          }
          const nestedFormGroups: UntypedFormGroup[] = [];
          widgetValue.forEach((valueObj) => {
            nestedFormGroups.push(createFormGroup(widget, valueObj));
          });
          group[widget.name] = new UntypedFormArray(nestedFormGroups);
        } else {
          throw new Error(
            `Unknown controlType '${widget.controlType}' for widget named '${widget.name}'`
          );
        }
      }
    });
    return new UntypedFormGroup(group);
  }
}

export function createFormGroup(
  widget: WidgetConfig<unknown>,
  extraValues: Record<string, unknown>
): UntypedFormGroup {
  const childControls = widget.childWidgets.reduce((curVal, childConfig) => {
    curVal[childConfig.name] = new UntypedFormControl(
      childConfig.value || extraValues?.[childConfig.name]
    );
    return curVal;
  }, {}) as { [key: string]: UntypedFormControl };
  return new UntypedFormGroup(childControls);
}
