import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControlName, UntypedFormGroup } from '@angular/forms';

import { RangeCustomEvent } from '@ionic/angular';
import { RangeValue } from '@ionic/core';

import { TranslocoService } from '@ngneat/transloco';

import { Store } from '@ngrx/store';

import { WidgetBaseComponent } from '../../../models/widget';
import { Events } from '../../../services/events.service';
import { HestiaQnrFormSessionService } from '../../../services/qnr-form-session.service';
import { LikertRadioGroupWidgetWithImageConfig } from './likert-radio-group-widget-with-image.config';

@Component({
  selector: 'hestia-likert-radio-group-widget-with-image',
  templateUrl: './likert-radio-group-widget-with-image.component.html',
  styleUrls: ['./likert-radio-group-widget-with-image.component.scss'],
})
export class LikertRadioGroupWidgetWithImageComponent extends WidgetBaseComponent implements OnInit, OnDestroy {
  @Input() config: LikertRadioGroupWidgetWithImageConfig;
  @Input() form: UntypedFormGroup;

  lastEmittedValue: RangeValue;

  lastOption: number;
  constructor(
    transloco: TranslocoService,
    questionnaireService: HestiaQnrFormSessionService,
    store: Store,
    element: ElementRef,
    events: Events
  ) {
    super(transloco, questionnaireService, store, element, events);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onRangeChange(ev: Event) {
    this.lastEmittedValue = (ev as RangeCustomEvent).detail.value;

    // const trueValue = this.config?.options.find(
    //  (e) => e.ordinalValue === this.lastEmittedValue
    //);

    const trueValue = this.config?.options[String(this.lastEmittedValue)];

    this.form.controls[this.config.name].setValue(trueValue.code);
  }

  get isValid() {
    return this.form.controls[this.config.name].valid;
  }
}
