import {
  HestiaFhirResourceTypeName,
  IHestiaCarePlan,
  IHestiaCarePlanActivity,
} from '../types';

export class HestiaCarePlan implements IHestiaCarePlan {
  /* config derived properties */
  subjectId: string;
  subjectType: HestiaFhirResourceTypeName;
  fhirId: string;
  fhirResource: fhir4.CarePlan;
  carePlanResource: fhir4.CarePlan;
  activitiesByCode: Map<string, IHestiaCarePlanActivity>;
  allActivities: Array<IHestiaCarePlanActivity>;
  completedActivityCount: number;
  inProgressActivityCount: number;
  totalActivityCount: number;
  /* Services */
  constructor(public config: IHestiaCarePlan) {
    this.subjectId = config.subjectId;
    this.subjectType = config.subjectType;
    this.fhirId = config.carePlanResource.id;
    this.fhirResource = config.carePlanResource;
    this.carePlanResource = config.carePlanResource;
    this.activitiesByCode = config.activitiesByCode;
    this.allActivities = config.allActivities;
    this.completedActivityCount = config.completedActivityCount;
    this.inProgressActivityCount = config.inProgressActivityCount;
    this.totalActivityCount = config.totalActivityCount;
  }
}
