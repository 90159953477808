<ng-container *ngrxLet="curPage$ as curPage">
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button *ngIf="!modalSessionId" defaultHref="/" color="primary"></ion-back-button>
      </ion-buttons>
      <ion-title>{{ curPage?.session.displayTitle }}</ion-title>
      <ion-buttons slot="end">
        <ion-button *ngIf="modalSessionId" (click)="modalController.dismiss()">Luk</ion-button>
        <ion-menu-button color="primary"></ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-progress-bar value="{{getProgress(curPage)}}"></ion-progress-bar>
  </ion-header>
  <ion-content [fullscreen]="true">
    <ion-grid [fixed]="true">
      <ion-row>
        <ion-col [size]="12">
          <hestia-questionnaire
            *ngIf="curPage.pageWidgets?.length > 0"
            [widgets]="curPage.pageWidgets"
            [submitButtonName]="submitButtonName"
            [activityStatus]="curPage.session.activityStatus"
            [renderType]="curPage.session.renderType"
            [alertWhenEmptySubmit]="curPage.session.alertWhenEmptySubmit"
            (pageSubmitted)="handlePageSubmit($event.event, $event.form, curPage)"
            (formChanged)="handleFormChange($event.form, curPage)"
            (toPrevious)="toPreviousPage($event, curPage)"
          ></hestia-questionnaire>
          <!-- <ng-container *ngIf="sessionService.moduleConfig.enablePageNumber">
            // Page # out of # indicator
            <ion-text style="text-align: center">
              <h4>
                {{ 'IFQ.PAGE_OUT_OF_TOTAL' | transloco: { currentPage:
                getPageNumber(curPage), totalPages: getTotalNrOfPages(curPage) }
                }}
              </h4>
            </ion-text>
          </ng-container> -->
        </ion-col>
        <ion-col [size]="12" class="ion-margin" *ngIf="sessionService.moduleConfig.enableDebugMode">
          <ion-button shape="round" fill="clear" expand="block" (click)="showWidgetConfig = !showWidgetConfig">
            {{ !showWidgetConfig ? 'Show' : 'Hide' }} debug info
          </ion-button>

          <ng-container *ngIf="showWidgetConfig">
            <ion-card>
              <ion-card-header>
                <ion-card-subtitle>Current linkIdPaths on page</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content>
                <p *ngFor="let widget of curPage.pageWidgets">{{ widget.linkIdPath | json }}</p>
              </ion-card-content>
            </ion-card>
            <ion-card>
              <ion-card-header>
                <ion-card-subtitle>All linkIdPaths</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content>
                <div *ngFor="let widget of curPage.session.widgets">{{ widget.linkIdPath | json }}</div>
              </ion-card-content>
            </ion-card>
            <ion-card>
              <ion-card-header>
                <ion-card-subtitle>Page Widgets</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content class="overflowSpace">
                <pre>{{ curPage.pageWidgets | json }}</pre>
              </ion-card-content>
            </ion-card>
          </ng-container>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-container>
