import {
  IStorageSyncProvider,
  StorageSyncProviderBase,
} from './storage-provider.interface';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

export class CapacitorStorageSyncProvider extends StorageSyncProviderBase
  implements IStorageSyncProvider {
  public STORAGE_KEY: string;
  constructor(storageKey: string) {
    super();
    this.STORAGE_KEY = storageKey
  }

  fetchState(): Promise<void | {}> {
    return Storage.get({ key: this.STORAGE_KEY })
      .then((s) => {
        const value = s.value;
        if (typeof value === 'string') {
          return JSON.parse(value) || {}; // Our NGRX state will always be an object
        } else {
          return value || {}; // Our NGRX state will always be an object
        }
      })
      .catch((err) => {});
  }

  saveState(state: any, keys: string[]): Promise<void> {
    // Pull out the portion of the state to save.
    if (keys) {
      state = keys.reduce((acc, k) => {
        const val = this.getNested(state, k);
        if (val) {
          this.setNested(acc, k, val);
        }
        return acc;
      }, {});
    }
    return Storage.set({ key: this.STORAGE_KEY, value: JSON.stringify(state) });
  }
}
