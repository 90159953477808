<div [formGroup]="form">
  <hestia-widget-group-header
    [config]="config"
    [i18n]="i18n"
  ></hestia-widget-group-header>
  <!-- We cannot conditionally set a directive in Agular, here 'radio-group', so we have to have use a switch statement-->
  <ion-list [id]="config.name" lines="full" text-wrap>
    <ion-list-header>
      <hestia-widget-header
        [config]="config"
        [i18n]="i18n"
      ></hestia-widget-header>
    </ion-list-header>
    <ion-item *ngFor="let option of config.options">
      <ion-checkbox
        slot="end"
        [id]="config.name + '|' + option.code"
        (ngModelChange)="checkboxValueChange($event, option)"
        [(ngModel)]="values[option.code]"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="isCheckboxDisabled(option.code)"
      ></ion-checkbox>
      <ion-label>
        {{
          i18n({
            translate: option.i18nName,
            fallback: option.display,
            type: 'i18nName'
          })
        }}
      </ion-label>
    </ion-item>
  </ion-list>
</div>
