import { Optional } from 'utility-types';

import { InjectionToken } from '@angular/core';

import { StoreConfig } from '@ngrx/store';

import { IQnrFormSessionModuleConfig } from './models/typings';
import { IWidgetConfig } from './models/widget.config';
import { IUserControlledSettingsState } from './store/module-settings/module-settings.reducer';
import { calculateSessionValues } from './utils/calculate-session-values';

export interface QnrFormModuleSettings {
  config: IQnrFormSessionModuleConfig;
  userSettingsInitalState: IUserControlledSettingsState;
  translations?: { [key: string]: unknown };
}

export const QNR_CORE_MODULE_CONFIG = new InjectionToken<IQnrFormSessionModuleConfig>('Module config');

export const QNR_USER_CONTROLLED_SETTINGS_INITIAL_STATE = new InjectionToken<StoreConfig<IUserControlledSettingsState>>(
  'Module user controlled settings - initial state'
);

export const QNR_CORE_MODULE_TRANSLATIONS = new InjectionToken('Module translations');

export const ADAPTIVE_QUESTIONNAIRE_RESPONSE_PROFILE =
  'http://hl7.org/fhir/uv/sdc/StructureDefinition/sdc-questionnaireresponse-adapt';

export const ADAPTIVE_QUESTIONNAIRE_PROFILE = 'http://hl7.org/fhir/uv/sdc/StructureDefinition/sdc-questionnaire-adapt';

export type RenderType = 'full' | 'fullEditable' | 'page' | 'widget';

export interface IQnrFormSession {
  isLast?: string;
  id: string;
  isAdaptive: boolean;
  questionnaireId: string;
  patientId: string;
  /** The ID of the associated FHIR CarePaan resource */
  carePlanId: string;
  carePlanStatus: string;
  activityStatus: string;
  activityCode: string;
  values: Record<string, unknown>;
  questionnaireResponse: fhir4.QuestionnaireResponse;
  doesQnrResponseExistOnServer: boolean;
  /** initDate is automatically set when the questionnaire form session is initiated */
  readonly initDate: string;
  firstAnswerDate: string;
  latestAnswerDate: string;
  localVariables: { [key: string]: { value?: string; global: boolean } };
  /** A reference to the FHIR Patient/Practioner/RelatedPerson resource that the QuestionnaireResponse concerns */
  subjectRef: fhir4.Reference;
  /** A reference to the FHIR Patient/Practioner/RelatedPerson resource that recorded the QuestionnaireResponse */
  authorRef: fhir4.Reference;
  /** A reference to the associated FHIR patient resource that answered the QuestionnaireResponse (othen same as authorId) */
  sourceRef: fhir4.Reference;
  renderType: RenderType;
  /**
   * The widget configs calculated based on the FHIR questionnaire.
   * Note that they must be transformed to the WidgetConfig class before use in the form engine
   */
  widgets: IWidgetConfig<unknown>[];
  /** initCompleted marks that widgets for the form session have been calculated succesfully */
  initCompleted: boolean;
  /** The current linkIdPath that the user has reached or activated */
  curLinkIdPath: string; // TODO: Implement guard that presents user from navigating to hidden fields or skip required fields before curLinkIdPath
  displayTitle: string;
  allowMenu: boolean;
  /** Display an alert when user attempts to submit a page with one or more missing answers */
  alertWhenEmptySubmit: boolean;
  responseExtensions: fhir4.Extension[];
  responseProfiles: string[];
  /** Any additional metadata you want to store about the questionnaire session */
  metadata: Record<string, unknown>;
}

export type InitFormSessionPayload = Optional<
  Pick<
    IQnrFormSession,
    | 'id'
    | 'isAdaptive'
    | 'questionnaireId'
    | 'patientId'
    | 'carePlanId'
    | 'carePlanStatus'
    | 'activityStatus'
    | 'activityCode'
    | 'values'
    | 'questionnaireResponse'
    | 'doesQnrResponseExistOnServer'
    | 'subjectRef'
    | 'authorRef'
    | 'sourceRef'
    | 'renderType'
    | 'displayTitle'
    | 'localVariables'
    | 'allowMenu'
    | 'curLinkIdPath'
    | 'alertWhenEmptySubmit'
    | 'responseExtensions'
    | 'responseProfiles'
    | 'metadata'
  >,
  | 'values'
  | 'questionnaireResponse'
  | 'localVariables'
  | 'allowMenu'
  | 'alertWhenEmptySubmit'
  | 'responseExtensions'
  | 'responseProfiles'
  | 'metadata'
>;

export const createSessionFromInitPayload = (initConfig: InitFormSessionPayload): IQnrFormSession => {
  const session: IQnrFormSession = {
    id: initConfig.id,
    isAdaptive: initConfig.isAdaptive,
    questionnaireId: initConfig.questionnaireId,
    patientId: initConfig.patientId,
    carePlanId: initConfig.carePlanId,
    carePlanStatus: initConfig.carePlanStatus,
    activityStatus: initConfig.activityStatus,
    activityCode: initConfig.activityCode,
    values: calculateSessionValues(initConfig.questionnaireResponse, initConfig.values),
    questionnaireResponse: initConfig.questionnaireResponse ?? null,
    doesQnrResponseExistOnServer: initConfig.doesQnrResponseExistOnServer,
    initDate: new Date().toISOString(),
    firstAnswerDate: null,
    latestAnswerDate: null,
    localVariables: initConfig.localVariables ?? {},
    subjectRef: initConfig.subjectRef,
    authorRef: initConfig.authorRef,
    sourceRef: initConfig.sourceRef,
    renderType: initConfig.renderType,
    initCompleted: false,
    widgets: null,
    curLinkIdPath: null,
    displayTitle: initConfig.displayTitle,
    allowMenu: initConfig.allowMenu ?? true,
    alertWhenEmptySubmit: initConfig.alertWhenEmptySubmit ?? false,
    responseExtensions: initConfig.responseExtensions ?? [],
    responseProfiles: initConfig.responseProfiles ?? [],
    metadata: initConfig.metadata ?? {},
  };
  return session;
};

/** Use this as linkIdPath for QnrFormPage to ensure you start from first page */
export const FIRST_QUESTION_LINK_ID_PATH = '___hestia_first___';

/** FHIR Extension URL for enableValueHistoryExt */
export const ENABLE_VALUE_HISTORY_QNR_RESPONSE_EXT_URL =
  'http://zitelab.eu/fhir/extensions/QuestionnaireResponseValueHistory';

/** FHIR Extension URL, used for tracking latest item's linkIdPath in QuestionnaireResponse to adaptive questionnaire */
export const LATEST_ITEM_IN_ADAPTIVE_RESPONSE_EXT_URL =
  'http://zitelab.eu/fhir/extensions/QuestionnaireResponseLatestItemInAdaptiveResponse';

/** FHIR Extension URL, used for tracking latest top-level group item in QuestionnaireResponse to adaptive questionnaire */
export const LATEST_TOP_LEVEL_GROUP_ITEM_IN_ADAPTIVE_RESPONSE_EXT_URL =
  'http://zitelab.eu/fhir/extensions/QuestionnaireResponseLatestTopLevelGroupItemInAdaptiveResponse';

/** FHIR Extension URL for enableValueHistoryExt */
export const HESTIA_QUESTIONNAIRE_RESPONSE_START_TIME_EXT_URL =
  'https://fhir.zitelab.eu/extensions/hestia-questionnaire-response-start-time';

/** Link ID path seperator */
export const LINK_ID_SEPERATOR = '$$$';
