import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

import { Events } from '../../../services/events.service';
import { HestiaQnrFormSessionService } from '../../../services/qnr-form-session.service';
import { NumberWidgetConfig } from './number-widget.config';
import { Store } from '@ngrx/store';
import { TranslocoService } from '@ngneat/transloco';
import { UntypedFormGroup } from '@angular/forms';
import { WidgetBaseComponent } from '../../../models/widget';

@Component({
  selector: 'hestia-number-widget',
  templateUrl: './number-widget.component.html',
  styleUrls: ['./number-widget.component.scss'],
})
export class NumberWidgetComponent
  extends WidgetBaseComponent
  implements OnInit, OnDestroy
{
  @Input() config: NumberWidgetConfig;
  @Input() form: UntypedFormGroup;
  constructor(
    transloco: TranslocoService,
    questionnaireService: HestiaQnrFormSessionService,
    store: Store,
    element: ElementRef,
    events: Events
  ) {
    super(transloco, questionnaireService, store, element, events);
  }
  get isValid() {
    return this.form.controls[this.config.name].valid;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  updateFormValue(value) {
    this.form.controls[this.config.name].setValue(value);
  }

  newVirtualKeyboardValue(value) {
    this.updateFormValue(value);
  }

  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
