// Import of store actions
import { OnDestroy, OnInit, ElementRef, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';

import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { Events } from '../services/events.service';
import { HestiaQnrFormSessionService } from '../services/qnr-form-session.service';
import { updateSessionSettings } from '../store/qnr-form-session/qnr-form-session.actions';
import {
  selectAllQnrFormSessions,
  selectQnrFormSessionById,
} from '../store/qnr-form-session/qnr-form-session.selectors';
import { IAdditionalQuestionnaireSettings } from './typings';
import { WidgetConfig } from './widget.config';

@Component({
  selector: 'hestia-widget',
  template: '<div>Abstract widget, template should never render</div>',
})
export class WidgetBaseComponent implements OnInit, OnDestroy {
  config: WidgetConfig<unknown>;
  form: UntypedFormGroup;
  questionnaireSettings: IAdditionalQuestionnaireSettings;
  private unsubscribe = new Subject();
  constructor(
    public transloco: TranslocoService,
    private questionnaireService: HestiaQnrFormSessionService,
    private store: Store,
    public element: ElementRef,
    public events: Events
  ) {}

  ngOnInit() {
    if (this.config?.setLocalVariable) {
      this.setLocalVariableHandler();
    }
    if (this.config?.varInterpolation) {
      this.varInterpolationHandler();
    }
    if (this.config?.suggestedValueConfig) {
      this.suggestedValueHandler();
    }
    if (this.config?.enableWhen || this.config?.enableWhenFunction) {
      this.triggerDynamicEnableWhen();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  triggerDynamicEnableWhen(): void {
    this.store
      .select(selectQnrFormSessionById(this.config.metadata.sessionId))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((session) => {
        const formValues = session?.values ?? {};
        const result = this.config.isShown(formValues, null);
        this.config.shown = result;
        const natElem = this.element.nativeElement;
        natElem.hidden = !this.config.shown;
      });
  }

  setLocalVariableHandler(): void {
    const widgetForm = this.form.get(this.config.name);
    if (widgetForm !== null) {
      // widgetForm is null for LabelWidget etc
      this.form
        .get(this.config.name)
        .valueChanges.pipe(takeUntil(this.unsubscribe))
        .pipe(
          withLatestFrom(
            this.store.select(
              selectQnrFormSessionById(this.config.metadata.sessionId)
            )
          )
        )
        .subscribe(([value, session]) => {
          this.store.dispatch(
            updateSessionSettings({
              session: {
                id: session.id,
                changes: {
                  localVariables: {
                    ...session.localVariables,
                    [this.config.setLocalVariable.varName]: {
                      value: value,
                      global: this.config.setLocalVariable.isGlobal,
                    },
                  },
                },
              },
            })
          );
        });
    }
  }

  varInterpolationHandler(): void {
    this.store
      .select(selectAllQnrFormSessions)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((sessions) => {
        const varInterpolation = this.config.varInterpolation;
        const oldValue = varInterpolation.curValue;
        let newValue = varInterpolation.defaultValue;
        const curQ = sessions.find(
          (x) =>
            x.id === this.config.metadata.sessionId &&
            x.carePlanId === this.config.metadata.carePlanId
        );
        if (curQ?.localVariables?.[varInterpolation.varName]) {
          newValue = curQ.localVariables[varInterpolation.varName].value;
        } else {
          let allGlobalVars = {};
          sessions.forEach((elem) => {
            if (elem.localVariables !== null) {
              // TODO: We are missing check that respects if the local variable is not set with global = true
              allGlobalVars = { ...allGlobalVars, ...elem.localVariables };
            }
          });
          if (allGlobalVars?.[varInterpolation.varName]) {
            newValue = allGlobalVars[varInterpolation.varName].value;
          }
        }
        if (newValue !== oldValue) {
          this.config.varInterpolation = {
            ...this.config.varInterpolation,
            curValue: newValue,
          };
        }
      });
  }

  suggestedValueHandler(): void {
    if (this.config.suggestedValueConfig.valueType === 'value') {
      return;
    }
    this.store
      .select(selectAllQnrFormSessions)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((questionnaires) => {
        const curQ = questionnaires.find(
          (x) => x.id === this.config.metadata.sessionId
        );
        const valueInput = this.config.suggestedValueConfig.valueInput;
        if (curQ?.localVariables?.[valueInput]) {
          this.config.suggestedValueConfig.value =
            curQ.localVariables[valueInput].value;
        } else {
          let allGlobalVars = {};
          questionnaires.forEach((elem) => {
            if (elem.localVariables !== null) {
              // TODO: We are missing check that respects if the local variable is not set with global = true
              allGlobalVars = { ...allGlobalVars, ...elem.localVariables };
            }
          });
          if (allGlobalVars?.[valueInput]) {
            this.config.suggestedValueConfig.value =
              allGlobalVars[valueInput].value;
          }
        }
      });
  }

  i18n = (args: {
    translate: string;
    fallback?: string;
    type?: 'configParam' | 'i18nName';
    config?: WidgetConfig<any>; // Allows us to pass the config of a childWidget
  }): string => {
    args.fallback = args.fallback ?? args.translate;
    args.type = args.type ?? 'configParam';
    args.config = args.config ?? this.config;
    const i18nIdentifier: string =
      args.config.translations[args.translate] ?? args.translate;
    let fallbackString: string;
    if (args.type === 'configParam') {
      if (!args.config.translations?.[args.translate]) {
        return args.fallback !== args.translate
          ? args.fallback
          : this[args.translate];
      }
      fallbackString =
        args.config[args.fallback] !== undefined
          ? args.config[args.fallback]
          : args.fallback;
    } else if (args.type === 'i18nName') {
      fallbackString = args.fallback;
    } else {
      throw Error(`Unknown translation type ${args.type}`);
    }
    const translated = this.transloco.translate(i18nIdentifier);
    if (translated === i18nIdentifier) {
      return fallbackString;
    } else {
      return translated;
    }
  };
}
