import { QnrItemChoiceListOrientation } from '../../../models/fhir-extensions/questionnaire-item/item-choice-orientation.fhir-ext';
import { WidgetConfig } from '../../../models/widget.config';

export class LikertRadioGroupWidgetConfig extends WidgetConfig<string> {
  widgetType = 'LikertRadioGroupWidget';
  choiceListOrientation: QnrItemChoiceListOrientation;
  hideOptionLabels: boolean;
  leftLabel: string;
  rightLabel: string;
  constructor(
    config: Partial<
      WidgetConfig<any> & {
        choiceListOrientation: QnrItemChoiceListOrientation;
        hideOptionLabels: boolean;
        leftLabel: string;
        rightLabel: string;
      }
    >
  ) {
    super(config);
    this.multiple = false;
    this.choiceListOrientation = config.choiceListOrientation ?? null;
    this.leftLabel = config.leftLabel ?? null;
    this.rightLabel = config.rightLabel ?? null;
    this.hideOptionLabels = config.hideOptionLabels ?? null;
  }
}
