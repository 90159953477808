import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { HestiaFhirResource } from '@hestia/ngx-types';

export const loadFhirResources = createAction(
  '[FhirResource/API] Load FhirResources',
  props<{ fhirResources: HestiaFhirResource[] }>()
);

export const addFhirResource = createAction(
  '[FhirResource/API] Add FhirResource',
  props<{ fhirResource: HestiaFhirResource }>()
);

export const upsertFhirResource = createAction(
  '[FhirResource/API] Upsert FhirResource',
  props<{ fhirResource: HestiaFhirResource }>()
);

export const addFhirResources = createAction(
  '[FhirResource/API] Add FhirResources',
  props<{ fhirResources: HestiaFhirResource[] }>()
);

export const upsertFhirResources = createAction(
  '[FhirResource/API] Upsert FhirResources',
  props<{ fhirResources: HestiaFhirResource[] }>()
);

export const updateFhirResource = createAction(
  '[FhirResource/API] Update FhirResource',
  props<{ fhirResource: Update<HestiaFhirResource> }>()
);

export const updateFhirResources = createAction(
  '[FhirResource/API] Update FhirResources',
  props<{ fhirResources: Update<HestiaFhirResource>[] }>()
);

export const deleteFhirResource = createAction(
  '[FhirResource/API] Delete FhirResource',
  props<{ id: string }>()
);

export const deleteFhirResources = createAction(
  '[FhirResource/API] Delete FhirResources',
  props<{ ids: string[] }>()
);

export const clearFhirResources = createAction(
  '[FhirResource/API] Clear FhirResources'
);

export const fhirResourceActions = {
  loadFhirResources,
  addFhirResource,
  upsertFhirResource,
  addFhirResources,
  upsertFhirResources,
  updateFhirResource,
  updateFhirResources,
  deleteFhirResource,
  deleteFhirResources,
  clearFhirResources,
};
