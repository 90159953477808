import { WidgetTypes } from '../../typings';
import {
  HestiaExtParentElem,
  IHestiaQnrItemExt,
  IQnrItemExtParsedExt,
  QnrItemType,
} from '../extension-base';

export const QNR_ITEM_CHOICE_HIDE_LABELS_EXT_URL =
  'http://hl7.org/fhir/StructureDefinition/questionnaireItem-choiceHideOptionLabels';

export interface IWidgetChoiceHideOptionLabelProps
  extends Record<string, unknown> {
  hideOptionLabels: boolean;
}

export class QnrItemChoiceHideOptionLabelsExt
  implements IHestiaQnrItemExt<IWidgetChoiceHideOptionLabelProps>
{
  readonly extendsElem = HestiaExtParentElem.QuestionnaireItem;
  readonly url = QNR_ITEM_CHOICE_HIDE_LABELS_EXT_URL;
  readonly supportedItemTypes = [QnrItemType.Choice];
  readonly supportedWidgets = [WidgetTypes.LikertRadioGroupWidget, WidgetTypes.LikertRadioGroupWidgetWithImage];
  json: fhir4.Extension;
  get configProps(): IQnrItemExtParsedExt<IWidgetChoiceHideOptionLabelProps> {
    return this.parse();
  }
  constructor(json: fhir4.Extension) {
    this.json = json;
  }

  parse = (): {
    widgetProperties: IWidgetChoiceHideOptionLabelProps;
  } => {
    let hideOptionLabels = false;
    if (this.json?.valueBoolean) {
      hideOptionLabels = this.json.valueBoolean;
    }

    return {
      widgetProperties: {
        hideOptionLabels,
      },
    };
  };
}

export function getItemChoiceHideOptionLabels(item: fhir4.QuestionnaireItem): {
  hideOptionLabels: boolean;
} {
  let extensionObj: QnrItemChoiceHideOptionLabelsExt;
  if (item?.extension) {
    // the FHIR resource is already validated at this point
    item.extension.forEach((extension) => {
      if (extension.url === QNR_ITEM_CHOICE_HIDE_LABELS_EXT_URL) {
        extensionObj = new QnrItemChoiceHideOptionLabelsExt(extension);
      }
    });
  }
  return extensionObj !== undefined
    ? extensionObj.configProps.widgetProperties
    : { hideOptionLabels: false };
}
