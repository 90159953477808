import { InjectionToken } from '@angular/core';

export interface IPagesConfig {
  /** Name of Application  */
  name?: string;
  /** Client Contact  */
  contactList?: { name?: string; contactVia?: string }[];
  /** Cards  */
  cards?: { title?: string; description?: string }[];
}

export const PAGES_CONFIG = new InjectionToken<IPagesConfig>('PAGES_CONFIG');

export const PAGES_I18N_SCOPE = 'hestiaPages';
