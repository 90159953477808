import { Pipe, PipeTransform } from '@angular/core';

import { WidgetTypes } from '../../typings';
import { LegacyQnrItemExtBase, IQnrItemExtParsedExt } from '../extension-base';

export class QuestionnaireItemVarInterpolation extends LegacyQnrItemExtBase {
  /*
   *  {
   *    "url": "http://zitelab.eu/fhir/extensions/QuestionnaireItemVariableStringInterpolation",
   *    "extension": [
   *      {
   *        "url": "default",
   *        "valueString": "Use your family's word for vomiting"
   *      }
   *    ],
   *    "valueString": "familyWordForVomiting"
   *  }
   */
  extensionUrl =
    'http://zitelab.eu/fhir/extensions/QuestionnaireItemVariableStringInterpolation';
  supportedWidgets = [WidgetTypes.All];
  constructor(config: Record<string, unknown> = {}) {
    super(config);
  }

  parse = (): IQnrItemExtParsedExt => {
    const varName = this.extensionJson.valueString;
    let defaultValue: string = null;
    if (this.extensionJson?.extension) {
      this.extensionJson.extension.forEach((extension) => {
        if (extension.url === 'default') {
          defaultValue = extension.valueString;
        }
      });
    }
    return {
      widgetProperties: {
        varInterpolation: {
          varName,
          defaultValue,
        },
      },
    };
  };
}

@Pipe({ name: 'varInterpolation' })
export class VarInterpolationPipe implements PipeTransform {
  transform(
    value: string,
    interpolation: {
      varName: string;
      defaultValue: string;
      curValue?: string;
    },
    isMarkdown: boolean = false
  ): string {
    if (interpolation === null) {
      return value;
    }
    const strToReplace = '${' + interpolation.varName + '}';
    let interpolateValue = interpolation?.curValue
      ? interpolation.curValue
      : interpolation.defaultValue;
    if (isMarkdown) {
      interpolateValue = `<span class="${
        interpolation.varName + '-var-interpolation'
      }">${interpolateValue}</span>`;
    }
    const newValue = value.split(strToReplace).join(interpolateValue);
    return newValue;
  }
}

export function getVariableInterpolationExt(item: fhir4.QuestionnaireItem) {
  let extensionObj: QuestionnaireItemVarInterpolation;
  if (item?.extension) {
    // the FHIR resource is already validated at this point
    item.extension.forEach((extension) => {
      if (
        extension.url ===
        'http://zitelab.eu/fhir/extensions/QuestionnaireItemVariableStringInterpolation'
      ) {
        extensionObj = new QuestionnaireItemVarInterpolation({
          extensionJson: extension,
        });
      }
    });
  }
  return extensionObj !== undefined
    ? extensionObj.parse().widgetProperties
    : {};
}
