<div class="ion-padding">
  <hestia-widget-group-header
    [config]="config"
    [i18n]="i18n"
  ></hestia-widget-group-header>
  <p [attr.for]="config.label">
    <hestia-widget-header
      [config]="config"
      [i18n]="i18n"
    ></hestia-widget-header>
  </p>
</div>
