import { Injectable, Inject } from '@angular/core';

import { defer, from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { createEffect } from '@ngrx/effects';
import { ActionReducer } from '@ngrx/store';

import {
  StorageSyncModuleSettings,
  StorageSyncOptions,
  STORAGE_SYNC_MODULE_SETTINGS,
} from './constants';
import { CapacitorStorageSyncProvider } from './storage-providers/capacitor';
import { LocalForageStorageSyncProvider } from './storage-providers/local-forage';
import { IStorageSyncProvider } from './storage-providers/storage-provider.interface';

export const storageSyncActions = {
  hydrated: 'HESTIA_APP_HYDRATED',
};

@Injectable()
export class StorageSyncEffects {
  provider: IStorageSyncProvider;
  constructor(
    @Inject(STORAGE_SYNC_MODULE_SETTINGS)
    private settings: StorageSyncModuleSettings
  ) {
    this.provider =
      settings.syncProvider === 'local-forage'
        ? new LocalForageStorageSyncProvider(settings.storageKey)
        : new CapacitorStorageSyncProvider(settings.storageKey);
  }

  hydrate$: Observable<unknown> = createEffect(() =>
    // eslint-disable-next-line arrow-body-style
    {
      return defer(() =>
        from(this.provider.fetchState()).pipe(
          map((state) => ({
            type: storageSyncActions.hydrated,
            payload: state,
          })),
          catchError((e) => {
            console.warn(`error fetching data from store for hydration: ${e}`);

            return of({
              type: storageSyncActions.hydrated,
              payload: {},
            });
          })
        )
      );
    }
  );
}

const defaultOptions: StorageSyncOptions = {
  keys: [],
  ignoreActions: [],
  onSyncError: (err) => {},
};

export function storageSync(
  provider: IStorageSyncProvider,
  options?: StorageSyncOptions
) {
  const { keys, ignoreActions, hydratedStateKey, onSyncError } = Object.assign(
    {},
    defaultOptions,
    options || {}
  );

  ignoreActions.push(storageSyncActions.hydrated);
  ignoreActions.push('@ngrx/store/init');
  ignoreActions.push('@ngrx/effects/init');
  ignoreActions.push('@ngrx/store/update-reducers');
  ignoreActions.push('@ngrx/router-store/request');
  ignoreActions.push('@ngrx/router-store/init');
  ignoreActions.push('@ngrx/router-store/navigation');
  ignoreActions.push('@ngrx/router-store/navigated');
  ignoreActions.push('@ngrx/store-devtools/recompute');

  const hydratedState: Record<string, unknown> = {};

  return (reducer: ActionReducer<any>) => (state: any, action: any) => {
    const { type, payload } = action;
    if (type === storageSyncActions.hydrated) {
      state = Object.assign({}, state, payload);
      if (hydratedStateKey) {
        hydratedState[hydratedStateKey] = true;
      }
    }

    const nextState = Object.assign({}, reducer(state, action), hydratedState);

    if (ignoreActions.indexOf(type) === -1) {
      provider.saveState(nextState, keys).catch((err) => onSyncError(err));
    }

    return nextState;
  };
}
