import { Component, HostListener, Inject, OnInit } from '@angular/core';

import { AlertController, LoadingController, NavController } from '@ionic/angular';
import { HTMLIonOverlayElement } from '@ionic/core';

import { TranslocoService } from '@ngneat/transloco';

import { combineLatest, firstValueFrom, map, Observable, Subject, switchMap, tap } from 'rxjs';

import { Store } from '@ngrx/store';

import { fhirResourceActions, selectFhirResourceState } from '@hestia/ngx-fhir';
import {
  qnrFormSessionActions,
  qnrModuleSettingsActions,
  selectModuleSettingsState,
  selectQnrFormSessionsState,
} from '@hestia/ngx-fhir-qnr-forms';
import { selectUserState, userActions } from '@hestia/ngx-user';

import { PAGES_CONFIG, IPagesConfig } from '../../constants';

@Component({
  selector: 'hestia-app-shell',
  templateUrl: 'app-shell.html',
  styles: [
    `
      ion-split-pane {
        --side-width: 350px;
      }
    `,
  ],
})
export class AppShellComponent implements OnInit {
  userState$: Observable<any>;
  moduleSettingsState$: Observable<any>;
  currentLanguage: string;
  availableLanguages: any;
  timerAlert: HTMLIonAlertElement;
  userActivity: NodeJS.Timeout;
  userExit: NodeJS.Timeout;
  userInactive: Subject<any> = new Subject();

  constructor(
    @Inject(PAGES_CONFIG) public config: IPagesConfig,
    private store: Store,
    private alertController: AlertController,
    private navController: NavController,
    private loadingController: LoadingController,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.userState$ = this.store.select(selectUserState);
    this.moduleSettingsState$ = this.store.select(selectModuleSettingsState);
    this.initTimeout();
    this.getLoadingStates();
    this.availableLanguages = this.translocoService.getAvailableLangs();
    this.currentLanguage = this.translocoService.getActiveLang();
  }

  async handleSignOut(): Promise<void> {
    const userState = await firstValueFrom(this.userState$);
    if (userState.kioskMode) {
      this.store.dispatch(userActions.resetState());
    } else {
      this.store.dispatch(userActions.logoutFixed());
    }
  }

  handleKioskReset(): void {
    this.store.dispatch(userActions.fullResetState());
  }

  handleKeyboardToggle = (event: any): void => {
    this.store.dispatch(
      qnrModuleSettingsActions.updateSettings({
        enableVirtualKeyboard: event.detail.checked,
      })
    );
  };

  refresh(): void {
    location.reload();
  }

  async getLoadingStates() {
    combineLatest([this.store.select(selectUserState), this.store.select(selectQnrFormSessionsState)])
      .pipe(
        tap(async ([userState, qnrFormSessionsState]) => {
          const loaderAlert = await this.alertController.create({
            header: this.translocoService.translate('hestiaPages.appShell.loading'),
            message: `<ion-spinner name="crescent" color="primary"></ion-spinner>`,
            backdropDismiss: false,
            buttons: [
              {
                text: this.translocoService.translate('hestiaPages.appShell.loadingStuck'),
                role: 'dismiss',
                handler: async () => {
                  await this.dismissAllOverlays();
                  await this.handleSignOut();
                },
              },
            ],
          });

          /* Detect Loading State */
          if (userState.loading || qnrFormSessionsState.loading) {
            loaderAlert.present();
          } else {
            this.dismissAllOverlays();

            // const exisitingLoader = await this.alertController.getTop();

            // if (exisitingLoader !== undefined) {
            //   await this.alertController.dismiss();
            // }
          }
        })
      )
      .subscribe();
  }

  async dismissAllOverlays() {
    const overlays = document.querySelectorAll(
      'ion-alert, ion-action-sheet, ion-loading, ion-modal, ion-picker, ion-popover, ion-toast'
    );
    const overlaysArr = Array.from(overlays) as HTMLIonOverlayElement[];
    overlaysArr.forEach((o) => o.dismiss());
  }

  async initTimeout() {
    this.setTimeout();
    this.userInactive.subscribe(async () => {
      const userState = await firstValueFrom(this.userState$);

      if (userState.kioskMode && userState.ssn) {
        this.presentAlert();
      }
    });
  }

  setTimeout() {
    const inactiveMinutes = 5;

    this.userActivity = setTimeout(() => this.userInactive.next(undefined), inactiveMinutes * 60000);
  }

  @HostListener('window:mousemove')
  @HostListener('window:click')
  @HostListener('window:keypress')
  @HostListener('window:scroll')
  refreshUserState() {
    clearTimeout(this.userActivity);
    clearTimeout(this.userExit);
    this.timerAlert?.dismiss();
    this.setTimeout();
  }

  async presentAlert() {
    const afterInactiveMinutes = 5;

    this.userExit = setTimeout(() => {
      this.timerAlert?.dismiss();
      this.store.dispatch(userActions.resetState());
    }, afterInactiveMinutes * 60000);

    this.timerAlert = await this.alertController.create({
      header: this.translocoService.translate('hestiaPages.appShell.inactivityHeader'),
      message: this.translocoService.translate('hestiaPages.appShell.inactivityMessage'),
    });

    await this.timerAlert.present();
  }

  changeLanguage(event: any) {
    this.translocoService.setActiveLang(event?.detail?.value);
  }
}
