import {
  IStorageSyncProvider,
  StorageSyncProviderBase,
} from './storage-provider.interface';

import localForage from 'localforage';

export class LocalForageStorageSyncProvider
  extends StorageSyncProviderBase
  implements IStorageSyncProvider
{
  constructor(private storageKey: string) {
    super();
  }

  fetchState(): Promise<void | Record<string, unknown>> {
    return localForage
      .getItem(this.storageKey)
      .then((value) => {
        if (typeof value === 'string') {
          return JSON.parse(value) || {}; // Our NGRX state will always be an object
        } else {
          return value || {}; // Our NGRX state will always be an object
        }
      })
      .catch((_err) => {});
  }

  saveState(state: any, keys: string[]): Promise<string> {
    // Pull out the portion of the state to save.
    if (keys) {
      state = keys.reduce((acc, k) => {
        const val = this.getNested(state, k);
        if (val) {
          this.setNested(acc, k, val);
        }
        return acc;
      }, {});
    }
    return localForage.setItem(this.storageKey, state);
  }
}
