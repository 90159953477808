import {
  Component,
  Input,
  ElementRef,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';

import { Observable, startWith } from 'rxjs';

import { Store } from '@ngrx/store';

import { OptionObject } from '../../../models/typings';
import { WidgetBaseComponent } from '../../../models/widget';
import { WidgetConfig } from '../../../models/widget.config';
import { Events } from '../../../services/events.service';
import { HestiaQnrFormSessionService } from '../../../services/qnr-form-session.service';
import { GridGroupWidgetConfig } from './grid-group-widget.config';

@Component({
  selector: 'hestia-grid-group-widget',
  templateUrl: './grid-group-widget.component.html',
  styleUrls: ['./grid-group-widget.component.scss'],
})
export class GridGroupWidgetComponent
  extends WidgetBaseComponent
  implements OnInit
{
  @Input() config: GridGroupWidgetConfig;
  @Input() form: UntypedFormGroup;
  formValues$: Observable<{ [key: string]: { [key: string]: unknown } }>;
  constructor(
    transloco: TranslocoService,
    questionnaireService: HestiaQnrFormSessionService,
    store: Store,
    element: ElementRef,
    events: Events,
    public changeDectection: ChangeDetectorRef
  ) {
    super(transloco, questionnaireService, store, element, events);
  }
  get isValid() {
    return true;
  }

  ngOnInit() {
    super.ngOnInit();
    this.formValues$ = this.form.valueChanges.pipe(startWith(this.form.value));
  }

  markValue(
    _event: any,
    formValues: { [key: string]: { [key: string]: unknown } },
    parent: WidgetConfig<unknown>,
    child: WidgetConfig<unknown>,
    option: OptionObject
  ) {
    if (_event.target.checked) {
      const newValues = {
        ...formValues,
        [parent.name]: {
          ...formValues[parent.name],
          [child.name]: option.code,
        },
      };
      this.form.patchValue(newValues);
    }
    else{
      
      const newValues = {
        ...formValues,
        [parent.name]: {
          ...formValues[parent.name],
          [child.name]: null,
        },
      };
      this.form.patchValue(newValues);

      for(var item of this.config.childWidgets){
        // console.log(newValues[parent.name][item.name]);
        if (item.required === true && newValues[parent.name][item.name] === null){
          const formControl = this.form.get(this.config.name);
          formControl.setErrors({ untouched: true });
        }
      }
      
    }

    
    

  }

  isMarked(
    formValues: { [key: string]: { [key: string]: unknown } },
    childName: string,
    code: string
  ) {

    return (formValues[this.config.name][childName] ?? null) === code;
  }

  checkValidForm(){
    if(this.form.status != 'INVALID'){
      for(var item of this.config.childWidgets){
        // debugger;
        // console.log(this.form.value[this.config.name][item.name]);
        // console.log(item.required);
        if (item.required === true && this.form.value[this.config.name][item.name] === null){
          const formControl = this.form.get(this.config.name);
          formControl.setErrors({ untouched: true });
        }
      }
    }
  }
}
