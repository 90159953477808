import { WidgetTypes } from '../../typings';
import { LegacyQnrItemExtBase } from '../extension-base';

export class QuestionnaireItemCustomSelectionModal extends LegacyQnrItemExtBase {
  extensionUrl = 'http://zitelab.eu/fhir/extensions/enableCustomSelectionModal';
  supportedWidgets = [WidgetTypes.TextAreaWidget];
  constructor(config: {} = {}) {
    super(config);
  }

  parse = (): {
    widgetProperties: {
      isCustomSelectionModalEnabled: boolean;
      customSelectionModalConfig?: {
        modalEventIdentifier: string;
        btnText: string;
      };
    };
  } => {
    let isCustomSelectionModalEnabled = false;
    let modalEventIdentifier: string = null;
    let btnText: string = null;
    if (this.extensionJson?.valueBoolean) {
      isCustomSelectionModalEnabled = this.extensionJson.valueBoolean;
    }
    if (this.extensionJson?.extension) {
      this.extensionJson.extension.forEach((extension) => {
        if (extension.url === 'modalEventIdentifier') {
          modalEventIdentifier = extension.valueString;
        }
        if (extension.url === 'btnText') {
          btnText = extension.valueString;
        }
      });
    }
    return {
      widgetProperties: {
        isCustomSelectionModalEnabled,
        customSelectionModalConfig: {
          modalEventIdentifier,
          btnText,
        },
      },
    };
  };
}

export function getCustomSelectionModalExt(item: fhir4.QuestionnaireItem): {
  isCustomSelectionModalEnabled: boolean;
  customSelectionModalConfig?: {
    modalEventIdentifier: string;
    btnText: string;
  };
} {
  let extensionObj: QuestionnaireItemCustomSelectionModal;
  if (item?.extension) {
    // the FHIR resource is already validated at this point
    item.extension.forEach((extension) => {
      if (
        extension.url ===
        'http://zitelab.eu/fhir/extensions/enableCustomSelectionModal'
      ) {
        extensionObj = new QuestionnaireItemCustomSelectionModal({
          extensionJson: extension,
        });
      }
    });
  }
  return extensionObj !== undefined
    ? extensionObj.parse().widgetProperties
    : { isCustomSelectionModalEnabled: false };
}
