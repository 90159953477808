<ng-container [formGroup]="form" *ngIf="formValues$ | async as formValues">
    <ion-grid [formGroupName]="config.name">
        <ion-row>
            <hestia-widget-header [config]="config" [i18n]="i18n"></hestia-widget-header>
        </ion-row>
        <ion-row *ngIf="config.gridGroupConfig?.showOptionsHeaderRow === true" class="border-0 border-b border-gray-300 border-solid" justify-content-center align-items-center style="height: 100%">
            <!-- Empty col since we have a col label -->
            <ion-col size="6">
                <div></div>
            </ion-col>
            <ion-col *ngFor="let option of config?.childWidgets[0]?.options ?? []">
                <div class="flex items-end justify-center h-full">
                    <span [innerHTML]="i18n({ translate: option.i18nName, fallback: option.display, type: 'i18nName' })"></span>
                </div>
            </ion-col>
        </ion-row>
        <ion-row *ngFor="let child of config.childWidgets" class="border-0 border-b border-gray-300 border-solid" justify-content-center align-items-center style="height: 100%" [id]="child.name">
            <ion-col size="6" class="flex items-center">
                <font *ngIf="child.required" color="red">*&nbsp;</font>
                <span [innerHTML]="
            i18n({
              translate: 'label',
              fallback: child.label,
              type: 'i18nName',
              config: child
            })
          "></span>


            </ion-col>
            <!-- Click event has been moved on to the column as a trial-->
            <ion-col *ngFor="let option of child.options" class="flex items-center justify-center hover:cursor-pointer" (click)="markValue($event, formValues, config, child, option)">
                <ion-label [ngSwitch]="config.gridGroupConfig.useOrdinalValuesAsRadioLabel" class="mr-1">
                    <ng-container *ngSwitchCase="true">
                        {{ option.ordinalValue }}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <span [innerHTML]="i18n({ translate: option.i18nName, fallback: option.display, type: 'i18nName' })"></span>
                    </ng-container>

                </ion-label>
                <!-- (ionChange)="markValue($event, formValues, config, child, option)" -->
                <ion-checkbox [checked]="isMarked(formValues, child.name, option.code)" [disabled]="child.disabled"></ion-checkbox>
            </ion-col>
        </ion-row>
    </ion-grid>
</ng-container>
{{checkValidForm()}}