import { WidgetTypes } from '../../typings';
import { LegacyQnrItemExtBase } from '../extension-base';

export class QuestionnaireItemRenderAsGridGroup extends LegacyQnrItemExtBase {
  extensionUrl =
    'http://zitelab.eu/fhir/extensions/questionnaire-item-render-as-grid-group';
  supportedWidgets = [WidgetTypes.GridGroupWidget];
  constructor(config: Record<string, unknown> = {}) {
    super(config);
  }

  parse = (): {
    widgetProperties: {
      isGridGroup: boolean;
      gridGroupConfig?: {
        showOptionsHeaderRow: boolean;
        useOrdinalValuesAsRadioLabel: boolean;
      };
    };
  } => {
    let isGridGroup = false;
    let showOptionsHeaderRow = true;
    let useOrdinalValuesAsRadioLabel = false;
    if (this.extensionJson?.valueBoolean) {
      isGridGroup = this.extensionJson.valueBoolean;
    }
    if (this.extensionJson?.extension) {
      this.extensionJson.extension.forEach((extension) => {
        if (extension.url === 'showOptionsHeaderRow') {
          showOptionsHeaderRow = extension.valueBoolean;
        }
        if (extension.url === 'useOrdinalValuesAsRadioLabel') {
          useOrdinalValuesAsRadioLabel = extension.valueBoolean;
        }
      });
    }
    return {
      widgetProperties: {
        isGridGroup,
        gridGroupConfig: {
          showOptionsHeaderRow,
          useOrdinalValuesAsRadioLabel,
        },
      },
    };
  };
}

export function getRenderAsGridGroupExt(item: fhir4.QuestionnaireItem): {
  isGridGroup: boolean;
  gridGroupConfig?: {
    showOptionsHeaderRow: boolean;
    useOrdinalValuesAsRadioLabel: boolean;
  };
} {
  let extensionObj: QuestionnaireItemRenderAsGridGroup;
  if (item?.extension && item.type === 'group') {
    // the FHIR resource is already validated at this point
    item.extension.forEach((extension) => {
      if (
        extension.url ===
        'http://zitelab.eu/fhir/extensions/questionnaire-item-render-as-grid-group'
      ) {
        extensionObj = new QuestionnaireItemRenderAsGridGroup({
          extensionJson: extension,
        });
      }
    });
  }
  return extensionObj !== undefined
    ? extensionObj.parse().widgetProperties
    : { isGridGroup: false };
}
