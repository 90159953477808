import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  adapter,
  IQnrFormSessionsState,
  qnrFormSessionFeatureKey,
} from './qnr-form-session.reducers';

export const selectQnrFormSessionsState =
  createFeatureSelector<IQnrFormSessionsState>(qnrFormSessionFeatureKey);

export const {
  selectIds: selectQnrFormSessionIds,
  selectEntities: selectQnrFormSessionEntities,
  selectAll: selectAllQnrFormSessions,
  selectTotal: selectTotalQnrFormSessions,
} = adapter.getSelectors(selectQnrFormSessionsState);

export const selectQnrFormSessionById = (id) =>
  createSelector(
    selectQnrFormSessionEntities,
    (resources) => resources[id] ?? null
  );

export const selectQnrFormSessionByCarePlanId = (carePlanId: string) =>
  createSelector(
    selectAllQnrFormSessions,
    (resources) =>
      resources.find((elem) => elem.carePlanId === carePlanId) ?? null
  );

export const selectSessionByCarePlanAndActivity = (
  carePlanId: string,
  activityCode: string
) =>
  createSelector(
    selectAllQnrFormSessions,
    (resources) =>
      resources.find(
        (elem) =>
          elem.carePlanId === carePlanId && elem.activityCode === activityCode
      ) ?? null
  );
