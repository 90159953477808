import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { AlertController } from '@ionic/angular';

import { TranslocoService } from '@ngneat/transloco';

import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { WidgetConfig } from '../../models/widget.config';
import { WidgetService } from '../../services/widget.service';
import { isQnrAdaptive } from '../../utils/is-qnr-adaptive';

@Component({
  selector: 'hestia-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  providers: [WidgetService],
})
export class QuestionnaireComponent implements OnInit, OnDestroy, OnChanges {
  @Input() widgets: WidgetConfig<any>[] = [];
  @Input() submitButtonName = 'Next';
  @Input() activityStatus: string;
  @Input() renderType: string;
  @Input() alertWhenEmptySubmit = true;
  @Input() isSubmitAlwaysEnabled = false;
  @Input() displayMode = false;
  @Output() pageSubmitted = new EventEmitter();
  @Output() formChanged = new EventEmitter();
  @Output() toPrevious = new EventEmitter();
  form: UntypedFormGroup;
  private unsubscribe = new Subject();
  constructor(
    private service: WidgetService,
    private transloco: TranslocoService,
    private alertCtrl: AlertController
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.widgets) {
      const widgetsChange = changes['widgets'];
      if (!widgetsChange.firstChange) {
        this.form = this.service.toFormGroup(this.widgets);
        this.onFormChanges();
      }
    }
  }

  ngOnInit() {
    this.form = this.service.toFormGroup(this.widgets);
    this.onFormChanges();
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  onFormChanges(): void {
    this.form.valueChanges.pipe(takeUntil(this.unsubscribe), distinctUntilChanged()).subscribe((form) => {
      this.formChanged.emit({ form });
    });
  }

  submitHandler(event: Event) {
    event.preventDefault();
    const formValues = Object.values(this.form.value);
    const missingValues =
      formValues.indexOf(null) !== -1 ||
      formValues.find((value) => Array.isArray(value) && value.length === 0) !== undefined ||
      formValues.indexOf('') !== -1;
    if (missingValues && this.alertWhenEmptySubmit) {
      this.presentEmptyValuesAlert(event);
    } else {
      this.onSubmit(event);
    }
  }

  async presentEmptyValuesAlert(event) {
    const prompt = await this.alertCtrl.create({
      header: this.transloco.translate('IFQ.WANT_TO_PROCEED_ALERT.HEADER'),
      message: this.transloco.translate('IFQ.WANT_TO_PROCEED_ALERT.MESSAGE'),
      buttons: [
        {
          text: this.transloco.translate('IFQ.WANT_TO_PROCEED_ALERT.OK_BTN'),
          handler: () => {
            this.onSubmit(event);
          },
        },
        {
          text: this.transloco.translate('IFQ.WANT_TO_PROCEED_ALERT.CANCEL_BTN'),
          handler: () => {
            //
          },
        },
      ],
    });
    await prompt.present();
  }

  isSubmitEnabled() {
    // False means enabled since func is used in disabled property
    if (this.isSubmitAlwaysEnabled === true) {
      return false;
    }

    if (this.renderType === 'fullEditable') {
      return false;
    }

    //RICARDO ADDED CONDITION isQnrAdaptive - SO IS POSSIBLE TO EDIT ADAPTIVE QNR. MIKKEL COULD HAVE BETTER IDEA.
    return !this.form?.valid || (this.activityStatus === 'completed' && !isQnrAdaptive);
  }

  onSubmit(event) {
    this.pageSubmitted.emit({ event: event, form: this.form.value });
  }

  previousPage(event) {
    event.preventDefault();
    this.toPrevious.emit(event);
  }

  trackWidgetById(widget) {
    return widget.id;
  }
}
