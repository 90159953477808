import { WidgetTypes } from '../../typings';
import { LegacyQnrItemExtBase, IQnrItemExtParsedExt } from '../extension-base';

export class QuestionnaireItemSvgGroupItemElement extends LegacyQnrItemExtBase {
  extensionUrl =
    'http://zitelab.eu/fhir/extensions/questionnaire-item-svg-group-item-element';
  supportedWidgets = [WidgetTypes.RadioGroupWidget];
  constructor(config: Record<string, unknown> = {}) {
    super(config);
  }

  parse = (): IQnrItemExtParsedExt => {
    let svgGroupItemElement: string = null;
    if (this.extensionJson?.valueString) {
      svgGroupItemElement = this.extensionJson.valueString;
    }
    return {
      widgetProperties: {
        svgGroupItemElement,
      },
    };
  };
}

export function getSvgGroupItemElement(item: fhir4.QuestionnaireItem) {
  let extensionObj: QuestionnaireItemSvgGroupItemElement;
  if (item?.extension) {
    // the FHIR resource is already validated at this point
    item.extension.forEach((extension) => {
      if (
        extension.url ===
        'http://zitelab.eu/fhir/extensions/questionnaire-item-svg-group-item-element'
      ) {
        extensionObj = new QuestionnaireItemSvgGroupItemElement({
          extensionJson: extension,
        });
      }
    });
  }
  return extensionObj !== undefined
    ? extensionObj.parse().widgetProperties
    : {};
}
