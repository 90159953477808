import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  Renderer2,
  OnInit,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';

import { ActionSheetController } from '@ionic/angular';

import { TranslocoService } from '@ngneat/transloco';

import { Store } from '@ngrx/store';

import { OptionObject } from '../../../models/typings';
import { WidgetBaseComponent } from '../../../models/widget';
import { WidgetConfig } from '../../../models/widget.config';
import { Events } from '../../../services/events.service';
import { HestiaQnrFormSessionService } from '../../../services/qnr-form-session.service';
import { SvgGroupWidgetConfig } from './svg-group-widget.config';

@Component({
  selector: 'hestia-svg-group-widget',
  templateUrl: './svg-group-widget.component.html',
  styleUrls: ['./svg-group-widget.component.scss'],
})
export class SvgGroupWidgetComponent
  extends WidgetBaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('svgContainer') svgContainer: ElementRef;
  @Input() config: SvgGroupWidgetConfig;
  @Input() form: UntypedFormGroup;
  childWidgetNames: string[] = [];
  valueCount: {
    code: string;
    i18n: string;
    color: string;
    count: number;
  }[] = [];
  constructor(
    transloco: TranslocoService,
    questionnaireService: HestiaQnrFormSessionService,
    store: Store,
    element: ElementRef,
    events: Events,
    private actionSheetCtrl: ActionSheetController,
    private rd: Renderer2
  ) {
    super(transloco, questionnaireService, store, element, events);
  }
  get isValid() {
    return true;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    const itemElements = this.config.childWidgets.map(
      (child) => child.svgGroupItemElement
    );
    this.childWidgetNames = this.config.childWidgets.map((child) => child.name);
    this.svgContainer.nativeElement.innerHTML = this.config.svgSource.replace(
      '</svg>',
      `${itemElements.join('')}</svg>`
    );
    this.config.childWidgets.map((child) => this.setElementColor(child.name));
    this.setValueCount();
    this.form.valueChanges.subscribe((values) => {
      const nestedValues = values[this.config.name];
      Object.entries(nestedValues).map(([widgetName, value], index) =>
        this.setElementColor(widgetName, value)
      );
      this.setValueCount();
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  setValueCount() {
    const exampleWidget = this.config.childWidgets[0]; // TODO: Right now we assume that all child items use the same ValueSet
    const formGroup = this.form.controls[this.config.name] as UntypedFormGroup;
    const values = Object.values(formGroup.value);
    const newValueCount = [
      ...exampleWidget.options.map((option) => ({
        code: option.code,
        i18n: option.i18nName,
        color: option.colorInterpretation,
        count: values.filter((elem) => elem === option.code).length,
      })),
      {
        code: 'NO_VALUE',
        i18n: 'IFQ.SVG_GROUP_WIDGET.LEGEND.NO_VALUE',
        color: '#F8F8F8',
        count: values.filter((elem) => elem === '').length,
      },
    ];
    this.valueCount = newValueCount;
  }

  setElementColor(widgetName, value = null) {
    if (value === null) {
      const formGroup = this.form.controls[this.config.name] as UntypedFormGroup;
      const childWidgetControl = formGroup.controls[widgetName];
      value = childWidgetControl.value;
    }
    const widget: WidgetConfig<any> = this.config.childWidgets.find(
      (child) => child.name == widgetName
    );
    let color: string;
    if (value === '') {
      color = '#F8F8F8';
    } else {
      const selectedOption = widget.options.find(
        (option) => option.code === value
      ) || { colorInterpretation: 'yellow' };
      color = selectedOption.colorInterpretation;
    }
    const svgElement = this.svgContainer.nativeElement.querySelector(
      `#${widgetName}`
    );
    this.rd.setAttribute(svgElement, 'fill', color);
  }

  markElementAsActive(element) {
    this.rd.setStyle(element, 'animation', 'pulsate 2s ease-out');
    this.rd.setStyle(element, 'animation-iteration-count', 'infinite');
    this.rd.setStyle(element, 'opacity', '0');
  }

  unmarkElementAsActive(element: Element) {
    this.rd.setStyle(element, 'animation', null);
    this.rd.setStyle(element, 'animation-iteration-count', null);
    this.rd.setStyle(element, 'opacity', '1');
  }

  async handleClick($event) {
    const element = $event.target;
    const targetElementId = element.id;
    if (this.childWidgetNames.indexOf(targetElementId) === -1) {
      return;
    }
    this.markElementAsActive(element);
    const childWidget: WidgetConfig<any> = this.config.childWidgets.find(
      (child) => child.name === targetElementId
    );
    const formGroup = this.form.controls[this.config.name] as UntypedFormGroup;
    const childWidgetControl = formGroup.controls[childWidget.name];
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.i18n({ translate: 'label', fallback: childWidget.label }),
      cssClass: 'svg-group-widget-action-sheet',
      buttons: [
        ...childWidget.options.map((option) =>
          this.createActionSheetButton(childWidgetControl, option, element)
        ),
        {
          text: this.transloco.translate(
            'IFQ.SVG_GROUP_WIDGET.ACTION_SHEET.CANCEL'
          ),
          role: 'cancel',
          handler: () => {
            this.unmarkElementAsActive(element);
          },
        },
      ],
    });
    await actionSheet.present();
  }

  createActionSheetButton(
    formControl: AbstractControl,
    option: OptionObject,
    element: Element
  ) {
    const isSelected = formControl.value === option.code;
    return {
      text: this.i18n({
        translate: option.i18nName,
        fallback: option.display,
        type: 'i18nName',
      }),
      icon: isSelected ? 'checkmark' : null,
      cssClass: isSelected ? 'action-sheet-button-disabled' : undefined,
      handler: () => {
        formControl.setValue(option.code);
        this.unmarkElementAsActive(element);
      },
    };
  }

  markAllUnanswered(valueCode) {
    const formGroup = this.form.controls[this.config.name] as UntypedFormGroup;
    const oldValue = formGroup.value;
    const newValue = {};
    Object.entries(oldValue).forEach(([key, curValue], index) => {
      if (!curValue) {
        newValue[key] = valueCode;
      } else {
        newValue[key] = curValue;
      }
    });
    formGroup.setValue(newValue);
  }

  async markAllUnansweredPrompt(event) {
    event.preventDefault();
    const exampleWidget = this.config.childWidgets[0]; // TODO: Right now we assume that all child items use the same ValueSet
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.transloco.translate(
        'IFQ.SVG_GROUP_WIDGET.ACTION_SHEET.MARK_UNANSWERED_TITLE'
      ),
      buttons: [
        ...exampleWidget.options.map((option) => ({
          text: this.i18n({
            translate: option.i18nName,
            fallback: option.display,
            type: 'i18nName',
          }),
          handler: () => {
            this.markAllUnanswered(option.code);
          },
        })),
        {
          text: this.transloco.translate(
            'IFQ.SVG_GROUP_WIDGET.ACTION_SHEET.CANCEL'
          ),
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await actionSheet.present();
  }
}
