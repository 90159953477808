<hestia-widget-group-header
  [config]="config"
  [i18n]="i18n"
></hestia-widget-group-header>
<ion-list lines="full" [formGroup]="form">
  <ion-item>
    <ion-label position="stacked" [attr.for]="config.label">
      <hestia-widget-header
        [config]="config"
        [i18n]="i18n"
      ></hestia-widget-header>
    </ion-label>
    <ion-input
      type="number"
      min="0"
      (keypress)="numberOnlyValidation($event)"
      [virtualKeyboard]="{
        value: form.get(config.name).value,
        keyboardType: 'numerical',
        inputId: config.name
      }"
      (updateVirtualKeyboardValue)="newVirtualKeyboardValue($event)"
      [disabled]="config.disabled"
      [attr.value]="config.value || config.defaultValue"
      [formControlName]="config.name"
      [placeholder]="'IFQ.NUMBER_WIDGET.PLACEHOLDER' | transloco"
      [id]="config.name"
    ></ion-input>
  </ion-item>
</ion-list>
<div [ngClass]="config.name + '-virtual-keyboard'"></div>
