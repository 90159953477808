<!-- TODO: Move group header to its own component that can just be called here -->
<h3 padding *ngIf="config.firstInGroup && config.widgetGroup !== null">
  {{ i18n({ translate: 'widgetGroup', fallback: config.widgetGroup.label }) }}
</h3>
<h3 padding text-center style="padding-bottom: 0px; padding-top: 0px">
  {{ i18n({ translate: 'label' }) | varInterpolation: config.varInterpolation }}
</h3>
<ion-item [formGroup]="form" lines="none">
  <input style="display: none" [formControlName]="config.name" type="text" />
  <div text-center>
    <hestia-signing
      [width]="300"
      [height]="180"
      (onClear)="onClear()"
      (onEnd)="onEnd()"
      (onInit)="signingInit($event)"
    ></hestia-signing>
  </div>
</ion-item>
