import { WidgetTypes } from '../../typings';
import { IQnrItemExtParsedExt, LegacyQnrItemExtBase } from '../extension-base';

export class QuestionnaireInvertedImages extends LegacyQnrItemExtBase {
  extensionUrl = 'http://hl7.org/fhir/StructureDefinition/invertedImages';
  supportedWidgets = [WidgetTypes.LikertRadioGroupWidgetWithImage];
  constructor(config: Record<string, unknown> = {}) {
    super(config);
  }

  parse = (): IQnrItemExtParsedExt => {
    let invertedImages = false;
    if (this.extensionJson?.valueBoolean) {
      invertedImages = this.extensionJson.valueBoolean;
    }
    return {
      widgetProperties: {
        invertedImages,
      },
    };
  };
}

export function getInvertedImagesExt(item: fhir4.QuestionnaireItem) {
  let extensionObj: QuestionnaireInvertedImages;
  if (item?.extension) {
    // the FHIR resource is already validated at this point
    item.extension.forEach((extension) => {
      if (extension.url === 'http://hl7.org/fhir/StructureDefinition/invertedImages') {
        extensionObj = new QuestionnaireInvertedImages({
          extensionJson: extension,
        });
      }
    });
  }
  return extensionObj !== undefined ? extensionObj.parse().widgetProperties : {};
}
