/* eslint-disable @typescript-eslint/naming-convention */
export type CarePlanStatus = 'draft' | 'active' | 'on-hold' | 'revoked' | 'completed' | 'entered-in-error' | 'unknown';

export type CarePlanActivityStatus =
  | 'not-started'
  | 'scheduled'
  | 'in-progress'
  | 'on-hold'
  | 'completed'
  | 'cancelled'
  | 'stopped'
  | 'unknown'
  | 'entered-in-error';

export type HestiaSupportedLanguage = 'en-US' | 'en-GB' | 'da-DK' | 'is-IS';

export type HestiaAppUse = 'PATIENT_APP' | 'HCP_APP' | 'KIOSK_APP' | 'DEV';

export type HestiaAuthMethod = 'NONE' | 'USERNAME_AND_PASSWORD' | 'NEMID' | 'MITID' | 'ACTIVE_DIRECTORY';

export enum HestiaAuthErrorEnum {
  Unknown = 'Unknown',
  NoResponse = 'NoResponse',
  UsernameAndPasswordUnknownCredentials = 'UnknownCredentials',
  UsernameAndPasswordRequires2FA = 'UsernameAndPasswordRequires2FA',
  MitIdUserNotFound = 'MitIdUserNotFound',
  MitIdBadRequest = 'MitIdBadRequest',
  LogoutUnknownJwt = 'LogoutUnknownJwt',
  LogoutExpiredJwt = 'LogoutExpiredJwt',
}

export class HestiaAuthError extends Error {
  errorType: HestiaAuthErrorEnum;
  loginMethod: HestiaAuthMethod;
  errorI18n: string;
  operation: 'login' | 'logout';
  constructor(
    errorType: HestiaAuthErrorEnum,
    loginMethod: HestiaAuthMethod,
    errorI18n: string,
    operation: 'login' | 'logout',
    ...params
  ) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    this.name = 'HestiaAuthError';
    // Custom debugging information
    this.errorType = errorType;
    this.loginMethod = loginMethod;
    this.errorI18n = errorI18n;
    this.operation = operation;
  }
}

export const isHestiaAuthError = (error: Error): error is HestiaAuthError => error instanceof HestiaAuthError;

export enum HestiaFhirResourceTypeName {
  ValueSet = 'ValueSet',
  Questionnaire = 'Questionnaire',
  QuestionnaireResponse = 'QuestionnaireResponse',
  Patient = 'Patient',
  Practitioner = 'Practitioner',
  RelatedPerson = 'RelatedPerson',
  Person = 'Person',
  Organization = 'Organization',
  CarePlan = 'CarePlan',
}

export enum HestiaUserType {
  Anonymous = 'Anonymous',
  Patient = 'Patient',
  Kiosk = 'Person',
  KioskFhir4 = 'OrganizationKioskUser',
  Hcp = 'Practitioner',
  RelatedPerson = 'RelatedPerson',
}

export enum AgeGroup {
  FOUR_TO_EIGHT = 'FOUR_TO_EIGHT',
  NINE_TO_EIGHTEEN = 'NINE_TO_EIGHTEEN',
}

export type HestiaFhirUserResourceType =
  | HestiaFhirResourceTypeName.Practitioner
  | HestiaFhirResourceTypeName.RelatedPerson
  | HestiaFhirResourceTypeName.Patient;

export interface IHestiaSentryConfig {
  enabled: boolean;
  dsn?: string;
}

/* New 2022 */
export interface IHestiaUser {
  username: string;
  password: string;
  diagnosis: string;
  social_security_number: string;
  email: string;
  fullname: string;
  gender: string;
  birthdate: string;
  organization_uid?: string;
  user_type: HestiaUserType;
  properties?: unknown;
}

export interface IHestiaUserPatch {
  username?: string;
  password?: string;
  diagnosis?: string;
  social_security_number?: string;
  email?: string;
  fullname?: string;
  gender?: string;
  birthdate?: string;
  organization_uid?: string;
  user_type?: HestiaUserType;
  properties?: unknown;
}

export interface IHestiaCoreConfig {
  // TODO: Write documentation for each of the invidual keys
  production: boolean;
  debugMode: boolean;
  envName: string;
  apiUrl: string;
  availableLangs: HestiaSupportedLanguage[];
  defaultLang: HestiaSupportedLanguage;
  appBuiltFor: HestiaAppUse;
  defaultAuthMethod: HestiaAuthMethod;
  /** The key used by local storage */
  syncStorageKey: string;
  /** The boolean key used in NGRX to mark if state has been synced from local storage */
  syncHydratedKey: string;
  release: string;
  sentry?: IHestiaSentryConfig;
}

export type OptionalExceptFor<T, TRequired extends keyof T> = Partial<T> & Pick<T, TRequired>;

export enum HestiaAuthEndpoint {
  'Login' = '@login',
  'Logout' = '@auth-logout',
  'KioskSession' = '@kiosk-session',
  'User' = '@fhir-user',
  'NemidLogin' = '@auth-nemid',
  'OTPLogin' = '@phone-send-otp-code',
  'OTPVerify' = '@phone-verify-otp-code',
}

export interface IHestiaUserServerProfile {
  id: string;
  email: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  fhir_id: string;
  fullname: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  home_path: string;
  language: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  mobile_number: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  organization_uid: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  social_security_number: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  user_type: HestiaUserType;
  username: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  user_resource: fhir4.Patient | fhir4.Practitioner | fhir4.RelatedPerson;
  diagnosis: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  diagnosis_group: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  cell_phone_number: string;
  ageGroup?: AgeGroup;
  startDate?: string;
  endDate?: string;
  confirmedVomitDates?: [];
  confirmedMedicineDates?: [];
}

export type HestiaFhirResource =
  | fhir4.ValueSet
  | fhir4.Questionnaire
  | fhir4.QuestionnaireResponse
  | fhir4.Patient
  | fhir4.Practitioner
  | fhir4.Person
  | fhir4.RelatedPerson
  | fhir4.CarePlan
  | fhir4.Organization;

export enum HestiaKioskTaskType {
  Standard = 'STANDARD',
  Yearly = 'YEARLY',
  Custom = 'CUSTOM',
  OneTime = 'ONE_TIME',
  InProgress = 'IN_PROGRESS',
}

export type Sex = 'MALE' | 'FEMALE' | 'UNKNOWN' | 'OTHER';

export interface IHestiaCarePlanActivity {
  carePlanId: string;
  fhirElem: fhir4.CarePlanActivity;
  code: string;
  qnrCanonical: string;
  qnrResource: fhir4.Questionnaire;
  qnrResponseResource: fhir4.QuestionnaireResponse;
  status: CarePlanActivityStatus;
}

export interface IHestiaCarePlan {
  subjectType: HestiaFhirResourceTypeName;
  subjectId: string;
  carePlanResource: fhir4.CarePlan;
  activitiesByCode: Map<string, IHestiaCarePlanActivity>;
  allActivities: Array<IHestiaCarePlanActivity>;
  completedActivityCount: number;
  inProgressActivityCount: number;
  totalActivityCount: number;
}

/* eslint-disable @typescript-eslint/naming-convention */
export interface IHestiaUserProfile {
  responses?: any;
  email: string;
  fhir_id: string;
  fullname: string;
  home_path: string;
  participation: string;
  diagnosis: string;
  language: string;
  mobile_number: string;
  sms_reminders: string;
  cell_phone_number?: string;
  organization_uid: string;
  social_security_number: string;
  comment: string;
  patientSection: string;
  status: string;
  available_from_date: string;
  due_date: string;
  user_type: 'Practitioner' | 'Patient' | 'RelatedPerson';
  username: string;
  user_resource: fhir4.Patient | fhir4.Practitioner | fhir4.RelatedPerson;
  ageGroup?: AgeGroup;
  startDate?: string;
  endDate?: string;
  confirmedVomitDates?: [];
  confirmedMedicineDates?: [];
  latest_questionnaireresponse_authored?: string;
}
