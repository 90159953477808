<hestia-widget-group-header
  [config]="config"
  [i18n]="i18n"
></hestia-widget-group-header>
<div
  *ngIf="formControl"
  [formGroup]="form"
  text-wrap
  style="overflow: visible; padding-bottom: 15px"
  class="slider-widget-container"
>
  <!-- horizontal slider -->
  <div
    *ngIf="config.sliderOrientation === 'horizontal'"
    class="flex flex-col flex-auto"
  >
    <hestia-widget-header
      [config]="config"
      [i18n]="i18n"
    ></hestia-widget-header>
    <ngx-slider
      [ngClass]="{ sliderNotTouched: isUntouched(config.name) }"
      [options]="optionsObj"
      [formControlName]="config?.name"
      (userChangeEnd)="onUserChangeEnd($event)"
    ></ngx-slider>
    <div class="range-labels">
      <ion-note class="min-value-label" *ngIf="config.leftLabel !== null">
        {{ i18n({ translate: 'leftLabel' }) }}
      </ion-note>
      <ion-note
        class="max-value-label"
        *ngIf="config.rightLabel !== null"
        style="float: right"
      >
        {{ i18n({ translate: 'rightLabel' }) }}
      </ion-note>
    </div>
  </div>
  <!-- vertical slider -->
  <div
    class="flex flex-col flex-auto"
    *ngIf="config.sliderOrientation === 'vertical'"
  >
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col [size]="12" [sizeSm]="12" [sizeMd]="6" [sizeLg]="6" [sizeXl]="6">
        <hestia-widget-header
          [config]="config"
          [i18n]="i18n"
        ></hestia-widget-header>
      </ion-col>
      <ion-col [size]="12" [sizeSm]="12" [sizeMd]="6" [sizeLg]="6" [sizeXl]="6">
        <ion-row class="ion-no-padding">
          <ion-note
            class="max-value-label vertical"
            *ngIf="config.rightLabel !== null"
          >
            {{ i18n({ translate: 'rightLabel' }) }}
          </ion-note>
        </ion-row>
        <ion-row style="height: min(50vh, 24rem)">
          <ngx-slider
            [options]="optionsObj"
            [formControlName]="config?.name"
            (userChangeEnd)="onUserChangeEnd($event)"
          ></ngx-slider>
        </ion-row>
        <ion-row>
          <ion-note
            class="min-value-label vertical"
            *ngIf="config.leftLabel !== null"
          >
            {{ i18n({ translate: 'leftLabel' }) }}
          </ion-note>
        </ion-row>
      </ion-col>
    </ion-row>
  </div>
</div>
