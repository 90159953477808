import { decodeJwt } from 'jose';

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { filter, Observable, of, race, switchMap, take } from 'rxjs';

import { Store } from '@ngrx/store';

import { userActions } from '../+state/user.actions';
import { selectUserState } from '../+state/user.selectors';
import { HESTIA_USER_MODULE_CONFIG, IHestiaUserModuleConfig } from '../constants';

@Injectable()
export class AuthGuard {
  constructor(
    private store: Store,
    private router: Router,
    @Inject(HESTIA_USER_MODULE_CONFIG) private userConfig: IHestiaUserModuleConfig
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return race(
      this.store
        .select((state) => state[this.userConfig.syncHydratedKey])
        .pipe(
          filter((hydrated) => hydrated === true),
          switchMap(() => this.store.select(selectUserState)),
          take(1),
          switchMap((userState) => {
            if (!userState.jwt) {
              return of(this.router.parseUrl(this.userConfig.isAuthenticatedGuardFailureRoute));
            }

            const decodedToken = decodeJwt(userState.jwt);

            if (Date.now() <= decodedToken?.exp! * 1000) {
              return of(true);
            } else {
              this.store.dispatch(userActions.fullResetState());
              return of(this.router.parseUrl(this.userConfig.isAuthenticatedGuardFailureRoute));
            }
          })
        )
    );
  }
}
