import { WidgetTypes } from '../../typings';
import {
  HestiaExtParentElem,
  IHestiaQnrItemExt,
  IQnrItemExtParsedExt,
  QnrItemType,
} from '../extension-base';

export const QNR_ITEM_DESC_EXT_URL =
  'http://zitelab.eu/fhir/extensions/questionnaireItemDescription';

export interface IWidgetDescProps extends Record<string, unknown> {
  descriptionText: string;
  renderDescriptionAsMarkdown: boolean;
}

export class QnrItemDescriptionExt
  implements IHestiaQnrItemExt<IWidgetDescProps>
{
  readonly url = QNR_ITEM_DESC_EXT_URL;
  readonly extendsElem = HestiaExtParentElem.QuestionnaireItem;
  readonly supportedItemTypes = [QnrItemType.All];
  readonly supportedWidgets = [WidgetTypes.All];
  json: fhir4.Extension;
  get configProps(): IQnrItemExtParsedExt<IWidgetDescProps> {
    return this.parse();
  }
  constructor(json: fhir4.Extension) {
    this.json = json;
  }

  parse = (): IQnrItemExtParsedExt<IWidgetDescProps> => {
    let descriptionText: string = null;
    let renderDescriptionAsMarkdown = false;
    if (this.json?.valueString) {
      descriptionText = this.json.valueString;
      renderDescriptionAsMarkdown = false;
    }
    if (this.json?.valueMarkdown) {
      descriptionText = this.json.valueMarkdown;
      renderDescriptionAsMarkdown = true;
    }
    return {
      widgetProperties: {
        descriptionText,
        renderDescriptionAsMarkdown,
      },
    };
  };
}

export function getItemDescription(item: fhir4.QuestionnaireItem) {
  let extensionObj: QnrItemDescriptionExt;
  if (item?.extension) {
    // the FHIR resource is already validated at this point
    item.extension.forEach((extension) => {
      if (extension.url === QNR_ITEM_DESC_EXT_URL) {
        extensionObj = new QnrItemDescriptionExt(extension);
      }
    });
  }
  return extensionObj !== undefined
    ? extensionObj.parse().widgetProperties
    : {};
}
