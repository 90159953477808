import { format, formatISO } from 'date-fns';
import { QuestionnaireResponseItem } from 'fhir/r4';
import { isObject } from 'lodash-es';

import { Inject, Injectable } from '@angular/core';

import { HestiaFhirResourcesService } from '@hestia/ngx-fhir';
import { HestiaFhirResourceTypeName } from '@hestia/ngx-types';

import { ADAPTIVE_QUESTIONNAIRE_RESPONSE_PROFILE, LINK_ID_SEPERATOR, QNR_CORE_MODULE_CONFIG } from '../constants';
import {
  addLatestGroupItemInAdaptiveResponseExt,
  addLatestItemInAdaptiveResponseExt,
  addValueHistoryExt,
  addResponseStartTimeExt,
  getAuthoredDate
} from '../models/fhir-extensions/questionaire-response/utils';
import {
  ExtractedItemAnswer,
  AnswerInfo,
  OptionObject,
  FhirValueTypes,
  IQnrFormSessionModuleConfig,
} from '../models/typings';
import { IWidgetConfig, WidgetConfig } from '../models/widget.config';
import { calculateSessionValues } from '../utils/calculate-session-values';
import { HestiaQnrFormSessionService } from './qnr-form-session.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireResponseService {
  constructor(
    private sessionService: HestiaQnrFormSessionService,
    private fhirService: HestiaFhirResourcesService,
    @Inject(QNR_CORE_MODULE_CONFIG)
    public moduleConfig: IQnrFormSessionModuleConfig
  ) {}

  public async getQuestionnaireResponseAnswers(props: {
    sessionId?: string;
    carePlanId?: string;
    questionnaireId: string;
    responseResource: fhir4.QuestionnaireResponse;
  }): Promise<{ config: WidgetConfig<unknown>; answerInfo: AnswerInfo }[]> {
    const questionnaire: fhir4.Questionnaire = this.fhirService.fhirEntities[
      props.questionnaireId
    ] as fhir4.Questionnaire;
    const extractedAnswers = this.extractAnswersFromQuestionnaireResponse(props.responseResource);
    const widgets = await this.sessionService.getWidgets({
      sessionId: props.sessionId,
      carePlanId: props.carePlanId,
      questionnaireId: props.questionnaireId,
      values: this.flattenQuestionnaireAnswers(extractedAnswers),
      items: questionnaire.item,
      linkIdPaths: [],
    });
    const flattenedAnswers = this.flattenQuestionnaireAnswers(extractedAnswers);
    return widgets.map((widget) => ({
      config: widget,
      answerInfo: this.getItemAnswerInfo(widget, flattenedAnswers),
    }));
  }

  public async getQuestionnaireResponseAnswerMap(props: {
    sessionId: string;
    carePlanId: string;
    questionnaireId: string;
    responseResource: fhir4.QuestionnaireResponse;
  }): Promise<Map<string, { config: WidgetConfig<unknown>; answerInfo: AnswerInfo }>> {
    const answerMap = new Map();
    const array = await this.getQuestionnaireResponseAnswers(props);
    array.forEach((widget) => answerMap.set(widget.config.name, widget));
    return answerMap;
  }

  /**
   * Parse QuestionnaireResponse to a simple key-value object usable by the form session
   *
   * @param qnrResponse FHIR4 QuestionnaireResponse
   * @returns Record<string, unknown>
   */
  public getFormValuesFromQuestionnaireResponse(qnrResponse: fhir4.QuestionnaireResponse): Record<string, unknown> {
    if (!qnrResponse) {
      return {};
    }
    const extractedAnswers = this.extractAnswersFromQuestionnaireResponse(qnrResponse);
    const flattened = this.flattenQuestionnaireAnswers(extractedAnswers);
    if (this.moduleConfig.enableValueHistoryExt) {
      const withValueHistory = calculateSessionValues(qnrResponse, flattened);
      return withValueHistory;
    }
    return flattened;
  }

  getAnswerFromItemAnswer(linkId: string, itemAnswer: fhir4.QuestionnaireResponseItemAnswer): ExtractedItemAnswer {
    const valueProperties = Object.keys(itemAnswer).filter((propertyName) => propertyName.indexOf('value') === 0);
    if (valueProperties.length === 0) {
      if (itemAnswer?.item) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const value = itemAnswer.item.map((subItem) => {
          if (subItem?.answer) {
            const subItemAnswers = subItem.answer.map((subAnswer) =>
              this.getAnswerFromItemAnswer(subItem.linkId, subAnswer)
            );
            return subItemAnswers;
          } else {
            return [{ linkId: subItem.linkId, answerType: null, answer: null }];
          }
        });
        const typedValue = value as any; // Type must be any, otherwise it gives bad type errors
        return typedValue;
      } else {
        throw new Error(`${JSON.stringify(itemAnswer)} has no value properties`);
      }
    }
    if (valueProperties.length > 1) {
      throw new Error(
        `${JSON.stringify(itemAnswer)} has multiple value properties: ${JSON.stringify(valueProperties)}`
      );
    }
    const answerType = valueProperties[0] as FhirValueTypes;
    const value = {
      linkId,
      answerType: answerType,
      answer: itemAnswer[valueProperties[0]],
    };
    return value;
  }

  private getItemAnswer = (item: fhir4.QuestionnaireResponseItem): ExtractedItemAnswer => {
    if (item?.answer) {
      // If answer property, item type is not display or group
      const itemAnswers = item.answer.map((answer) => this.getAnswerFromItemAnswer(item.linkId, answer));
      if (itemAnswers.length > 1) {
        return {
          linkId: itemAnswers[0]['linkId'],
          answerType: itemAnswers[0]['answerType'],
          answer: itemAnswers,
        };
      } else {
        return itemAnswers[0];
      }
    }
    if (item?.item) {
      // If item property is present, item is group
      const groupItemAnswers = item.item.map((nestedItem) => this.getItemAnswer(nestedItem));
      return {
        linkId: item.linkId,
        answerType: 'group',
        answer: groupItemAnswers,
      };
    }
    return null;
  };

  private extractAnswersFromQuestionnaireResponse(
    resource: fhir4.QuestionnaireResponse
  ): Record<string, ExtractedItemAnswer> {
    let answers: Record<string, ExtractedItemAnswer> = {};
    resource.item.map((item) => {
      const isGroupItem = item?.item ? true : false;
      if (isGroupItem) {
        const groupItemAnswers: Record<string, ExtractedItemAnswer> = {};
        item.item.forEach((nestedItem) => {
          const nestedAnswer: ExtractedItemAnswer = this.getItemAnswer(nestedItem);
          if (nestedAnswer) {
            groupItemAnswers[nestedAnswer.linkId] = nestedAnswer;
          }
        });
        answers = { ...answers, ...groupItemAnswers };
      } else {
        const answer = this.getItemAnswer(item);
        if (answer) {
          answers = { ...answers, [answer.linkId]: answer };
        }
      }
    });
    return answers;
  }

  private flattenQuestionnaireAnswers(extractedAnswers: Record<string, ExtractedItemAnswer>) {
    const flattenedAnswers = {};
    Object.keys(extractedAnswers).forEach((key) => {
      const value = extractedAnswers[key];

      let flattenedValue;
      if (value.answerType === 'valueCoding') {
        if (Array.isArray(value.answer)) {
          flattenedValue = value.answer.map((elem) => elem['answer']['code']);
        } else {
          flattenedValue = value.answer['code'];
        }
      } else if (value.answerType === 'valueInteger') {
        flattenedValue = value.answer;
      } else if (value.answerType === 'valueDateTime') {
        flattenedValue = value.answer;
      } else if (value.answerType === 'valueDecimal') {
        flattenedValue = value.answer;
      } else if (value.answerType === 'valueString') {
        flattenedValue = value.answer;
      } else if (value.answerType === 'group') {
        const answerArray = value.answer as ExtractedItemAnswer[];
        const nestedAnswerStructure: {
          [key: string]: ExtractedItemAnswer;
        } = answerArray
          .filter(function (el) {
            return el != null;
          })
          .reduce(
            (struct, nestedValue) => ({
              ...struct,
              [nestedValue.linkId]: nestedValue,
            }),
            {}
          );
        flattenedValue = this.flattenQuestionnaireAnswers(nestedAnswerStructure);
      } else {
        console.warn({ key, value });
      }
      flattenedAnswers[key] = flattenedValue;
    });
    return flattenedAnswers;
  }

  getItemAnswerInfo(
    widget: WidgetConfig<unknown>,
    allAnswers: { [key: string]: unknown },
    enableWhenFunctions: {
      [key: string]: (formAnswers: { [key: string]: unknown }) => boolean;
    } = {}
  ): AnswerInfo {
    let rawAnswer = allAnswers?.[widget.name] ? allAnswers[widget.name] : null;
    if (rawAnswer === null) {
      const linkIds = widget.name.split(LINK_ID_SEPERATOR);
      if (linkIds.length > 1) {
        rawAnswer = allAnswers?.[linkIds[1]] ? allAnswers[linkIds[1]] : null;
        // console.log([linkIds, rawAnswer]);
      }
    }

    const isEnabled = widget.isShown(allAnswers, enableWhenFunctions);
    if (!isEnabled && rawAnswer === null) {
      return {
        rawAnswer: 'NOT_ANSWERABLE',
        optionObj: {
          code: 'NOT_ANSWERABLE',
          display: 'Skulle ikke besvares',
          designation: [
            { language: 'da-DK', value: 'Skulle ikke besvares' },
            { language: 'en-UK', value: 'Not answerable' },
          ],
          colorInterpretation: 'NONE',
          index: 0,
        } as OptionObject,
        colorCode: 'dashboard-item-not-answerable',
        isEnabled,
      };
    }
    if (rawAnswer === null) {
      return {
        rawAnswer: 'NO_ANSWER',
        optionObj: {
          code: 'NO_ANSWER',
          display: 'Ikke besvaret',
          designation: [
            { language: 'da-DK', value: 'Ikke besvaret' },
            { language: 'en-UK', value: 'Not answered' },
          ],
          colorInterpretation: 'NONE',
          index: 0,
        } as OptionObject,
        colorCode: 'dashboard-item-no-answer',
        isEnabled,
      };
    }
    if (widget.options === undefined || widget.options === null) {
      return {
        rawAnswer: rawAnswer,
        optionObj: {
          code: rawAnswer,
          display: rawAnswer,
          designation: [
            { language: 'da-DK', value: rawAnswer },
            { language: 'en-UK', value: rawAnswer },
          ],
          colorInterpretation: 'NONE',
          index: 0,
        } as OptionObject,
        colorCode: 'dashboard-item-none',
        isEnabled,
      };
    }
    const colors = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      RED: 'dashboard-item-red',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      YELLOW: 'dashboard-item-yellow',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      GREEN: 'dashboard-item-green',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      NONE: 'dashboard-item-none',
    };
    const getColorCode = (elem) => colors[elem.colorInterpretation] || 'danger';
    let optionObj: OptionObject | OptionObject[];
    let colorCode: string | string[];
    if (Array.isArray(rawAnswer)) {
      optionObj = rawAnswer.map((answerElem) => this.getOptionObj(answerElem, widget));
      colorCode = optionObj.map((elem) => getColorCode(elem));
    } else {
      optionObj = this.getOptionObj(rawAnswer, widget);
      colorCode = getColorCode(optionObj);
    }
    return { rawAnswer, optionObj, colorCode, isEnabled };
  }

  getOptionObj = (rawAnswer: unknown, widget: WidgetConfig<unknown>): OptionObject => {
    const answerOptionObj = widget.options.find((option) => option.code === rawAnswer) as OptionObject;
    if (answerOptionObj !== undefined) {
      return answerOptionObj;
    } else {
      return {
        code: rawAnswer,
        display: rawAnswer,
        designation: [
          { language: 'da-DK', value: rawAnswer },
          { language: 'en-UK', value: rawAnswer },
        ],
        colorInterpretation: 'NONE',
        index: 0,
      } as OptionObject;
    }
  };

  async createQuestionnaireResponse(props: {
    sessionId: string;
    questionnaireId: string;
    carePlanId: string;
    latestAnswerDate: string;
    formValues: Record<string, unknown>;
    subjectRef: fhir4.Reference;
    authorRef: fhir4.Reference;
    sourceRef: fhir4.Reference;
    responseExtensions: fhir4.Extension[];
    responseProfiles: string[];
    isAdaptive: boolean;
    widgets: IWidgetConfig<unknown>[];
    curLinkIdPath: string;
    isInitAdaptiveAction?: boolean;
    existingContainedQnr?: fhir4.Questionnaire;
    existingQuestionnaireResponse?: fhir4.QuestionnaireResponse;
  }): Promise<fhir4.QuestionnaireResponse> {
    /**
     * Start by handling matters related to questionnaire
     * potentially being adaptive
     */
    props.isInitAdaptiveAction = props.isInitAdaptiveAction ?? false;
    if (props.isAdaptive && !(props.responseProfiles ?? []).includes(ADAPTIVE_QUESTIONNAIRE_RESPONSE_PROFILE)) {
      props.responseProfiles = [...props.responseProfiles, ADAPTIVE_QUESTIONNAIRE_RESPONSE_PROFILE];
    }

    /* If response has no profiles, set responseProfiles prop to null */
    if (props.responseProfiles.length === 0) {
      props.responseProfiles = null;
    }

    /* We get hold of the Questionnaire resource */
    let questionnaire: fhir4.Questionnaire =
      props?.existingContainedQnr ??
      (await this.fhirService.resolveReference<fhir4.Questionnaire>({
        resourceType: HestiaFhirResourceTypeName.Questionnaire,
        resourceId: props.questionnaireId,
      }));

    /* Convert latestAnswerDate to ISO8601 string */
    // const authoredDate = new Date(props.latestAnswerDate).toISOString(); Removed because of missing timezone

    const authoredDate = getAuthoredDate(props.latestAnswerDate, props.existingQuestionnaireResponse);

    /* Calculate all response items */
    const allResponseItems: QuestionnaireResponseItem[] = [];
    if (!props.isInitAdaptiveAction) {
      for (const qnrItem of questionnaire.item.filter((x) => x.type !== 'display')) {
        const responseItem = await this.createQuestionnaireResponseItem({
          qnrItem,
          formValues: props.formValues,
          widgets: props.widgets,
          linkIdPathArray: [qnrItem.linkId],
        });
        allResponseItems.push(responseItem);
      }
    }

    /* Now we create the questionnaire response resource */
    const qnrResponse: fhir4.QuestionnaireResponse = {
      resourceType: 'QuestionnaireResponse',
      id: props.sessionId,
      meta: {
        versionId: '1.0.0',
        lastUpdated: authoredDate,
        profile: props.responseProfiles ?? undefined,
      },
      status: 'in-progress',
      questionnaire: questionnaire.url,
      basedOn: props.carePlanId !== null ? [{ reference: `CarePlan/${props.carePlanId}` }] : undefined,
      subject: props.subjectRef,
      author: props.authorRef,
      source: props.sourceRef,
      authored: authoredDate,
      extension: props.responseExtensions ?? undefined,
      item: allResponseItems,
    };

    qnrResponse.extension = addResponseStartTimeExt(qnrResponse.extension, props.existingQuestionnaireResponse);
    /*
     * We check if the value history ext is enabled on a module level
     */
    
    if (this.moduleConfig.enableValueHistoryExt) {
      qnrResponse.extension = addValueHistoryExt(qnrResponse.extension, props.formValues);
    }
    /*
     * Finally we handle contained questionnaire if it is response for an adaptive questionnaire
     */
    if (props.isAdaptive) {
      qnrResponse.extension = addLatestItemInAdaptiveResponseExt({
        exts: qnrResponse.extension,
        curLinkIdPath: props.curLinkIdPath,
      });
      qnrResponse.extension = addLatestGroupItemInAdaptiveResponseExt({
        exts: qnrResponse.extension,
        curLinkIdPath: props.curLinkIdPath,
      });
      if (props.isInitAdaptiveAction) {
        // If we are initiating adaptive questionnaire, disregard any existing items in QNR resource
        questionnaire = { ...questionnaire, item: [] };
      }
      qnrResponse.contained = [questionnaire];
    }
    return qnrResponse;
  }

  createValueCoding(answer: any, widget: IWidgetConfig<unknown>) {
    const usedOptionObject: OptionObject = widget?.options.find((x) => x.code === answer);
    return {
      valueCoding: {
        system: usedOptionObject?.system,
        code: answer,
        display: usedOptionObject?.display,
      },
    };
  }

  async createQuestionnaireResponseItem(props: {
    qnrItem: fhir4.QuestionnaireItem;
    formValues: Record<string, unknown>;
    widgets: IWidgetConfig<unknown>[];
    linkIdPathArray: string[];
  }) {
    const { qnrItem, formValues } = props;
    const ansItem: fhir4.QuestionnaireResponseItem = {} as fhir4.QuestionnaireResponseItem;
    ansItem.linkId = qnrItem.linkId;
    ansItem.text = qnrItem.text;
    const linkIdPath = props.linkIdPathArray.join(LINK_ID_SEPERATOR);
    const answerValue = formValues?.[linkIdPath];
    if (answerValue || qnrItem.type === 'group') {
      ansItem.answer = [];
      if (qnrItem.type === 'choice') {
        const itemWidget = this.sessionService.getWidgetByLinkIdPath({
          widgets: props.widgets,
          linkIdPath,
        });

        if (Array.isArray(answerValue)) {
          ansItem.answer = answerValue.map((elem) => {
            if (elem !== '' && elem !== null) {
              return this.createValueCoding(elem, itemWidget);
            }
          });
        } else {
          if (answerValue !== '' && answerValue !== null) {
            ansItem.answer = [this.createValueCoding(answerValue, itemWidget)];
          }
        }
      } else if (['integer', 'positiveInt', 'unsignedInt'].indexOf(qnrItem.type) !== -1) {
        if (answerValue !== '' && answerValue !== null) {
          ansItem.answer[0] = {
            valueInteger: +answerValue,
          };
        }
      } else if (qnrItem.type === 'decimal') {
        if (answerValue !== '' && answerValue !== null) {
          ansItem.answer[0] = {
            valueDecimal: +answerValue,
          };
        }
      } else if (qnrItem.type === 'boolean') {
        ansItem.answer[0] = {
          valueBoolean: answerValue as boolean,
        };
      } else if (qnrItem.type === 'attachment') {
        const rawValue = answerValue as string;
        const mimeType = rawValue.split(';')[0].split(':')[1];
        const base64Data = rawValue.split(';')[1].split(',')[1];
        ansItem.answer[0] = {
          valueAttachment: {
            contentType: mimeType,
            data: base64Data,
          },
        };
      } else if (['dateTime', 'time', 'date'].indexOf(qnrItem.type) !== -1) {
        ansItem.answer[0] = {
          [`value${this.capitalizeFirstLetter(qnrItem.type)}`]: answerValue,
        };
      } else if (qnrItem.type === 'group') {
        if (qnrItem?.repeats === true) {
          // Since it is a repeating group, we get answers for the group in an array
          const answerArray = // We have to check if we have any answers
            answerValue !== undefined ? (answerValue as Record<string, unknown>[]) : ([] as Record<string, unknown>[]);
          answerArray.forEach((answerObj) => {
            const anyAnswers = Object.values(answerObj).filter((val) => val !== '').length > 0;
            if (anyAnswers) {
              // We only want to add answers if we actually have any in the array
              const nestedAnswerItems = qnrItem.item.reduce(
                (curAnswerSet, nestedItem) => [
                  ...curAnswerSet,
                  ...[
                    this.createQuestionnaireResponseItem({
                      qnrItem: nestedItem,
                      formValues: answerObj,
                      widgets: props.widgets,
                      linkIdPathArray: [...props.linkIdPathArray, nestedItem.linkId],
                    }),
                  ],
                ],
                []
              );
              ansItem.answer.push({
                item: nestedAnswerItems,
              });
            }
          });
          if (ansItem.answer.length === 0) {
            // if we only had empty arrays, we delete the answer property
            delete ansItem.answer;
          }
        } else {
          // Doesn't repeat
          // Items are generally assumed not to repeat unless explicitly specified
          delete ansItem.answer; // As group doesn't repeat, we use ansItem.item to contain nested answers
          if (qnrItem?.item) {
            // We only want to add answers if group question has nested items
            ansItem.item = [];
            let groupValues: Record<string, unknown>;
            if (isObject(answerValue)) {
              groupValues = answerValue as Record<string, unknown>;
            } else {
              groupValues = formValues;
            }
            for (const childQuestion of qnrItem.item) {
              const childItem = await this.createQuestionnaireResponseItem({
                qnrItem: childQuestion,
                formValues: groupValues,
                widgets: props.widgets,
                linkIdPathArray: [...props.linkIdPathArray, childQuestion.linkId],
              });
              ansItem.item.push(childItem);
            }
          }
        }
      } else
        ansItem.answer[0] = {
          valueString: answerValue as string,
        };
    }
    if (Array.isArray(qnrItem.item) && qnrItem.type !== 'group') {
      // Note that we don't handle "group" questions here, we do that seperately
      ansItem.item = [];
      for (const childQuestion of qnrItem.item) {
        const childItem = await this.createQuestionnaireResponseItem({
          qnrItem: childQuestion,
          formValues,
          widgets: props.widgets,
          linkIdPathArray: [...props.linkIdPathArray, childQuestion.linkId],
        });
        ansItem.item.push(childItem);
      }
    }
    return ansItem;
  }

  capitalizeFirstLetter(str): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getContainedQnrFromResponse(qnrResponse: fhir4.QuestionnaireResponse): fhir4.Questionnaire {
    return qnrResponse.contained?.[0] as fhir4.Questionnaire;
  }

  /**
   * Removes a specific top-level linkId from both questionnaire response and its nested questionnaire
   *
   * Used for adaptive questionnaires when you click 'Previous' button
   *
   * @param groupLinkId linkId for top level item in questionnaire
   * @param qnrResponse Existing questionnaire response resource
   * @returns fhir4.QuestionnaireResponse
   */
  removeLatestGroupFromAdaptiveResponse(
    groupLinkId: string,
    qnrResponse: fhir4.QuestionnaireResponse
  ): fhir4.QuestionnaireResponse {
    const newResponse: fhir4.QuestionnaireResponse = { ...qnrResponse };
    newResponse.item = [...newResponse.item.filter((item) => item.linkId !== groupLinkId)];
    const newContainedQnr: fhir4.Questionnaire = {
      ...this.getContainedQnrFromResponse(newResponse),
    };
    newContainedQnr.item = [...newContainedQnr.item.filter((item) => item.linkId !== groupLinkId)];
    newResponse.contained = [newContainedQnr];
    return newResponse;
  }
}
