import { IQnrFormSession, InitFormSessionPayload } from '../../constants';
import { createAction, props } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { UpdateStr } from '@ngrx/entity/src/models';

export type RequiredUpdate<T> = UpdateStr<T> & { changes: Required<T> };

/**
 * Used to initiate a new questionnaire form session
 */
export const initSession = createAction(
  '[QnrFormSession/API] Init QnrFormSession',
  props<{
    session: InitFormSessionPayload;
  }>()
);

/**
 * Indicates a new questionnaire form session has been initialized successfully,
 * i.e. widgets have been calculated
 *
 * Marks the session with `initCompleted` = true
 */
export const initSessionSuccess = createAction(
  '[QnrFormSession/API] Init QnrFormSession Success',
  props<{
    session: RequiredUpdate<
      Pick<IQnrFormSession, 'widgets' | 'questionnaireResponse' | 'doesQnrResponseExistOnServer'>
    >;
  }>()
);

// TODO: Implement failure state for initialization of questionnaire form session
export const initSessionFailure = createAction(
  '[QnrFormSession/API] Init QnrFormSession failure',

  props<{ id: string; error: string }>()
);

export const submitSessionPage = createAction(
  '[QnrFormSession/API] Submit QnrFormSession page',
  props<{
    session: RequiredUpdate<Pick<IQnrFormSession, 'questionnaireResponse' | 'values'>>;
  }>()
);

export const submitSessionPageSuccess = createAction(
  '[QnrFormSession/API] Submit QnrFormSession page success',
  props<{
    session: RequiredUpdate<
      Pick<IQnrFormSession, 'curLinkIdPath' | 'questionnaireResponse' | 'doesQnrResponseExistOnServer' | 'widgets'>
    >;
    wasFinalPage: boolean;
  }>()
);

export const submitSessionPageFailure = createAction(
  '[QnrFormSession/API] Submit QnrFormSession page failure',
  props<{ id: string; error: string }>()
);

export const previousSessionPage = createAction(
  '[QnrFormSession/API] Previous QnrFormSession page',
  props<{ id: string }>()
);

export const previousSessionPageSuccess = createAction(
  '[QnrFormSession/API] Previous QnrFormSession page success',
  props<{
    session: RequiredUpdate<Pick<IQnrFormSession, 'curLinkIdPath' | 'questionnaireResponse'>>;
    wasFirstPage: boolean;
  }>()
);

export const previousSessionPageFailure = createAction(
  '[QnrFormSession/API] Previous QnrFormSession page failure',
  props<{ id: string; error: string }>()
);

export const updateSessionFormValues = createAction(
  '[QnrFormSession/API] Update QnrFormSession form values',
  props<{
    session: RequiredUpdate<Pick<IQnrFormSession, 'values' | 'latestAnswerDate'>>;
  }>()
);

export const updateSessionSettings = createAction(
  '[QnrFormSession/API] Update QnrFormSession settings',
  props<{
    session: Update<
      Pick<
        IQnrFormSession,
        | 'localVariables'
        | 'renderType'
        | 'curLinkIdPath'
        | 'allowMenu'
        | 'alertWhenEmptySubmit'
        | 'responseExtensions'
        | 'responseProfiles'
      >
    >;
  }>()
);

export const completeSession = createAction('[QnrFormSession/API] Complete QnrFormSession', props<{ id: string }>());

export const completeSessionSuccess = createAction(
  '[QnrFormSession/API] Complete QnrFormSession success',
  props<{ id: string }>()
);

export const completeSessionFailure = createAction(
  '[QnrFormSession/API] Complete QnrFormSession failure',
  props<{ id: string; error: string }>()
);

export const deleteSession = createAction('[QnrFormSession/API] Delete QnrFormSession', props<{ id: string }>());

export const deleteSessions = createAction('[QnrFormSession/API] Delete QnrFormSessions', props<{ ids: string[] }>());

export const clearSessions = createAction('[QnrFormSession/API] Clear QnrFormSessions');

export const qnrFormSessionActions = {
  initSession,
  initSessionSuccess,
  initSessionFailure,
  submitSessionPage,
  submitSessionPageSuccess,
  submitSessionPageFailure,
  previousSessionPage,
  previousSessionPageSuccess,
  previousSessionPageFailure,
  updateSessionFormValues,
  updateSessionSettings,
  completeSession,
  completeSessionSuccess,
  completeSessionFailure,
  deleteSession,
  deleteSessions,
  clearSessions,
};
