import {
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { widgetTypes } from '../components/widgets';
import { widgetConfigFactory } from '../components/widgets';
import { WidgetBaseComponent } from '../models/widget';
import { WidgetConfig } from '../models/widget.config';

@Directive({
  selector: '[hestiaWidget]',
})
export class WidgetRenderDirective implements OnChanges, OnInit {
  @Input() config: WidgetConfig<unknown>;
  @Input() form: UntypedFormGroup;
  @Input() renderStandalone = false;
  @Input() displayMode = false;
  widget: ComponentRef<WidgetBaseComponent>;

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnChanges(_changes: SimpleChanges) {
    this.createWidgetInstance();
  }

  ngOnInit() {
    this.createWidgetInstance();
  }

  createWidgetInstance() {
    this.viewContainerRef.clear();
    if (!widgetTypes[this.config.widgetType]) {
      const supportedTypes = Object.keys(widgetTypes).join(', ');
      throw new Error(
        `Trying to use an unsupported type (${this.config.widgetType}).
        Supported types: ${supportedTypes}`
      );
    }
    this.widget = this.viewContainerRef.createComponent(
      widgetTypes[this.config.widgetType]
    );

    // We reinitialize the widget config as it might be rehydrated version from NGRX/storage
    const config = {
      ...this.config,
      renderStandalone: this.renderStandalone,
    };
    if (this.displayMode) {
      config.disabled = true;
    }
    this.widget.instance.config = widgetConfigFactory(config);
    this.widget.instance.form = this.form;
    this.widget.changeDetectorRef.detectChanges();
  }
}
