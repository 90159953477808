import { createAction, props } from '@ngrx/store';

import { IUserControlledSettingsState } from './module-settings.reducer';

export const resetSettings = createAction(
  '[fhir-questionnaire-settings] reset'
);

export const updateSettings = createAction(
  '[fhir-questionnaire-settings] Update',
  // eslint-disable-next-line ngrx/prefer-inline-action-props
  props<Partial<IUserControlledSettingsState>>()
);

export const qnrModuleSettingsActions = {
  resetSettings,
  updateSettings
};