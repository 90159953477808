import { InjectionToken } from '@angular/core';

export interface IMitIdModuleConfig {
  loginUrl: string;
  callbackRoute: string;
  logoutUrl: string;
}

export const MITID_MODULE_CONFIG = new InjectionToken('MITID_MODULE_CONFIG');

export type MidIdOperationType = 'login' | 'slo';
export type MitIdOperationStatus = 'success' | 'failed';

export const MITID_MODULE_I18N_SCOPE = 'mitId';
