import { JwtHelperService } from '@auth0/angular-jwt';
import * as jose from 'jose';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';

import { AlertController, NavController } from '@ionic/angular';

import { interval, Observable, race } from 'rxjs';
import { of, throwError } from 'rxjs';
import { catchError, filter, first, map, switchMap, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { CORE_HESTIA_CONFIG, HestiaCommonService } from '@hestia/ngx-common';
import { HestiaAuthError, HestiaAuthErrorEnum, HestiaUserType } from '@hestia/ngx-types';
import { HestiaAuthEndpoint, IHestiaCoreConfig, IHestiaUserServerProfile } from '@hestia/ngx-types';

import { userActions } from './+state/user.actions';
import { IHestiaUserState } from './+state/user.reducer';
import { selectUserState } from './+state/user.selectors';
import { HESTIA_USER_MODULE_CONFIG, IHestiaUserModuleConfig, moduleI18nScope } from './constants';

export interface cprLookupResponse {
  fullname: string;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  postalCode: string;
}

export interface LogoutResponse {
  error?: {
    message: 'Unknown token';
    type: 'Logout failed';
  };
}

@Injectable({ providedIn: 'root' })
export class HestiaUserFacade {
  apiUrl: string;
  jwtHelper: JwtHelperService;
  userState$: Observable<IHestiaUserState> = this.store.select(selectUserState);
  jwt$: Observable<string> = this.userState$.pipe(
    first(),
    map((state) => state.jwt),
    tap((jwt) => jwt)
  );
  userFirstName$: Observable<string> = this.userState$.pipe(
    map((user) => (typeof user.fullName === 'string' ? user.fullName.split(' ')[0] : 'N/A'))
  );
  constructor(
    public http: HttpClient,
    public store: Store,
    public alertCtrl: AlertController,
    private hestiaCommon: HestiaCommonService,
    @Inject(CORE_HESTIA_CONFIG) private hestiaCoreConfig: IHestiaCoreConfig,
    @Inject(HESTIA_USER_MODULE_CONFIG)
    private hestiaUserModuleConfig: IHestiaUserModuleConfig
  ) {
    this.apiUrl = this.hestiaCoreConfig.apiUrl;
    this.jwtHelper = new JwtHelperService();
  }

  /* Rework */

  tokenExpired = (): Observable<boolean> => this.store.select(selectUserState).pipe(map((state) => true)); // WIP

  helloServer = (): Observable<unknown> => this.http.get<unknown>(`${this.apiUrl}`);

  doSignIn = (login: string, password: string): Observable<{ token: string }> =>
    this.http
      .post<{ token: string }>(`${this.apiUrl}@login`, { login, password })
      .pipe(catchError(this.handleLoginError));

  doSignOut = (): Observable<unknown> => this.http.post<unknown>(`${this.apiUrl}@auth-logout`, null);

  oneTimePassword = (ssn: string): Observable<{ auth_token: string; expires: string }> =>
    this.http.post<{ auth_token: string; expires: string }>(`${this.apiUrl}@phone-send-otp-code`, { ssn });

  oneTimePasswordConfirm = (auth_token: string, otp: string): Observable<{ token: string }> =>
    this.http.post<{ token: string }>(`${this.apiUrl}@phone-verify-otp-code`, { auth_token, otp });

  fetchUser = (fhirId: string): Observable<IHestiaUserState> =>
    this.http.get<IHestiaUserState>(`${this.apiUrl}@fhir-user/${fhirId}`);

  initKioskSession = (social_security_number: string): Observable<{ userid: string; patient_resource: any }> =>
    this.http.post<{ userid: string; patient_resource: any }>(this.apiUrl + `@kiosk-session`, {
      social_security_number,
    });

  getKioskSession = (): Observable<any> => this.http.get<any>(this.apiUrl + `@kiosk-session`);

  /** Project Specific */
  createCarePlan = (email: string): Observable<any> => this.http.post<any>(this.apiUrl + `@start-careplan`, { email });

  /** Project Specific */
  cprLookup = (cpr: string, lookup_reason: string): Observable<cprLookupResponse> =>
    this.http.post<cprLookupResponse>(this.apiUrl + `@cpr-lookup`, { cpr, lookup_reason });

  // **** //

  public isUserType$ = (userType: HestiaUserType): Observable<boolean> =>
    this.userState$.pipe(map((state) => state.userType === userType));

  public fhirId$ = (): Observable<string> => this.userState$.pipe(map((state) => state.fhirId));

  // TODO: Refactor so it isn't a function, but a deferred observable
  public isUserAuthenticated$(): Observable<boolean> {
    if (this.hestiaUserModuleConfig.syncHydratedKey) {
      // If we use local storage sync, wait for it to be completed before checking JWT
      return race(
        this.store
          // eslint-disable-next-line ngrx/prefer-selector-in-select
          .select((state) => state[this.hestiaUserModuleConfig.syncHydratedKey])
          .pipe(
            filter((hydrated) => {
              return hydrated === true;
            }),
            switchMap(() => this.jwt$),
            take(1),
            switchMap((jwt) => {
              const isTokenExpired = this.jwtHelper.isTokenExpired(jwt);
              return of(!isTokenExpired);
            })
          ),
        interval(2500).pipe(switchMap(() => of(false)))
      ).pipe(take(1));
    } else {
      // If we don't use local storage sync in the app, just immediately check present JWT from store
      return this.jwt$.pipe(map((jwt) => !this.jwtHelper.isTokenExpired(jwt))).pipe(take(1));
    }
  }

  public initLogin(login: string, password: string): void {
    this.store.dispatch(userActions.login({ login, password }));
  }

  public initOTPLogin(username: string): void {
    this.store.dispatch(userActions.otplogin({ username }));
  }

  public verifyOTPLogin(otpcode: string, auth_token: string): void {
    this.store.dispatch(userActions.otpverify({ otpcode, auth_token }));
  }

  public setJwt(token: string): void {
    this.store.dispatch(userActions.loginSuccess({ jwt: token }));
  }

  // See https://angular.io/guide/http#error-handling
  private handleLoginError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    let errorType: HestiaAuthErrorEnum;
    let errI18n: string;
    switch (error.status) {
      case 0:
        errorType = HestiaAuthErrorEnum.NoResponse;
        errI18n = `${moduleI18nScope}.error.auth.noResponse`;
        break;
      case 401:
        errorType = HestiaAuthErrorEnum.UsernameAndPasswordUnknownCredentials;
        errI18n = `${moduleI18nScope}.error.auth.unknownCredentials`;
        break;
      case 303:
        errorType = HestiaAuthErrorEnum.UsernameAndPasswordRequires2FA;
        errI18n = `${moduleI18nScope}.error.auth.requires2fa`;
        break;
      default:
        errorType = HestiaAuthErrorEnum.Unknown;
        errI18n = `${moduleI18nScope}.error.auth.defaultMsg`;
    }

    console.log(error.status);
    // return an ErrorObservable with a user-facing error message
    return throwError(() => new HestiaAuthError(errorType, 'USERNAME_AND_PASSWORD', errI18n, 'login'));
  }

  public loginRequest$ = (login: string, password: string): Observable<{ token: string }> =>
    this.http.post<{ token: string }>(`${this.apiUrl}${HestiaAuthEndpoint.Login}`, {
      login,
      password,
    });
  // .pipe(catchError(this.handleLoginError))

  public userProfileRequest$ = (): Observable<IHestiaUserServerProfile> =>
    this.jwt$.pipe(
      switchMap((jwt) => {
        const jwtProperties = this.jwtHelper.decodeToken(jwt);
        const userId = jwtProperties.sub;
        return this.http.get<IHestiaUserServerProfile>(`${this.apiUrl}${HestiaAuthEndpoint.User}/${userId}`);
      })
    );

  public otpLoginRequest$ = (username: string): Observable<{ auth_token: string; expires: string }> =>
    this.http
      .post<{ auth_token: string; expires: string }>(`${this.apiUrl}${HestiaAuthEndpoint.OTPLogin}`, {
        ssn: username,
      })
      .pipe(catchError(this.handleLoginError));

  public otpVerifyRequest$ = (otp: string, auth_token: string): Observable<{ token: string }> =>
    this.http
      .post<{ token: string }>(`${this.apiUrl}${HestiaAuthEndpoint.OTPVerify}`, {
        auth_token: auth_token,
        otp,
      })
      .pipe(catchError(this.handleLoginError));

  public logoutRequest$ = () =>
    this.http.post<Record<string, unknown>>(`${this.apiUrl}${HestiaAuthEndpoint.Logout}`, null).pipe(
      catchError((err) => {
        this.hestiaCommon.dismissLoader();
        return this.handleLogoutError(err);
      })
    );

  async initLogout(loaderMsg?: string): Promise<void> {
    // TODO: Refactor to use logoutSuccess and logoutFailure actions
    // await this.hestiaCommon.presentLoader(loaderMsg ? { message: loaderMsg } : undefined);
    this.store.dispatch(userActions.logoutFixed());
    return;
  }

  // See https://angular.io/guide/http#error-handling
  handleLogoutError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    let errorType: HestiaAuthErrorEnum;
    let errI18n: string;
    switch (error.status) {
      case 0:
        errorType = HestiaAuthErrorEnum.NoResponse;
        errI18n = `${moduleI18nScope}.error.auth.noResponse`;
        break;
      case 400:
        errorType = HestiaAuthErrorEnum.LogoutUnknownJwt;
        errI18n = `${moduleI18nScope}.error.auth.unknownJwt`;
        break;
      case 401:
        errorType = HestiaAuthErrorEnum.LogoutExpiredJwt;
        errI18n = `${moduleI18nScope}.error.auth.expiredJwt`;
        break;
      default:
        errorType = HestiaAuthErrorEnum.Unknown;
        errI18n = `${moduleI18nScope}.error.auth.logoutError`;
    }
    // return an ErrorObservable with a user-facing error message
    return throwError(() => new HestiaAuthError(errorType, 'USERNAME_AND_PASSWORD', errI18n, 'logout'));
  }
}
