/* eslint-disable ngrx/no-store-subscription */
import { Inject, Injectable } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import { first, forkJoin, map, Observable, switchMap } from 'rxjs';

import { Store } from '@ngrx/store';

import { HestiaFhirResourcesService, isValueSetResource } from '@hestia/ngx-fhir';
import { HestiaFhirResourceTypeName } from '@hestia/ngx-types';

import {
  BooleanWidgetConfig,
  CheckboxWidgetConfig,
  DateTimeWidgetConfig,
  GridGroupWidgetConfig,
  ImageSelectionWidgetConfig,
  LabelWidgetConfig,
  LikertRadioGroupWidgetWithImageConfig,
  LikertRadioGroupWidgetConfig,
  NumberWidgetConfig,
  RadioGroupWidgetConfig,
  RepeatableGroupWidgetConfig,
  SignWidgetConfig,
  SliderWidgetConfig,
  StringWidgetConfig,
  SvgGroupWidgetConfig,
  SvgSliderWidgetConfig,
  TextAreaWidgetConfig,
} from '../components/widgets';
import {
  IQnrFormSession,
  LINK_ID_SEPERATOR,
  QNR_CORE_MODULE_CONFIG,
  QNR_CORE_MODULE_TRANSLATIONS,
  RenderType,
} from '../constants';
// depricated
// import { getEnableWhenOperator } from '../models/fhir-extensions/questionnaire-item/item-enable-when-operator.fhir-ext';
import { getInvertedImagesExt } from '../models/fhir-extensions/questionnaire-item/inverted-images.fhir-ext';
import { getItemChoiceHideOptionLabels } from '../models/fhir-extensions/questionnaire-item/item-choice-hide-option-labels.fhir-ext';
import { getQnrItemChoiceOrientationExt } from '../models/fhir-extensions/questionnaire-item/item-choice-orientation.fhir-ext';
// Item extension imports
import { getItemDescription } from '../models/fhir-extensions/questionnaire-item/item-description.fhir-ext';
import { getCustomSelectionModalExt } from '../models/fhir-extensions/questionnaire-item/item-enable-custom-selection-modal.fhir-ext';
import { getEnableWhenFunction } from '../models/fhir-extensions/questionnaire-item/item-enable-when-function.fhir-ext';
import { getItemMaxOccursExt } from '../models/fhir-extensions/questionnaire-item/item-max-occurs.fhir-ext';
import { getItemMinOccursExt } from '../models/fhir-extensions/questionnaire-item/item-min-occurs.fhir-ext';
import { getRenderAsGridGroupExt } from '../models/fhir-extensions/questionnaire-item/item-render-as-grid-group.fhir-ext';
import { getRenderLabelAsMarkdown } from '../models/fhir-extensions/questionnaire-item/item-render-label-as-markdown.fhir-ext';
import { getSetVariableExt } from '../models/fhir-extensions/questionnaire-item/item-set-local-var.fhir-ext';
import { getItemSliderOrientationExt } from '../models/fhir-extensions/questionnaire-item/item-slider-orientation.fhir-ext';
import { getSuggestedVariableExt } from '../models/fhir-extensions/questionnaire-item/item-suggested-value.fhir-ext';
import { getSvgGroupExt } from '../models/fhir-extensions/questionnaire-item/item-svg-group';
import { getSvgGroupItemElement } from '../models/fhir-extensions/questionnaire-item/item-svg-group-item-element.fhir-ext';
import { getSvgSliderExt } from '../models/fhir-extensions/questionnaire-item/item-svg-slider.fhir-ext';
import { getValueBasedHealthTopic } from '../models/fhir-extensions/questionnaire-item/item-value-based-health-topic.fhir-ext';
import { getVariableInterpolationExt } from '../models/fhir-extensions/questionnaire-item/item-var-interpolation.fhir-ext';
import { IQnrFormSessionModuleConfig, OptionObject } from '../models/typings';
import { IWidgetConfig, WidgetConfig, WidgetGroupSettings } from '../models/widget.config';
import {
  selectAllQnrFormSessions,
  selectQnrFormSessionById,
} from '../store/qnr-form-session/qnr-form-session.selectors';
import { flattenValueSetConcepts } from '../utils/flatten-valueset-concepts';
import { isQnrAdaptive } from '../utils/is-qnr-adaptive';
import { mergeDeep } from '../utils/merge-deep.function';

@Injectable({
  providedIn: 'root',
})
export class HestiaQnrFormSessionService {
  formSessions: IQnrFormSession[] = [];
  public enableWhenFunctions: {
    [key: string]: (formAnswers: { [key: string]: unknown }) => boolean;
  };
  public rootPath = '/';
  constructor(
    public store: Store,
    private fhirService: HestiaFhirResourcesService,
    private transloco: TranslocoService,
    @Inject(QNR_CORE_MODULE_TRANSLATIONS) private coreTranslations,
    @Inject(QNR_CORE_MODULE_CONFIG)
    public moduleConfig: IQnrFormSessionModuleConfig
  ) {
    this.enableWhenFunctions = this.moduleConfig?.enableWhenFunctions ?? {};
    this.rootPath = this.moduleConfig?.rootPath ?? '/';
    Object.keys(this.coreTranslations).forEach((lang) => {
      this.transloco.setTranslation(this.coreTranslations[lang], lang, {
        merge: true,
      });
    });
    this.store.select(selectAllQnrFormSessions).subscribe((questionnaires) => (this.formSessions = questionnaires));
  }

  async resolveValueSet(url: string): Promise<fhir4.ValueSet> {
    const resource = this.fhirService.fhirResources.find((elem) => {
      if (isValueSetResource(elem)) {
        return elem.url === url;
      }
    });
    if (isValueSetResource(resource)) {
      return resource;
    }
    // TODO: We need a better solution for handling canonical URLs
    const valueSetId = url.split('/').pop();
    return this.fhirService.resolveReference<fhir4.ValueSet>({
      resourceType: HestiaFhirResourceTypeName.ValueSet,
      resourceId: valueSetId,
    });
  }

  getWidgetByLinkIdPath(props: { widgets: IWidgetConfig<unknown>[]; linkIdPath: string }): IWidgetConfig<unknown> {
    if (!props?.linkIdPath) {
      // If no linkIdPath provided, just grab
      return props.widgets[0];
    }
    for (const widget of props.widgets) {
      const match = widget.linkIdPath === props.linkIdPath;
      if (match) {
        return widget;
      }
      if (widget.childWidgets) {
        const matchedChildWidget = this.getWidgetByLinkIdPath({
          widgets: widget.childWidgets,
          linkIdPath: props.linkIdPath,
        });
        if (matchedChildWidget) {
          return matchedChildWidget;
        }
      }
    }
    return null;
  }

  calculateActiveWidgets(props: {
    allWidgets: WidgetConfig<unknown>[];
    linkIdPath: string;
    activityStatus: string;
    renderType: RenderType;
  }) {
    const pointerWidget: IWidgetConfig<unknown> = this.getWidgetByLinkIdPath({
      widgets: props.allWidgets,
      linkIdPath: props.linkIdPath,
    });
    const pageNumber = pointerWidget?.pageNumber ?? 1; // Defaults to page 1 if pointer widget not found
    //RICARDO ADDED CONDITION isQnrAdaptive - SO IS POSSIBLE TO EDIT ADAPTIVE QNR. MIKKEL COULD HAVE BETTER IDEA.
    if (props.renderType === 'fullEditable') {
      return props.allWidgets;
    }
    if (props.renderType === 'full' || (props.activityStatus === 'completed' && !isQnrAdaptive)) {
      if (props.activityStatus === 'completed') {
        return props.allWidgets.map((widget) => {
          widget.disabled = true;
          return widget;
        });
      } else {
        return props.allWidgets;
      }
    }
    const widgets: WidgetConfig<unknown>[] = [];
    props.allWidgets.forEach((widget, index) => {
      if (props.renderType === 'page' && pageNumber === widget.pageNumber) {
        widgets.push(widget);
      }
      if (props.renderType === 'widget' && pageNumber === index + 1) {
        // index starts at 0, we start the widget numbering from 1
        widget.firstInGroup = true; // Move this to a more atomic function
        widgets.push(widget);
      }
    });
    // If the activity is completed, disable all
    //RICARDO ADDED CONDITION isQnrAdaptive - SO IS POSSIBLE TO EDIT ADAPTIVE QNR. MIKKEL COULD HAVE BETTER IDEA.
    if (props.activityStatus === 'completed' && !isQnrAdaptive) {
      return widgets.map((widget) => {
        widget.disabled = true;
        return widget;
      });
    } else {
      return widgets;
    }
  }

  getQnrTitle(resource: fhir4.Questionnaire) {
    return resource.title ?? resource.name;
  }

  reduceItemExtensions(item: fhir4.QuestionnaireItem): {
    [key: string]: fhir4.Extension;
  } {
    if (item?.extension) {
      return item.extension.reduce(function (elemMap, extension) {
        elemMap[extension.url] = extension;
        return elemMap;
      }, {});
    } else {
      return {};
    }
  }

  getItemTranslations(args: {
    item: fhir4.QuestionnaireItem;
    extensions: { [key: string]: fhir4.Extension };
    widgetGroup?: WidgetGroupSettings;
  }): { [key: string]: { [key: string]: string } } {
    args.widgetGroup = args.widgetGroup || null;
    /*
     * We add the translations from the translation extensions
     */
    const extUrl = 'http://zitelab.eu/fhir/extensions/translations';
    const translationExtension = args.extensions[extUrl] ?? null;
    if (!translationExtension) {
      return {};
    }
    const itemTranslations = translationExtension.extension.reduce((elemMap, extension) => {
      elemMap[extension.url] = extension.extension.reduce((innerMap, translation) => {
        innerMap[translation.url] =
          translation.valueString !== undefined ? translation.valueString : translation.valueMarkdown;
        return innerMap;
      }, {});
      return elemMap;
    }, {});
    let widgetGroupTranslations;
    if (args.widgetGroup !== null && args.widgetGroup.translations !== null) {
      widgetGroupTranslations = Object.entries(args.widgetGroup.translations).reduce((innerMap, entry) => {
        innerMap[entry[0]] = { widgetGroup: entry[1]['text'] };
        return innerMap;
      }, {});
      const mergedTranslations = mergeDeep(itemTranslations, widgetGroupTranslations);
      return mergedTranslations;
    } else {
      return itemTranslations;
    }
  }

  addItemTranslations(args: {
    resourceId: string;
    itemLinkId: string;
    itemTranslations: { [key: string]: { [key: string]: string } };
  }) {
    const output = {};
    if (Object.keys(args.itemTranslations).length > 0) {
      for (const [language, translations] of Object.entries(args.itemTranslations)) {
        const i18n = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          FHIR: { [args.resourceId]: { [args.itemLinkId]: translations } },
        }; // e.g., {FHIR: {HAQ: {1.1.1|dressYourselfHaq: {text: "Dress yourself, including shoelaces and buttons?"}}}}
        this.transloco.setTranslation(i18n, language, { merge: true });
        output[language] = i18n;
      }
    }
    return `FHIR.${args.resourceId}.${args.itemLinkId}`;
  }

  async transformValueSetToOptionObjects(valueSetCanonicalUrl: string): Promise<OptionObject[]> {
    const translations: {
      [key: string]: {
        code: string;
        display: string;
        i18nName: string;
        imageUrl?: string;
      }[];
    } = {};

    const valueSet = (await this.resolveValueSet(valueSetCanonicalUrl)) as fhir4.ValueSet;
    if (valueSet === undefined) {
      console.error({ missingValueSet: valueSetCanonicalUrl });
      throw new Error('MissingValueSet: ' + JSON.stringify(valueSetCanonicalUrl));
    }
    const flattenedOptions = flattenValueSetConcepts(valueSet.compose.include);
    const options = flattenedOptions.map<OptionObject>((elem, index) => {
      const option: OptionObject = {
        ...elem,
        index: index,
        totalOptions: flattenedOptions.length,
        i18nName: `FHIR.${valueSet.id}.${elem.code}`,
        sourceType: 'ValueSet',
        source: valueSetCanonicalUrl,
        system: valueSet.compose.include[0].system,
      };
      if (option?.extension) {
        option.extension.forEach((extElem) => {
          if (extElem.url === 'http://zitelab.eu/fhir/extensions/valueSet-code-image') {
            option['imageUrl'] = extElem.valueString;
          } else if (extElem.url === 'http://zitelab.eu/fhir/extensions/valueSet-code-image-hide-text') {
            option.imageHideText = extElem.valueBoolean;
          } else if (extElem.url === 'http://zitelab.eu/fhir/extensions/valueSet-code-colorInterpretation') {
            option['colorInterpretation'] = extElem.valueString;
          } else if (extElem.url === 'http://zitelab.eu/fhir/extensions/valueSet-code-isExclusive') {
            option['isExclusive'] = extElem.valueBoolean;
          } else if (extElem.url === 'http://hl7.org/fhir/StructureDefinition/ordinalValue') {
            option['ordinalValue'] = extElem.valueDecimal;
          } else {
            console.error('Unknown extension type for valueSet: ', extElem);
          }
        });
      }
      if (option?.designation) {
        for (const designation of option.designation) {
          if (!translations?.[designation.language]) {
            translations[designation.language] = [
              {
                code: option.code,
                display: designation.value,
                i18nName: option['i18nName'],
              },
            ];
          } else {
            translations[designation.language] = [
              ...translations[designation.language],
              {
                code: option.code,
                display: designation.value,
                i18nName: option['i18nName'],
              },
            ];
          }
        }
      }
      return option;
    });
    if (Object.keys(translations).length > 0) {
      for (const [language, values] of Object.entries(translations)) {
        const i18n = values.reduce((elemMap, value) => {
          elemMap[value.code] = value.display;
          return elemMap;
        }, {});

        this.transloco.setTranslation(
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { FHIR: { [valueSet.id]: i18n } },
          language,
          { merge: true }
        );
      }
    }
    return options;
  }

  private async transformAnswerOptionsToOptionObjects(args: {
    item: fhir4.QuestionnaireItem;
    qnrId: string;
    linkIdPathArray: string[];
  }): Promise<OptionObject[]> {
    const options: OptionObject[] = [];
    for (const [index, answerOption] of args.item.answerOption.entries()) {
      // TODO: Support non-valueCoding uses of answerOption
      const code = answerOption.valueCoding.code;
      const display = answerOption.valueCoding.display;
      const i18nName = `FHIR.${args.qnrId}.${args.linkIdPathArray.join(LINK_ID_SEPERATOR)}.answerOption.${code}}`;
      const optionObj: OptionObject = {
        code,
        display,
        sourceType: 'answerOption',
        source: args.linkIdPathArray.join(LINK_ID_SEPERATOR),
        i18nName,
        index,
        totalOptions: args.item.answerOption.length,
        system: answerOption.valueCoding?.system,
      };
      if (answerOption?.extension) {
        answerOption.extension.forEach((extElem) => {
          if (extElem.url === 'http://hl7.org/fhir/StructureDefinition/ordinalValue') {
            optionObj['ordinalValue'] = extElem.valueDecimal;
          } else {
            console.error('Unknown extension type for valueSet: ', extElem);
          }
        });
      }
      const availableLanguages = this.transloco.getAvailableLangs();

      availableLanguages.forEach((language) => {
        if (language.id) {
          language = language.id;
        }

        this.transloco.setTranslation(
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { [i18nName]: display },
          language,
          { merge: true }
        );
      });
      options.push(optionObj);
    }
    return options;
  }

  private async getWidgetConfig(
    qnrId: string,
    item: fhir4.QuestionnaireItem,
    pageNumber: number,
    widgetGroup: WidgetGroupSettings,
    linkIdPathArray: string[],
    existingValues: Record<string, unknown>,
    metadata: {
      sessionId: string;
      carePlanId: string;
    }
  ): Promise<WidgetConfig<unknown>> {
    if (!item?.type) {
      /*
       * If the `type` attribute is not provided
       * then we do not include the item as a widget
       */
      return null;
    }
    let value: unknown = null;
    if (existingValues?.[item.linkId]) {
      value = existingValues[item.linkId];
    }
    let config: WidgetConfig<unknown> = null,
      itemDescription = null;
    let options: OptionObject[];
    const extensions = this.reduceItemExtensions(item);
    const itemTranslations = this.getItemTranslations({
      item,
      extensions,
      widgetGroup,
    });
    const i18nBasePath = this.addItemTranslations({
      resourceId: qnrId,
      itemLinkId: item.linkId,
      itemTranslations,
    });
    const widgetName = linkIdPathArray.join(LINK_ID_SEPERATOR);
    const coreConfig: Partial<WidgetConfig<unknown>> = {
      name: widgetName,
      linkIdPath: widgetName,
      linkIdPathArray: linkIdPathArray,
      linkId: item.linkId,
      pageNumber: pageNumber,
      widgetGroup: widgetGroup,
      value: value,
      required: item.required,
    };
    switch (item.type) {
      case 'group':
        if (item.repeats === true) {
          config = new RepeatableGroupWidgetConfig({});
          break;
        } else {
          const gridGroupExt = getRenderAsGridGroupExt(item);
          const svgGroupExt = getSvgGroupExt(item);
          if (svgGroupExt.isSvgGroup) {
            config = new SvgGroupWidgetConfig({
              ...coreConfig,
              svgSourceType: svgGroupExt.svgSourceType,
              svgSource: svgGroupExt.svgSource,
            });
            break;
          } else if (gridGroupExt.isGridGroup) {
            config = new GridGroupWidgetConfig({
              ...coreConfig,
              gridGroupConfig: gridGroupExt.gridGroupConfig,
            });
            break;
          }
          // We don't turn non-repeatable groups into widgets if they are not SVG or grid groups
          return null;
        }
      case 'display':
        config = new LabelWidgetConfig({
          name: widgetName,
          pageNumber: pageNumber,
          widgetGroup: widgetGroup,
        });
        break;
      case 'choice':
        if (!!item.answerOption && !!item.answerValueSet) {
          throw Error(`Error in item '${item.linkId}': A question cannot have both answerOption and answerValueSet`);
        }
        if (item?.answerOption) {
          options = await this.transformAnswerOptionsToOptionObjects({
            item,
            qnrId,
            linkIdPathArray,
          });
        } else {
          options = await this.transformValueSetToOptionObjects(item.answerValueSet);
        }
        // eslint-disable-next-line no-var
        var isImageOptions = options.filter((option) => !!option?.imageUrl).length === options.length;
        if (item?.repeats === true) {
          config = new CheckboxWidgetConfig({
            ...coreConfig,
            // TODO: Need support for ValueSet 'expansion' and 'exclude' in option calculation
            options: options,
          });
          if (item?.extension) {
            // the FHIR resource is already validated at this point
            Object.assign(config, getItemMaxOccursExt(item));
            Object.assign(config, getItemMinOccursExt(item));
          }
        } else {
          if (isImageOptions) {
            config = new ImageSelectionWidgetConfig({
              ...coreConfig,
              // TODO: Need some sort of error handling for value set
              // TODO: Need support for ValueSet 'expansion' and 'exclude' in option calculation
              options: options,
            });
          } else {
            const choiceListOrientation = getQnrItemChoiceOrientationExt(item);
            if (choiceListOrientation?.choiceListOrientation === 'horizontal') {
              config = new LikertRadioGroupWidgetConfig({
                ...coreConfig,
                options: options,
                ...choiceListOrientation,
                ...getItemChoiceHideOptionLabels(item),
                ...getInvertedImagesExt(item),
              });
              if (item?.extension) {
                // the FHIR resource is already validated at this point
                item.extension.map((extension) => {
                  if (extension.url === 'http://hl7.org/fhir/StructureDefinition/questionnaire-lowRangeLabel') {
                    config.setTranslatableProperty({
                      property: 'leftLabel',
                      value: extension.valueString,
                      i18nPath: i18nBasePath,
                      i18nName: 'questionnaire-lowRangeLabel',
                      overwriteProperty: true,
                    });
                  }
                  if (extension.url === 'http://hl7.org/fhir/StructureDefinition/questionnaire-highRangeLabel') {
                    config.setTranslatableProperty({
                      property: 'rightLabel',
                      value: extension.valueString,
                      i18nPath: i18nBasePath,
                      i18nName: 'questionnaire-highRangeLabel',
                      overwriteProperty: true,
                    });
                  }
                });
              }
            } else if (choiceListOrientation?.choiceListOrientation === 'horizontal-image') {
              config = new LikertRadioGroupWidgetWithImageConfig({
                ...coreConfig,
                options: options,
                ...choiceListOrientation,
                ...getItemChoiceHideOptionLabels(item),
                ...getInvertedImagesExt(item),
              });
              if (item?.extension) {
                // the FHIR resource is already validated at this point
                item.extension.map((extension) => {
                  if (extension.url === 'http://hl7.org/fhir/StructureDefinition/questionnaire-lowRangeLabel') {
                    config.setTranslatableProperty({
                      property: 'leftLabel',
                      value: extension.valueString,
                      i18nPath: i18nBasePath,
                      i18nName: 'questionnaire-lowRangeLabel',
                      overwriteProperty: true,
                    });
                  }
                  if (extension.url === 'http://hl7.org/fhir/StructureDefinition/questionnaire-highRangeLabel') {
                    config.setTranslatableProperty({
                      property: 'rightLabel',
                      value: extension.valueString,
                      i18nPath: i18nBasePath,
                      i18nName: 'questionnaire-highRangeLabel',
                      overwriteProperty: true,
                    });
                  }
                });
              }
            } else {
              config = new RadioGroupWidgetConfig({
                ...coreConfig,
                // TODO: Need support for ValueSet 'expansion' and 'exclude' in option calculation
                options: options,
              });
            }
          }
        }
        break;
      case 'string':
        config = new StringWidgetConfig({
          ...coreConfig,
        });
        break;
      case 'text':
        config = new TextAreaWidgetConfig({
          ...coreConfig,
          ...getCustomSelectionModalExt(item),
        });
        break;
      case 'boolean':
        config = new BooleanWidgetConfig({
          ...coreConfig,
        });
        break;
      case 'date':
        config = new DateTimeWidgetConfig({
          ...coreConfig,
          datetimeWidgetFormat: 'date',
        });
        break;
      case 'dateTime':
        config = new DateTimeWidgetConfig({
          ...coreConfig,
          datetimeWidgetFormat: 'date-time',
        });
        break;
      case 'time':
        config = new DateTimeWidgetConfig({
          ...coreConfig,
          datetimeWidgetFormat: 'time',
        });
        break;
      case 'attachment':
        config = new SignWidgetConfig({
          ...coreConfig,
        });
        break;
      case 'decimal':
        config = new NumberWidgetConfig({
          ...coreConfig,
        });
        break;
      case 'integer':
        if (this.isSliderWidget(item)) {
          if (this.isSvgSliderWidget(item)) {
            const svgProps = getSvgSliderExt(item);
            config = new SvgSliderWidgetConfig({
              name: item.linkId,
              value: value,
              required: item.required,
              pageNumber: pageNumber,
              widgetGroup: widgetGroup,
              ...getItemSliderOrientationExt(item),
              ...svgProps,
            });
          } else {
            config = new SliderWidgetConfig({
              name: item.linkId,
              value: value,
              required: item.required,
              pageNumber: pageNumber,
              widgetGroup: widgetGroup,
              ...getItemSliderOrientationExt(item),
            });
          }
        } else {
          config = new NumberWidgetConfig({
            ...coreConfig,
          });
        }
        if (item?.extension) {
          // the FHIR resource is already validated at this point
          item.extension.forEach((extension) => {
            if (extension.url === 'http://hl7.org/fhir/StructureDefinition/minValue') {
              config['minValue'] = extension.valueInteger;
            }
            if (extension.url === 'http://hl7.org/fhir/StructureDefinition/maxValue') {
              config['maxValue'] = extension.valueInteger;
            }
            if (extension.url === 'http://zitelab.eu/fhir/extensions/questionnaire-showRangeMinValue') {
              config['displayMinValue'] = extension.valueBoolean;
            }
            if (extension.url === 'http://zitelab.eu/fhir/extensions/questionnaire-showRangeMaxValue') {
              config['displayMaxValue'] = extension.valueBoolean;
            }
            if (extension.url === 'http://hl7.org/fhir/StructureDefinition/questionnaire-lowRangeLabel') {
              config.setTranslatableProperty({
                property: 'leftLabel',
                value: extension.valueString,
                i18nPath: i18nBasePath,
                i18nName: 'questionnaire-lowRangeLabel',
                overwriteProperty: true,
              });
            }
            if (extension.url === 'http://zitelab.eu/fhir/extensions/questionnaire-showRangeCurrentValue') {
              config['displayCurrentValue'] = extension.valueBoolean;
            }
            if (extension.url === 'http://hl7.org/fhir/StructureDefinition/questionnaire-highRangeLabel') {
              config.setTranslatableProperty({
                property: 'rightLabel',
                value: extension.valueString,
                i18nPath: i18nBasePath,
                i18nName: 'questionnaire-highRangeLabel',
                overwriteProperty: true,
              });
            }
          });
        }
        break;
      default:
        console.error([`Could not turn the following item into a widget`, item]);
    }
    if (config !== null && widgetGroup !== null) {
      config.setTranslatableProperty({
        property: 'widgetGroup',
        value: widgetGroup.label,
        i18nPath: i18nBasePath,
        i18nName: 'widgetGroup',
        overwriteProperty: false,
      });
    }
    if (item?.enableWhen) {
      config.enableWhen = item.enableWhen;
    }
    if (item?.enableBehavior) {
      config.enableBehavior = item.enableBehavior;
    }
    config.setTranslatableProperty({
      property: 'label',
      value: item.text,
      i18nPath: i18nBasePath,
      i18nName: 'text',
      overwriteProperty: true,
    });
    itemDescription = getItemDescription(item);
    if (itemDescription?.descriptionText) {
      config.setTranslatableProperty({
        property: 'descriptionText',
        value: itemDescription.descriptionText,
        i18nPath: i18nBasePath,
        i18nName: 'QuestionnaireItemDescription',
        overwriteProperty: true,
      });
    }
    Object.assign(config, itemDescription);
    Object.assign(
      config,
      { fhirItem: item },
      { metadata: metadata },
      { prefix: item.prefix ?? undefined },
      getValueBasedHealthTopic(item),
      // depricated so remvoed by tareq
      // getEnableWhenOperator(item),
      getEnableWhenFunction(item),
      getRenderLabelAsMarkdown(item),
      getSetVariableExt(item),
      getVariableInterpolationExt(item),
      getSuggestedVariableExt(item),
      getSvgGroupItemElement(item)
    );
    return config;
  }

  public async getWidgets(props: {
    sessionId: string;
    carePlanId: string;
    questionnaireId: string;
    values: Record<string, unknown>;
    pageNumber?: number;
    widgetGroup?: WidgetGroupSettings;
    inRecursion?: boolean;
    items?: fhir4.QuestionnaireItem[];
    linkIdPaths: string[];
  }): Promise<WidgetConfig<unknown>[]> {
    /** Set default values */
    props.pageNumber = props.pageNumber ?? 1;
    props.widgetGroup = props.widgetGroup ?? null;
    props.inRecursion = props.inRecursion ?? false;
    props.linkIdPaths = props.linkIdPaths ?? [];
    props.values = props.values ?? {};

    let widgets: WidgetConfig<unknown>[] = [];
    let nextPageNumber: number = props.pageNumber;
    for (const item of props.items) {
      if (item.type === 'group') {
        const itemExtensions = this.reduceItemExtensions(item);
        props.widgetGroup = {
          id: item.linkId,
          label: item.text,
          translations: this.getItemTranslations({
            item: item,
            extensions: itemExtensions,
            widgetGroup: null,
          }),
        };
      } else {
        if (!props.inRecursion) {
          props.widgetGroup = null;
        }
      }
      const widgetConfig: WidgetConfig<unknown> = await this.getWidgetConfig(
        props.questionnaireId,
        item,
        nextPageNumber,
        props.widgetGroup,
        [...props.linkIdPaths, item.linkId],
        props.values,
        {
          sessionId: props.sessionId,
          carePlanId: props.carePlanId,
        }
      );
      let nestedWidgets: WidgetConfig<unknown>[] = [];
      if (item?.item) {
        nestedWidgets = await this.getWidgets({
          sessionId: props.sessionId,
          carePlanId: props.carePlanId,
          questionnaireId: props.questionnaireId,
          values: props.values,
          items: item.item,
          pageNumber: nextPageNumber,
          widgetGroup: props.widgetGroup,
          linkIdPaths: [...props.linkIdPaths, item.linkId],
          inRecursion: true,
        });
      }
      if (item.type === 'group') {
        if (item.repeats === true) {
          const repeatableGroupConfig = widgetConfig as RepeatableGroupWidgetConfig;
          repeatableGroupConfig.childWidgets = nestedWidgets;
          widgets.push(repeatableGroupConfig);
        } else if (widgetConfig !== null && widgetConfig.widgetType === 'SvgGroupWidget') {
          const svgGroupConfig = widgetConfig as SvgGroupWidgetConfig;
          svgGroupConfig.childWidgets = nestedWidgets;
          widgets.push(svgGroupConfig);
        } else if (widgetConfig !== null && widgetConfig.widgetType === 'GridGroupWidget') {
          const gridGroupConfig = widgetConfig as GridGroupWidgetConfig;
          gridGroupConfig.childWidgets = nestedWidgets;
          widgets.push(gridGroupConfig);
        } else {
          nestedWidgets.forEach((nestedWidgetConfig) => {
            widgets.push(nestedWidgetConfig);
          });
        }
      } else {
        widgets.push(widgetConfig);
      }
      if (!props.inRecursion) {
        // We don't want to increase the page number if we are recursing for a group
        nextPageNumber = nextPageNumber + 1;
      }
    }
    if (!props.inRecursion) {
      /*
       * Here we can calculate configuration settings that
       * require knowledge of the preceding and/or subsequent widgets
       */
      widgets = widgets.map((widget, index, widgetArray) => {
        widget.widgetNumber = index + 1;
        if (widget.widgetGroup !== null) {
          if (index === 0 || widgetArray[index - 1].widgetGroup !== widget.widgetGroup) {
            widget.firstInGroup = true;
          } else {
            const prevWidget = widgetArray[index - 1];
            if (prevWidget.widgetGroup !== null) {
              widget.firstInGroup = prevWidget.widgetGroup.id !== widget.widgetGroup.id;
            } else {
              widget.firstInGroup = true;
            }
          }
        }
        return widget;
      });
    }
    return widgets;
  }

  private isSliderWidget(item: fhir4.QuestionnaireItem): boolean {
    // TODO: Refactor this function away
    let result = false;
    const vasExtensions = [
      'http://zitelab.eu/fhir/extensions/questionnaire-showRangeMinValue',
      'http://zitelab.eu/fhir/extensions/questionnaire-showRangeMaxValue',
      'http://hl7.org/fhir/StructureDefinition/questionnaire-lowRangeLabel',
      'http://hl7.org/fhir/StructureDefinition/questionnaire-highRangeLabel',
      'http://zitelab.eu/fhir/extensions/questionnaire-showRangeSnaps',
      'http://zitelab.eu/fhir/extensions/questionnaire-showRangeCurrentValue',
    ];
    if (item?.extension) {
      // the FHIR resource is already validated at this point
      item.extension.forEach((extension) => {
        if (vasExtensions.indexOf(extension.url) > -1) {
          result = true;
        }
      });
    }
    return result;
  }

  private isSvgSliderWidget(item: fhir4.QuestionnaireItem): boolean {
    // TODO: Refactor this function away
    let result = false;
    const svgSliderExtensions = ['http://zitelab.eu/fhir/extensions/questionnaire-item-slider-svg'];
    if (item?.extension) {
      // the FHIR resource is already validated at this point
      item.extension.forEach((extension) => {
        if (svgSliderExtensions.indexOf(extension.url) > -1) {
          result = true;
        }
      });
    }
    return result;
  }

  public findTargetLinkIdPathInfo(props: {
    renderType: RenderType;
    prevLinkIdPath: string;
    direction: 'next' | 'current' | 'previous';
    formAnswers: { [key: string]: unknown };
    widgets: WidgetConfig<unknown>[];
  }): {
    pageNumber: number;
    widget: WidgetConfig<unknown>;
    targetLinkIdPath: string;
    wasFinalPage: boolean;
  } {
    const curWidget: IWidgetConfig<unknown> = this.getWidgetByLinkIdPath({
      widgets: props.widgets,
      linkIdPath: props.prevLinkIdPath,
    });
    const curNumber = curWidget?.pageNumber ?? 1; // Fallback to first page
    const numberVar: 'widgetNumber' | 'pageNumber' = props.renderType === 'widget' ? 'widgetNumber' : 'pageNumber';
    let widgetsToCheck: WidgetConfig<unknown>[];
    switch (props.direction) {
      case 'next':
        widgetsToCheck = props.widgets.filter((elem) => elem[numberVar] > curNumber);
        break;
      case 'previous':
        widgetsToCheck = props.widgets.filter((elem) => elem[numberVar] < curNumber).reverse();
        break;
      default:
        // 'current'
        widgetsToCheck = props.widgets.filter((elem) => elem[numberVar] === curNumber);
        break;
    }
    const widget: WidgetConfig<unknown> = widgetsToCheck.find((checkWidget) =>
      checkWidget.isShown(props.formAnswers, this.enableWhenFunctions)
    );
    if (widget === undefined || props.renderType === 'full' || props.renderType === 'fullEditable') {
      return {
        pageNumber: null,
        widget: null,
        targetLinkIdPath: null,
        wasFinalPage: true,
      }; // This is equal to submit of full questionnnaire
    }
    const newNumber = props.renderType === 'widget' ? widget.widgetNumber : widget.pageNumber;
    const result = {
      pageNumber: newNumber,
      widget,
      targetLinkIdPath: widget.linkIdPath,
      wasFinalPage: false,
    };
    return result;
  }

  public sessionWithQnr$ = (
    sessionId: string,
    options: { widgetsMustBeLoaded: boolean } = { widgetsMustBeLoaded: true }
  ): Observable<{
    session: IQnrFormSession;
    questionnaire: fhir4.Questionnaire;
  }> =>
    this.store.select(selectQnrFormSessionById(sessionId)).pipe(
      first((session) => session !== null && (!options.widgetsMustBeLoaded || session.initCompleted === true)),
      switchMap((session) =>
        this.fhirService
          .resolveReference$<fhir4.Questionnaire>({
            resourceId: session.questionnaireId,
            resourceType: HestiaFhirResourceTypeName.Questionnaire,
          })
          .pipe(map((result) => ({ session: session, questionnaire: result })))
      )
    );

  public sessionWithReferencedResources$ = (
    sessionId: string,
    options: { widgetsMustBeLoaded: boolean } = { widgetsMustBeLoaded: true }
  ): Observable<{
    session: IQnrFormSession;
    questionnaire: fhir4.Questionnaire;
    carePlan: fhir4.CarePlan;
  }> =>
    this.store.select(selectQnrFormSessionById(sessionId)).pipe(
      first((session) => session !== null && (!options.widgetsMustBeLoaded || session.initCompleted === true)),
      switchMap((session) =>
        forkJoin([
          this.fhirService.resolveReference$<fhir4.Questionnaire>({
            resourceId: session.questionnaireId,
            resourceType: HestiaFhirResourceTypeName.Questionnaire,
          }),
          this.fhirService.resolveReference$<fhir4.CarePlan>({
            resourceId: session.carePlanId,
            resourceType: HestiaFhirResourceTypeName.CarePlan,
          }),
        ]).pipe(
          map(([questionnaire, carePlan]) => ({
            session,
            questionnaire,
            carePlan,
          }))
        )
      )
    );
}
