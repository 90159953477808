import { WidgetConfig } from '../../../models/widget.config';

export class BooleanWidgetConfig extends WidgetConfig<boolean> {
  widgetType = 'BooleanWidget';
  constructor(config: {} = {}) {
    super(config);
    if (this.defaultValue === null) {
      this.defaultValue = false;
    }
  }
}
