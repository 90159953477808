import { CustomStepDefinition, Options } from '@m0t0r/ngx-slider';

import {
  Component,
  Input,
  ElementRef,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';

import { Store } from '@ngrx/store';

import { WidgetBaseComponent } from '../../../models/widget';
import { Events } from '../../../services/events.service';
import { HestiaQnrFormSessionService } from '../../../services/qnr-form-session.service';
import { SliderWidgetConfig } from './slider-widget.config';

@Component({
  selector: 'hestia-slider-widget',
  templateUrl: './slider-widget.component.html',
  styleUrls: ['./slider-widget.component.scss'],
})
export class SliderWidgetComponent
  extends WidgetBaseComponent
  implements OnInit, OnChanges, OnDestroy
{
  constructor(
    transloco: TranslocoService,
    questionnaireService: HestiaQnrFormSessionService,
    store: Store,
    element: ElementRef,
    events: Events
  ) {
    super(transloco, questionnaireService, store, element, events);
  }
  @Input() config: SliderWidgetConfig;
  @Input() form: UntypedFormGroup = null;
  optionsObj: Options;
  get isValid() {
    return true;
  }
  get formControl() {
    return this.form?.get(this.config.name) ?? null;
  }

  ngOnInit() {
    super.ngOnInit();
    this.setOptionsForSlider();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const configChange = changes?.config ?? null;
    if (configChange) {
      this.setOptionsForSlider();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  setOptionsForSlider(): void {
    this.optionsObj = {
      floor: this.config.minValue,
      ceil: this.config.maxValue,
      showTicks: false,
      showTicksValues: false,
      step: this.config.stepSize,
      hideLimitLabels: !(
        this.config.displayMinValue || this.config.displayMaxValue
      ),
      hidePointerLabels: !this.config.displayCurrentValue,
      disabled: this.config.disabled,
      showSelectionBar: true,
      boundPointerLabels: true,
      getPointerColor: (_value: number) => this.getPrimaryAppColor(),
      getSelectionBarColor: (_minValue: number, _maxValue?: number) =>
        this.getPrimaryAppColor(),
      getLegend: (value: number): string => {
        if (value === this.config.minValue && this.config.leftLabel !== null) {
          return this.config.leftLabel;
        }
        if (value === this.config.maxValue && this.config.rightLabel !== null) {
          return this.config.rightLabel;
        }
        return null;
      },
      vertical: this.config.sliderOrientation === 'vertical',
    };
    setTimeout(() => {
      if (!this.config.disabled && this.config.required) {
        // We only add the error if value is required and widget is not disabled
        const formControl = this.form.get(this.config.name);
        const value = formControl.value;
        if (value === 0) {
          formControl.setErrors({ untouched: true });
        }
      }
    }, 100);
  }

  onUserChangeEnd(_event) {
    this.form.get(this.config.name).setErrors(null);
  }

  isUntouched(_name: string) {
    const formControl = this.form.get(this.config.name);
    return formControl?.hasError('untouched') ?? true;
  }

  getPrimaryAppColor() {
    /*
     * We cannot get the primary color of the app other than by getting it from other elements
     */
    const btnEl = document.getElementsByClassName(
      'questionnaire-next-or-submit-btn'
    )[0];
    if (btnEl === undefined) {
      return 'black';
    }
    const color = window.getComputedStyle(btnEl).getPropertyValue('color');
    if (color === '' || color === 'rgb(255, 255, 255)') {
      return 'black';
    }
    return color;
  }

  getStepsArray() {
    // WIP - do not activate
    const stepsArray: CustomStepDefinition[] = [];
    if (this.config.leftLabel !== null) {
      stepsArray.push({
        value: this.config.minValue,
        legend: this.config.leftLabel,
      });
    }
    if (this.config.rightLabel !== null) {
      stepsArray.push({
        value: this.config.maxValue,
        legend: this.config.rightLabel,
      });
    }
    return stepsArray.length > 0 ? stepsArray : undefined;
  }
}
