<hestia-widget-group-header
  [config]="config"
  [i18n]="i18n"
></hestia-widget-group-header>
<ion-list
  [formGroup]="form"
  lines="none"
  (keydown.enter)="$event.preventDefault()"
>
  <ion-list-header>
    <ion-label style="display: contents;"
      [attr.for]="config.label"
      position="stacked"
      class="ion-text-wrap"
    >
      <hestia-widget-header
        [config]="config"
        [i18n]="i18n"
      ></hestia-widget-header>
    </ion-label>
  </ion-list-header>
  <ion-item lines="full">
    <ion-textarea
      type="text"
      [autoGrow]="true"
      rows="1"
      [virtualKeyboard]="{
        value: form?.get(config.name).value,
        keyboardType: 'alphanumerical',
        inputId: config.name
      }"
      (updateVirtualKeyboardValue)="newVirtualKeyboardValue($event)"
      [disabled]="config.disabled"
      [attr.value]="config.value || config.defaultValue"
      [formControlName]="config.name"
      [placeholder]="'IFQ.STRING_WIDGET.PLACEHOLDER' | transloco"
      [id]="config.name"
    ></ion-textarea>
  </ion-item>
</ion-list>
<div [ngClass]="config.name + '-virtual-keyboard'"></div>
