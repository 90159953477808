<hestia-widget-group-header [config]="config" [i18n]="i18n"></hestia-widget-group-header>
<ion-item [formGroup]="form" lines="none" padding>
    <ion-label [attr.for]="config.label" position="stacked">
        <hestia-widget-header [config]="config" [i18n]="i18n"></hestia-widget-header>
    </ion-label>
    <ion-textarea [autoGrow]="true" [autofocus]="true" [virtualKeyboard]="{
      value: form.get(config.name).value,
      keyboardType: 'alphanumerical',
      inputId: config.name
    }" (updateVirtualKeyboardValue)="newVirtualKeyboardValue($event)" (click)="newVirtualKeyboardValue($event)" [attr.value]="config.value || config.defaultValue" [formControlName]="config.name" [placeholder]="'IFQ.TEXTAREA_WIDGET.PLACEHOLDER' | transloco"
        [id]="config.name"></ion-textarea>
</ion-item>
<div [ngClass]="config.name + '-virtual-keyboard'"></div>
<ion-row *ngIf="config.isCustomSelectionModalEnabled" style="padding-left: 0px; padding-right: 0px">
    <ion-col col-6 col-lg-3 style="padding-left: 0px">
        <button ion-button block outline icon-left color="secondary" (click)="customSelectionModalOpen($event)">
      <ion-icon name="color-wand"></ion-icon>
      {{ config.customSelectionModalConfig.btnText }}
    </button>
    </ion-col>
</ion-row>