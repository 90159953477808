import { OptionObject } from "../models/typings";

export const flattenValueSetConcepts = (
  includeArray: fhir4.ValueSetComposeInclude[]
): OptionObject[] => {
  const options = [];
  includeArray.forEach((elem) => {
    elem.concept.forEach((concept) => {
      options.push(concept);
    });
  });
  return options;
};
