/* eslint-disable ngrx/avoid-mapping-selectors */
import { da } from 'date-fns/locale';
import { CarePlanActivity } from 'fhir/r2';
import { DateFnsModule } from 'ngx-date-fns';

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { TranslocoModule } from '@ngneat/transloco';

import { firstValueFrom } from 'rxjs';

import { Store } from '@ngrx/store';

import { HestiaFhirResourcesService, fhirResourceActions, selectCarePlanResources } from '@hestia/ngx-fhir';
import { HestiaUserFacade, selectUserState, userActions } from '@hestia/ngx-user';

import { HestiaPagesService } from '../../service';

interface PageData {
  allowCreation?: boolean;
  instructionsText?: string;
  autoHandleCreateCarePlan?: boolean;
}

@Component({
  standalone: true,
  selector: 'hestia-home',
  imports: [CommonModule, DateFnsModule, FormsModule, ReactiveFormsModule, TranslocoModule, IonicModule],
  templateUrl: 'home.html',
  styles: [
    `
      ion-grid {
        display: grid;
        place-items: center;
        height: 100%;

        text-align: center;
      }

      #bigIcon {
        font-size: 4rem;
      }

      ion-text {
        display: block;

        h2 {
          padding: 1.5rem 0;
          font-size: 1.5rem;
          font-weight: bolder;
        }
      }
    `,
  ],
})
export class HomeComponent implements OnInit {
  pageData: PageData | undefined;

  carePlans$ = this.store.select(selectCarePlanResources);
  userState$ = this.store.select(selectUserState);

  dfnsConfig = {
    locale: da,
  };

  toDate = (date: any) => new Date(date);

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    public pagesService: HestiaPagesService,
    private userFacade: HestiaUserFacade,
    private fhirResourcesService: HestiaFhirResourcesService
  ) {
    this.route.data.subscribe((data: PageData) => (this.pageData = data));
  }

  ngOnInit(): void {
    this.carePlans$.subscribe((result) => {
      console.log(result)
      if(result.length===0&&this.pageData?.autoHandleCreateCarePlan===true){
        this.handleCreateCarePlan();
      }
    });
    return;
  }

  handleSignOut() {
    console.log('done');
  }

  getActivityStatus(activity: fhir4.CarePlanActivity[] | undefined) {
    if (activity) {
      const completedActivities = activity?.filter((a) => a?.detail?.status === 'completed');

      return completedActivities.length / activity.length;
    } else {
      return;
    }
  }

  async handleCreateCarePlan() {
    const user = await firstValueFrom(this.userState$);

    this.userFacade.createCarePlan(user.email as string).subscribe({
      next: (resource) => this.store.dispatch(fhirResourceActions.addFhirResources({ fhirResources: [resource] })),
      error: (e) => console.log(e),
    });
  }
}
