/* eslint-disable @typescript-eslint/naming-convention */
export const britishEnglish = {
  IFQ: {
    PAGE_OUT_OF_TOTAL: 'Page {{currentPage}} out of {{totalPages}}',
    PREVIOUS: 'Previous',
    NEXT: 'Next',
    SUBMIT: 'Submit',
    YOU_PICKED: 'You have picked',
    STRING_WIDGET: {
      PLACEHOLDER: 'Write',
    },
    DATETIME_WIDGET: {
      PLACEHOLDER: 'Click here',
    },
    TEXTAREA_WIDGET: {
      PLACEHOLDER: 'Write',
    },
    SIGN_WIDGET: {
      ClEAR_BTN: 'Clear',
    },
    NUMBER_WIDGET: {
      PLACEHOLDER: 'Number',
    },
    SELECTION_WIDGET: {
      PLACEHOLDER: 'Vælg',
    },
    SUGGESTED_VALUE_EXT: {
      DEFAULT_BTN_TEXT: 'Suggested value',
      DEFAULT_ALERT_HEADER: 'Insert value?',
      DEFAULT_ALERT_MESSAGE:
        'Do you want to insert the suggested value?<br/><br/>Any existing values you have entered will be overwriten.',
      DEFAULT_ALERT_OK_BTN: 'OK',
      DEFAULT_ALERT_CANCEL_BTN: 'Cancel',
    },
    WANT_TO_PROCEED_ALERT: {
      HEADER: 'Proceed without answer?',
      MESSAGE: 'Do you wish to proceed without an answer?',
      OK_BTN: 'OK',
      CANCEL_BTN: 'CANCEL',
    },
    REPEATABLE_GROUP_WIDGET: {
      INSERT_SUGGESTION_BTN: 'Use suggestion',
      ADD_NEW_BTN: 'Add new',
    },
  },
};
