import { WidgetConfig } from '../../../models/widget.config';

export class ImageSelectionWidgetConfig extends WidgetConfig<string> {
  widgetType = 'ImageSelectionWidget';
  fhirValueSet: string;
  constructor(config: Partial<WidgetConfig<any>>) {
    super(config);
    this.multiple = true;
    this.fhirValueSet = config['fhirValueSet'] || null;
  }
}
