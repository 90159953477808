import { DeepPartial } from 'utility-types';
import { WidgetConfig } from '../../../models/widget.config';
import { formatISO } from 'date-fns';

export class DateTimeWidgetConfig extends WidgetConfig<string> {
  widgetType = 'DateTimeWidget';
  datetimeWidgetFormat: 'date' | 'time' | 'date-time' = 'date-time';
  constructor(config: DeepPartial<WidgetConfig<unknown>>) {
    super(config as unknown);
    this.datetimeWidgetFormat = config?.datetimeWidgetFormat ?? 'date-time';

    // const date = new Date();
    // this.defaultValue = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

    this.defaultValue = formatISO(new Date());
  }
}
