<div
  [ngClass]="{ 'is-label-widget': config.widgetType === 'LabelWidget' }"
  [ngClass]="{ 'is-string-widget': config.widgetType === 'StringWidget' }"
  class="widget-label ion-text-wrap"
  [ngSwitch]="labelAsMarkdown"
>
  <markdown *ngSwitchCase="true" class="ion-text-wrap">
    {{
      i18n({ translate: 'label', fallback: config.label })
        | varInterpolation: config.varInterpolation:true
    }}
  </markdown>
  <ng-container *ngSwitchDefault>
    {{
      i18n({ translate: 'label' }) | varInterpolation: config.varInterpolation
    }}
  </ng-container>
</div>
<div
  class="mt-2 text-base text-gray-600"
  *ngIf="config.descriptionText !== null"
  [ngSwitch]="descAsMarkdown"
>
  <markdown *ngSwitchCase="true" class="ion-text-wrap">
    {{
      i18n({ translate: 'descriptionText', fallback: config.descriptionText })
    }}
  </markdown>
  <span *ngSwitchDefault>
    {{
      i18n({ translate: 'descriptionText', fallback: config.descriptionText })
    }}
  </span>
</div>
