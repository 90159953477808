import { IPagesConfig, PAGES_CONFIG, PAGES_I18N_SCOPE } from './constants';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

/* eslint-disable @typescript-eslint/naming-convention */
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { HestiaCommonModule } from '@hestia/ngx-common';
import { HestiaPagesService } from './service';
import { PagesEffects } from './page.effects';
import { StoreModule } from '@ngrx/store';
import daDK from './i18n/da-DK.json';
import enGB from './i18n/en-GB.json';
import { loadModuleTranslations } from '@hestia/ngx-i18n';

@NgModule({
  imports: [CommonModule, HestiaCommonModule.forChild(), TranslocoModule, EffectsModule.forFeature([PagesEffects])],
})
export class HestiaPagesModule {
  constructor(private transloco: TranslocoService) {
    loadModuleTranslations(this.transloco, PAGES_I18N_SCOPE, {
      'da-DK': daDK,
      'en-GB': enGB,
    });
  }
  static forRoot(config: IPagesConfig): ModuleWithProviders<HestiaPagesModule> {
    return {
      ngModule: HestiaPagesModule,
      providers: [
        HestiaPagesService,
        {
          provide: PAGES_CONFIG,
          useValue: config,
        },
      ],
    };
  }
  static forChild(): ModuleWithProviders<HestiaPagesModule> {
    /**
     * We want a singleton instance of LayoutService,
     * therefore we do not provide it when forChild is called
     * (which is done by lazy loaded modules)
     * See https://stackoverflow.com/a/49878339
     */
    return {
      ngModule: HestiaPagesModule,
      providers: [],
    };
  }
}
