import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { META_REDUCERS, MetaReducer } from '@ngrx/store';

import {
  STORAGE_SYNC_MODULE_SETTINGS,
  StorageSyncModuleSettings,
} from './constants';
import { CapacitorStorageSyncProvider } from './storage-providers/capacitor';
import { LocalForageStorageSyncProvider } from './storage-providers/local-forage';
import { StorageSyncEffects, storageSync } from './storage-sync';

export function metaReducerFactory(
  settings: StorageSyncModuleSettings
): MetaReducer<any> {
  const provider =
    settings.syncProvider === 'local-forage'
      ? new LocalForageStorageSyncProvider(settings.storageKey)
      : new CapacitorStorageSyncProvider(settings.storageKey);
  const syncer = storageSync(provider, settings.syncSettings);
  return syncer;
}

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature([StorageSyncEffects])],
})
export class HestiaNgrxLocalStorageSyncModule {
  static forRoot(
    config: StorageSyncModuleSettings
  ): ModuleWithProviders<HestiaNgrxLocalStorageSyncModule> {
    return {
      ngModule: HestiaNgrxLocalStorageSyncModule,
      providers: [
        {
          provide: STORAGE_SYNC_MODULE_SETTINGS,
          useValue: config,
        },
        {
          provide: META_REDUCERS,
          deps: [STORAGE_SYNC_MODULE_SETTINGS],
          useFactory: metaReducerFactory,
          multi: true,
        },
      ],
    };
  }
}
