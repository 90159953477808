<ng-container *transloco="let t; read: 'hestiaPages.appShell'">
  <ion-split-pane when="xl" contentId="main" *ngIf="userState$ | async as user" disabled>
    <ion-menu contentId="main" side="end" [disabled]="!user.jwt">
      <ion-header>
        <ion-toolbar>
          <ion-title>{{ config.name }}</ion-title>
          <ion-buttons slot="end">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
        </ion-toolbar>
        <ion-toolbar *ngIf="availableLanguages" (ionChange)="changeLanguage($event)">
          <ion-segment scrollable [value]="currentLanguage">
            <ion-segment-button *ngFor="let lang of availableLanguages" [value]="lang?.id">
              {{ lang?.label }}
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list lines="none" *ngIf="user?.ssn">
          <ion-list-header>
            <ion-label>{{ user?.ssn?.slice(0, -4) + '####' }}</ion-label>
          </ion-list-header>

          <ion-item button (click)="handleSignOut()">
            <ion-icon name="exit-outline" slot="start" color="danger" />
            <ion-label color="danger">{{ t('signOut') }}</ion-label>
          </ion-item>
        </ion-list>

        <ion-list lines="none" *ngIf="user.kioskMode">
          <ion-list-header>
            <ion-label>{{ t('preferences') }}</ion-label>
          </ion-list-header>

          <ion-item button (click)="refresh()">
            <ion-icon name="refresh-circle-outline" slot="start" />
            <ion-label>{{ t('refresh') }}</ion-label>
          </ion-item>

          <ion-item *ngIf="moduleSettingsState$ | async as moduleSettings">
            <ion-icon name="apps-outline" slot="start" />
            <ion-label>{{ t('virtualKeyboard') }}</ion-label>
            <ion-toggle [checked]="moduleSettings.enableVirtualKeyboard" (ionChange)="handleKeyboardToggle($event)" />
          </ion-item>
        </ion-list>

        <ion-list lines="full">
          <ion-list-header>
            <ion-label>{{ t("support") }}</ion-label>
          </ion-list-header>

          <ion-item-group *ngIf="config?.contactList?.length">
            <ion-item-divider>
              <ion-label>{{ t("contacts") }}</ion-label>
            </ion-item-divider>

            <ion-item *ngFor="let contact of config?.contactList">
              <ion-label>
                <h2>{{ contact?.name }}</h2>
                <p>{{ contact?.contactVia }}</p>
              </ion-label>
            </ion-item>
          </ion-item-group>

          <ion-item-group>
            <ion-item-divider>
              <ion-label>{{ t("technical") }}</ion-label>
            </ion-item-divider>

            <ion-item button href="tel:+4553512506" [detail]="false">
              <ion-label>
                <h2>ZiteLab ApS</h2>
                <p>+45 53 51 25 06</p>
              </ion-label>
            </ion-item>
          </ion-item-group>
        </ion-list>

        <ng-container *ngFor="let card of config?.cards">
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle [innerHTML]="t(card?.title)" />
            </ion-card-header>
            <ion-card-content [innerHTML]="t(card.description)" />
          </ion-card>
        </ng-container>
      </ion-content>

      <ion-footer *ngIf="user.kioskMode">
        <ion-toolbar>
          <ion-button class="ion-margin" expand="block" fill="clear" color="danger" (click)="handleKioskReset()">
            {{ t('reset') }}
          </ion-button>
        </ion-toolbar>
      </ion-footer>
    </ion-menu>

    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ng-container>
