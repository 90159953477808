import { WidgetConfig } from '../../../models/widget.config';

export class LabelWidgetConfig extends WidgetConfig<string> {
  widgetType = 'LabelWidget';
  displayOnly = true;
  controlType = null; // Will not be added to form
  constructor(config: {} = {}) {
    super(config);
  }
}
