import { ENABLE_VALUE_HISTORY_QNR_RESPONSE_EXT_URL } from '../constants';
import { extractValueHistoryExtValues } from '../models/fhir-extensions/questionaire-response/utils';

export const calculateSessionValues = (
  qnrResponse: fhir4.QuestionnaireResponse,
  formValues: Record<string, unknown>
): Record<string, unknown> => {
  const valueHistory = extractValueHistoryExtValues(
    qnrResponse?.extension?.find(
      (x) => x.url === ENABLE_VALUE_HISTORY_QNR_RESPONSE_EXT_URL
    )
  );
  // TODO: Look into whether or not we should deep merge these, not sure it is needed.
  return { ...valueHistory, ...(formValues ?? {}) };
};
