import { CarePlanActivityStatus, IHestiaCarePlanActivity } from '../types';

export class HestiaCarePlanActivity implements IHestiaCarePlanActivity {
  carePlanId: string;
  fhirElem: fhir4.CarePlanActivity;
  code: string;
  qnrCanonical: string;
  qnrResource: fhir4.Questionnaire;
  qnrResponseResource: fhir4.QuestionnaireResponse;
  status: CarePlanActivityStatus;
  constructor(config: IHestiaCarePlanActivity) {
    this.carePlanId = config.carePlanId;
    this.fhirElem = config.fhirElem;
    this.code = config.code;
    this.qnrCanonical = config.qnrCanonical;
    this.qnrResource = config.qnrResource;
    this.qnrResponseResource = config.qnrResponseResource;
    this.status = config.status;
  }
}
