import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';

import { Store } from '@ngrx/store';

import { OptionObject } from '../../../models/typings';
import { WidgetBaseComponent } from '../../../models/widget';
import { Events } from '../../../services/events.service';
import { HestiaQnrFormSessionService } from '../../../services/qnr-form-session.service';
import { CheckboxWidgetConfig } from './checkbox-widget.config';

@Component({
  selector: 'hestia-checkbox-widget',
  templateUrl: './checkbox-widget.component.html',
  styleUrls: ['./checkbox-widget.component.scss'],
})
export class CheckboxWidgetComponent
  extends WidgetBaseComponent
  implements OnInit
{
  @Input() config: CheckboxWidgetConfig;
  @Input() form: UntypedFormGroup;
  values: { [key: string]: boolean };
  constructor(
    transloco: TranslocoService,
    questionnaireService: HestiaQnrFormSessionService,
    store: Store,
    element: ElementRef,
    events: Events
  ) {
    super(transloco, questionnaireService, store, element, events);
  }
  get isValid() {
    return this.form.controls[this.config.name].valid;
  }

  ngOnInit() {
    super.ngOnInit();
    this.values = this.config.options.reduce(
      (acc, option) => ({
        ...acc,
        ...{
          [option.code]:
            (Array.isArray(this.config.value) &&
              this.config.value.includes(option.code)) ||
            this.isDefaultSuggestedValue(option.code),
        },
      }),
      {}
    );
    const formValue = Object.keys(this.values).filter(
      (key) => this.values[key] === true
    );
    this.form.controls[this.config.name].setValue(formValue);
  }

  isDefaultSuggestedValue(code): boolean {
    if (
      this.config.value !== null ||
      this.config.suggestedValueConfig === null ||
      this.config.suggestedValueConfig.isDefaultValue !== true
    ) {
      return false;
    }
    const suggestedValue = this.config.suggestedValueConfig.value as string[];
    if (Array.isArray(suggestedValue)) {
      return suggestedValue.indexOf(code) > -1;
    } else {
      return code === suggestedValue;
    }
  }

  checkIfExclusiveOption(option: OptionObject) {
    if (option?.isExclusive) {
      return option.isExclusive === true;
    } else {
      return false;
    }
  }

  isCheckboxDisabled(optionCode: string): boolean {
    let isDisabled = false;
    if (this.config.disabled) {
      isDisabled = true;
    } else {
      const values = this.form.controls[this.config.name].value as any[];
      if (
        this.config.maxSelected !== null &&
        values.length >= this.config.maxSelected &&
        values.indexOf(optionCode) === -1
      ) {
        isDisabled = true;
      }
    }
    return isDisabled;
  }

  checkboxValueChange(value: boolean, clickedOption: OptionObject): void {
    let innerValues = { ...this.values, [clickedOption.code]: value };
    const isExclusive = this.checkIfExclusiveOption(clickedOption);
    if (isExclusive) {
      innerValues = this.config.options.reduce(
        (acc, option) => ({
          ...acc,
          ...{
            [option.code]: option.code === clickedOption.code ? true : false,
          },
        }),
        {}
      );
    } else {
      this.config.options.map((option) => {
        if (option?.isExclusive === true) {
          innerValues[option.code] = false;
        }
      });
    }
    const formValue = Object.keys(innerValues).filter(
      (key) => innerValues[key] === true
    );
    this.form.controls[this.config.name].setValue(formValue);
    this.values = innerValues;
  }
}
