import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * These breakpoints mirrors those from Tailwind
 */
export enum Breakpoint {
  none = '(max-width: 639px)',
  sm = '(min-width: 640px)',
  md = '(min-width: 768px)',
  lg = '(min-width: 1024px)',
  xl = '(min-width: 1280px)',
  xxl = '(min-width: 1536px)', // 2xl
}

@Injectable({ providedIn: 'root' })
export class HestiaLayoutService {
  curBreakpointState: {
    [key in Breakpoint]: boolean;
  };
  allBreakPointsSubject = new BehaviorSubject<
    {
      [key in Breakpoint]: boolean;
    }
  >({
    [Breakpoint.none]: this.breakpointObserver.isMatched(Breakpoint.none),
    [Breakpoint.sm]: this.breakpointObserver.isMatched(Breakpoint.sm),
    [Breakpoint.md]: this.breakpointObserver.isMatched(Breakpoint.md),
    [Breakpoint.lg]: this.breakpointObserver.isMatched(Breakpoint.lg),
    [Breakpoint.xl]: this.breakpointObserver.isMatched(Breakpoint.xl),
    [Breakpoint.xxl]: this.breakpointObserver.isMatched(Breakpoint.xxl),
  });
  allBreakpoints$: Observable<
    {
      [key in Breakpoint]: boolean;
    }
  > = this.allBreakPointsSubject.asObservable();

  noneBreakpointSubject = new BehaviorSubject<boolean>(
    this.breakpointObserver.isMatched(Breakpoint.none)
  );
  noneBreakpoint$ = this.noneBreakpointSubject.asObservable();

  smBreakpointSubject = new BehaviorSubject<boolean>(
    this.breakpointObserver.isMatched(Breakpoint.sm)
  );
  smBreakpoint$ = this.smBreakpointSubject.asObservable();

  mdBreakpointSubject = new BehaviorSubject<boolean>(
    this.breakpointObserver.isMatched(Breakpoint.md)
  );
  mdBreakpoint$ = this.mdBreakpointSubject.asObservable();

  lgBreakpointSubject = new BehaviorSubject<boolean>(
    this.breakpointObserver.isMatched(Breakpoint.lg)
  );
  lgBreakpoint$ = this.lgBreakpointSubject.asObservable();

  xlBreakpointSubject = new BehaviorSubject<boolean>(
    this.breakpointObserver.isMatched(Breakpoint.xl)
  );
  xlBreakpoint$ = this.xlBreakpointSubject.asObservable();

  xxlBreakpointSubject = new BehaviorSubject<boolean>(
    this.breakpointObserver.isMatched(Breakpoint.xxl)
  );
  xxxBreakpoint$ = this.xxlBreakpointSubject.asObservable();
  constructor(public breakpointObserver: BreakpointObserver) {
    const tailwindBreakpoints = [
      Breakpoint.none,
      Breakpoint.sm,
      Breakpoint.md,
      Breakpoint.lg,
      Breakpoint.xl,
      Breakpoint.xxl,
    ];
    this.breakpointObserver.observe(tailwindBreakpoints).subscribe((result) => {
      this.allBreakPointsSubject.next(
        result.breakpoints as {
          [key in Breakpoint]: boolean;
        }
      );
      this.noneBreakpointSubject.next(result.breakpoints[Breakpoint.none]);
      this.smBreakpointSubject.next(result.breakpoints[Breakpoint.sm]);
      this.mdBreakpointSubject.next(result.breakpoints[Breakpoint.md]);
      this.lgBreakpointSubject.next(result.breakpoints[Breakpoint.lg]);
      this.xlBreakpointSubject.next(result.breakpoints[Breakpoint.xl]);
      this.xxlBreakpointSubject.next(result.breakpoints[Breakpoint.xxl]);
    });
    this.allBreakpoints$.subscribe(
      (result) => (this.curBreakpointState = result)
    );
  }
}
