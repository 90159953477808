export interface IHestiaQuestionnaireResponse {
  fhirId: string;
  fhirResource: fhir4.QuestionnaireResponse;
}

export class HestiaQuestionnaireResponse
  implements IHestiaQuestionnaireResponse
{
  fhirId: string;
  fhirResource: fhir4.QuestionnaireResponse;
  constructor(public config: IHestiaQuestionnaireResponse) {
    this.fhirId = config.fhirId;
    this.fhirResource = config.fhirResource;
  }
}
