// import * as Sentry from '@sentry/angular';
import { Inject, Injectable } from '@angular/core';

import { AlertController, LoadingController } from '@ionic/angular';
import { AlertButton, AlertOptions, LoadingOptions } from '@ionic/core';

import { TranslocoService } from '@ngneat/transloco';

import { Store } from '@ngrx/store';

import { IHestiaCoreConfig } from '@hestia/ngx-types';

import { CORE_HESTIA_CONFIG, moduleI18nScope } from '../constants';

@Injectable({ providedIn: 'root' })
export class HestiaCommonService {
  loader: HTMLIonLoadingElement;
  loading = false;
  constructor(
    private loadingCtrl: LoadingController,
    private store: Store,
    private alertCtrl: AlertController,
    @Inject(CORE_HESTIA_CONFIG) private hestiaCoreConfig: IHestiaCoreConfig,
    private transloco: TranslocoService
  ) {}

  async presentLoader(options?: AlertOptions) {
    options = options ?? {
      header: this.transloco.translate(`${moduleI18nScope}.defaultLoadingMsg`),
      message: `<ion-spinner name="crescent" color="primary"></ion-spinner>`,
      buttons: [
        {
          text: this.transloco.translate(`${moduleI18nScope}.defaultLoadingMsgReset`),
          handler: () => window.location.reload(),
        },
      ],
    };

    const loadingAlert = await this.alertCtrl.create(options);

    await loadingAlert.present();
  }

  async dismissLoader() {
    const exisitingAlert = await this.alertCtrl.getTop();
    const exisitingLoading = await this.loadingCtrl.getTop();

    if (exisitingAlert !== undefined) {
      await this.alertCtrl.dismiss();
    }

    if (exisitingLoading !== undefined) {
      await this.loadingCtrl.dismiss();
    }
  }

  // public async presentLoader(options?: LoadingOptions) {
  //   this.loading = true;
  //   options = options ?? {
  //     message: this.transloco.translate(`${moduleI18nScope}.defaultLoadingMsg`),
  //   };
  //   await this.dismissLoader(); // We dismiss any existing
  //   this.loader = await this.loadingCtrl.create(options);
  //   await this.loader.present();
  // }

  // public async dismissLoader() {
  //   const a = await this.loadingCtrl.getTop();
  //   if (a) {
  //     a.dismiss();
  //     this.loader = null;
  //     this.loading = false;
  //   }
  //   return true;
  // }

  public async createErrorAlert(props: {
    header: string;
    message: string;
    buttons?: (AlertButton | string)[];
    rawError?: unknown;
  }) {
    // TODO: Reenable Sentry
    // if (props?.rawError && this.hestiaCoreConfig.sentry.enabled) {
    //   Sentry.captureEvent(props.rawError);
    // }
    await this.dismissLoader();
    const alert = await this.alertCtrl.create({
      header: props.header,
      message: props.message,
      buttons: props.buttons ?? ['OK'],
    });
    alert.present();
  }
}
