/**
 * Creates a FHIR address element suited for use in Hestia
 */
export const createFhirAddress = (props: {
  addressLineOne: string;
  addressLineTwo?: string;
  city: string;
  postalCode: string;
  countryCode: string;
}): fhir4.Address => {
  const fhirAddress: fhir4.Address = {
    use: 'home',
    type: 'both',
    line: [props.addressLineOne],
    city: props.city,
    postalCode: props.postalCode,
    country: props.countryCode,
  };
  if (props.addressLineTwo) {
    fhirAddress.line.push(props.addressLineTwo);
  }
  return fhirAddress;
};
