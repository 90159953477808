<ng-container *transloco="let t; read: 'hestiaPages.smsActivation'">
  <ion-header [translucent]="true">
    <ion-toolbar>
      <ion-title>{{ t('title') }}</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="modalCtrl.dismiss()">
          <ion-icon name="chevron-down-outline" slot="icon-only" />
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content [fullscreen]="true">
    <ion-grid fixed>
      <ion-list lines="full">
        <ion-item>
          <ion-label position="stacked">{{ t('ssn') }}</ion-label>
          <ion-input
            [formControl]="ssn"
            name="ssn"
            [placeholder]="t('ssnPlaceholder')"
            inputmode="numeric"
            autocomplete="off"
            maxlength="10"
          />
          <ion-note slot="helper">{{ t('valid') }}</ion-note>
          <ion-note slot="error" *ngIf="ssn.value?.length === 10">{{ t('invalid') }}</ion-note>
        </ion-item>

        <ion-item button (click)="requestActivationCode()" [disabled]="otpPayload?.expires || !ssn.valid">
          <ion-label>{{ t('requestActivationCode') }}</ion-label>
        </ion-item>
      </ion-list>

      <ion-list lines="full" *ngIf="otpPayload?.authToken">
        <ion-list-header>
          <ion-label>{{ t('confirm') }}</ion-label>
        </ion-list-header>

        <ion-item>
          <ion-label position="stacked">{{ t('activationCode') }}</ion-label>
          <ion-input
            [formControl]="activationCode"
            name="activationCode"
            placeholder="Indtast aktiveringskode"
            maxlength="5"
          />
        </ion-item>

        <ion-item button (click)="verifyActivationCode()" [disabled]="!activationCode.valid">
          <ion-label>{{ t('signIn') }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-grid>
  </ion-content>
</ng-container>
