import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable, switchMap } from 'rxjs';

import { HESTIA_INTERCEPTOR_SKIP_HEADER } from '../constants';
import { HestiaUserFacade } from '../user.facade';

@Injectable({ providedIn: 'root' })
export class HestiaAuthInterceptor implements HttpInterceptor {
  inflightAuthRequest = null;
  constructor(private inj: Injector) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userFacade = this.inj.get(HestiaUserFacade);
    return userFacade.jwt$.pipe(
      switchMap((jwt) => {
        const headers = req.headers
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Access-Control-Allow-Origin', '*')
          .set('Authorization', 'Bearer ' + jwt);
        let authReq: HttpRequest<any>;
        if (req.headers.has(HESTIA_INTERCEPTOR_SKIP_HEADER)) {
          authReq = req.clone();
          return next.handle(authReq); // We don't want the JWT expiration logic to fire if we have interceptorSkipHeader
        }
        let isExpired: boolean = null;
        authReq = req.clone({ headers });
        if (jwt !== null) {
          isExpired = userFacade.jwtHelper.isTokenExpired(jwt);
        }
        if (isExpired === true) {
          userFacade.initLogout();
        }

        return next.handle(authReq);
      })
    );
  }
}
