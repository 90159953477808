import { WidgetTypes } from '../typings';

export interface IQnrItemExtParsedExt<T = Record<string, unknown>> {
  widgetProperties: T;
  translations?: { [key: string]: { [key: string]: string } };
}

export enum HestiaExtParentElem {
  Questionnaire = 'Questionnaire',
  QuestionnaireItem = 'QuestionnaireItem',
  QuestionnaireResponse = 'QuestionnaireResponse',
}

export enum QnrItemType {
  Group = 'group',
  Display = 'display',
  Question = 'question',
  Boolean = 'boolean',
  Decimal = 'decimal',
  Integer = 'integer',
  Date = 'date',
  DateTime = 'dateTime',
  Time = 'time',
  String = 'string',
  Text = 'text',
  Url = 'url',
  Choice = 'choice',
  OpenChoice = 'open-choice',
  Attachment = 'attachment',
  Reference = 'reference',
  Quantity = 'quantity',
  All = 'all',
}

export interface IHestiaFhirExt {
  readonly extendsElem: HestiaExtParentElem;
  readonly url: string;
  json: fhir4.Extension;
}

export interface IHestiaQnrItemExt<T = Record<string, unknown>>
  extends IHestiaFhirExt {
  readonly supportedItemTypes: Array<QnrItemType>;
  readonly supportedWidgets: WidgetTypes[];
  parse: () => IQnrItemExtParsedExt;
  get configProps(): IQnrItemExtParsedExt<T>;
}

export class LegacyQnrItemExtBase {
  extendsElement: 'Questionnaire' | 'Item';
  extensionUrl: string;
  extensionJson: fhir4.Extension;
  supportedWidgets: WidgetTypes[];
  output: IQnrItemExtParsedExt;
  parse: (extensionJson: fhir4.Extension) => IQnrItemExtParsedExt;
  constructor(config: {
    extensionJson?: fhir4.Extension;
    supportedWidgets?: WidgetTypes[];
  }) {
    this.extensionJson = config.extensionJson || null;
    this.supportedWidgets = config.supportedWidgets || [];
  }

  defaultParser = (
    parseMapping,
    extensionJson: fhir4.Extension
  ): IQnrItemExtParsedExt => ({ widgetProperties: {}, translations: {} });
}
