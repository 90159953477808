/* eslint-disable ngrx/no-dispatch-in-effects */

/* eslint-disable arrow-body-style */
import { v4 } from 'uuid';

import { Injectable } from '@angular/core';

import { AlertController, LoadingController, NavController } from '@ionic/angular';

import { TranslocoService } from '@ngneat/transloco';

import { forkJoin, of } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { HestiaCommonService } from '@hestia/ngx-common';
import {
  HestiaFhirResourcesService,
  fhirResourceActions,
  selectHestiaCarePlanById,
  selectHestiaCarePlans,
} from '@hestia/ngx-fhir';
import { QuestionnaireResponseService, initSession, isQnrAdaptive } from '@hestia/ngx-fhir-qnr-forms';
import { qnrFormSessionActions, selectQnrFormSessionById } from '@hestia/ngx-fhir-qnr-forms';
import { HestiaUserFacade, loginFailure, selectUserState, userActions } from '@hestia/ngx-user';

@Injectable()
export class PagesEffects {
  constructor(
    private actions$: Actions,
    private fhirResources: HestiaFhirResourcesService,
    private store: Store,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private navCtrl: NavController,
    private qnrResponseService: QuestionnaireResponseService,
    private userFacade: HestiaUserFacade,
    private translocoService: TranslocoService,
    private commonService: HestiaCommonService
  ) {}

  userProfileLoadSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(userActions.userProfileLoadSuccess),
        map((action) =>
          !action.kioskMode
            ? this.store.dispatch(userActions.userInitialized())
            : this.navCtrl.navigateRoot('/ssn-entry')
        )
      );
    },
    { dispatch: false }
  );

  initKioskSessionSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.initKioskSessionSuccess),
      map(() => userActions.userInitialized())
    );
  });

  userInitialized$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.userInitialized),
      switchMap((_action) =>
        forkJoin([
          // this.fhirResources.fetchResponsesFromPatient({ patientFhirId: '', resolveReferences: false }),
          this.fhirResources.fetchAllQuestionnaires(),
          this.fhirResources.fetchUnfinishedUserCarePlan(),
        ]).pipe(
          map(() => userActions.userInitializedSuccess()),
          catchError(() => of(userActions.userInitializedFailure()))
        )
      )
    );
  });

  userInitializedSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.userInitializedSuccess),
      switchMap((_action) =>
        this.store.select(selectHestiaCarePlans).pipe(
          first(),
          withLatestFrom(this.store.select(selectUserState)),
          switchMap(([carePlans, userState]) => {
            const sorted = carePlans.sort((a, b) => (a.fhirResource.created <= b.fhirResource.created ? 1 : -1));
            const newestCarePlan = sorted[sorted.length - 1] ?? null;

            let nextActivityCode: string;

            for (const [code, activity] of newestCarePlan.activitiesByCode) {
              if (['in-progress', 'scheduled'].includes(activity.status)) {
                nextActivityCode = code;
                break;
              }
            }

            const nextActivity = newestCarePlan.activitiesByCode.get(nextActivityCode);

            const values = this.qnrResponseService.getFormValuesFromQuestionnaireResponse(
              nextActivity.qnrResponseResource
            );

            return of(
              initSession({
                session: {
                  id: nextActivity?.qnrResponseResource?.id ?? v4(),
                  isAdaptive: isQnrAdaptive(nextActivity.qnrResource),
                  questionnaireResponse: nextActivity.qnrResponseResource,
                  doesQnrResponseExistOnServer: nextActivity.qnrResponseResource ? true : false,
                  values: values,
                  displayTitle: nextActivity.qnrResource.title,
                  questionnaireId: nextActivity.qnrResource.id,
                  patientId: userState.fhirId,
                  carePlanId: nextActivity.carePlanId,
                  carePlanStatus: newestCarePlan.carePlanResource.status,
                  activityStatus: nextActivity.status,
                  activityCode: nextActivity.code,
                  renderType: 'page',
                  subjectRef: { reference: userState.fhirId },
                  authorRef: { reference: userState.fhirId },
                  sourceRef: { reference: userState.fhirId },
                  allowMenu: true,
                  curLinkIdPath: null,
                  alertWhenEmptySubmit: false,
                },
              })
            );
          })
        )
      ),
      catchError(() => of(userActions.userInitializedFailure()))
    );
  });

  userInitializedFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(userActions.userInitializedFailure),
        tap((_action) => this.navCtrl.navigateRoot(['/home']))
      );
    },
    { dispatch: false }
  );

  initSessionSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(qnrFormSessionActions.initSessionSuccess),
        tap((action) => this.navCtrl.navigateRoot(['/quest', action.session.id]))
      );
    },
    { dispatch: false }
  );

  completeSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(qnrFormSessionActions.completeSession),
      switchMap((action) => this.store.select(selectQnrFormSessionById(action.id)).pipe(first())),
      switchMap((session) =>
        this.store.select(selectHestiaCarePlanById(session?.carePlanId)).pipe(
          first(),
          withLatestFrom(this.userFacade.userState$),
          map(([carePlan, userState]) => {
            return { completedSession: session, carePlan, userState };
          }),
          switchMap(({ completedSession, carePlan, userState }) => {
            console.log(carePlan);

            if (!carePlan) {
              return;
            }

            let nextActivityCode: string;

            for (const [code, activity] of carePlan.activitiesByCode) {
              if (['in-progress', 'scheduled'].includes(activity.status) && completedSession.activityCode !== code) {
                nextActivityCode = code;
                break;
              }
            }

            const nextActivity = carePlan.activitiesByCode.get(nextActivityCode);

            if (!nextActivity) {
              return of(userActions.carePlanCompleted());
            }

            const values = this.qnrResponseService.getFormValuesFromQuestionnaireResponse(
              nextActivity.qnrResponseResource
            );

            return of(
              initSession({
                session: {
                  id: nextActivity?.qnrResponseResource?.id ?? v4(),
                  isAdaptive: isQnrAdaptive(nextActivity.qnrResource),
                  questionnaireResponse: nextActivity.qnrResponseResource,
                  doesQnrResponseExistOnServer: nextActivity.qnrResponseResource ? true : false,
                  values: values,
                  displayTitle: nextActivity.qnrResource.title,
                  questionnaireId: nextActivity.qnrResource.id,
                  patientId: userState.fhirId as string,
                  carePlanId: nextActivity.carePlanId,
                  carePlanStatus: carePlan?.carePlanResource?.status as string,
                  activityStatus: nextActivity.status,
                  activityCode: nextActivity.code,
                  renderType: 'page',
                  subjectRef: { reference: userState.fhirId as string },
                  authorRef: { reference: userState.fhirId as string },
                  sourceRef: { reference: userState.fhirId as string },
                  allowMenu: true,
                  curLinkIdPath: '',
                  alertWhenEmptySubmit: false,
                },
              })
            );
          })
        )
      )
    );
  });

  carePlanCompleted$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(userActions.carePlanCompleted),
        concatLatestFrom(() => this.store.select(selectUserState)),
        map(async ([_action, userState]) => {
          const alert = await this.alertCtrl.create({
            cssClass: 'completeAlert',
            header: this.translocoService.translate('hestiaPages.appShell.completionHeader'),
            message: this.translocoService.translate('hestiaPages.appShell.completionMessage'),
            buttons: [
              {
                text: this.translocoService.translate('hestiaPages.appShell.signOut'),
                handler: () =>
                  userState.kioskMode
                    ? this.store.dispatch(userActions.resetState())
                    : this.store.dispatch(userActions.logoutFixed()),
              },
            ],
            backdropDismiss: false,
          });

          alert.present();
        })
      );
    },
    { dispatch: false }
  );

  returnHome$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<Action>(userActions.resetState, userActions.fullResetState),
        map(() => {
          this.store.dispatch(qnrFormSessionActions.clearSessions());
          this.store.dispatch(fhirResourceActions.clearFhirResources());
          this.navCtrl.navigateRoot('/');

          // Todo: Find a better way the chain of dispatches. We had an issue where the user couldn't sign in with SSN again after logout.

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
      );
    },
    { dispatch: false }
  );
}
