import { Component, Input, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';

import { Store } from '@ngrx/store';

import { WidgetBaseComponent } from '../../../models/widget';
import { Events } from '../../../services/events.service';
import { HestiaQnrFormSessionService } from '../../../services/qnr-form-session.service';
import { ImageSelectionWidgetConfig } from './image-selection-widget.config';

@Component({
  selector: 'hestia-image-selection-widget',
  templateUrl: './image-selection-widget.component.html',
  styleUrls: ['./image-selection-widget.component.scss'],
})
export class ImageSelectionWidgetComponent
  extends WidgetBaseComponent
  implements OnInit, OnDestroy
{
  @Input() config: ImageSelectionWidgetConfig;
  @Input() form: UntypedFormGroup;
  value: string;
  constructor(
    transloco: TranslocoService,
    questionnaireService: HestiaQnrFormSessionService,
    store: Store,
    element: ElementRef,
    events: Events
  ) {
    super(transloco, questionnaireService, store, element, events);
  }
  get isValid() {
    return this.form.controls[this.config.name].valid;
  }

  ngOnInit() {
    super.ngOnInit();
    this.value = this.config.value;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  setFormValue(selectedValue): void {
    this.value = selectedValue;
    this.form.controls[this.config.name].setValue(selectedValue);
  }
}
