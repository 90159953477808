/// <reference types="fhir" />
import { Action, createReducer, on } from '@ngrx/store';

import { AgeGroup, HestiaUserType } from '@hestia/ngx-types';

import { userActions } from './user.actions';

export const userFeatureKey = 'user';

export interface IHestiaUserState {
  loading: boolean;
  kioskMode: boolean;
  jwt: string | null;
  fhirId: string | null;
  fhirResource: fhir4.Patient | fhir4.Practitioner | fhir4.RelatedPerson | null;
  userId: string | null;
  userType: HestiaUserType | null;
  fullName: string | null;
  orgUuid: string | null;
  orgName: string | null;
  cellPhone: string | null;
  diagnosis: string | null;
  diagnosisGroup: string | null;
  ssn: string | null;
  auth_token: string | null;
  expires: string | null;
  email: string | null;
  clinicalSuite: boolean | null;

  // Temporary, will be removed later.
  ageGroup?: string | null;
  confirmedMedicineDates?: string[] | null;
  confirmedVomitDates?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
}

export const initialState: IHestiaUserState = {
  loading: false,
  kioskMode: false,
  jwt: null,
  fhirId: null,
  fhirResource: null,
  userId: null,
  userType: HestiaUserType.Anonymous,
  fullName: null,
  orgUuid: null,
  orgName: null,
  cellPhone: null,
  diagnosis: null,
  diagnosisGroup: null,
  ssn: null,
  auth_token: null,
  expires: null,
  email: null,
  clinicalSuite: null,

  // Temporary, will be removed later.
  ageGroup: null,
  confirmedMedicineDates: null,
  confirmedVomitDates: null,
  startDate: null,
  endDate: null,
};

export const userReducer = createReducer(
  initialState,
  on(userActions.login, (state, action) => {
    return { ...state, ...{ userType: action?.userType ?? undefined }, loading: true };
  }),
  on(userActions.loginSuccess, (state, { jwt, clinicalSuite }) => {
    return { ...state, jwt, clinicalSuite, loading: false };
  }),
  on(userActions.loginFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(userActions.otplogin, (state, action) => {
    return { ...state, ...action };
  }),
  on(userActions.otpPublished, (state, action) => {
    return { ...state, ...action };
  }),
  on(userActions.userProfileLoad, (state) => {
    return { ...state, loading: true };
  }),
  on(userActions.userProfileLoadSuccess, (state, action) => {
    return { ...state, ...action, loading: false };
  }),
  on(userActions.userProfileLoadFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(userActions.userInitialized, (state) => {
    return { ...state, loading: true };
  }),
  on(userActions.userInitializedSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(userActions.userInitializedFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(userActions.updateUser, (state, action) => {
    return { ...state, ...action };
  }),
  on(userActions.initKioskSession, (state) => {
    return { ...state, loading: true };
  }),
  on(userActions.initKioskSessionSuccess, (state, action) => {
    return { ...state, ...action, loading: false };
  }),
  on(userActions.initKioskSessionFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(userActions.logout, () => initialState),
  on(userActions.logoutFixed, (state) => {
    return { ...state, loading: true };
  }),
  on(userActions.resetState, (state) => {
    return { ...initialState, kioskMode: state.kioskMode, jwt: state.kioskMode ? state.jwt : null };
  }),
  on(userActions.fullResetState, () => initialState)
);

export function reducer(state: IHestiaUserState | undefined, action: Action) {
  return userReducer(state, action);
}
