import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, merge, of } from 'rxjs';
import { initKioskSession, userActions } from './user.actions';

import { HestiaAuthError } from '@hestia/ngx-types';
import { HestiaCommonService } from '@hestia/ngx-common';
import { HestiaUserFacade } from '../user.facade';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { _i18n } from '@hestia/ngx-i18n';
import { fhirResourceActions } from '@hestia/ngx-fhir';
import { moduleI18nScope } from '../constants';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userFacade: HestiaUserFacade,
    private transloco: TranslocoService,
    private hestiaCommon: HestiaCommonService
  ) {}

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.login),
      mergeMap((action) =>
        this.userFacade.doSignIn(action.login, action.password).pipe(
          map((result) => userActions.loginSuccess({ jwt: result.token })),
          catchError((errorRaw) => {
            const error = errorRaw as HestiaAuthError;
            return of(
              userActions.loginFailure({
                errorType: error.errorType,
                loginMethod: error.loginMethod,
                errorI18n: error.errorI18n,
                error: error,
              })
            );
          })
        )
      )
    );
  });

  // eslint-disable-next-line arrow-body-style
  loginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.loginSuccess),
      map(() => userActions.userProfileLoad())
    );
  });

  loginFailure$ = createEffect(
    // eslint-disable-next-line arrow-body-style
    () => {
      return this.actions$.pipe(
        ofType(userActions.loginFailure),
        tap((action) =>
          this.hestiaCommon.createErrorAlert({
            header: this.transloco.translate(_i18n('error.auth.logoutAlertHeader'), {}, moduleI18nScope),
            message: this.transloco.translate(action.errorI18n as string),
          })
        )
      );
    },
    { dispatch: false }
  );

  otplogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.otplogin),
      mergeMap((action) =>
        this.userFacade.otpLoginRequest$(action.username).pipe(
          map((result) =>
            userActions.otpPublished({
              auth_token: result.auth_token,
              expires: result.expires,
            })
          ),
          catchError((errorRaw) => {
            const error = errorRaw as HestiaAuthError;
            return of(
              userActions.loginFailure({
                errorType: error.errorType,
                loginMethod: error.loginMethod,
                errorI18n: error.errorI18n,
                error: error, // TODO: Figure out how to save this while ensuring strict serializability -https://ngrx.io/guide/store/configuration/runtime-checks#strictactionserializability
              })
            );
          })
        )
      )
    );
  });

  otpverify$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.otpverify),
      mergeMap((action) =>
        this.userFacade.otpVerifyRequest$(action.otpcode, action.auth_token).pipe(
          map((result) => userActions.loginSuccess({ jwt: result.token })),
          catchError((errorRaw) => {
            const error = errorRaw as HestiaAuthError;
            return of(
              userActions.loginFailure({
                errorType: error.errorType,
                loginMethod: error.loginMethod,
                errorI18n: error.errorI18n,
                error: error, // TODO: Figure out how to save this while ensuring strict serializability -https://ngrx.io/guide/store/configuration/runtime-checks#strictactionserializability
              })
            );
          })
        )
      )
    );
  });

  otpPublished$ = createEffect(
    // eslint-disable-next-line arrow-body-style
    () => {
      return this.actions$.pipe(
        ofType(userActions.otpPublished),
        map((action) => {
          // console.log(action);
          userActions.otpPublished(action);
        })
      );
    },
    { dispatch: false }
  );

  // eslint-disable-next-line arrow-body-style
  userProfileLoad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.userProfileLoad),
      switchMap((_action) =>
        this.userFacade.userProfileRequest$().pipe(
          withLatestFrom(this.userFacade.userState$),
          map(([user, userState]) => {
            const jwtProperties = this.userFacade.jwtHelper.decodeToken(userState.jwt!);

            if (user.user_type === 'OrganizationKioskUser') {
              return userActions.userProfileLoadSuccess({
                kioskMode: true,
                userType: user.user_type, // To be removed
              });
            }

            return userActions.userProfileLoadSuccess({
              fullName: jwtProperties.fullname,
              userId: user.username,
              fhirId: user.fhir_id,
              orgUuid: user.organization_uid,
              fhirResource: user.user_resource,
              userType: user.user_type,
              cellPhone: user.cell_phone_number,
              ssn: user.social_security_number,
              diagnosis: user.diagnosis,
              diagnosisGroup: user.diagnosis_group,
              email: user.email,

              // Temporary, will be removed later.
              ageGroup: user.ageGroup,
              confirmedMedicineDates: user.confirmedMedicineDates,
              confirmedVomitDates: user.confirmedVomitDates,
              startDate: user.startDate,
              endDate: user.endDate,
            });
          }),
          catchError((err) => of(userActions.userProfileLoadFailure({ error: err })))
        )
      )
    );
  });

  loadUserProfileFailure$ = createEffect(
    // eslint-disable-next-line arrow-body-style
    () => {
      return this.actions$.pipe(
        ofType(userActions.userProfileLoadFailure),
        tap((_action) =>
          this.hestiaCommon.createErrorAlert({
            header: this.transloco.translate('userProfileLoadFailureAlert.header', {}, moduleI18nScope),
            message: this.transloco.translate('userProfileLoadFailureAlert.message', {}, moduleI18nScope),
          })
        )
      );
    },
    { dispatch: false }
  );

  initKioskSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.initKioskSession),
      switchMap((action) =>
        this.userFacade.initKioskSession(action.social_security_number).pipe(
          switchMap(() =>
            this.userFacade.getKioskSession().pipe(
              switchMap((response) => {
                const { consent_enabled, patient_user_info: user, uncompleted_tasks } = response;

                return [
                  fhirResourceActions.addFhirResources({ fhirResources: uncompleted_tasks }),
                  userActions.initKioskSessionSuccess({
                    fullName: user.fullname,
                    userId: user.username,
                    fhirId: user.fhir_id,
                    orgUuid: user.organization_uid,
                    fhirResource: user.user_resource,
                    userType: user.user_type,
                    cellPhone: user.cell_phone_number,
                    ssn: user.social_security_number,
                    diagnosis: user.diagnosis,
                    diagnosisGroup: user.diagnosis_group,
                    email: user.email,
                  }),
                ];
              })
            )
          ),
          catchError((error) => of(userActions.initKioskSessionFailure({ error })))
        )
      )
    );
  });

  initKioskSessionFailure$ = createEffect(
    // eslint-disable-next-line arrow-body-style
    () => {
      return this.actions$.pipe(
        ofType(userActions.initKioskSessionFailure),
        tap((_action) =>
          this.hestiaCommon.createErrorAlert({
            header: this.transloco.translate('userProfileLoadFailureAlert.header', {}, moduleI18nScope),
            message: this.transloco.translate('userProfileLoadFailureAlert.message', {}, moduleI18nScope),
          })
        )
      );
    },
    { dispatch: false }
  );

  // eslint-disable-next-line arrow-body-style
  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.logout, userActions.logoutFixed),
      switchMap((_action) =>
        this.userFacade.doSignOut().pipe(
          map(() => userActions.logoutSuccess()),
          catchError((errorRaw) => {
            const error = errorRaw as HestiaAuthError;
            return of(
              userActions.logoutFailure({
                errorType: error.errorType,
                loginMethod: error.loginMethod,
                errorI18n: error.errorI18n,
                error: error,
              })
            );
          })
        )
      )
    );
  });

  logoutFailure$ = createEffect(
    // eslint-disable-next-line arrow-body-style
    () => {
      return this.actions$.pipe(
        ofType(userActions.logoutFailure),
        switchMap((_action) => of(userActions.resetState()))
      );
    }
  );

  logoutSuccess$ = createEffect(
    // eslint-disable-next-line arrow-body-style
    () => {
      return this.actions$.pipe(
        ofType(userActions.logoutSuccess),
        switchMap((_action) => of(userActions.resetState()))
      );
    }
  );
}
