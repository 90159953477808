import { Component, OnInit } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import { HestiaCommonService } from '@hestia/ngx-common';

import { MITID_MODULE_I18N_SCOPE } from '../../constants';
import { HestiaMitIdService } from '../../services/mitid.service';

@Component({
  selector: 'hestia-mitid-callback',
  templateUrl: 'mitid-callback.component.html',
  styleUrls: ['mitid-callback.component.scss'],
})
export class HestiaMitIdCallbackComponent implements OnInit {
  constructor(
    private mitIdService: HestiaMitIdService,
    private transloco: TranslocoService,
    private commonService: HestiaCommonService
  ) {}

  ngOnInit() {
    const loadingMsg = this.transloco.translate(`${MITID_MODULE_I18N_SCOPE}.loading`);
    this.commonService.presentLoader({ message: loadingMsg }).then(() => this.mitIdService.handleCallback());
  }
}
