import { WidgetTypes } from '../../typings';
import { LegacyQnrItemExtBase } from '../extension-base';

const EXTENSION_URL =
  'http://hl7.org/fhir/StructureDefinition/questionnaire-sliderOrientation';
export type SliderOrientation = 'horizontal' | 'vertical';

export class QuestionnaireItemItemSliderOrientation extends LegacyQnrItemExtBase {
  extensionUrl = EXTENSION_URL;
  supportedWidgets = [WidgetTypes.SliderWidget];
  constructor(config: Record<string, unknown> = {}) {
    super(config);
  }

  parse = (): {
    widgetProperties: { sliderOrientation: SliderOrientation };
  } => {
    let sliderOrientation: SliderOrientation = null;
    if (this.extensionJson?.valueCode) {
      sliderOrientation = this.extensionJson.valueCode as SliderOrientation;
    }

    return {
      widgetProperties: {
        sliderOrientation,
      },
    };
  };
}

export function getItemSliderOrientationExt(item: fhir4.QuestionnaireItem): {
  sliderOrientation: SliderOrientation;
} {
  let extensionObj: QuestionnaireItemItemSliderOrientation;
  if (item?.extension) {
    // the FHIR resource is already validated at this point
    item.extension.forEach((extension) => {
      if (extension.url === EXTENSION_URL) {
        extensionObj = new QuestionnaireItemItemSliderOrientation({
          extensionJson: extension,
        });
      }
    });
  }
  return extensionObj !== undefined
    ? extensionObj.parse().widgetProperties
    : { sliderOrientation: 'horizontal' };
}
