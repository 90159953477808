import { InjectionToken } from '@angular/core';

export interface IHestiaUserModuleConfig {
  /** The boolean key used in NGRX to mark if state has been synced from local storage */
  syncHydratedKey: string;
  isAuthenticatedGuardFailureRoute: string;
  isUnauthenticatedGuardFailureRoute: string;
}

export const HESTIA_USER_MODULE_CONFIG =
  new InjectionToken<IHestiaUserModuleConfig>('HESTIA_USER_MODULE_CONFIG');

export const moduleI18nScope = 'hestiaUser';

export const HESTIA_INTERCEPTOR_SKIP_HEADER = 'X-Skip-Interceptor';
