export function concatMaps<K, T>(
  map: Map<K, T>,
  iterables: Array<Map<K, T>>
): Map<K, T> {
  for (const iterable of iterables) {
    for (const item of iterable) {
      map.set(...item);
    }
  }
  return map;
}
