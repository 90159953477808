export interface IHestiaPractitioner {
  fhirId: string;
  fhirResource: fhir4.Practitioner;
}

export class HestiaPractitioner implements IHestiaPractitioner {
  fhirId: string;
  fhirResource: fhir4.Practitioner;
  constructor(public config: IHestiaPractitioner) {
    this.fhirId = config.fhirId;
    this.fhirResource = config.fhirResource;
  }
}
