import {
  compare,
  applyReducer,
  AddOperation,
  RemoveOperation,
  ReplaceOperation,
  MoveOperation,
  CopyOperation,
  TestOperation,
  GetOperation,
} from 'fast-json-patch';
import * as jsonpath from 'jsonpath';
import { pickBy } from 'lodash-es';

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { firstValueFrom, forkJoin, from, lastValueFrom, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Dictionary } from '@ngrx/entity';
import { Action, Store } from '@ngrx/store';

import { CORE_HESTIA_CONFIG } from '@hestia/ngx-common';
import { extractCodeFromCodeableConcept } from '@hestia/ngx-fhir';
import { _i18n } from '@hestia/ngx-i18n';
import {
  CarePlanActivityStatus,
  CarePlanStatus,
  HestiaFhirResource,
  HestiaFhirResourceTypeName,
  HestiaUserType,
  IHestiaCoreConfig,
} from '@hestia/ngx-types';
import { HestiaUserFacade, selectUserState } from '@hestia/ngx-user';

import { fhirResourceActions } from '../+state/fhir-resource.actions';
import {
  selectCarePlanResources,
  selectFhirResourceById,
  selectFhirResourceEntities,
  selectFhirResourceIds,
  selectFhirResources,
} from '../+state/fhir-resource.selectors';
import { anyCarePlanActivitiesNotFinalized } from '../utils/care-plan.utils';
import { concatMaps } from '../utils/concat-maps';
import { splitRelativeReference } from '../utils/split-relative-reference';

export type PatchOperation =
  | AddOperation<unknown>
  | RemoveOperation
  | ReplaceOperation<unknown>
  | MoveOperation
  | CopyOperation
  | TestOperation<unknown>
  | GetOperation<unknown>;

@Injectable({ providedIn: 'root' })
export class HestiaFhirResourcesService {
  userState$ = this.store.select(selectUserState);

  apiUrl: string;
  endpoints = {
    fhir: async (method): Promise<string> => {
      const appType = this.hestiaCoreConfig.appBuiltFor;
      let endpoint: '@fhir' | '@kiosk-fhir';

      // Need to add a way to get kioskMode from user.

      if (appType !== 'KIOSK_APP') {
        endpoint = '@fhir';
      } else {
        const endpointsForMethod: {
          [key: string]: '@fhir' | '@kiosk-fhir';
        } = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          GET: '@fhir',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          POST: '@kiosk-fhir',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          PUT: '@fhir',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          PATCH: '@fhir',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          DELETE: '@fhir',
        };
        endpoint = endpointsForMethod[method];
      }
      return endpoint;
    },
  };
  getEndpoint$ = from(this.endpoints.fhir('GET')).pipe(first());
  postEndpoint$ = from(this.endpoints.fhir('POST')).pipe(first());
  putEndpoint$ = from(this.endpoints.fhir('PUT')).pipe(first());
  patchEndpoint$ = from(this.endpoints.fhir('PATCH')).pipe(first());
  deleteEndpoint$ = from(this.endpoints.fhir('DELETE')).pipe(first());
  nextQuestionEndpoint = '@next-question'; // TODO: Must be replaced with proper endpoint implemeentation

  public fhirEntities$: Observable<Dictionary<HestiaFhirResource>>;
  public fhirEntities: Dictionary<HestiaFhirResource>;
  public fhirResources$: Observable<Array<HestiaFhirResource>>;
  public fhirResources: Array<HestiaFhirResource>;
  public carePlans$: Observable<fhir4.CarePlan[]>;
  public existingFhirIds$: Observable<(string | number)[]>;
  public existingFhirIds: (string | number)[];
  public inflightFetchIds = new Set();
  constructor(
    public http: HttpClient,
    public store: Store,
    @Inject(CORE_HESTIA_CONFIG) private hestiaCoreConfig: IHestiaCoreConfig,
    private userFacade: HestiaUserFacade
  ) {
    this.apiUrl = this.hestiaCoreConfig.apiUrl;
    this.fhirEntities$ = this.store.select(selectFhirResourceEntities);
    this.fhirEntities$.subscribe((entities) => (this.fhirEntities = entities));
    this.fhirResources$ = this.store.select(selectFhirResources);
    this.fhirResources$.subscribe((resources) => (this.fhirResources = resources));
    this.carePlans$ = this.store.select(selectCarePlanResources);
    this.existingFhirIds$ = this.store.select(selectFhirResourceIds);
    this.existingFhirIds$.subscribe((result) => (this.existingFhirIds = result));
  }

  dispatch(action: Action): void {
    this.store.dispatch(action);
  }

  /**
   * The key fuction used for GET requests to the FHIR API
   */
  fetchQuery(params: {
    resourceType: HestiaFhirResourceTypeName;
    queryParams: { [key: string]: string | number | boolean };
    addToStore?: boolean;
    resolveReferences?: boolean;
  }): Observable<fhir4.Bundle> {
    const cleanedParams = pickBy(params.queryParams, (v) => v !== undefined);
    const options = {
      params: new HttpParams({ fromObject: cleanedParams }),
    };
    return this.getEndpoint$.pipe(
      switchMap((endpoint) =>
        this.http.get<fhir4.Bundle>(`${this.apiUrl}${endpoint}/${params.resourceType}`, options).pipe(
          map((bundle) => {
            if (bundle.total > 0 && (params?.addToStore ?? false)) {
              const resources = bundle.entry.map((elem) => elem.resource as HestiaFhirResource);
              if (params.resolveReferences ?? false) {
                const blacklist = resources.map((elem) => elem.id);
                const uniqueReferences = concatMaps<string, HestiaFhirResourceTypeName>(
                  new Map<string, HestiaFhirResourceTypeName>(),
                  resources.map((resource) => this.findReferencesInResource({ resource, blacklist }))
                );
                uniqueReferences.forEach((resourceType, resourceId) =>
                  this.resolveReference$({
                    resourceId,
                    resourceType,
                    nestedResolveDepth: 2,
                  }).subscribe()
                );
              }
              this.dispatch(
                fhirResourceActions.upsertFhirResources({
                  fhirResources: resources,
                })
              );
            }
            return bundle;
          })
        )
      )
    );
  }

  findReferencesInResource(params: {
    resource: HestiaFhirResource;
    blacklist: (string | number)[];
  }): Map<string, HestiaFhirResourceTypeName> {
    const references = new Map<string, HestiaFhirResourceTypeName>();
    jsonpath.query(params.resource, '$..reference').forEach((reference) => {
      const [resourceType, resourceId] = reference.split('/');
      if (!params.blacklist.includes(resourceId)) {
        references.set(resourceId, resourceType);
      }
    });
    return references;
  }

  fetchResource<T extends HestiaFhirResource = HestiaFhirResource>(
    resourceType: HestiaFhirResourceTypeName,
    resourceId: string,
    options: { queryStoreFirst: boolean; addToStore: boolean } = {
      queryStoreFirst: false,
      addToStore: true,
    }
  ): Observable<T> {
    if (options.queryStoreFirst) {
      const storedResource = this.fhirEntities[resourceId] ?? null;
      if (storedResource) {
        return of(storedResource as T);
      }
    }
    return this.getEndpoint$.pipe(
      switchMap((endpoint) =>
        this.http.get<T>(`${this.apiUrl}${endpoint}/${resourceType}/${resourceId}`).pipe(
          tap((resource) => {
            // Dispatch upsert of resource if addToStore is enabled
            if (resource && options.addToStore) {
              this.store.dispatch(
                fhirResourceActions.upsertFhirResource({
                  fhirResource: resource,
                })
              );
            }
          })
        )
      )
    );
  }

  fetchUnfinishedUserCarePlan(): Observable<fhir4.Bundle> {
    return this.fetchQuery({
      resourceType: HestiaFhirResourceTypeName.CarePlan,
      queryParams: {
        'status:not': 'completed,cancelled',
      },
      addToStore: true,
      resolveReferences: true,
    });
  }

  fetchUncanceledUserCarePlan(): Observable<fhir4.Bundle> {
    return this.fetchQuery({
      resourceType: HestiaFhirResourceTypeName.CarePlan,
      queryParams: {
        'status:not': 'cancelled,on-hold',
      },
      addToStore: true,
      resolveReferences: false,
    });
  }

  updateActivityInCarePlan(props: {
    carePlan: fhir4.CarePlan;
    activityCode: string;
    status: CarePlanActivityStatus;
    qnrResponse?: fhir4.QuestionnaireResponse;
  }) {
    const newCarePlanVersion: fhir4.CarePlan = {
      ...props.carePlan,
      activity: props.carePlan.activity.map((activity) => {
        const code = extractCodeFromCodeableConcept(activity.detail.code);
        if (code !== props.activityCode) {
          return activity;
        }
        let updatedActivity = JSON.parse(JSON.stringify(activity));
        updatedActivity.outcomeReference = props.qnrResponse
          ? [{ reference: `QuestionnaireResponse/${props.qnrResponse.id}` }]
          : null;
        updatedActivity.detail.status = props.status;
        return updatedActivity;
      }),
    };
    return newCarePlanVersion;
  }

  async getPatchOperations(
    newVersion: fhir4.Resource,
    options: { forceServerLoad: boolean } = { forceServerLoad: false }
  ): Promise<PatchOperation[]> {
    let oldVersion: HestiaFhirResource;
    if (options.forceServerLoad) {
      oldVersion = await firstValueFrom(
        this.fetchResource(newVersion.resourceType as HestiaFhirResourceTypeName, newVersion.id, {
          queryStoreFirst: false,
          addToStore: false,
        })
      );
    } else {
      oldVersion = this.fhirEntities[newVersion.id];
    }
    if (!oldVersion) {
      throw Error('Cannot calculate patch operations if old resource version not found in state');
    }
    const operations = compare(oldVersion, newVersion);
    return operations;
  }

  patchResource(props: {
    resourceType: 'QuestionnaireResponse' | 'CarePlan';
    resourceId: string;
    /** The JSON patch operations, can also be generated based on a new version of the resource */
    operations?: PatchOperation[];
    /** Must provide newResourceVersion if operations are not explicitly provided */
    newResourceVersion?: fhir4.Resource;
    options?: { forceServerReload: boolean };
  }): Observable<unknown> {
    props.options = props?.options ?? { forceServerReload: false };
    if (!props?.operations) {
      if (!props?.newResourceVersion) {
        throw Error('Must provide newResourceVersion if operations are not explicitly provided');
      }
    }
    const operations$ = props.operations
      ? of(props.operations)
      : from(
          this.getPatchOperations(props.newResourceVersion, {
            forceServerLoad: props.options.forceServerReload,
          })
        );
    return this.patchEndpoint$.pipe(
      withLatestFrom(operations$),
      switchMap(([endpoint, operations]) => {
        const url = `${this.apiUrl}${endpoint}/${props.resourceType}/${props.resourceId}`;
        return this.http.patch(url, { patch: operations }).pipe(
          tap(() => {
            const oldVersion = JSON.parse(JSON.stringify(this.fhirEntities[props.resourceId])) ?? null;
            if (oldVersion) {
              const newVersion = operations.reduce(applyReducer, {
                ...oldVersion,
              });
              this.store.dispatch(
                fhirResourceActions.upsertFhirResource({
                  fhirResource: newVersion,
                })
              );
            }
          })
        );
      })
    );
  }

  async resolveReference<T extends HestiaFhirResource = HestiaFhirResource>(params: {
    reference?: string;
    resourceType?: HestiaFhirResourceTypeName;
    resourceId?: string;
    nestedResolve?: boolean;
  }): Promise<T> {
    return firstValueFrom(this.resolveReference$<T>(params));
  }

  resolveReference$<T extends HestiaFhirResource = HestiaFhirResource>(props: {
    reference?: string;
    resourceType?: HestiaFhirResourceTypeName;
    resourceId?: string;
    nestedResolveDepth?: number;
  }): Observable<T> {
    if (!props.reference && !props.resourceId) {
      console.warn("Called resolveReference$ but didn't provide reference string or resource ID");
      return of(null);
    }
    let resourceType, resourceId;
    if (props?.reference) {
      const splitReference = splitRelativeReference(props.reference);
      resourceType = splitReference[0];
      resourceId = splitReference[1];
    } else {
      resourceType = props.resourceType;
      resourceId = props.resourceId;
    }
    const resource: T = (this.fhirEntities[resourceId] as T) ?? null;
    if (resource === null && resourceType !== 'Organization') {
      if (this.inflightFetchIds.has(resourceId)) {
        return this.store.select(selectFhirResourceById(resourceId)).pipe(
          filter((result) => result !== null),
          first()
        ) as Observable<T>;
      }
      this.inflightFetchIds.add(resourceId);
      // TODO: Remove the temporary hardcoding of Organization not being resolved
      return this.fetchResource<T>(resourceType, resourceId).pipe(
        tap((fhirResource) => {
          if ((props.nestedResolveDepth ?? 0) > 0) {
            const uniqueReferences = this.findReferencesInResource({
              resource: fhirResource,
              blacklist: this.existingFhirIds,
            });
            uniqueReferences.forEach((refResourceType, refResourceId) =>
              this.resolveReference$({
                resourceId: refResourceId,
                resourceType: refResourceType,
                nestedResolveDepth: props.nestedResolveDepth - 1,
              }).subscribe()
            );
          }
          this.store.dispatch(fhirResourceActions.upsertFhirResource({ fhirResource }));
          this.inflightFetchIds.delete(resourceId);
        })
      );
    } else {
      return of(resource as T);
    }
  }

  findAdaptiveQnrNextItems(props: {
    qnrResponse: fhir4.QuestionnaireResponse;
  }): Observable<fhir4.QuestionnaireResponse> {
    return this.http
      .post<fhir4.QuestionnaireResponse>(`${this.apiUrl}${this.nextQuestionEndpoint}`, props.qnrResponse)
      .pipe(
        tap((qnrResponse) => {
          this.store.dispatch(
            fhirResourceActions.upsertFhirResources({
              fhirResources: [qnrResponse, qnrResponse.contained?.[0] as fhir4.Questionnaire],
            })
          );
        })
      );
  }

  /**
   * Returns true once calls are completed
   *
   * TODO: Implement stronger error handling
   */
  public async submitQnrResponse(props: {
    qnrResponse: fhir4.QuestionnaireResponse;
    doesQnrResponseExistOnServer: boolean;
    carePlan: fhir4.CarePlan;
    activityCode: string;
    activityStatus: CarePlanActivityStatus;
    newCarePlanStatus?: CarePlanStatus;
    options?: { forceServerReloadForPatch: boolean };
  }): Promise<true> {
    // TODO: Tighten typing here for return value

    // console.log('submitQnrResponse', { props });
    const requests: {
      requestType: 'POST' | 'PATCH';
      resourceType: 'QuestionnaireResponse' | 'CarePlan';
      id: string;
      body: fhir4.QuestionnaireResponse | { patch: PatchOperation[] };
    }[] = [
      {
        requestType: props.doesQnrResponseExistOnServer ? 'PATCH' : 'POST',
        resourceType: 'QuestionnaireResponse',
        id: props.qnrResponse.id,
        body: props.doesQnrResponseExistOnServer
          ? {
              patch: await this.getPatchOperations(props.qnrResponse, {
                forceServerLoad: props?.options?.forceServerReloadForPatch ?? false,
              }),
            }
          : props.qnrResponse,
      },
    ];
    if (props.carePlan) {
      // If CarePlan is not provided, we are running in standalone mode
      const updatedCarePlanVersion = this.updateActivityInCarePlan({
        carePlan: props.carePlan,
        activityCode: props.activityCode,
        status: props.activityStatus,
        qnrResponse: props.qnrResponse,
      });
      if (!props?.newCarePlanStatus) {
        // We calculate status for CarePlan
        if (!anyCarePlanActivitiesNotFinalized(updatedCarePlanVersion)) {
          props.newCarePlanStatus = 'completed';
        } else {
          props.newCarePlanStatus = 'active';
        }
      }

      //if(props.newCarePlanStatus!=='active'){// only PATCH if you want else than active
      requests.push({
        requestType: 'PATCH',
        resourceType: 'CarePlan',
        id: props.carePlan.id,
        body: {
          patch: [
            { op: 'replace', path: '/status', value: props.newCarePlanStatus },
            ...(await this.getPatchOperations(updatedCarePlanVersion, {
              forceServerLoad: props?.options?.forceServerReloadForPatch ?? false,
            })),
          ],
        },
      });
      //                                         }// only PATCH if you want else than active end
    }
    const observables = requests.map((request) => {
      if (request.requestType === 'POST') {
        const baseRequest = this.postEndpoint$.pipe(
          switchMap((postEndpoint) =>
            this.http.post(`${this.apiUrl}${postEndpoint}/${request.resourceType}`, request.body).pipe(
              tap(() =>
                this.store.dispatch(
                  fhirResourceActions.upsertFhirResource({
                    fhirResource: request.body as fhir4.QuestionnaireResponse,
                  })
                )
              )
            )
          )
        );

        return baseRequest.pipe(
          catchError((err: HttpErrorResponse) => {
            console.log('CATCH', err);
            return this.getEndpoint$.pipe(
              switchMap((getEndpoint) =>
                this.http
                  .get<fhir4.Resource>(`${this.apiUrl}${getEndpoint}/${request.resourceType}/${request.id}`)
                  .pipe(
                    catchError((_error) => baseRequest),
                    switchMap((existingResource: fhir4.Resource) => {
                      const newResource = request.body as fhir4.Resource;
                      if (existingResource === null) {
                        return baseRequest;
                      }
                      if (existingResource.resourceType !== newResource.resourceType) {
                        return baseRequest;
                      }
                      if (existingResource.resourceType === 'QuestionnaireResponse') {
                        const oldQr = existingResource as fhir4.QuestionnaireResponse;
                        const newQr = newResource as fhir4.QuestionnaireResponse;
                        if (oldQr.questionnaire !== newQr.questionnaire) {
                          return baseRequest;
                        }
                      }
                      const patchOps = compare(existingResource, request.body);
                      return this.patchResource({
                        resourceType: request.resourceType,
                        resourceId: request.id,
                        operations: patchOps,
                      });
                    })
                  )
              )
            );
          })
        );
      } else {
        return this.patchResource({
          resourceType: request.resourceType,
          resourceId: request.id,
          operations: Object.prototype.hasOwnProperty.call(request.body, 'patch') ? request.body['patch'] : [],
        });
      }
    });

    return firstValueFrom(forkJoin(observables).pipe(map(() => true)));
  }

  /**
   * Fetches all questionnaires from the Hestia FHIR API backend
   */
  fetchAllQuestionnaires(
    props: {
      resolveReferences: boolean;
      addToStore: boolean;
    } = {
      resolveReferences: false,
      addToStore: true,
    }
  ): Observable<fhir4.Questionnaire[]> {
    return this.fetchQuery({
      resourceType: HestiaFhirResourceTypeName.Questionnaire,
      queryParams: { _count: 999 },
      addToStore: props.addToStore,
      resolveReferences: props.resolveReferences ?? false,
    }).pipe(
      map((bundle) => {
        let resources: fhir4.Questionnaire[] = [];
        if (bundle.total) {
          resources = bundle.entry.map((elem) => elem.resource as fhir4.Questionnaire);
        }
        return resources;
      })
    );
  }

  /**
   * Fetches all valueSets from the Hestia FHIR API backend
   */
  fetchAllValueSets(
    props: {
      resolveReferences: boolean;
      addToStore: boolean;
    } = {
      resolveReferences: false,
      addToStore: true,
    }
  ): Observable<fhir4.ValueSet[]> {
    return this.fetchQuery({
      resourceType: HestiaFhirResourceTypeName.ValueSet,
      queryParams: { _count: 999 },
      addToStore: props.addToStore,
      resolveReferences: props.resolveReferences ?? false,
    }).pipe(
      map((bundle) => {
        let resources: fhir4.ValueSet[] = [];
        if (bundle.total) {
          resources = bundle.entry.map((elem) => elem.resource as fhir4.ValueSet);
        }
        return resources;
      })
    );
  }

  fetchCarePlansForPatient(props: {
    patientFhirId: string;
    category?: string;
    status?: CarePlanStatus;
    resolveReferences: boolean;
  }): Observable<fhir4.CarePlan[]> {
    return this.fetchQuery({
      resourceType: HestiaFhirResourceTypeName.CarePlan,
      queryParams: {
        subject: props.patientFhirId,
        category: props.category ?? undefined,
        status: props.status ?? undefined,
        // _sort: '-created',
      },
      addToStore: true,
      resolveReferences: props.resolveReferences ?? false,
    }).pipe(
      map((bundle) => {
        let resources: fhir4.CarePlan[] = [];
        if (bundle.total) {
          resources = bundle.entry.map((elem) => elem.resource as fhir4.CarePlan);
        }
        return resources;
      })
    );
  }

  fetchResponsesFromPatient(props: {
    patientFhirId: string;
    qnrCanonicalUrl?: string;
    status?: CarePlanStatus;
    _sort?: string;
    resolveReferences: boolean;
  }): Observable<fhir4.QuestionnaireResponse[]> {
    return this.fetchQuery({
      resourceType: HestiaFhirResourceTypeName.QuestionnaireResponse,
      queryParams: {
        source: props.patientFhirId,
        questionnaire: props.qnrCanonicalUrl,
        _sort: props._sort,
      },
      addToStore: true,
      resolveReferences: props.resolveReferences ?? false,
    }).pipe(
      map((bundle) => {
        let resources: fhir4.QuestionnaireResponse[] = [];
        if (bundle.total) {
          resources = bundle.entry.map((elem) => elem.resource as fhir4.QuestionnaireResponse);
        }
        return resources;
      })
    );
  }

  deleteResource(resourceType: HestiaFhirResourceTypeName, resourceId: string) {
    if (!resourceType || !resourceId) {
      console.log('Both resourceType and resourceId need to be supplied.');
      return;
    }

    return this.http.delete(`${this.apiUrl}@fhir/${resourceType}/${resourceId}`);
  }
}
