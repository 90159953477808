<hestia-widget-group-header [config]="config" [i18n]="i18n"></hestia-widget-group-header>
<form [formGroup]="form">
  <ion-list lines="none">
    <ion-item>
      <ion-label>
        <hestia-widget-header [config]="config" [i18n]="i18n"></hestia-widget-header>
      </ion-label>
      <ion-datetime-button datetime="dateWidget"></ion-datetime-button>
    </ion-item>

    <ion-popover [keepContentsMounted]="true">
      <ng-template>
        <ion-datetime
          id="dateWidget"
          [presentation]="config.datetimeWidgetFormat"
          [disabled]="config.disabled"
          [attr.value]="config.value ?? config.defaultValue"
          [formControlName]="config.name"
          [minuteValues]="getMinuteValues()"
          [max]="config.defaultValue"
        ></ion-datetime>
      </ng-template>
    </ion-popover>
  </ion-list>
</form>
