import { Translation, TranslocoService } from '@ngneat/transloco';

export const loadModuleTranslations = (
  transloco: TranslocoService,
  scope: string,
  languages: { [key: string]: Translation }
) =>
  Object.entries(languages).forEach(([key, value]) => {
    transloco.setTranslation({ [scope]: value }, key);
  });

/**
 * @remarks Marker function used by transloco-keys-manager. Used to wrap standalone translation keys that are not part of any translation call to tell the keys manager to extract them.
 *
 * @param key string
 */
export const _i18n = (key: string) => key;
