import { Component, ElementRef, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';

import { Store } from '@ngrx/store';

import { WidgetBaseComponent } from '../../../models/widget';
import { Events } from '../../../services/events.service';
import { HestiaQnrFormSessionService } from '../../../services/qnr-form-session.service';
import { SignWidgetConfig } from './sign-widget.config';
import { SigningComponent } from './signing.component/signing';

@Component({
  selector: 'hestia-sign-widget',
  templateUrl: './sign-widget.component.html',
  styleUrls: ['./sign-widget.component.scss'],
})
export class SignWidgetComponent extends WidgetBaseComponent {
  signing: SigningComponent;
  @Input() config: SignWidgetConfig;
  @Input() form: UntypedFormGroup;
  constructor(
    transloco: TranslocoService,
    questionnaireService: HestiaQnrFormSessionService,
    store: Store,
    element: ElementRef,
    events: Events
  ) {
    super(transloco, questionnaireService, store, element, events);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  get isValid() {
    if (this.signing) {
      const data = this.signing.signaturePad.toDataURL('');
      this.form.controls[this.config.name].setValue(data);
    }

    return this.signing == null ? true : this.signing.signaturePad.isEmpty();
  }

  onEnd() {
    if (this.signing) {
      const data = this.signing.signaturePad.toDataURL('');
      this.form.controls[this.config.name].setValue(data);
    }
    //return this.signing == null ? true : this.signing.signaturePad.isEmpty();
  }
  public signingInit(signing: SigningComponent) {
    this.signing = signing;
    if (this.config.value) {
      this.signing.signaturePad.fromDataURL(this.config.value);
    }
  }

  onClear() {
    this.form.controls[this.config.name].setValue(null);
  }
}
