import { CommonModule } from '@angular/common';
import { Component, OnInit, isDevMode, Inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { IonicModule, ModalController } from '@ionic/angular';

import { TranslocoModule } from '@ngneat/transloco';

import { Store } from '@ngrx/store';

import { CORE_HESTIA_CONFIG } from '@hestia/ngx-common';
import { HestiaMitIdService } from '@hestia/ngx-mitid';
import { IHestiaCoreConfig } from '@hestia/ngx-types';
import { HestiaUserFacade, userActions } from '@hestia/ngx-user';

import { SMSActivationComponent } from '../sms-activation/sms-activation';
import { Video } from '../video';

export interface LoginPageData {
  /** Path to local image */
  imageSrc: string | undefined;
  auth: {
    usernameAndPassword?: boolean;
    /** Enable One Time Password/SMS Verification? */
    oneTimePassword?: boolean;
    /** Enable MitID? */
    mitID?: boolean;
    mitIDLabel?: string;
  };
  userTemplates?: {
    /** Username */
    login: string;
    /** Password */
    password: string;
  }[];
  userTemplatesVisible?: boolean;
  cards?: {
    /** Card Title (Local i18n string) */
    title: string | undefined;
    /** Card Description (Local i18n string) */
    description: string | undefined;
    /** Card Color  */
    color?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'medium' | 'light';
  }[];
  buttons?: {
    /** Button Label (Local i18n string) */
    label: string | undefined;
    /** Ionicon Name */
    icon: string | undefined;
    /** Card Color  */
    color?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'medium' | 'light';
    /** Handler  */
    handler?: () => void;
  }[];
  videoUrl: string | undefined;
  footerLogoSrc?: string | undefined;
}

@Component({
  standalone: true,
  selector: 'hestia-login',
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslocoModule, IonicModule],
  templateUrl: 'login.html',
  styles: [
    `
      ion-content {
        --background: linear-gradient(
          to top,
          rgba(var(--ion-color-light-rgb), 0.75),
          rgba(var(--ion-color-primary-rgb), 0.75)
        );
        background-blend-mode: saturation;
      }

      #imageSrc {
        width: 250px;
        margin: 3rem auto;
      }

      #footerLogoSrc {
        width: 150px;
        margin: 0 auto;
        margin-top: 5rem;
      }

      ion-chip {
        display: block;
        width: fit-content;
        margin: 2rem auto;
      }
    `,
  ],
})
export class LoginComponent implements OnInit {
  pageData: LoginPageData | undefined;

  showPassword = false;

  serviceStatus: { status?: string; color?: string } | undefined = { status: 'Unknown', color: 'warning' };

  form = new FormGroup({
    login: new FormControl('', [Validators.required, Validators.minLength(2)]),
    password: new FormControl('', [Validators.required, Validators.minLength(5)]),
  });
  appType: string;

  constructor(
    public modalController: ModalController,
    private route: ActivatedRoute,
    private store: Store,
    private modalCtrl: ModalController,
    private userFacade: HestiaUserFacade,
    public mitIdService: HestiaMitIdService,
    @Inject(CORE_HESTIA_CONFIG) private hestiaCoreConfig: IHestiaCoreConfig
  ) {
    this.route.data.subscribe((data: LoginPageData) => (this.pageData = data));
    this.appType = this.hestiaCoreConfig.appBuiltFor;

    this.getServerStatus();
  }

  async presentVideo() {
    const modal = await this.modalController.create({
      component: Video,
      componentProps: { videoUrl: this.pageData?.videoUrl },
    });

    modal.present();
  }

  ngOnInit(): void {
    return;
  }

  onSubmit() {
    const formValues = this.form.getRawValue();

    this.store.dispatch(userActions.login(formValues));
  }

  handleUserTemplateChange(ev) {
    this.form.patchValue(ev.target.value);
  }

  async openSMSActivationModal() {
    const modal = await this.modalCtrl.create({
      component: SMSActivationComponent,
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.store.dispatch(userActions.loginSuccess({ jwt: data?.token }));
    }
  }

  getServerStatus() {
    this.userFacade.helloServer().subscribe({
      next: () => (this.serviceStatus = { status: 'Operational', color: 'success' }),
      error: () => (this.serviceStatus = { status: 'Down', color: 'danger' }),
    });
  }

  fullReset() {
    this.store.dispatch(userActions.fullResetState());
  }
}
