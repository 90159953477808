import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, createFeatureSelector } from '@ngrx/store';

import { HestiaFhirResource } from '@hestia/ngx-types';

import * as FhirResourceActions from './fhir-resource.actions';

export const fhirResourcesFeatureKey = 'fhirResources';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFhirResourcesState extends EntityState<HestiaFhirResource> {
  // additional entities state properties
}

export const adapter: EntityAdapter<HestiaFhirResource> =
  createEntityAdapter<HestiaFhirResource>();

export const selectFhirResourceState =
  createFeatureSelector<IFhirResourcesState>(fhirResourcesFeatureKey);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectFhirResourceState);

export const initialState: IFhirResourcesState = adapter.getInitialState({
  // additional entity state properties
});

export const fhirResourcesReducer = createReducer(
  initialState,
  on(
    FhirResourceActions.addFhirResource,
    (state: IFhirResourcesState, action) =>
      adapter.addOne(action.fhirResource, state)
  ),
  on(FhirResourceActions.upsertFhirResource, (state, action) =>
    adapter.upsertOne(action.fhirResource, state)
  ),
  on(FhirResourceActions.addFhirResources, (state, action) =>
    adapter.addMany(action.fhirResources, state)
  ),
  on(FhirResourceActions.upsertFhirResources, (state, action) =>
    adapter.upsertMany(action.fhirResources, state)
  ),
  on(FhirResourceActions.updateFhirResource, (state, action) =>
    adapter.updateOne(action.fhirResource, state)
  ),
  on(FhirResourceActions.updateFhirResources, (state, action) =>
    adapter.updateMany(action.fhirResources, state)
  ),
  on(FhirResourceActions.deleteFhirResource, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(FhirResourceActions.deleteFhirResources, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(FhirResourceActions.loadFhirResources, (state, action) =>
    adapter.setAll(action.fhirResources, state)
  ),
  on(FhirResourceActions.clearFhirResources, (state) =>
    adapter.removeAll(state)
  )
);
