import {
  HestiaAppUse,
  HestiaAuthMethod,
  HestiaSupportedLanguage,
  IHestiaCoreConfig,
  IHestiaSentryConfig,
  OptionalExceptFor,
} from '@hestia/ngx-types';

// import { AppRouteEnum } from '../app/route-constants';
import packageJson from '../../../../package.json';

export const PRO_PSYCH_PATIENT_SENTRY_DSN = 'https://5304e77e67d349209d2592c94c8903c6@sentry.zitelab.eu/40';

export interface IProPsychPatientCoreConfig extends IHestiaCoreConfig {
  nemidUrl: string;
  testUserName?: string;
  testPassword?: string;
  syncStateToStorage: boolean;
  enableDevAdmin: boolean;
  backendAdminUser: string;
  backendAdminPassword: string;
  mitIdLoginUrl: string;
  mitIdLogoutUrl: string;
  mitIdCallbackRoute: string;
}

export class ProPsychPatientEnv implements IProPsychPatientCoreConfig {
  production: boolean;
  debugMode: boolean;
  envName: string;
  apiUrl: string;
  availableLangs: HestiaSupportedLanguage[];
  defaultLang: HestiaSupportedLanguage;
  appBuiltFor: HestiaAppUse;
  defaultAuthMethod: HestiaAuthMethod;
  syncStateToStorage: boolean;
  syncStorageKey: string;
  syncHydratedKey: string;
  release: string;
  sentry: IHestiaSentryConfig;
  nemidUrl: string;
  testUserName?: string;
  testPassword?: string;
  enableDevAdmin: boolean;
  backendAdminUser: string;
  backendAdminPassword: string;
  isAuthenticatedGuardFailureRoute: string;
  isUnauthenticatedGuardFailureRoute: string;
  mitIdLoginUrl: string;
  mitIdLogoutUrl: string;
  mitIdCallbackRoute: string;
  constructor(
    config: OptionalExceptFor<
      IProPsychPatientCoreConfig,
      'production' | 'debugMode' | 'envName' | 'sentry' | 'appBuiltFor' | 'nemidUrl' | 'syncStateToStorage'
    >
  ) {
    this.production = config.production;
    this.debugMode = config.debugMode;
    this.envName = config.envName;
    this.apiUrl = config.apiUrl;
    this.availableLangs = config.availableLangs ?? ['da-DK', 'en-GB'];
    this.defaultLang = config.defaultLang ?? 'da-DK';
    this.appBuiltFor = config.appBuiltFor;
    this.defaultAuthMethod = config.defaultAuthMethod ?? 'NEMID';
    this.syncStorageKey = config.syncStorageKey ?? 'zitelab.eu.pro.psych.patient.app';
    this.syncHydratedKey = config.syncHydratedKey ?? 'hydrated';
    this.release = config.release ?? packageJson.version;
    this.sentry = config.sentry;
    this.nemidUrl = config.nemidUrl;
    this.testUserName = config?.testUserName;
    this.testPassword = config?.testPassword;
    this.syncStateToStorage = config?.syncStateToStorage ?? true;
    this.enableDevAdmin = config?.enableDevAdmin ?? false;
    this.backendAdminUser = config?.backendAdminUser;
    this.backendAdminPassword = config?.backendAdminPassword;
    // this.isAuthenticatedGuardFailureRoute = `/${AppRouteEnum.LoginPage}`;
    // this.isUnauthenticatedGuardFailureRoute = `/${AppRouteEnum.InstructionsPage}`;
    this.mitIdLoginUrl = config?.mitIdLoginUrl;
    this.mitIdLogoutUrl = config?.mitIdLogoutUrl;
    this.mitIdCallbackRoute = config?.mitIdCallbackRoute;
    if (this.backendAdminUser || this.backendAdminPassword) {
      console.warn('BACKEND user and/or password was found in env, only use when developing');
    }
  }
}
