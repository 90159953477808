import { DeepPartial } from 'utility-types';

import { WidgetConfig } from '../../../models/widget.config';

export class NumberWidgetConfig extends WidgetConfig<string> {
  widgetType = 'NumberWidget';
  constructor(config: DeepPartial<WidgetConfig<unknown>>) {
    super(config as unknown);
  }
}
