import { WidgetConfig } from '../../../models/widget.config';

export class SvgGroupWidgetConfig extends WidgetConfig<{}> {
  controlType = 'FormGroup';
  widgetType = 'SvgGroupWidget';
  svgSourceType: 'string' | 'filename';
  svgSource: string;
  constructor(config: {} = {}) {
    super(config);
    this.svgSourceType = config['svgSourceType'] || null;
    this.svgSource = config['svgSource'] || null;
  }
}
