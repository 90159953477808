import { PRO_PSYCH_PATIENT_SENTRY_DSN, ProPsychPatientEnv } from './pro-psych-patient-env';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// import { AppRouteEnum } from '../app/route-constants';

export const environment: ProPsychPatientEnv = new ProPsychPatientEnv({
  production: true,
  debugMode: false,
  envName: 'prod',
  apiUrl: 'https://pro-p.dk/api/',
  appBuiltFor: 'PATIENT_APP', // Important: toggle this between PATIENT_APP and KIOSK_APP depending on which app you build for.
  sentry: { enabled: false, dsn: PRO_PSYCH_PATIENT_SENTRY_DSN },
  nemidUrl: 'https://pro-psykiatri.dk/nemid-login/', // 'http://localhost:9999/',
  defaultAuthMethod: 'USERNAME_AND_PASSWORD',
  testUserName: null, //psychtest or 7b3da6bf-5b0a-41a3-a4d7-654503ee28a2
  testPassword: null, //psychtest or 37c2f7c7-36fc-4f0f-8397-78a6a5b8fdd6
  syncStateToStorage: true,
  mitIdLoginUrl: 'https://pro-p.dk/api/mitid-auth/login/',
  mitIdLogoutUrl: 'https://pro-p.dk/api/mitid-auth/logout/',
  mitIdCallbackRoute: 'mitid-callback',
});
