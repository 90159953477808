import { WidgetTypes } from '../../typings';
import { IQnrItemExtParsedExt, LegacyQnrItemExtBase } from '../extension-base';

export class QuestionnaireItemEnableWhenFunction extends LegacyQnrItemExtBase {
  extensionUrl =
    'http://zitelab.eu/fhir/extensions/QuestionnaireItemEnableWhenFunction';
  supportedWidgets = [WidgetTypes.All];
  constructor(config: Record<string, unknown> = {}) {
    super(config);
  }

  parse = (): IQnrItemExtParsedExt => {
    let enableWhenFunction: string = null;
    if (this.extensionJson?.valueString) {
      enableWhenFunction = this.extensionJson.valueString;
    }

    return {
      widgetProperties: {
        enableWhenFunction,
      },
    };
  };
}

export function getEnableWhenFunction(item: fhir4.QuestionnaireItem) {
  let extensionObj: QuestionnaireItemEnableWhenFunction;
  if (item?.extension) {
    // the FHIR resource is already validated at this point
    item.extension.forEach((extension) => {
      if (
        extension.url ===
        'http://zitelab.eu/fhir/extensions/QuestionnaireItemEnableWhenFunction'
      ) {
        extensionObj = new QuestionnaireItemEnableWhenFunction({
          extensionJson: extension,
        });
      }
    });
  }
  return extensionObj !== undefined
    ? extensionObj.parse().widgetProperties
    : {};
}
