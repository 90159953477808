import { MarkdownModule } from 'ngx-markdown';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { HestiaNgrxLocalStorageSyncModule } from '@hestia/ngrx-local-storage-sync';
import { HestiaCommonModule } from '@hestia/ngx-common';
import { HestiaNgxFhirModule, fhirResourcesFeatureKey } from '@hestia/ngx-fhir';
import {
  NgxFhirQnrFormsModule,
  QnrSessionServerSaveStrategy,
  defaultTranslations,
  qnrFormSessionFeatureKey,
  qnrModuleSettingsFeatureKey,
} from '@hestia/ngx-fhir-qnr-forms';
import { HestiaNgxMitIdModule } from '@hestia/ngx-mitid';
import { AppShellComponent, HestiaPagesModule } from '@hestia/ngx-pages';
import {
  AuthGuard,
  AuthNoneGuard,
  HestiaNgxUserModule,
  IsAuthenticatedRouteGuard,
  IsUnauthenticatedRouteGuard,
  userFeatureKey,
} from '@hestia/ngx-user';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { TranslocoRootModule } from './transloco-root.module';

const allGuards = [IsAuthenticatedRouteGuard, IsUnauthenticatedRouteGuard, AuthGuard, AuthNoneGuard];

@NgModule({
  declarations: [AppShellComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IonicModule.forRoot({ mode: 'md' }),
    HestiaCommonModule.forRoot(environment),
    HestiaPagesModule.forRoot({
      name: 'PRO-Psykiatri',
      // contactList: [{ name: 'Ida Vittrup Nielsen', contactVia: '+45 12 34 56 78' }],
    }),
    HestiaNgxMitIdModule.forRoot({
      loginUrl: `${environment.apiUrl}/mitid-auth/login/`,
      logoutUrl: `${environment.apiUrl}/mitid-auth/login/`,
      callbackRoute: 'mitid-callback',
    }),
    TranslocoRootModule,
    MarkdownModule.forRoot(),
    NgxFhirQnrFormsModule.forRoot({
      config: {
        enableWhenFunctions: {},
        enableDebugMode: false,
        rootPath: '/',
        globalMarkdown: true,
        qnrPageRoutePath: 'quest',
        serverSaveStrategy: QnrSessionServerSaveStrategy.OnFinalPageSubmit,
        enableValueHistoryExt: true,
        enablePageNumber: true,
      },
      translations: defaultTranslations,
      userSettingsInitalState: {
        enableVirtualKeyboard: false,
      },
    }),
    StoreModule.forRoot(),
    StoreDevtoolsModule.instrument(),
    HestiaNgxUserModule.forRoot({
      syncHydratedKey: environment.syncHydratedKey,
      isAuthenticatedGuardFailureRoute: '/',
      isUnauthenticatedGuardFailureRoute: '/home',
    }),
    HestiaNgxFhirModule.forRoot(),
    HestiaNgrxLocalStorageSyncModule.forRoot({
      storageKey: environment.syncStorageKey,
      syncProvider: 'local-forage',
      syncSettings: {
        keys: [userFeatureKey, qnrModuleSettingsFeatureKey, qnrFormSessionFeatureKey, fhirResourcesFeatureKey],
        ignoreActions: [],
        hydratedStateKey: environment.syncHydratedKey,
      },
    }),
    EffectsModule.forRoot(),
  ],
  providers: [...allGuards, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppShellComponent],
})
export class AppModule {}
