import { WidgetTypes } from '../../typings';
import { LegacyQnrItemExtBase, IQnrItemExtParsedExt } from '../extension-base';

export class QuestionnaireItemSuggestedValue extends LegacyQnrItemExtBase {
  /*
   *  {
   *   "url": "http://zitelab.eu/fhir/extensions/QuestionnaireItemSuggestedValue",
   *   "extension": [
   *     {
   *       "url": "valueType",
   *       "valueString": "variable"
   *     },
   *     {
   *       "url": "isDefaultValue",
   *       "valueBoolean": "true"
   *     },
   *     {
   *       "url": "buttonText",
   *       "valueString": "Use yesterday's values"
   *     },
   *     {
   *       "url": "alertMessage",
   *       "valueMarkdown": "Do you want to insert the values from yesterday?\n<br/><br/>\nAny existing values you have entered will be overwritten."
   *     }
   *   ],
   *   "valueString": "yesterdaysDrugs"
   * },
   */
  extensionUrl =
    'http://zitelab.eu/fhir/extensions/QuestionnaireItemSuggestedValue';
  supportedWidgets = [WidgetTypes.All];
  constructor(config: Record<string, unknown> = {}) {
    super(config);
  }

  parse = (): IQnrItemExtParsedExt => {
    let value: any = null;
    const valueInput: string = this.extensionJson.valueString;
    let valueType = 'value';
    let isDefaultValue = false;
    let buttonText = 'IFQ.SUGGESTED_VALUE_EXT.DEFAULT_BTN_TEXT';
    let alertMessage = 'IFQ.SUGGESTED_VALUE_EXT.DEFAULT_ALERT_MESSAGE';
    if (this.extensionJson?.extension) {
      this.extensionJson.extension.forEach((extension) => {
        if (extension.url === 'valueType') {
          valueType = extension.valueString;
        }
        if (extension.url === 'isDefaultValue') {
          isDefaultValue = extension.valueBoolean;
        }
        if (extension.url === 'buttonText') {
          buttonText = extension.valueString;
        }
        if (extension.url === 'alertMessage') {
          alertMessage = extension.valueMarkdown;
        }
      });
    }
    if (valueType === 'value') {
      value = valueInput;
    }
    return {
      widgetProperties: {
        suggestedValueConfig: {
          value,
          valueInput,
          valueType,
          isDefaultValue,
          buttonText,
          alertMessage,
        },
      },
    };
  };
}

export function getSuggestedVariableExt(item: fhir4.QuestionnaireItem) {
  let extensionObj: QuestionnaireItemSuggestedValue;
  if (item?.extension) {
    // the FHIR resource is already validated at this point
    item.extension.forEach((extension) => {
      if (
        extension.url ===
        'http://zitelab.eu/fhir/extensions/QuestionnaireItemSuggestedValue'
      ) {
        extensionObj = new QuestionnaireItemSuggestedValue({
          extensionJson: extension,
        });
      }
    });
  }
  return extensionObj !== undefined
    ? extensionObj.parse().widgetProperties
    : {};
}
