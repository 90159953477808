import { Component } from '@angular/core';

import { IonicModule, ModalController, NavParams } from '@ionic/angular';

@Component({
  standalone: true,
  selector: 'video-modal',
  imports: [IonicModule],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>Video</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="modalController.dismiss()">
            <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <video [src]="this.navParams.get('videoUrl')" controls></video>
    </ion-content>
  `,
  styles: [
    `
      video {
        height: 100%;
        width: 100%;
      }
    `,
  ],
})
export class Video {
  constructor(public modalController: ModalController, public navParams: NavParams) {}
}
