import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';

import { AlertController, NavController } from '@ionic/angular';

import { TranslocoService } from '@ngneat/transloco';

import { Observable, firstValueFrom } from 'rxjs';

import { HestiaUserFacade } from '../../lib/user.facade';

@Injectable({
  providedIn: 'root',
})
export class IsHestiaUserTypeGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  constructor(
    private userFacade: HestiaUserFacade,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private transloco: TranslocoService
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
    const nextUrl: string = next.url.toString();
    const desiredUserType = next.data?.userType ?? null;
    if (!desiredUserType) {
      console.warn(
        `IsHestiaUserTypeGuard is enabled for ${nextUrl}, but userType property has not been provided in data property of the route specification`
      );
      return true;
    }
    const isUserType = await firstValueFrom(this.userFacade.isUserType$(desiredUserType));
    if (!isUserType) {
      this.navCtrl.navigateRoot('/');
      const alert = await this.alertCtrl.create({
        header: this.transloco.translate('isHestiaUserTypeGuard.alert.header'),
        message: this.transloco.translate('isHestiaUserTypeGuard.alert.message'),
      });
      alert.present();
    }
    return isUserType;
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }
  canDeactivate(
    _component: unknown,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    _nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(_route: Route, _segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
