import { NgxCaptureModule } from 'ngx-capture';

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HestiaCommonModule } from '@hestia/ngx-common';
import { loadModuleTranslations } from '@hestia/ngx-i18n';

import { HestiaAuthInterceptor } from '../lib/interceptors/auth.interceptor';
import { UserEffects } from './+state/user.effects';
import * as fromUser from './+state/user.reducer';
import { HESTIA_USER_MODULE_CONFIG, IHestiaUserModuleConfig, moduleI18nScope } from './constants';
import daDK from './i18n/da-DK.json';
import enGB from './i18n/en-GB.json';
import { HestiaErrorInterceptor } from './interceptors/error.interceptor';
import { HestiaUserFacade } from './user.facade';

@NgModule({
  imports: [
    CommonModule,
    HestiaCommonModule.forChild(),
    StoreModule.forFeature(fromUser.userFeatureKey, fromUser.userReducer),
    EffectsModule.forFeature([UserEffects]),
    TranslocoModule,
    NgxCaptureModule,
  ],
  providers: [],
  exports: [],
  declarations: [],
})
export class HestiaNgxUserModule {
  constructor(private transloco: TranslocoService) {
    loadModuleTranslations(this.transloco, moduleI18nScope, {
      'da-DK': daDK,
      'en-GB': enGB,
    });
  }
  static forRoot(hestiaUserModuleConfig: IHestiaUserModuleConfig): ModuleWithProviders<HestiaNgxUserModule> {
    return {
      ngModule: HestiaNgxUserModule,
      providers: [
        {
          provide: HESTIA_USER_MODULE_CONFIG,
          useValue: hestiaUserModuleConfig,
        },
        HestiaUserFacade,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HestiaAuthInterceptor,
          multi: true,
        },
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: HestiaErrorInterceptor,
        //   multi: true,
        //   deps: [HttpClient, NgxCaptureModule],
        // },
      ],
    };
  }
  static forChild(): ModuleWithProviders<HestiaNgxUserModule> {
    /**
     * We want a singleton instance of LayoutService,
     * therefore we do not provide it when forChild is called
     * (which is done by lazy loaded modules)
     * See https://stackoverflow.com/a/49878339
     */
    return {
      ngModule: HestiaNgxUserModule,
      providers: [],
    };
  }
}
