import { createAction, props } from '@ngrx/store';

import { HestiaAuthError, HestiaAuthErrorEnum, HestiaAuthMethod, HestiaUserType } from '@hestia/ngx-types';

import { IHestiaUserState } from './user.reducer';

export const login = createAction(
  '[User] Login',
  props<{ login: string; password: string; userType?: HestiaUserType }>()
);

export const loginSuccess = createAction('[User] Login Success', props<{ jwt: string; clinicalSuite?: boolean }>());

export const loginFailure = createAction(
  '[User] Login Failure',
  props<{
    errorType?: HestiaAuthErrorEnum;
    loginMethod?: HestiaAuthMethod;
    errorI18n?: string;
    error?: HestiaAuthError;
  }>()
);

/** REMOVING SOON */

export const otplogin = createAction('[User] One Time Password', props<{ username: string }>());

export const otpverify = createAction(
  '[User] One Time Password Verification',
  props<{ otpcode: string; auth_token: string }>()
);

export const otpPublished = createAction(
  '[User] One Time Password Publish',
  props<{ auth_token: string; expires: string }>()
);

////////

export const userProfileLoad = createAction('[User] Load Profile');

export const userProfileLoadSuccess = createAction('[User] Load Profile Success', props<Partial<IHestiaUserState>>());

export const userProfileLoadFailure = createAction('[User] Load Profile Failure', props<{ error: any }>());

export const userInitialized = createAction('[User] Initialized');

export const userInitializedSuccess = createAction('[User] Initialized Success');

export const userInitializedFailure = createAction('[User] Initialized Failure');

export const updateUser = createAction('[User] Update Profile', props<Partial<IHestiaUserState>>());

export const initKioskSession = createAction('[User] Init Kiosk Session', props<{ social_security_number: string }>());

export const initKioskSessionSuccess = createAction(
  '[User] Init Kiosk Session Success',
  props<Partial<IHestiaUserState>>()
);

export const initKioskSessionFailure = createAction('[User] Init Kiosk Session Failure', props<{ error: any }>());

export const carePlanCompleted = createAction('[User] Care Plan Completed');

export const logout = createAction('[User] Logout');

export const logoutFixed = createAction('[User] Logout Fixed');

export const logoutSuccess = createAction('[User] Logout Success');

export const logoutFailure = createAction(
  '[User] Logout Failure',
  props<{
    errorType: HestiaAuthErrorEnum;
    loginMethod: HestiaAuthMethod;
    errorI18n: string;
    error?: HestiaAuthError;
  }>()
);

export const resetState = createAction('[User] Reset State');

export const fullResetState = createAction('[User] Full Reset State');

export const userActions = {
  login,
  loginSuccess,
  loginFailure,
  otplogin,
  otpPublished,
  otpverify,
  userProfileLoad,
  userProfileLoadSuccess,
  userProfileLoadFailure,
  userInitialized,
  userInitializedSuccess,
  userInitializedFailure,
  updateUser,
  initKioskSession,
  initKioskSessionSuccess,
  initKioskSessionFailure,
  carePlanCompleted,
  logout,
  logoutFixed,
  logoutSuccess,
  logoutFailure,
  resetState,
  fullResetState,
};
