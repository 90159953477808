<hestia-widget-group-header
  [config]="config"
  [i18n]="i18n"
></hestia-widget-group-header>
<ion-item
  [formGroup]="form"
  lines="none"
  (keydown.enter)="$event.preventDefault()"
>
  <ion-label position="stacked" [attr.for]="config.label">
    <hestia-widget-header
      [config]="config"
      [i18n]="i18n"
    ></hestia-widget-header>
  </ion-label>
  <ion-toggle
    item-start
    [disabled]="config.disabled"
    [attr.value]="config.value || config.defaultValue"
    [formControlName]="config.name"
    [id]="config.name"
  ></ion-toggle>
</ion-item>
