import { WidgetConfig } from '../../../models/widget.config';

export class SelectionWidgetConfig extends WidgetConfig<string> {
  widgetType = 'SelectionWidget';
  multiple = false;
  fhirValueSet: string;
  constructor(config: Partial<WidgetConfig<any>>) {
    super(config);
    this.pageNumber = config['pageNumber'];
    this.fhirValueSet = config['fhirValueSet'] || null;
    this.multiple = config['multiple'] || false;
  }
}