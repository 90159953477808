import { NgxSliderModule } from '@m0t0r/ngx-slider';
import { MarkdownModule } from 'ngx-markdown';

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TranslocoModule } from '@ngneat/transloco';

import { LetModule, PushModule } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HestiaCommonModule } from '@hestia/ngx-common';

import { QuestionnaireComponent } from './components/questionnaire.component/questionnaire.component';
import { WidgetGroupHeaderComponent } from './components/widget-elements/widget-group-header.component/widget-group-header.component';
import { WidgetHeaderComponent } from './components/widget-elements/widget-header.component/widget-header.component';
import { BooleanWidgetComponent } from './components/widgets/boolean-widget.component/boolean-widget.component';
import { CheckboxWidgetComponent } from './components/widgets/checkbox-widget.component/checkbox-widget.component';
import { DateTimeWidgetComponent } from './components/widgets/datetime-widget.component/datetime-widget.component';
import { GridGroupWidgetComponent } from './components/widgets/grid-group-widget.component/grid-group-widget.component';
import { ImageSelectionWidgetComponent } from './components/widgets/image-selection-widget.component/image-selection-widget.component';
import { LabelWidgetComponent } from './components/widgets/label-widget.component/label-widget.component';
import { LikertRadioGroupWidgetWithImageComponent } from './components/widgets/likert-radio-group-widget-with-image.component/likert-radio-group-widget-with-image.component';
import { LikertRadioGroupWidgetComponent } from './components/widgets/likert-radio-group-widget.component/likert-radio-group-widget.component';
import { NumberWidgetComponent } from './components/widgets/number-widget.component/number-widget.component';
import { RadioGroupWidgetComponent } from './components/widgets/radio-group-widget.component/radio-group-widget.component';
import { RepeatableGroupWidgetComponent } from './components/widgets/repeatable-group-widget.component/repeatable-group-widget.component';
import { SelectionWidgetComponent } from './components/widgets/selection-widget.component/selection-widget.component';
import { SignWidgetComponent } from './components/widgets/sign-widget.component/sign-widget.component';
import { SigningComponent } from './components/widgets/sign-widget.component/signing.component/signing';
import { SliderWidgetComponent } from './components/widgets/slider-widget.component/slider-widget.component';
import { StringWidgetComponent } from './components/widgets/string-widget.component/string-widget.component';
import { SvgGroupWidgetComponent } from './components/widgets/svg-group-widget.component/svg-group-widget.component';
import { SvgSliderWidgetComponent } from './components/widgets/svg-slider-widget.component/svg-slider-widget.component';
import { TextAreaWidgetComponent } from './components/widgets/textarea-widget.component/textarea-widget.component';
import {
  QNR_CORE_MODULE_CONFIG,
  QNR_CORE_MODULE_TRANSLATIONS,
  QNR_USER_CONTROLLED_SETTINGS_INITIAL_STATE,
  QnrFormModuleSettings,
} from './constants';
import { VirtualKeyboardDirective } from './directives/virtual-keyboard.directive';
import { WidgetRenderDirective } from './directives/widget.directive';
import { defaultTranslations } from './i18n';
import { VarInterpolationPipe } from './models/fhir-extensions/questionnaire-item/item-var-interpolation.fhir-ext';
import { QnrFormPageComponent } from './pages/qnr-form.page/qnr-form.page';
import { Events } from './services/events.service';
import { HestiaQnrFormSessionService } from './services/qnr-form-session.service';
import { QuestionnaireResponseService } from './services/questionnaire-response.service';
import * as fromModuleSettings from './store/module-settings/module-settings.reducer';
import { QnrFormSessionEffects } from './store/qnr-form-session/qnr-form-session.effects';
import * as fromFormSession from './store/qnr-form-session/qnr-form-session.reducers';

const widgetElems = [WidgetHeaderComponent, WidgetGroupHeaderComponent];

const widgets = [
  BooleanWidgetComponent,
  CheckboxWidgetComponent,
  ImageSelectionWidgetComponent,
  LabelWidgetComponent,
  NumberWidgetComponent,
  RadioGroupWidgetComponent,
  LikertRadioGroupWidgetWithImageComponent,
  LikertRadioGroupWidgetComponent,
  RepeatableGroupWidgetComponent,
  SelectionWidgetComponent,
  SigningComponent,
  SignWidgetComponent,
  StringWidgetComponent,
  SliderWidgetComponent,
  SvgGroupWidgetComponent,
  DateTimeWidgetComponent,
  TextAreaWidgetComponent,
  GridGroupWidgetComponent,
  SvgSliderWidgetComponent,
];

@NgModule({
  imports: [
    CommonModule,
    HestiaCommonModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    MarkdownModule.forChild(),
    NgxSliderModule,
    TranslocoModule,
    LetModule,
    PushModule,
    StoreModule.forFeature(
      fromModuleSettings.qnrModuleSettingsFeatureKey,
      fromModuleSettings.settingsReducer,
      QNR_USER_CONTROLLED_SETTINGS_INITIAL_STATE
    ),
    StoreModule.forFeature(fromFormSession.qnrFormSessionFeatureKey, fromFormSession.qnrFormSessionsReducer, {
      initialState: fromFormSession.qnrFormSessionsInitialState,
    }),
    EffectsModule.forFeature([QnrFormSessionEffects]),
  ],
  declarations: [
    QuestionnaireComponent,
    QnrFormPageComponent,
    VarInterpolationPipe,
    WidgetRenderDirective,
    VirtualKeyboardDirective,
    ...widgets,
    ...widgetElems,
  ],
  exports: [VirtualKeyboardDirective, QuestionnaireComponent],
  providers: [HestiaQnrFormSessionService, QuestionnaireResponseService, Events],
})
export class NgxFhirQnrFormsModule {
  static forRoot(config: QnrFormModuleSettings): ModuleWithProviders<NgxFhirQnrFormsModule> {
    return {
      ngModule: NgxFhirQnrFormsModule,
      providers: [
        HestiaQnrFormSessionService,
        QuestionnaireResponseService,
        Events,
        {
          provide: QNR_USER_CONTROLLED_SETTINGS_INITIAL_STATE,
          useValue: { initialState: config.userSettingsInitalState },
        },
        {
          provide: QNR_CORE_MODULE_TRANSLATIONS,
          useValue: config?.translations ?? defaultTranslations,
        },
        {
          provide: QNR_CORE_MODULE_CONFIG,
          useValue: config.config,
        },
      ],
    };
  }
}
