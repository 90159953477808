<ng-container [formGroup]="form">
  <div [formGroupName]="config.name">
    <ion-row>
      <hestia-widget-header
        [config]="config"
        [i18n]="i18n"
      ></hestia-widget-header>
    </ion-row>
    <ion-row justify-content-center align-items-center>
      <ion-col col-auto>
        <div
          (click)="handleClick($event)"
          #svgContainer
          class="flex justify-center flex-auto"
        ></div>
      </ion-col>
    </ion-row>
    <ion-row justify-content-center *ngIf="valueCount.length > 0">
      <ion-col text-center>
        <span style="color: #a9a9a9">
          {{ 'IFQ.SVG_GROUP_WIDGET.LEGEND.HEADER' | transloco }}
        </span>
      </ion-col>
    </ion-row>
    <ion-row justify-content-center *ngIf="valueCount.length > 0">
      <ion-col text-center>
        <ion-chip *ngFor="let option of valueCount" style="margin-right: 5px">
          <ion-icon
            [color]="option.color"
            [ngStyle]="{
              'background-color': option.color,
              color: option.color === '#F8F8F8' ? 'black' : '#ffffff'
            }"
          >
            {{ option.count }}
          </ion-icon>
          <ion-label>
            {{ option.i18n | transloco }}
          </ion-label>
        </ion-chip>
      </ion-col>
    </ion-row>
    <ion-row style="padding-left: 0px; padding-right: 0px">
      <ion-col [size]="6" [sizeLg]="3" [offsetLg]="6">
        <ion-button
          expand="block"
          fill="outline"
          type="submit"
          class="questionnaire-next-or-submit-btn"
          (click)="markAllUnansweredPrompt($event)"
          color="secondary"
        >
          <ion-icon name="color-wand"></ion-icon>
          {{ 'IFQ.SVG_GROUP_WIDGET.MARK_UNANSWERED_BTN.LABEL' | transloco }}
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ng-container>
