import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { firstValueFrom } from 'rxjs';

import { Store } from '@ngrx/store';

import { HestiaCommonService } from '@hestia/ngx-common';
import { HestiaAuthErrorEnum } from '@hestia/ngx-types';
import { HestiaUserFacade, userActions } from '@hestia/ngx-user';

import {
  IMitIdModuleConfig,
  MidIdOperationType,
  MitIdOperationStatus,
  MITID_MODULE_CONFIG,
  MITID_MODULE_I18N_SCOPE,
} from '../constants';

@Injectable({ providedIn: 'root' })
export class HestiaMitIdService {
  constructor(
    @Inject(MITID_MODULE_CONFIG) public mitIdModuleConfig: IMitIdModuleConfig,
    public activatedRoute: ActivatedRoute,
    private userFacade: HestiaUserFacade,
    private commonService: HestiaCommonService,
    private store: Store
  ) {}

  initMitIdLogin(): void {
    const url = `${this.mitIdModuleConfig.loginUrl}?linkingUri=${window.location.origin}/${this.mitIdModuleConfig.callbackRoute}`;
    window.open(url, '_self');
  }

  async initMitIdLogout(): Promise<void> {
    const jwt = await firstValueFrom(this.userFacade.jwt$);
    const url = `${this.mitIdModuleConfig.logoutUrl}?token=${jwt}&linkingUri=${window.location.origin}/${this.mitIdModuleConfig.callbackRoute}`;
    window.open(url, '_self');
  }

  async handleCallback(): Promise<void> {
    const queryParams: Params = this.activatedRoute.snapshot.queryParams;
    const token = queryParams?.token;
    const operation: MidIdOperationType = queryParams?.operation;
    const status: MitIdOperationStatus = queryParams?.status;
    const statusCode: '200' | string = queryParams?.status_code;
    const statusReason: string = queryParams?.status_reason;
    switch (operation) {
      case 'login':
        if (token) {
          // If token is found, operation was login and successfull
          this.userFacade.setJwt(token);
          break;
        }
        this.handleError({ operation, status, statusCode, statusReason });
        break;
      case 'slo':
        if (status === 'success') {
          this.store.dispatch(userActions.logoutSuccess());
          break;
        }
        this.handleError({ operation, status, statusCode, statusReason });
        break;
      default:
        console.error('Got unknown job type for MitID login:', operation);
    }
    await this.commonService.dismissLoader();
  }

  handleError(params: {
    operation: MidIdOperationType;
    status: MitIdOperationStatus;
    statusCode: '200' | string;
    statusReason: string;
  }) {
    const errorMapping: { [key: string]: HestiaAuthErrorEnum } = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      USER_NOT_FOUND: HestiaAuthErrorEnum.MitIdUserNotFound,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      BAD_REQUEST: HestiaAuthErrorEnum.MitIdBadRequest,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      UNHANDLED: HestiaAuthErrorEnum.Unknown,
    };
    const errorType: HestiaAuthErrorEnum =
      errorMapping[params.statusReason] ?? HestiaAuthErrorEnum.Unknown;
    const errorI18n = `${MITID_MODULE_I18N_SCOPE}.error.${errorType}`;
    switch (params.operation) {
      case 'login':
        this.store.dispatch(
          userActions.loginFailure({
            errorType,
            loginMethod: 'MITID',
            errorI18n,
          })
        );
        break;
      case 'slo':
        this.store.dispatch(
          userActions.logoutFailure({
            errorType,
            loginMethod: 'MITID',
            errorI18n,
          })
        );
        break;
      default:
        console.error(
          'Got unknown job type for MitID login:',
          params.operation
        );
    }
  }
}
