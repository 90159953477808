import { WidgetTypes } from '../../typings';
import { LegacyQnrItemExtBase, IQnrItemExtParsedExt } from '../extension-base';

export class QuestionnaireItemSetLocalVariable extends LegacyQnrItemExtBase {
  /*
   * {
   *   "url": "http://zitelab.eu/fhir/extensions/QuestionnaireItemSetLocalVariable",
   *   "extension": [
   *     {
   *       "url": "isGlobal",
   *       "valueBoolean": true
   *     }
   *   ],
   *   "valueString": "familyWordForVomiting"
   * }
   */
  extensionUrl =
    'http://zitelab.eu/fhir/extensions/QuestionnaireItemSetLocalVariable';
  supportedWidgets = [WidgetTypes.All];
  constructor(config: Record<string, unknown> = {}) {
    super(config);
  }

  parse = (): IQnrItemExtParsedExt => {
    const varName: string = this.extensionJson.valueString;
    let isGlobal = false;
    if (this.extensionJson?.extension) {
      this.extensionJson.extension.forEach((extension) => {
        if (extension.url === 'isGlobal') {
          isGlobal = extension.valueBoolean;
        }
      });
    }
    return {
      widgetProperties: {
        setLocalVariable: {
          varName,
          isGlobal,
        },
      },
    };
  };
}

export function getSetVariableExt(item: fhir4.QuestionnaireItem) {
  let extensionObj: QuestionnaireItemSetLocalVariable;
  if (item?.extension) {
    // the FHIR resource is already validated at this point
    item.extension.forEach((extension) => {
      if (
        extension.url ===
        'http://zitelab.eu/fhir/extensions/QuestionnaireItemSetLocalVariable'
      ) {
        extensionObj = new QuestionnaireItemSetLocalVariable({
          extensionJson: extension,
        });
      }
    });
  }
  return extensionObj !== undefined
    ? extensionObj.parse().widgetProperties
    : {};
}
