import { differenceInYears } from 'date-fns';

import { _i18n } from '@hestia/ngx-i18n';
import { Sex } from '../types';

export class CPR {
  value: string;
  constructor(value: string) {
    this.value = value;
  }

  get validityStatus(): { valid: boolean; error?: string; errorI18n?: string } {
    if (typeof this.value !== 'string') {
      return { valid: false, error: 'Invalid CPR: must be of type string' };
    }
    if (this.value.length !== 10) {
      return {
        valid: false,
        error: 'Invalid CPR: must consist of 10 digits',
        errorI18n: _i18n('cpr.validation.error.mustBe10Digits'),
      };
    }
    const dateSegments = this.value.substring(0, 6).match(/.{1,2}/g);
    if (parseInt(dateSegments[0], 10) > 31) {
      return {
        valid: false,
        error: 'Invalid CPR: DD in DDMMYYXXXX cannot be greater than 31',
        errorI18n: _i18n(
          'cpr.validation.error.dayInMonthCannotBeGreaterThan31'
        ),
      };
    }
    if (parseInt(dateSegments[1], 10) === 0) {
      return {
        valid: false,
        error: 'Invalid CPR: MM in DDMMYYXXXX cannot be 00',
        errorI18n: _i18n('cpr.validation.error.monthCannotBe00'),
      };
    }
    if (parseInt(dateSegments[1], 10) > 12) {
      return {
        valid: false,
        error: 'Invalid CPR: MM in DDMMYYXXXX cannot be greater than 12',
        errorI18n: _i18n('cpr.validation.error.monthCannotBeGreaterThan12'),
      };
    }
    return { valid: true };
  }

  get isValid(): boolean {
    return this.validityStatus.valid;
  }

  get errorMessage(): string {
    return this.validityStatus.error || null;
  }

  get birthdate(): Date {
    const isValid = this.validityStatus;
    if (isValid.valid !== true) {
      return null;
    }
    const digit7 = parseInt(this.value[6], 10);
    const dateSegments = this.value.substring(0, 6).match(/.{1,2}/g);
    let date = new Date(
      Date.UTC(
        parseInt(dateSegments[2], 10),
        parseInt(dateSegments[1], 10) - 1,
        parseInt(dateSegments[0], 10),
        0,
        0,
        0,
        0
      )
    );
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    // century correction
    if (digit7 === 4 || digit7 === 9) {
      // 1900 or 2000
      if (year <= 1936) {
        date = new Date(Date.UTC(year + 100, month, day, 0, 0, 0, 0));
      }
    } else if (digit7 > 4) {
      // 1800 or 2000
      date = new Date(
        Date.UTC(year > 1957 ? year - 100 : year + 100, month, day, 0, 0, 0, 0)
      );
    }
    return date;
  }

  get age(): number {
    const birthdate = this.birthdate;
    if (birthdate === null) {
      return null;
    }
    return differenceInYears(new Date(), this.birthdate);
  }

  get sex(): Sex {
    const isValid = this.validityStatus;
    if (isValid.valid !== true) {
      return null;
    }
    return parseInt(this.value, 10) % 2 ? 'MALE' : 'FEMALE';
  }

  get prettyPrint(): string {
    if (this.value === undefined) {
      return this.value;
    }
    if (this.value.length < 10) {
      // if value is less than 10 characters, return it unchanged
      return this.value;
    }
    return `${this.value.substring(0, 6)}-${this.value.substring(6)}`;
  }
}
