import { Component, Inject, Input } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import { QNR_CORE_MODULE_CONFIG } from '../../../constants';
import { IQnrFormSessionModuleConfig } from '../../../models/typings';
import { WidgetConfig } from '../../../models/widget.config';

@Component({
  selector: 'hestia-widget-header',
  templateUrl: './widget-header.component.html',
  styleUrls: ['./widget-header.component.scss'],
})
export class WidgetHeaderComponent {
  @Input() config: WidgetConfig<unknown>;
  @Input() i18n: (args: {
    translate: string;
    fallback?: string;
    type?: 'configParam' | 'i18nName';
    config?: WidgetConfig<unknown>; // Allows us to pass the config of a childWidget
  }) => string;
  get labelAsMarkdown(): boolean {
    return (
      this.config.renderLabelAsMarkdown || this.coreModuleConfig.globalMarkdown
    );
  }
  get descAsMarkdown(): boolean {
    return (
      this.config.renderDescriptionAsMarkdown ||
      this.coreModuleConfig.globalMarkdown
    );
  }
  constructor(
    public transloco: TranslocoService,
    @Inject(QNR_CORE_MODULE_CONFIG)
    public coreModuleConfig: IQnrFormSessionModuleConfig
  ) {}
}
