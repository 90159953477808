import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { QnrFormPageComponent } from './pages/qnr-form.page/qnr-form.page';

const routes: Routes = [
  {
    path: ':sessionId',
    component: QnrFormPageComponent,
  },
  {
    path: ':sessionId/:linkIdPath',
    component: QnrFormPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgxFhirQnrFormsRoutingModule {}
