import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  HestiaCarePlan,
  HestiaFhirResource,
  HestiaPatient,
  IHestiaCarePlan,
  IHestiaCarePlanActivity,
} from '@hestia/ngx-types';

import { extractCodeFromCodeableConcept } from '../utils/extract-code-from-codeableconcept';
import { splitRelativeReference } from '../utils/split-relative-reference';
import {
  IFhirResourcesState,
  fhirResourcesFeatureKey,
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
} from './fhir-resource.reducer';

export const selectFhirResourcesState = createFeatureSelector<IFhirResourcesState>(fhirResourcesFeatureKey);

export const selectFhirResourceIds = selectIds;
export const selectFhirResourceEntities = selectEntities;
export const selectFhirResources = selectAll;
export const selectFhirResourceTotal = selectTotal;
export const selectFhirResourceById = (id) =>
  createSelector(selectFhirResourceEntities, (resources) => resources[id] ?? null);

/** Selector for getting all questionnaires in NGRX state */
export const selectQuestionnaires = createSelector(selectFhirResources, (resources: HestiaFhirResource[]) => {
  resources = resources ?? [];
  return resources.filter((resource) => resource.resourceType === 'Questionnaire') as fhir4.Questionnaire[];
});

/** Selector for getting all questionnaire responses in NGRX state */
export const selectQnrResponses = createSelector(selectFhirResources, (resources: HestiaFhirResource[]) => {
  resources = resources ?? [];
  return resources.filter(
    (resource) => resource.resourceType === 'QuestionnaireResponse'
  ) as fhir4.QuestionnaireResponse[];
});

export const selectCarePlanResources = createSelector(selectFhirResources, (resources: HestiaFhirResource[]) => {
  resources = resources ?? [];
  return resources.filter((resource) => resource.resourceType === 'CarePlan') as fhir4.CarePlan[];
});

export const selectCarePlanById = (id) =>
  createSelector(selectCarePlanResources, (carePlans) => {
    return carePlans.find((carePlan) => carePlan.id === id) ?? null;
  });

export const selectQuestionnaireById = (id) =>
  createSelector(selectQuestionnaires, (questionnaires) => {
    return questionnaires.find((questionnaire) => questionnaire.id === id) ?? null;
  });

export const selectPatientResources = createSelector(selectFhirResources, (resources: HestiaFhirResource[]) => {
  resources = resources ?? [];
  return resources.filter((resource) => resource.resourceType === 'Patient') as fhir4.Patient[];
});

export const selectPatientResourceById = (id) =>
  createSelector(selectPatientResources, (patients) => {
    return patients.find((patients) => patients.id === id) ?? null;
  });

export const selectHestiaPatientById = (id) =>
  createSelector(selectPatientResourceById(id), (patient) => {
    return new HestiaPatient({ fhirResource: patient });
  });

export const selectPractitionerResources = createSelector(selectFhirResources, (resources: HestiaFhirResource[]) => {
  resources = resources ?? [];
  return resources.filter((resource) => resource.resourceType === 'Practitioner') as fhir4.Practitioner[];
});

export const selectPractitionerResourceById = (id) =>
  createSelector(selectPractitionerResources, (practitioners) => {
    return practitioners.find((practitioner) => practitioner.id === id) ?? null;
  });

export const selectHestiaCarePlans = createSelector(
  selectQuestionnaires,
  selectQnrResponses,
  selectCarePlanResources,
  (questionnaires, qnrResponses, carePlans) => {
    return carePlans.map((carePlan) => {
      const activities: IHestiaCarePlanActivity[] = carePlan.activity.map((rawActivity) => {
        const questionnaireCanonical = rawActivity.detail.instantiatesCanonical[0] ?? null;
        const split = questionnaireCanonical.split('/');
        const qnrCanonicalId = split[split.length - 1]; // TODO: Undo this implementation
        const qnrResponseRefererence: fhir4.Reference = rawActivity.outcomeReference?.[0] ?? null;
        const qnrResponseId: string = qnrResponseRefererence?.reference?.split('/')?.[1] ?? null;
        const questionnaire = questionnaires.find((elem) => elem.id === qnrCanonicalId);
        const questionnaireResponse = qnrResponses.find((elem) => elem.id === qnrResponseId) ?? null;
        const code = extractCodeFromCodeableConcept(rawActivity.detail.code);
        const activity: IHestiaCarePlanActivity = {
          carePlanId: carePlan.id,
          fhirElem: rawActivity,
          qnrCanonical: questionnaireCanonical,
          qnrResource: questionnaire,
          qnrResponseResource: questionnaireResponse,
          code,
          status: rawActivity.detail.status,
        };
        return activity;
      });
      const activitiesByCode = new Map();
      activities.forEach((activity) => activitiesByCode.set(activity.code, activity));
      const subjectDetails = splitRelativeReference(carePlan.subject.reference);
      const config: IHestiaCarePlan = {
        subjectType: subjectDetails.resourceType,
        subjectId: subjectDetails.resourceId,
        carePlanResource: carePlan,
        allActivities: activities,
        totalActivityCount: activities.length,
        completedActivityCount: activities.filter((elem) => elem.status === 'completed').length,
        inProgressActivityCount: activities.filter((elem) => elem.status === 'in-progress').length,
        activitiesByCode: activitiesByCode,
      };
      return new HestiaCarePlan(config);
    });
  }
);

export const selectFirstHestiaCarePlan = createSelector(selectHestiaCarePlans, (carePlans) => carePlans[0]);

export const selectHestiaCarePlanById = (id) =>
  createSelector(selectHestiaCarePlans, (carePlans) => {
    return carePlans.find((carePlan) => carePlan.carePlanResource.id === id) ?? null;
  });

export const selectHestiaCarePlansBySubjectId = (id) =>
  createSelector(selectHestiaCarePlans, (carePlans) => {
    return carePlans.filter((carePlan) => carePlan.subjectId === id) ?? [];
  });
