import { v4 } from 'uuid';

import { Injectable } from '@angular/core';

import { map, take, withLatestFrom } from 'rxjs';

import { Store } from '@ngrx/store';

import { selectHestiaCarePlanById } from '@hestia/ngx-fhir';
import { QuestionnaireResponseService, initSession, isQnrAdaptive } from '@hestia/ngx-fhir-qnr-forms';
import { selectUserState } from '@hestia/ngx-user';

@Injectable({ providedIn: 'root' })
export class HestiaPagesService {
  constructor(private store: Store, private qnrResponseService: QuestionnaireResponseService) {}

  startCarePlan(carePlanId: string) {
    this.store
      .select(selectHestiaCarePlanById(carePlanId))
      .pipe(
        take(1),
        withLatestFrom(this.store.select(selectUserState)),
        map(([carePlan, userState]) => {
          if (!carePlan) {
            return;
          }

          let nextActivityCode: string;

          for (const [code, activity] of carePlan.activitiesByCode) {
            if (['in-progress', 'scheduled'].includes(activity.status)) {
              nextActivityCode = code;
              break;
            }
          }

          const nextActivity = carePlan.activitiesByCode.get(nextActivityCode);

          if (nextActivity) {
            const values = this.qnrResponseService.getFormValuesFromQuestionnaireResponse(
              nextActivity.qnrResponseResource
            );

            this.store.dispatch(
              initSession({
                session: {
                  id: nextActivity?.qnrResponseResource?.id ?? v4(),
                  isAdaptive: isQnrAdaptive(nextActivity.qnrResource),
                  questionnaireResponse: nextActivity.qnrResponseResource,
                  doesQnrResponseExistOnServer: nextActivity.qnrResponseResource ? true : false,
                  values: values,
                  displayTitle: nextActivity.qnrResource.title as string,
                  questionnaireId: nextActivity.qnrResource.id as string,
                  patientId: userState.fhirId as string,
                  carePlanId: nextActivity.carePlanId,
                  carePlanStatus: carePlan.carePlanResource.status,
                  activityStatus: nextActivity.status,
                  activityCode: nextActivity.code,
                  renderType: 'page',
                  subjectRef: { reference: userState.fhirId as string },
                  authorRef: { reference: userState.fhirId as string },
                  sourceRef: { reference: userState.fhirId as string },
                  allowMenu: true,
                  curLinkIdPath: '',
                  alertWhenEmptySubmit: true,
                },
              })
            );
          }
        })
      )
      .subscribe();
  }
}
