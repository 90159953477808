import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';

import { Store } from '@ngrx/store';

import { WidgetBaseComponent } from '../../../models/widget';
import { Events } from '../../../services/events.service';
import { HestiaQnrFormSessionService } from '../../../services/qnr-form-session.service';
import { DateTimeWidgetConfig } from './datetime-widget.config';

@Component({
  selector: 'hestia-datetime-widget',
  templateUrl: './datetime-widget.component.html',
  styleUrls: ['./datetime-widget.component.scss'],
})
export class DateTimeWidgetComponent extends WidgetBaseComponent implements OnInit {
  @Input() config: DateTimeWidgetConfig;
  @Input() form: UntypedFormGroup;

  constructor(
    transloco: TranslocoService,
    questionnaireService: HestiaQnrFormSessionService,
    store: Store,
    element: ElementRef,
    events: Events
  ) {
    super(transloco, questionnaireService, store, element, events);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  get isValid() {
    return this.form.controls[this.config.name].valid;
  }

  getMinuteValues() {
    return this.config.name.includes('Fixed') ? '0,5,10,15,20,25,30,35,40,45,50,55' : undefined;
  }
}
