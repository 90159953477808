export type FhirValueTypes =
  | 'valueBoolean'
  | 'valueDecimal'
  | 'valueInteger'
  | 'valueDate'
  | 'valueDateTime'
  | 'valueTime'
  | 'valueString'
  | 'valueUri'
  | 'valueAttachment'
  | 'valueCoding'
  | 'valueQuantity'
  | 'valueReference'
  | 'group'; // group is used when we have a repeating group

export interface ExtractedItemAnswer {
  linkId: string;
  answerType: FhirValueTypes;
  answer: string | number | ExtractedItemAnswer[];
}

export interface OptionObject {
  code: string;
  display: string;
  designation?: { language?: string; value: string }[];
  sourceType: 'ValueSet' | 'answerOption';
  /** source is either full linkId path (if option is provided by a contained answerOption) or canonical URL for ValueSet */
  source: string;
  system: string;
  index: number;
  totalOptions: number;
  i18nName: string;
  extension?: fhir4.Extension[];
  colorInterpretation?: string;
  ordinalValue?: number;
  isExclusive?: boolean;
  imageUrl?: string;
  imageHideText?: boolean;
}

export interface AnswerInfo {
  rawAnswer: unknown;
  optionObj: OptionObject | OptionObject[];
  colorCode: string | string[];
  isEnabled: boolean;
}

export interface IAdditionalQuestionnaireSettings {
  enableWhenFunctions: {
    [key: string]: (values: { [key: string]: unknown }) => boolean;
  };
}

export const VIRTUAL_KEYBOARD_SETTINGS: {
  [key: string]: {
    alphanumerical: {
      default: string[];
      shift?: any;
      [key: string]: string[];
    };
    numerical: {
      default: string[];
      shift?: any;
      [key: string]: string[];
    };
    display: { [button: string]: string };
  };
} = {
  'da-DK': {
    alphanumerical: {
      default: [
        '` 1 2 3 4 5 6 7 8 9 0 + ´ {bksp}',
        '{tab} q w e r t y u i o p å ¨ {close}',
        "{lock} a s d f g h j k l æ ø ; ' {enter}",
        '{shift} < z x c v b n m , . - {shift}',
        '.com @ {space}',
      ],
      shift: [
        '§ ! @ # € % / ( ) = ? ` {bksp}',
        '{tab} Q W E R T Y U I O P Å ^ {close}',
        '{lock} A S D F G H J K L Æ Ø * {enter}',
        '{shift} > Z X C V B N M ; : _ {shift}',
        '.com @ {space}',
      ],
    },
    numerical: {
      default: ['1 2 3', '4 5 6', '7 8 9', '{close} 0 {bksp}'],
      shift: ['1 2 3', '4 5 6', '7 8 9', '{close} 0 {bksp}'],
    },
    display: {
      '{close}': 'skjul tastatur',
      '{enter}': 'enter',
      '{clear}': 'CE',
    },
  },
  'en-GB': {
    alphanumerical: {
      default: [
        '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        '{tab} q w e r t y u i o p [ ]  {close}',
        "{lock} a s d f g h j k l ; ' {< enter}",
        '{shift} z x c v b n m , . / {shift}',
        '.com @ {space}',
      ],
      shift: [
        ' ~ ! @ # $ % ^ & * ( ) _ + {bksp}',
        '{tab} Q W E R T Y U I O P { } | {close}',
        "{lock} A S D F G H J K L : ' {enter}",
        '{shift} > Z X C V B N M < > ? {shift}',
        '.com @ {space}',
      ],
    },
    numerical: {
      default: ['1 2 3', '4 5 6', '7 8 9', '{close} 0 {bksp}'],
      shift: ['1 2 3', '4 5 6', '7 8 9', '{close} 0 {bksp}'],
    },
    display: {
      '{close}': 'hide keyboard',
      '{enter}': 'enter',
      '{clear}': 'CE',
    },
  },
  'tr-TR': {
    alphanumerical: {
      default: [
        "' 1 2 3 4 5 6 7 8 9 0 * - # {bksp}",
        '{tab} q w e r t y u ı o p ğ ü {close}',
        '{lock} a s d f g h j k l ş i , {enter}',
        '{shift} < z x c v b n m ö ç . | $ € {shift}',
        '.com @ {space}',
      ],
      shift: [
        "é ! ' ^ + % & / ( ) = ? _ ~ {bksp}",
        '{tab} Q W E R T Y U I O P Ğ Ü {close}',
        '{lock} A S D F G H J K L Ş İ ; {enter}',
        '{shift} > Z X C V B N M Ö Ç : \\ ` ´ {shift}',
        '.com @ {space}',
      ],
    },
    numerical: {
      default: ['1 2 3', '4 5 6', '7 8 9', '{close} 0 {bksp}'],
      shift: ['1 2 3', '4 5 6', '7 8 9', '{close} 0 {bksp}'],
    },
    display: {
      '{close}': 'klavyeyi gizle',
      '{enter}': 'enter',
      '{clear}': 'CE',
    },
  },
  'is-IS': {
    alphanumerical: {
      default: [
        '` 1 2 3 4 5 6 7 8 9 0  - + {bksp}',
        '{tab} q w e r t y u i o p ð ? {close}',
        "{lock} a s d f g h j k l æ ' {enter}",
        '{shift} < z x c v b n m , . Þ {shift}',
        '.com @ {space}',
      ],
      shift: [
        '¨ ! @ # $ % &  ( ) = Ö _ * {bksp}',
        "{tab} Q W E R T Y U I O P Ð ' {close}",
        '{lock} A S D F G H J K L Æ ´ {enter}',
        '{shift} > Z X C V B N M ; : Þ {shift}',
        '.com @ {space}',
      ],
    },
    numerical: {
      default: ['1 2 3', '4 5 6', '7 8 9', '{close} 0 {bksp}'],
      shift: ['1 2 3', '4 5 6', '7 8 9', '{close} 0 {bksp}'],
    },
    display: {
      '{close}': 'fela lyklaborðið',
      '{enter}': 'enter',
      '{clear}': 'CE',
    },
  },
};

export enum WidgetTypes {
  All = 'All',
  BooleanWidget = 'BooleanWidget',
  CheckboxWidget = 'CheckboxWidget',
  ImageSelectionWidget = 'ImageSelectionWidget',
  LabelWidget = 'LabelWidget',
  NumberWidget = 'NumberWidget',
  RadioGroupWidget = 'RadioGroupWidget',
  LikertRadioGroupWidgetWithImage = 'LikertRadioGroupWidgetWithImage',
  LikertRadioGroupWidget = 'LikertRadioGroupWidget',
  SelectionWidget = 'SelectionWidget',
  SignWidget = 'SignWidget',
  StringWidget = 'StringWidget',
  SliderWidget = 'SliderWidget',
  SvgGroupWidget = 'SvgGroupWidget',
  GridGroupWidget = 'GridGroupWidget',
  DateTimeWidget = 'DateTimeWidget',
  TextAreaWidget = 'TextAreaWidget',
  SvgSliderWidget = 'SvgSliderWidget',
}

/**
 * How should the QNR form session be saved on the server?
 */
export enum QnrSessionServerSaveStrategy {
  /**
   * Custom strategy: Save to server will be left to implementing app,
   * form session module will not save to server.
   *
   * NOTE: You will have to implement completeSessionSuccess action yourself
   **/
  Custom = 'Custom',
  /**
   * On every page submit strategy: Answers will be pushed to server on every page submit
   **/
  OnEveryPageSubmit = 'OnPageChange',
  /**
   * On final page submit strategy: Answers will be pushed to server on final page submit
   **/
  OnFinalPageSubmit = 'OnFinalPageSubmit',
}

export interface IQnrFormSessionModuleConfig {
  /**
   * Functions which might be called upon to determine whether or not a particular item is enabled or not.
   *
   * Used in conjuction with the QuestionnaireItemEnableWhenFunction extension.
   **/
  enableWhenFunctions: {
    [key: string]: (formAnswers: Record<string, unknown>) => boolean;
  };
  /**
   * enableDebugMode is not currently used for anything, but will be in the near future
   */
  enableDebugMode: boolean;
  /**
   * enablePageNumber is used for hide/show page number
   */
  enablePageNumber?: boolean;
  /**
   * Root route path for app
   */
  rootPath: string;
  /**
   * Route path assigned to QuestionnairePage in app
   */
  qnrPageRoutePath: string;
  /**
   * Enable markdown for all labels and descriptions without requring per-item extension
   */
  globalMarkdown: boolean;
  /**
   * Should we e.g., PATCH a copy of the questionnaire response on the server on each page change
   */
  serverSaveStrategy: QnrSessionServerSaveStrategy;
  /**
   * Adds an extension to the root level of the QuestionnaireResponse that contains the latest answer given to each item,
   * no matter if the item is still relevant or not.
   *
   * This is particularly useful when navigating back/forward in adaptive questionnaires,
   * in which case items might disappear completely from the questionnaire
   */
  enableValueHistoryExt: boolean;
}
