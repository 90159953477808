/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { NavController } from '@ionic/angular';

import { EMPTY, Observable, from, of } from 'rxjs';
import { catchError, concatMap, map, mapTo, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { HestiaFhirResourcesService } from '@hestia/ngx-fhir';
import { CarePlanActivityStatus, HestiaFhirResourceTypeName } from '@hestia/ngx-types';

import { widgetConfigFactory } from '../../components/widgets';
import { QnrSessionServerSaveStrategy } from '../../models/typings';
import { HestiaQnrFormSessionService } from '../../services/qnr-form-session.service';
import { QuestionnaireResponseService } from '../../services/questionnaire-response.service';
import { calculateSessionValues } from '../../utils/calculate-session-values';
import { getTopLevelLinkId } from '../../utils/get-top-level-link-id';
import { previousSessionPageSuccess, qnrFormSessionActions } from './qnr-form-session.actions';

@Injectable({ providedIn: 'root' })
export class QnrFormSessionEffects {
  constructor(
    private actions$: Actions,
    private qnrResponseService: QuestionnaireResponseService,
    private sessionService: HestiaQnrFormSessionService,
    private fhirService: HestiaFhirResourcesService,
    private navCtrl: NavController,
    private router: Router
  ) {}

  /**
   * Effect listening for initiation of questionnaire form session
   * It is responsible for calculating the widgets for the questionnaire
   * Outputs initSessionSuccess action when widgets have been calculated
   *
   * TODO: Add 'error' event action emit of initSessionFailure
   */
  initSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(qnrFormSessionActions.initSession),
      mergeMap((action) =>
        this.fhirService
          .resolveReference$<fhir4.Questionnaire>({
            resourceId: action.session.questionnaireId,
            resourceType: HestiaFhirResourceTypeName.Questionnaire,
          })
          .pipe(
            switchMap((qnr) => {
              let qnrResponse$: Observable<fhir4.QuestionnaireResponse>;
              if (action.session.isAdaptive) {
                if (action.session.questionnaireResponse) {
                  qnrResponse$ = of(action.session.questionnaireResponse);
                } else {
                  qnrResponse$ = from(
                    this.qnrResponseService.createQuestionnaireResponse({
                      sessionId: action.session.id,
                      questionnaireId: action.session.questionnaireId,
                      carePlanId: action.session.carePlanId,
                      latestAnswerDate: new Date().toISOString(),
                      formValues: action.session.values ?? {},
                      subjectRef: action.session.subjectRef,
                      authorRef: action.session.authorRef,
                      sourceRef: action.session.sourceRef,
                      responseExtensions: action.session.responseExtensions ?? [],
                      responseProfiles: action.session.responseProfiles ?? [],
                      isAdaptive: action.session.isAdaptive,
                      isInitAdaptiveAction: true,
                      widgets: [],
                      curLinkIdPath: action.session.curLinkIdPath ?? null,
                    })
                  ).pipe(
                    switchMap((qnrResponse) => {
                      return this.fhirService.findAdaptiveQnrNextItems({
                        qnrResponse,
                      });
                    })
                  );
                }
              } else {
                qnrResponse$ = of(action.session.questionnaireResponse ?? null);
              }
              return qnrResponse$.pipe(
                map((qnrResponse) => {
                  let items: fhir4.QuestionnaireItem[];
                  if (action.session.isAdaptive) {
                    items = this.qnrResponseService.getContainedQnrFromResponse(qnrResponse).item;
                  } else {
                    items = qnr.item;
                  }
                  return { qnrResponse, items };
                }),
                switchMap(({ qnrResponse, items }) => {
                  return from(
                    this.sessionService.getWidgets({
                      sessionId: action.session.id,
                      carePlanId: action.session.carePlanId,
                      questionnaireId: action.session.questionnaireId,
                      values: calculateSessionValues(qnrResponse, action.session.values),
                      items: items,
                      linkIdPaths: [],
                    })
                  ).pipe(map((widgets) => ({ qnrResponse, items, widgets })));
                }),
                map(({ qnrResponse, items: _items, widgets }) => {
                  return qnrFormSessionActions.initSessionSuccess({
                    session: {
                      id: action.session.id,
                      changes: {
                        widgets,
                        questionnaireResponse: qnrResponse,
                        doesQnrResponseExistOnServer: action.session.isAdaptive
                          ? false // Doing the adaptive search for next items doesn't save on server
                          : action.session.doesQnrResponseExistOnServer,
                      },
                    },
                  });
                })
              );
            })
          )
      ),
      catchError((e) => {
        return of(qnrFormSessionActions.initSessionFailure(e));
      })
    );
  });

  submitSessionPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(qnrFormSessionActions.submitSessionPage),
      mergeMap((action) =>
        this.sessionService.sessionWithReferencedResources$(action.session.id).pipe(
          // Check if we should save changes to server if questionnaire is not adaptive
          switchMap((result) => {
            if (
              this.sessionService.moduleConfig.serverSaveStrategy === QnrSessionServerSaveStrategy.OnEveryPageSubmit &&
              !result.session.isAdaptive
            ) {
              /**
               * Note that we don't do patch here when questionnaire is adaptive
               * This is as we want the contained questionnnaire to be updated on server as well,
               * so for adaptive questionnaire we patch AFTER calling @next-question
               */
              return from(
                this.fhirService.submitQnrResponse({
                  qnrResponse: {
                    ...result.session.questionnaireResponse,
                    status: 'in-progress',
                  },
                  doesQnrResponseExistOnServer: result.session.doesQnrResponseExistOnServer,
                  carePlan: result.carePlan,
                  activityCode: result.session.activityCode,
                  activityStatus: 'in-progress',
                })
              ).pipe(
                map((_response) => {
                  return {
                    ...result,
                    session: {
                      ...result.session,
                      doesQnrResponseExistOnServer: true,
                    },
                  };
                })
              );
            } else {
              return of(result);
            }
          }),
          // Then recalculate widgets based on server-response if questionnnaire IS adaptive
          switchMap((result) => {
            if (result.session.isAdaptive) {
              return this.fhirService
                .findAdaptiveQnrNextItems({
                  qnrResponse: result.session.questionnaireResponse,
                })
                .pipe(
                  // TODO: Fix this so @next-question saves response resource on server
                  switchMap((qnrResponse) => {
                    const props = {
                      qnrResponse,
                      doesQnrResponseExistOnServer: result.session.doesQnrResponseExistOnServer,
                      carePlan: result.carePlan,
                      activityCode: result.session.activityCode,
                      activityStatus: 'in-progress' as CarePlanActivityStatus,
                      options: { forceServerReloadForPatch: true },
                    };
                    return from(this.fhirService.submitQnrResponse(props)).pipe(map(() => qnrResponse));
                  })
                )
                .pipe(
                  switchMap((qnrResponse) => {
                    const containedQnr = qnrResponse.contained[0] as fhir4.Questionnaire;
                    return from(
                      this.sessionService.getWidgets({
                        sessionId: result.session.id,
                        carePlanId: result.session.carePlanId,
                        questionnaireId: result.session.questionnaireId,
                        values: calculateSessionValues(qnrResponse, result.session.values),
                        items: containedQnr.item,
                        linkIdPaths: [],
                      })
                    ).pipe(map((widgets) => ({ widgets, qnrResponse })));
                  }),
                  map(({ widgets, qnrResponse }) => {
                    return {
                      ...result,
                      widgets,
                      qnrResponse,
                    };
                  })
                );
            } else {
              return of({
                ...result,
                widgets: result.session.widgets,
                qnrResponse: result.session.questionnaireResponse,
              });
            }
          }),
          switchMap((result) => {
            const formAnswers = calculateSessionValues(
              result.session.questionnaireResponse,
              action.session.changes.values
            );
            const nextLinkIdPathInfo = this.sessionService.findTargetLinkIdPathInfo({
              renderType: result.session.renderType,
              direction: 'next',
              formAnswers,
              prevLinkIdPath: result.session.curLinkIdPath,
              widgets: result.widgets.map((x) => widgetConfigFactory(x, formAnswers)),
            });
            return of(
              qnrFormSessionActions.submitSessionPageSuccess({
                session: {
                  id: action.session.id,
                  changes: {
                    questionnaireResponse: result.qnrResponse,
                    curLinkIdPath: nextLinkIdPathInfo.targetLinkIdPath,
                    doesQnrResponseExistOnServer:
                      result.session.isAdaptive || // If adaptive it must exist at this point
                      result.session.doesQnrResponseExistOnServer,
                    widgets: result.widgets,
                  },
                },
                wasFinalPage: nextLinkIdPathInfo.wasFinalPage,
              })
            );
          })
        )
      )
    );
  });

  submitSessionPageSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(qnrFormSessionActions.submitSessionPageSuccess),
      concatMap((action) => {
        const wasFinalPage: boolean = action.wasFinalPage;

        if (wasFinalPage) {
          return of(
            qnrFormSessionActions.completeSession({
              id: action.session.id,
            })
          );
        } else {
          this.router.navigate([
            '/',
            this.sessionService.moduleConfig.qnrPageRoutePath,
            action.session.id,
            action.session.changes.curLinkIdPath,
          ]);
          return of({ type: 'NO_ACTION' });
        }
      })
    );
  });

  previousSessionPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(qnrFormSessionActions.previousSessionPage),
      mergeMap((action) =>
        this.sessionService.sessionWithReferencedResources$(action.id).pipe(
          map(({ session }) => {
            const targetLinkIdPathInfo = this.sessionService.findTargetLinkIdPathInfo({
              renderType: session.renderType,
              prevLinkIdPath: session.curLinkIdPath,
              direction: 'previous',
              formAnswers: session.values,
              widgets: session.widgets.map((x) => widgetConfigFactory(x, session.values)),
            });
            const curPageNumber = session.widgets.find(
              (widget) => widget.linkIdPath === session.curLinkIdPath
            )?.pageNumber;
            let questionnaireResponse: fhir4.QuestionnaireResponse;
            if (session.isAdaptive) {
              /** We must remove the group that we have just exited, to ensure that calls to @next-question endpoint stays in sync */
              const topLevelLinkId = getTopLevelLinkId(session.curLinkIdPath);
              questionnaireResponse = this.qnrResponseService.removeLatestGroupFromAdaptiveResponse(
                topLevelLinkId,
                session.questionnaireResponse
              );
            } else {
              questionnaireResponse = session.questionnaireResponse;
            }

            return previousSessionPageSuccess({
              session: {
                id: session.id,
                changes: {
                  questionnaireResponse: questionnaireResponse,
                  curLinkIdPath: targetLinkIdPathInfo.targetLinkIdPath,
                },
              },
              wasFirstPage: session.renderType === 'full' || curPageNumber === 1,
            });
          })
        )
      )
    );
  });

  previousSessionPageSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(qnrFormSessionActions.previousSessionPageSuccess),
      switchMap((action) => {
        if (action.wasFirstPage) {
          this.navCtrl.navigateBack([this.sessionService.rootPath]);
        } else {
          this.navCtrl.navigateBack([
            '/',
            this.sessionService.moduleConfig.qnrPageRoutePath,
            action.session.id,
            action.session.changes.curLinkIdPath,
          ]);
        }
        return of({ type: 'NO_ACTION' });
      })
    );
  });

  completeQnrSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(qnrFormSessionActions.completeSession),
      concatMap((action) =>
        this.sessionService.sessionWithReferencedResources$(action.id).pipe(
          switchMap((result) => {
            /**
             * Unless server save strategy is custom, submit questionnaire response
             * to server and mark activity in associated careplan as done
             */
            if (this.sessionService.moduleConfig.serverSaveStrategy === QnrSessionServerSaveStrategy.Custom) {
              return EMPTY;
            }
            return from(
              this.fhirService.submitQnrResponse({
                qnrResponse: {
                  ...result.session.questionnaireResponse,
                  status: 'completed',
                },
                doesQnrResponseExistOnServer: result.session.doesQnrResponseExistOnServer,
                carePlan: result.carePlan,
                activityCode: result.session.activityCode,
                activityStatus: 'completed',
                options: { forceServerReloadForPatch: true },
              })
            ).pipe(
              // TODO: Add error handling here
              switchMap((_result) =>
                of(
                  qnrFormSessionActions.completeSessionSuccess({
                    id: action.id,
                  })
                )
              )
            );
          })
        )
      )
    );
  });
}
