<ng-container *transloco="let t; read: 'hestiaPages.error'">
  <ion-header [translucent]="true">
    <ion-toolbar color="light">
      <ion-title>Something went wrong ..</ion-title>
      <ion-buttons slot="end">
        <ion-menu-button color="primary"></ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content [fullscreen]="true" color="light">
    <ion-grid fixed>
      <div>
        <ion-text color="primary">
          <h2>404</h2>
        </ion-text>

        <p class="ion-padding">We couldn't find the page you were looking for. ({{ snapshotUrl }})</p>

        <ion-button class="ion-margin" [routerLink]="['/']" routerDirection="back">
          <ion-icon name="arrow-back-outline" slot="start" />
          Take me back
        </ion-button>
      </div>
    </ion-grid>
  </ion-content>
</ng-container>
