import { WidgetTypes } from '../../typings';
import { LegacyQnrItemExtBase } from '../extension-base';

export class QuestionnaireItemSvgSlider extends LegacyQnrItemExtBase {
  extensionUrl =
    'http://zitelab.eu/fhir/extensions/questionnaire-item-slider-svg';
  supportedWidgets = [WidgetTypes.SvgSliderWidget];
  constructor(config: Record<string, unknown> = {}) {
    super(config);
  }

  parse = (): {
    widgetProperties: {
      isSvgSlider: boolean;
      svgSourceType?: 'string' | 'filename';
      svgSource?: string;
    };
  } => {
    let isSvgSlider = false;
    let svgSourceType: 'string' | 'filename' = null;
    let svgSource: string = null;
    if (this.extensionJson?.valueBoolean) {
      isSvgSlider = this.extensionJson.valueBoolean;
    }
    if (this.extensionJson?.extension) {
      this.extensionJson.extension.forEach((extension) => {
        if (extension.url === 'svgSourceType') {
          svgSourceType = extension.valueCode as 'string' | 'filename';
        }
        if (extension.url === 'svgSource') {
          svgSource = extension.valueString;
        }
      });
    }
    return {
      widgetProperties: {
        isSvgSlider,
        svgSourceType,
        svgSource,
      },
    };
  };
}

export function getSvgSliderExt(item: fhir4.QuestionnaireItem): {
  isSvgSlider: boolean;
  svgSourceType?: 'string' | 'filename';
  svgSource?: string;
} {
  let extensionObj: QuestionnaireItemSvgSlider;
  if (item?.extension) {
    // the FHIR resource is already validated at this point
    item.extension.forEach((extension) => {
      if (
        extension.url ===
        'http://zitelab.eu/fhir/extensions/questionnaire-item-slider-svg'
      ) {
        extensionObj = new QuestionnaireItemSvgSlider({
          extensionJson: extension,
        });
      }
    });
  }
  return extensionObj !== undefined
    ? extensionObj.parse().widgetProperties
    : { isSvgSlider: false };
}
