import { WidgetConfig } from '../../../models/widget.config';

export class CheckboxWidgetConfig extends WidgetConfig<string> {
  widgetType = 'CheckboxWidget';
  fhirValueSet: string;
  minSelected: number;
  maxSelected: number;
  constructor(config: Partial<WidgetConfig<any>>) {
    super(config);
    this.value =
      typeof config.value === 'string' ? [config.value] : config.value;
    this.multiple = true;
    this.options = config.options || [];
    this.fhirValueSet = config['fhirValueSet'] || null;
    this.minSelected = config['minSelected'] || null;
    this.maxSelected = config['maxSelected'] || null;
  }
}
