import { Sex } from '../types';
import { CPR } from './cpr';

export interface IHestiaPatient {
  // fhirId: string;
  // userName: string;
  fhirResource: fhir4.Patient;
  // socialSecurityNumber: string;
  // fullName: string;
  // homePath: string;
}

export class HestiaPatient implements IHestiaPatient {
  // fhirId: string;
  // userName: string;
  fhirResource: fhir4.Patient;
  // socialSecurityNumber: string;
  // fullName: string;
  // homePath: string;
  // readonly cpr: CPR;
  constructor(public config: IHestiaPatient) {
    //   this.fhirId = config.fhirId;
    //   this.userName = config.userName;
    this.fhirResource = config.fhirResource;
    // this.socialSecurityNumber = config.socialSecurityNumber;
    // this.cpr = new CPR(this.socialSecurityNumber);
    // this.fullName = config.fullName;
    // this.homePath = config.homePath;
  }

  // get birthDate(): Date {
  //   return this.cpr.birthdate;
  // }

  // get age(): number {
  //   return this.cpr.age;
  // }

  // get sex(): Sex {
  //   return this.cpr.sex;
  // }

  // get firstName(): string {
  //   return this.fullName.split(' ')[0];
  // }

  // get lastName(): string {
  //   return this.fullName.split(' ').slice(-1).join(' ');
  // }
}
