import * as QnrFormSessionActions from './qnr-form-session.actions';

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { IQnrFormSession, createSessionFromInitPayload } from '../../constants';
import { createReducer, on } from '@ngrx/store';

export const qnrFormSessionFeatureKey = 'qnr-form-sessions';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IQnrFormSessionsState extends EntityState<IQnrFormSession> {
  // additional entities state properties
  loading: boolean;
  activeSessionId: string;
}

export const adapter: EntityAdapter<IQnrFormSession> = createEntityAdapter<IQnrFormSession>();

export const qnrFormSessionsInitialState: IQnrFormSessionsState = adapter.getInitialState({
  loading: false,
  activeSessionId: null,
});

export const qnrFormSessionsReducer = createReducer(
  qnrFormSessionsInitialState,
  on(QnrFormSessionActions.initSession, (state: IQnrFormSessionsState, action) => {
    const fullSession = createSessionFromInitPayload(action.session);
    return adapter.addOne(fullSession, { ...state, loading: true });
  }),
  on(QnrFormSessionActions.initSessionSuccess, (state: IQnrFormSessionsState, action) => {
    const updatePayload = {
      id: action.session.id,
      changes: { ...action.session.changes, initCompleted: true },
    };
    return adapter.updateOne(updatePayload, { ...state, loading: false });
  }),
  on(QnrFormSessionActions.initSessionFailure, (state, action) =>
    adapter.removeOne(action.id, { ...state, loading: false })
  ), // Need revision
  on(QnrFormSessionActions.submitSessionPage, (state, action) =>
    adapter.updateOne(action.session, { ...state, loading: true })
  ),
  on(QnrFormSessionActions.submitSessionPageFailure, (state, action) =>
    adapter.removeOne(action.id, { ...state, loading: false })
  ), // Need revision
  on(QnrFormSessionActions.submitSessionPageSuccess, (state, action) =>
    adapter.updateOne(action.session, { ...state, loading: false })
  ),
  on(QnrFormSessionActions.previousSessionPageSuccess, (state, action) =>
    adapter.updateOne(action.session, { ...state, loading: false })
  ),
  on(QnrFormSessionActions.previousSessionPageFailure, (state, action) =>
    adapter.removeOne(action.id, { ...state, loading: false })
  ), // Need revision
  on(QnrFormSessionActions.updateSessionSettings, (state, action) => adapter.updateOne(action.session, state)),
  on(QnrFormSessionActions.updateSessionFormValues, (state, action) => adapter.updateOne(action.session, state)),
  on(QnrFormSessionActions.deleteSession, (state, action) => adapter.removeOne(action.id, state)),
  on(QnrFormSessionActions.deleteSessions, (state, action) => adapter.removeMany(action.ids, state)),
  on(QnrFormSessionActions.clearSessions, (state) => adapter.removeAll(state))
);
