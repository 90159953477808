/* eslint-disable ngrx/avoid-mapping-selectors */
import { DateFnsModule } from 'ngx-date-fns';

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { TranslocoModule } from '@ngneat/transloco';

import { HestiaPagesService } from '../../service';

interface PageData {
  instructionsText?: string;
}

@Component({
  standalone: true,
  selector: 'hestia-error',
  imports: [CommonModule, DateFnsModule, FormsModule, ReactiveFormsModule, RouterModule, TranslocoModule, IonicModule],
  templateUrl: 'error.html',
  styles: [
    `
      ion-grid {
        display: grid;
        height: 100vh;
        place-items: center;
        text-align: center;
      }

      ion-text {
        h2 {
          font-size: 5rem;
        }

        h2,
        h3 {
          font-weight: bolder;
        }
      }
    `,
  ],
})
export class ErrorComponent implements OnInit {
  pageData: PageData | undefined;

  snapshotUrl = '';

  constructor(private router: Router, private route: ActivatedRoute, public pagesService: HestiaPagesService) {
    this.route.data.subscribe((data: PageData) => (this.pageData = data));

    this.snapshotUrl = this.router.routerState.snapshot.url;
  }

  ngOnInit(): void {
    return;
  }
}
