import { WidgetTypes } from '../../typings';
import { LegacyQnrItemExtBase, IQnrItemExtParsedExt } from '../extension-base';

export class QuestionnaireItemMinOccurs extends LegacyQnrItemExtBase {
  extensionUrl =
    'http://hl7.org/fhir/StructureDefinition/questionnaire-minOccurs';
  supportedWidgets = [WidgetTypes.CheckboxWidget];
  constructor(config: Record<string, unknown> = {}) {
    super(config);
  }

  parse = (): IQnrItemExtParsedExt => {
    let minSelected: number = null;
    if (this.extensionJson?.valueInteger) {
      minSelected = this.extensionJson.valueInteger;
    }
    return {
      widgetProperties: {
        minSelected,
      },
    };
  };
}

export function getItemMinOccursExt(item: fhir4.QuestionnaireItem) {
  let extensionObj: QuestionnaireItemMinOccurs;
  if (item?.extension) {
    // the FHIR resource is already validated at this point
    item.extension.forEach((extension) => {
      if (
        extension.url ===
        'http://hl7.org/fhir/StructureDefinition/questionnaire-minOccurs'
      ) {
        extensionObj = new QuestionnaireItemMinOccurs({
          extensionJson: extension,
        });
      }
    });
  }
  return extensionObj !== undefined
    ? extensionObj.parse().widgetProperties
    : {};
}
