export const codingMatch = (
  taskCoding: fhir4.Coding[],
  matches: { code: string; present: boolean }[]
) => {
  const allCodings = taskCoding.map((elem) => elem.code),
    allMatches = matches.map(
      (elem) => allCodings.indexOf(elem.code) > -1 === elem.present
    );
  return allMatches.indexOf(false) === -1;
};
