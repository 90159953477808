<ng-container *transloco="let t; read: 'hestiaPages.login'">
  <ion-content [fullscreen]="true">
    <ion-grid fixed>
      <ion-img id="imageSrc" [src]="pageData?.imageSrc"></ion-img>

      <ion-card>
        <ion-row class="ion-margin">
          <ion-col *ngIf="pageData?.auth?.oneTimePassword">
            <ion-button expand="block" (click)="openSMSActivationModal()">{{ t('smsActivationCode') }}</ion-button>
          </ion-col>
          <ion-col *ngIf="pageData?.auth?.mitID">
            <ion-button expand="block" color="blue" (click)="mitIdService.initMitIdLogin()">
              {{ pageData?.auth?.mitIDLabel ?? t('useMitID') }}
            </ion-button>
          </ion-col>
        </ion-row>

        <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="pageData?.auth?.usernameAndPassword">
          <ion-list>
            <ion-list-header>
              <ion-label>{{ t('usernameAndPassword') }}</ion-label>
            </ion-list-header>

            <ion-item *ngIf="pageData?.userTemplatesVisible">
              <ion-label position="stacked">{{ t('userTemplates') }}</ion-label>
              <ion-select
                aria-label="User Templates"
                [placeholder]="t('selectUserTemplate')"
                (ionChange)="handleUserTemplateChange($event)"
              >
                <ion-select-option *ngFor="let user of pageData?.userTemplates" [value]="user">
                  {{ user.login }}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">{{ t('username') }}</ion-label>
              <ion-input formControlName="login" name="login" [placeholder]="t('usernamePlaceholder')"></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">{{ t('password') }}</ion-label>
              <ion-input
                formControlName="password"
                name="password"
                [type]="showPassword ? 'text' : 'password'"
                [placeholder]=" t('passwordPlaceholder')"
              ></ion-input>
              <ion-button fill="clear" slot="end" (click)="showPassword = !showPassword">
                {{ !showPassword ? t('show') : t('hide') }}
              </ion-button>
            </ion-item>

            <ion-row class="ion-margin">
              <ion-col [size]="12">
                <ion-button expand="block" type="submit" [disabled]="form.invalid">{{ t('signIn') }}</ion-button>
              </ion-col>
            </ion-row>
          </ion-list>
        </form>
      </ion-card>

      <ion-card *ngIf="pageData?.buttons || pageData?.videoUrl">
        <ion-grid>
          <ion-row>
            <ion-col [size]="6" *ngIf="pageData?.videoUrl">
              <ion-button expand="block" color="light" (click)="presentVideo()">
                <ion-icon slot="start" name="play-outline" />
                {{ t('watchVideo') }}
              </ion-button>
            </ion-col>
            <ion-col [size]="6" *ngFor="let button of pageData?.buttons">
              <ion-button expand="block" [color]="button?.color" (click)="button?.handler()">
                <ion-icon slot="start" [name]="button?.icon" *ngIf="button?.icon" />
                {{ t(button.label!) }}
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card>

      <ion-card *ngFor="let card of pageData?.cards" [color]="card.color">
        <ion-card-header *ngIf="card?.title">
          <ion-card-subtitle [innerHTML]="t(card?.title!)" />
        </ion-card-header>

        <ion-card-content *ngIf="card?.description">
          <ion-text [innerHTML]="t(card?.description!)" />
        </ion-card-content>
      </ion-card>

      <ion-buttons class="ion-margin ion-justify-content-center">
        <ion-button color="dark" shape="round" (click)="getServerStatus()" *ngIf="serviceStatus">
          <ion-icon name="ellipse" slot="start" [color]="serviceStatus.color" />
          <ion-label [innerHTML]="serviceStatus.status" />
        </ion-button>
        <ion-button color="dark" shape="round" (click)="fullReset()">
          <ion-icon name="refresh-circle" slot="start" />
          <ion-label [innerHTML]="t('reset')" />
        </ion-button>
      </ion-buttons>
    </ion-grid>

    <ng-container *ngIf="pageData?.footerLogoSrc">
      <ion-img id="footerLogoSrc" [src]="pageData?.footerLogoSrc"></ion-img>
    </ng-container>
  </ion-content>
</ng-container>
