<ng-container [formGroup]="form">
  <div [formArrayName]="config.name">
    <hestia-widget-header
      [config]="config"
      [i18n]="i18n"
    ></hestia-widget-header>
    <ion-row>
      <ion-col
        col-12
        col-sm-12
        col-md-6
        col-lg-6
        col-xl-4
        *ngFor="let row of control.controls; let i = index"
      >
        <ion-card [formGroupName]="i">
          <!-- <ion-card-header>
            {{ i + 1}}
          </ion-card-header> -->
          <ion-row>
            <ion-col col-3>
              <button
                ion-button
                icon-only
                clear
                small
                float-left
                style="margin-left: 8px"
              >
                <ion-badge>
                  {{ i + 1 }}
                </ion-badge>
              </button>
            </ion-col>
            <ion-col col-3 offset-6>
              <button
                ion-button
                icon-only
                clear
                small
                float-right
                (click)="removeGroup($event, i)"
              >
                <ion-icon name="trash"></ion-icon>
              </button>
            </ion-col>
          </ion-row>
          <ion-list>
            <ng-container
              *ngFor="let col of config.childWidgets"
              [ngSwitch]="col.widgetType"
            >
              <ion-item *ngSwitchCase="'StringWidget'">
                <ion-label stacked>
                  {{
                    i18n({
                      translate: 'label',
                      fallback: col.label,
                      config: col
                    })
                  }}
                </ion-label>
                <ion-input
                  type="text"
                  [placeholder]="'IFQ.STRING_WIDGET.PLACEHOLDER' | transloco"
                  [formControlName]="col.name"
                ></ion-input>
              </ion-item>
              <ion-item *ngSwitchCase="'NumberWidget'">
                <ion-label stacked>
                  {{
                    i18n({
                      translate: 'label',
                      fallback: col.label,
                      config: col
                    })
                  }}
                </ion-label>
                <ion-input
                  type="number"
                  [placeholder]="'IFQ.NUMBER_WIDGET.PLACEHOLDER' | transloco"
                  [formControlName]="col.name"
                ></ion-input>
              </ion-item>
              <ion-item *ngSwitchCase="'RadioGroupWidget'">
                <ion-label stacked>
                  {{
                    i18n({
                      translate: 'label',
                      fallback: col.label,
                      config: col
                    })
                  }}
                </ion-label>
                <ion-select
                  text-wrap
                  [placeholder]="'IFQ.SELECTION_WIDGET.PLACEHOLDER' | transloco"
                  [id]="col.name"
                  [formControlName]="col.name"
                  [interfaceOptions]="{
                    cssClass: 'repeatableGroupWidgetSelectAlert'
                  }"
                  [disabled]="col.disabled"
                  [multiple]="col.multiple"
                >
                  <ion-select-option
                    *ngFor="let option of col.options"
                    text-wrap
                    [value]="option.code"
                  >
                    {{
                      i18n({
                        translate: option.i18nName,
                        fallback: option.display,
                        type: 'i18nName'
                      })
                    }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item *ngSwitchCase="'CheckboxWidget'">
                <ion-label stacked>
                  {{
                    i18n({
                      translate: 'label',
                      fallback: col.label,
                      config: col
                    })
                  }}
                </ion-label>
                <ion-select
                  text-wrap
                  [placeholder]="'IFQ.SELECTION_WIDGET.PLACEHOLDER' | transloco"
                  [id]="col.name"
                  [formControlName]="col.name"
                  [interfaceOptions]="{
                    cssClass: 'repeatableGroupWidgetSelectAlert'
                  }"
                  [disabled]="col.disabled"
                  [multiple]="col.multiple"
                >
                  <ion-select-option
                    *ngFor="let option of col.options"
                    text-wrap
                    [value]="option.code"
                  >
                    <span class="testTextWrap">
                      {{
                        i18n({
                          translate: option.i18nName,
                          fallback: option.display,
                          type: 'i18nName'
                        })
                      }}
                    </span>
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item *ngSwitchDefault>
                <ion-label stacked>
                  {{
                    i18n({
                      translate: 'label',
                      fallback: col.label,
                      config: col
                    })
                  }}
                  <span ion-text color="danger">
                    (widget type {{ col.widgetType }} is unknown to
                    RepeatableGroupWidget)
                  </span>
                </ion-label>
                <ion-input
                  type="text"
                  [placeholder]="'IFQ.STRING_WIDGET.PLACEHOLDER' | transloco"
                  [formControlName]="col.name"
                ></ion-input>
              </ion-item>
            </ng-container>
          </ion-list>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row style="padding-left: 0px; padding-right: 0px">
      <ion-col col-6 col-lg-3 style="padding-left: 0px">
        <button
          ion-button
          block
          outline
          icon-left
          color="secondary"
          *ngIf="
            config.suggestedValueConfig !== null &&
            config.suggestedValueConfig.value !== null
          "
          (click)="triggerSuggestedValue($event)"
        >
          <ion-icon name="color-wand"></ion-icon>
          {{ getSuggestedValueBtnName() | transloco }}
        </button>
      </ion-col>
      <ion-col col-6 col-lg-3 offset-lg-6 style="padding-right: 0px">
        <button
          ion-button
          block
          outline
          icon-right
          color="secondary"
          (click)="addGroup($event)"
        >
          {{ 'IFQ.REPEATABLE_GROUP_WIDGET.ADD_NEW_BTN' | transloco }}
          <ion-icon name="add"></ion-icon>
        </button>
      </ion-col>
    </ion-row>
  </div>
</ng-container>
