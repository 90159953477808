import { Component, Input, ElementRef, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';

import { Store } from '@ngrx/store';

import { WidgetBaseComponent } from '../../../models/widget';
import { Events } from '../../../services/events.service';
import { HestiaQnrFormSessionService } from '../../../services/qnr-form-session.service';
import { BooleanWidgetConfig } from './boolean-widget.config';

@Component({
  selector: 'hestia-boolean-widget',
  templateUrl: './boolean-widget.component.html',
  styleUrls: ['./boolean-widget.component.scss'],
})
export class BooleanWidgetComponent
  extends WidgetBaseComponent
  implements OnInit
{
  constructor(
    transloco: TranslocoService,
    questionnaireService: HestiaQnrFormSessionService,
    store: Store,
    element: ElementRef,
    events: Events
  ) {
    super(transloco, questionnaireService, store, element, events);
  }
  @Input() config: BooleanWidgetConfig;
  @Input() form: UntypedFormGroup;

  ngOnInit(): void {
    super.ngOnInit();
  }

  get isValid() {
    return this.form.controls[this.config.name].valid;
  }
}
