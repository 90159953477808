<hestia-widget-group-header [config]="config" [i18n]="i18n"></hestia-widget-group-header>
<div [formGroup]="form">
  <ion-list lines="none" [id]="config.name" radio-group text-wrap>
    <ion-list-header class="radio-group-widget-header">
      <hestia-widget-header [config]="config" [i18n]="i18n"></hestia-widget-header>
    </ion-list-header>
    <ion-item>
      <div id="flexItem" [ngStyle]="{ 'flex-direction': config.invertedImages ? 'row-reverse' : 'row' }">
        <ion-icon slot="start" class="emoji" src="assets/img/1_really_good.svg"></ion-icon>
        <div
          style="margin: 0px auto; font-size: 20px; font-weight: bold; text-align: center; text-decoration: underline"
        >
          Du har valgt: {{ form.controls[config.name].value }}
        </div>
        <ion-icon slot="end" class="emoji" src="assets/img/4_really_bad.svg"></ion-icon>
      </div>
    </ion-item>

    <ion-item>
      <ion-segment mode="ios" [formControlName]="config.name">
        <ng-container *ngFor="let option of config.options" style="width: 4.45%">
          <ion-segment-button
            [value]="option.code"
            style="
              width: 4.45%;
              padding: 0px;
              margin: 0px;
              min-width: 4.45%;
              border-right: 1px solid #ddd;
              border-radius: 0px;
            "
            text-wrap
          >
            <ng-container *ngIf="config.hideOptionLabels; else showLabel">
              <ng-container *ngIf="form.controls[config.name].value === option.code; else notSelected">
                <ion-icon name="radio-button-on"></ion-icon>
              </ng-container>
              <ng-template #notSelected>
                <ion-icon name="radio-button-off"></ion-icon>
              </ng-template>
            </ng-container>

            <ng-template #showLabel>
              <!-- <div style="border:1px solid #1e1e1e;" *ngIf="
                            form.controls[config.name].value === option.code;">
                                {{ i18n({ translate: option.i18nName, fallback: option.display, type: 'i18nName' }) }}
                            </div> -->

              <div style="margin-left: -7px; font-size: 90%; transform: rotate(-90deg)">
                {{ i18n({ translate: option.i18nName, fallback: option.display, type: 'i18nName' }) }}
              </div>
            </ng-template>
          </ion-segment-button>
        </ng-container>
      </ion-segment>
    </ion-item>

    <!-- TODO: Investigate better solution -->
    <div class="range-labels">
      <ion-note class="min-value-label" *ngIf="config.leftLabel !== null">
        {{ i18n({ translate: 'leftLabel' }) }}
      </ion-note>
      <ion-note class="max-value-label" *ngIf="config.rightLabel !== null" style="float: right">
        {{ i18n({ translate: 'rightLabel' }) }}
      </ion-note>
    </div>
  </ion-list>
</div>
