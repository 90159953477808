import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AlertController, ModalController, NavController } from '@ionic/angular';

import { TranslocoService } from '@ngneat/transloco';

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { widgetConfigFactory } from '../../components/widgets';
import { FIRST_QUESTION_LINK_ID_PATH, IQnrFormSession } from '../../constants';
import { defaultTranslations } from '../../i18n';
import { WidgetConfig } from '../../models/widget.config';
import { Events } from '../../services/events.service';
import { HestiaQnrFormSessionService } from '../../services/qnr-form-session.service';
import { QuestionnaireResponseService } from '../../services/questionnaire-response.service';
import {
  previousSessionPage,
  submitSessionPage,
  updateSessionFormValues,
  updateSessionSettings,
} from '../../store/qnr-form-session/qnr-form-session.actions';

export interface SessionPageInfo {
  session: IQnrFormSession;
  questionnaire: fhir4.Questionnaire;
  pageWidgets: WidgetConfig<unknown>[];
}

// TODO: Check if swipe to next is possible when form is incomplete
@Component({
  selector: 'hestia-questionnaire-page',
  templateUrl: 'qnr-form.page.html',
  styleUrls: ['qnr-form.page.scss'],
})
export class QnrFormPageComponent implements OnInit, OnDestroy {
  curPage$: Observable<SessionPageInfo>;
  activeLinkId: string;
  activeGroupId: string;
  submitButtonName = 'IFQ.NEXT';
  path: string;
  showWidgetConfig = false;

  modalSessionId: string;

  constructor(
    public router: Router,
    public alertCtrl: AlertController,
    public sessionService: HestiaQnrFormSessionService,
    public qnrResponseService: QuestionnaireResponseService,
    public events: Events,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    public transloco: TranslocoService,
    public modalController: ModalController
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}

  ngOnInit(): void {
    for (const [language, i18n] of Object.entries(defaultTranslations)) {
      this.transloco.setTranslation(i18n, language, { merge: true });
    }
    this.curPage$ = this.activatedRoute.paramMap.pipe(
      switchMap((params) => {
        const sessionId: string = params.get('sessionId') || this.modalSessionId;
        let curLinkIdPath = params.get('linkIdPath');
        if (!sessionId) {
          this.alertCtrl
            .create({
              header: 'Session ID not provided',
              message: 'Please provide the questionnaire ID',
              buttons: [
                {
                  text: 'Restart',
                  handler: () => this.router.navigate(['/']),
                },
              ],
            })
            .then((alert) => alert.present());
        }
        return this.sessionService.sessionWithQnr$(sessionId).pipe(
          map((result) => {
            if (
              // If the curLinkIdPath doesn't match session state, update session state.
              curLinkIdPath !== result.session.curLinkIdPath ||
              curLinkIdPath === null
            ) {
              if (curLinkIdPath === FIRST_QUESTION_LINK_ID_PATH) {
                curLinkIdPath = null;
              }
              const targetLinkIdPathInfo = this.sessionService.findTargetLinkIdPathInfo({
                renderType: result.session.renderType,
                prevLinkIdPath: curLinkIdPath,
                direction: 'current',
                formAnswers: result.session.values,
                widgets: result.session.widgets.map((x) => widgetConfigFactory(x, result.session.values)),
              });
              curLinkIdPath = targetLinkIdPathInfo.targetLinkIdPath;
              this.store.dispatch(
                updateSessionSettings({
                  session: {
                    id: result.session.id,
                    changes: { curLinkIdPath },
                  },
                })
              );
              result.session = {
                // We ensure that we have this update available immediately
                ...result.session,
                curLinkIdPath,
              };
            }

            const pageWidgets = this.sessionService.calculateActiveWidgets({
              allWidgets: result.session.widgets.map((x) => widgetConfigFactory(x, result.session.values)),
              linkIdPath: result.session.curLinkIdPath,
              activityStatus: result.session.activityStatus,
              renderType: result.session.renderType,
            });
            const sessionPageInfo: SessionPageInfo = {
              session: result.session,
              questionnaire: result.questionnaire,
              pageWidgets,
            };
            return sessionPageInfo;
          })
        );
      })
    );
  }

  handleFormChange(form, curPage: SessionPageInfo): void {
    this.sessionService.store.dispatch(
      updateSessionFormValues({
        session: {
          id: curPage.session.id,
          changes: {
            values: { ...curPage.session.values, ...form },
            latestAnswerDate: new Date().toISOString(),
          },
        },
      })
    );
  }

  async handlePageSubmit(_event, form, curPage: SessionPageInfo): Promise<void> {
    const allValues: Record<string, unknown> = {
      ...curPage.session.values,
      ...form,
    };
    const qnrResponse = await this.qnrResponseService.createQuestionnaireResponse({
      sessionId: curPage.session.id,
      questionnaireId: curPage.session.questionnaireId,
      carePlanId: curPage.session.carePlanId,
      latestAnswerDate: curPage.session.latestAnswerDate ?? new Date().toISOString(),
      formValues: allValues, // Important we use allValues, not just the values already stored in NGRX
      subjectRef: curPage.session.subjectRef,
      authorRef: curPage.session.authorRef,
      sourceRef: curPage.session.sourceRef,
      responseExtensions: curPage.session.responseExtensions,
      responseProfiles: curPage.session.responseProfiles,
      isAdaptive: curPage.session.isAdaptive,
      isInitAdaptiveAction: false,
      existingContainedQnr: (curPage.session.questionnaireResponse?.contained?.[0] as fhir4.Questionnaire) ?? null,
      widgets: curPage.session?.widgets ?? [],
      curLinkIdPath: curPage.session.curLinkIdPath,
      existingQuestionnaireResponse: (curPage.session.questionnaireResponse as fhir4.QuestionnaireResponse) ?? null
    });
    this.store.dispatch(
      submitSessionPage({
        session: {
          id: curPage.session.id,
          changes: {
            values: allValues,
            questionnaireResponse: qnrResponse,
          },
        },
      })
    );

    if (this.modalSessionId) {
      const exisitingLoader = await this.modalController.getTop();

      if (exisitingLoader !== undefined) {
        await this.modalController.dismiss();
      }
    }
  }

  toPreviousPage(_event, curPage: SessionPageInfo): void {
    this.store.dispatch(
      previousSessionPage({
        id: curPage.session.id,
      })
    );
  }

  getPageNumber(curPage: SessionPageInfo) {
    return curPage.pageWidgets[0].pageNumber;
  }

  getTotalNrOfPages(curPage: SessionPageInfo) {
    return curPage.session.widgets.length;
  }

  getProgress(curPage: SessionPageInfo) {
    return this.getPageNumber(curPage) / this.getTotalNrOfPages(curPage);
  }
}
