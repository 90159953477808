import { formatISO } from 'date-fns';

import {
  ENABLE_VALUE_HISTORY_QNR_RESPONSE_EXT_URL,
  FIRST_QUESTION_LINK_ID_PATH,
  HESTIA_QUESTIONNAIRE_RESPONSE_START_TIME_EXT_URL,
  LATEST_ITEM_IN_ADAPTIVE_RESPONSE_EXT_URL,
  LATEST_TOP_LEVEL_GROUP_ITEM_IN_ADAPTIVE_RESPONSE_EXT_URL,
} from '../../../constants';
import { getTopLevelLinkId } from '../../../utils/get-top-level-link-id';

// TODO: Refactor so we can provide a class-based approach for value history ext
export const addValueHistoryExt = (
  existingExts: fhir4.Extension[],
  formValues: Record<string, unknown>
): fhir4.Extension[] => {
  existingExts = existingExts ?? [];
  const otherExts = existingExts.filter(
    (x) => x.url !== ENABLE_VALUE_HISTORY_QNR_RESPONSE_EXT_URL // We filter away existing version of extension
  );
  const existingExtRawValue =
    existingExts.find((x) => x.url === ENABLE_VALUE_HISTORY_QNR_RESPONSE_EXT_URL)?.valueString ?? '{}';
  const existingValues = JSON.parse(existingExtRawValue);
  const valueHistoryExt: fhir4.Extension = {
    url: ENABLE_VALUE_HISTORY_QNR_RESPONSE_EXT_URL,
    valueString: JSON.stringify({ ...existingValues, ...formValues }), // We must save any previously answered item that is now not answered in present form
  };
  return [...otherExts, valueHistoryExt];
};

export const getAuthoredDate = (latestAnswerDate: string, existingResponse: fhir4.QuestionnaireResponse): string => {
  const existingAuthorDate = existingResponse?.authored ?? null;
  if (existingAuthorDate) {
    return existingAuthorDate;
  }
  const existingExts = existingResponse?.extension ?? [];
  let authoredDate =
    existingExts.find((x) => x.url === HESTIA_QUESTIONNAIRE_RESPONSE_START_TIME_EXT_URL)?.valueDateTime ?? '';
  if (authoredDate === '' || authoredDate === undefined || !authoredDate) {
    authoredDate = formatISO(new Date(latestAnswerDate));
  }
  return authoredDate;
};

export const addResponseStartTimeExt = (
  exts: fhir4.Extension[],
  existingResponse: fhir4.QuestionnaireResponse
): fhir4.Extension[] => {
  const existingExts = existingResponse?.extension ?? [];
  const otherExts = exts.filter(
    (x) => x.url !== HESTIA_QUESTIONNAIRE_RESPONSE_START_TIME_EXT_URL // We filter away existing version of extension
  );
  let existingExtRawValue =
    existingExts.find((x) => x.url === HESTIA_QUESTIONNAIRE_RESPONSE_START_TIME_EXT_URL)?.valueDateTime ?? '';

  if (existingExtRawValue === '') {
    existingExtRawValue = formatISO(new Date());
  }
  const valueStartExt: fhir4.Extension = {
    url: HESTIA_QUESTIONNAIRE_RESPONSE_START_TIME_EXT_URL,
    valueDateTime: existingExtRawValue,
  };
  otherExts.push(valueStartExt);
  return otherExts;
};

export const extractValueHistoryExtValues = (ext: fhir4.Extension): Record<string, unknown> => {
  if (!ext) {
    return {};
  }
  return JSON.parse(ext.valueString);
};

// TODO: Refactor so we can provide a class-based approach
export const addLatestItemInAdaptiveResponseExt = (props: {
  exts: fhir4.Extension[];
  curLinkIdPath: string;
}): fhir4.Extension[] => {
  props.exts = props.exts ?? [];
  const otherExts = [
    ...props.exts.filter(
      (x) => x.url !== LATEST_ITEM_IN_ADAPTIVE_RESPONSE_EXT_URL // We filter away existing version of extension
    ),
  ];
  const ext: fhir4.Extension = {
    url: LATEST_ITEM_IN_ADAPTIVE_RESPONSE_EXT_URL,
    valueString: props?.curLinkIdPath ?? FIRST_QUESTION_LINK_ID_PATH, // valueString cannot be null, so we use special value
  };
  otherExts.push(ext);
  return otherExts;
};

// TODO: Refactor so we can provide a class-based approach
export const addLatestGroupItemInAdaptiveResponseExt = (props: {
  exts: fhir4.Extension[];
  curLinkIdPath: string;
}): fhir4.Extension[] => {
  const topLevelLinkId = getTopLevelLinkId(props?.curLinkIdPath);
  console.log({ topLevelLinkId });
  props.exts = props.exts ?? [];
  const otherExts = [
    ...props.exts.filter(
      (x) => x.url !== LATEST_ITEM_IN_ADAPTIVE_RESPONSE_EXT_URL // We filter away existing version of extension
    ),
  ];
  const ext: fhir4.Extension = {
    url: LATEST_TOP_LEVEL_GROUP_ITEM_IN_ADAPTIVE_RESPONSE_EXT_URL,
    valueString: topLevelLinkId,
  };
  otherExts.push(ext);
  return otherExts;
};
