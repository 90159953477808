<hestia-widget-group-header
  [config]="config"
  [i18n]="i18n"
></hestia-widget-group-header>
<div [formGroup]="form">
  <ion-list lines="none" [id]="config.name" radio-group text-wrap>
    <ion-list-header class="radio-group-widget-header">
      <hestia-widget-header
        [config]="config"
        [i18n]="i18n"
      ></hestia-widget-header>
    </ion-list-header>
    <ion-row justify-content-center align-items-center>
      <ion-col>
        <ion-segment mode="ios" [formControlName]="config.name">
          <ng-container *ngFor="let option of config.options">
            <ion-segment-button
              [value]="option.code"
              style="min-width: fit-content"
              text-wrap
            >
              <ng-container *ngIf="config.hideOptionLabels; else showLabel">
                <ng-container
                  *ngIf="
                    form.controls[config.name].value === option.code;
                    else notSelected
                  "
                >
                  <ion-icon name="radio-button-on"></ion-icon>
                </ng-container>
                <ng-template #notSelected>
                  <ion-icon name="radio-button-off"></ion-icon>
                </ng-template>
              </ng-container>
              <ng-template #showLabel>
                {{
                  i18n({
                    translate: option.i18nName,
                    fallback: option.display,
                    type: 'i18nName'
                  })
                }}
              </ng-template>
            </ion-segment-button>
          </ng-container>
        </ion-segment>
      </ion-col>
    </ion-row>
    <!-- TODO: Investigate better solution -->
    <div class="range-labels">
      <ion-note class="min-value-label" *ngIf="config.leftLabel !== null">
        {{ i18n({ translate: 'leftLabel' }) }}
      </ion-note>
      <ion-note
        class="max-value-label"
        *ngIf="config.rightLabel !== null"
        style="float: right"
      >
        {{ i18n({ translate: 'rightLabel' }) }}
      </ion-note>
    </div>
  </ion-list>
</div>
