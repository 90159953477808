import { WidgetTypes } from '../../typings';
import { LegacyQnrItemExtBase, IQnrItemExtParsedExt } from '../extension-base';

export class QuestionnaireItemValueBasedHealthTopic extends LegacyQnrItemExtBase {
  extensionUrl =
    'http://zitelab.eu/fhir/extensions/QuestionnaireItemValueBasedHealthTopic';
  supportedWidgets = [WidgetTypes.All];
  constructor(config: Record<string, unknown> = {}) {
    super(config);
  }

  parse = (): IQnrItemExtParsedExt => {
    let topic: string = null;
    let subTopic: string = null;
    let isKeyItem = false;
    if (this.extensionJson?.valueString) {
      topic = this.extensionJson.valueString;
    }
    if (this.extensionJson?.extension) {
      this.extensionJson.extension.forEach((extension) => {
        if (extension.url === 'isKeyItem') {
          isKeyItem = extension.valueBoolean;
        }
        if (extension.url === 'subTopic') {
          subTopic = extension.valueString;
        }
      });
    }
    return {
      widgetProperties: {
        topic,
        isKeyItem,
        subTopic,
      },
    };
  };
}

export function getValueBasedHealthTopic(item: fhir4.QuestionnaireItem) {
  let extensionObj: QuestionnaireItemValueBasedHealthTopic;
  if (item?.extension) {
    // the FHIR resource is already validated at this point
    item.extension.forEach((extension) => {
      if (
        extension.url ===
        'http://zitelab.eu/fhir/extensions/QuestionnaireItemValueBasedHealthTopic'
      ) {
        extensionObj = new QuestionnaireItemValueBasedHealthTopic({
          extensionJson: extension,
        });
      }
    });
  }
  return extensionObj !== undefined
    ? extensionObj.parse().widgetProperties
    : {};
}
