import { LINK_ID_SEPERATOR } from '../constants';
import { OptionObject } from './typings';

export interface WidgetGroupSettings {
  id: string;
  label: string;
  translations: { [key: string]: { [key: string]: string } };
}

export interface IWidgetConfig<T> {
  /** An identifier that is unique within the Questionnaire allowing linkage to the equivalent item in a QuestionnaireResponse resource. */
  linkId: string;
  /** String with '.'-seperated linkIds constituting the 'path' to the widget */
  linkIdPath: string;
  /** Array with linkIds constituting the 'path' to the widget */
  linkIdPathArray: string[];
  /**
   * To ensure unique name, we use the linkIdPath:
   * String with '$$$'-seperated linkIds constituting the 'path' to the widget
   */
  name: string;
  /**
   * The text property of the FHIR item
   *
   * questionnaire.item.text: "The name of a section, the text of a question or text content for a display item."
   */
  label: string;
  prefix: string;
  value: T;
  defaultValue: T;
  displayOnly: boolean;
  shown: boolean;
  disabled: boolean;
  required: boolean;
  widgetNumber: number;
  pageNumber: number;
  widgetType: string;
  multiple: boolean;
  validators: Array<T>;
  controlType: string;
  enableWhen: fhir4.QuestionnaireItemEnableWhen[];
  /**
   * TODO: Replace with https://build.fhir.org/questionnaire-definitions.html#Questionnaire.item.enableBehavior
   */
  enableBehavior: 'all' | 'any';
  enableWhenFunction: string;
  translations: { [key: string]: string };
  widgetGroup?: WidgetGroupSettings;
  firstInGroup?: boolean;
  renderStandalone?: boolean; // Set at render time
  descriptionText?: string;
  renderDescriptionAsMarkdown?: boolean;
  renderLabelAsMarkdown?: boolean;
  childWidgets: WidgetConfig<unknown>[];
  varInterpolation?: {
    varName: string;
    defaultValue: string;
    curValue?: string;
  };
  setLocalVariable?: {
    varName: string;
    isGlobal: boolean;
  };
  suggestedValueConfig?: {
    value: any;
    valueInput: string;
    valueType: 'variableName' | 'value';
    isDefaultValue: boolean;
    alertMessage: string;
    buttonText: string;
  };
  svgGroupItemElement: string;
  valueBasedHealthTopic?: {
    topic: string;
    subTopic: string;
    isKeyItem: boolean;
  };
  gridGroupConfig?: {
    showOptionsHeaderRow: boolean;
    useOrdinalValuesAsRadioLabel: boolean;
  };
  isCustomSelectionModalEnabled: boolean;
  customSelectionModalConfig?: {
    modalEventIdentifier: string;
    btnText: string;
  };
  metadata?: {
    sessionId: string;
    carePlanId: string;
  };
  fhirItem: fhir4.QuestionnaireItem;
  options?: OptionObject[];
  datetimeWidgetFormat?: 'time' | 'date' | 'date-time';
}

export class WidgetConfig<T> implements IWidgetConfig<T> {
  linkId: string;
  linkIdPath: string;
  linkIdPathArray: string[];
  name: string;
  label: string;
  prefix: string;
  value: T;
  defaultValue: T;
  displayOnly: boolean;
  shown: boolean;
  disabled: boolean;
  required: boolean;
  widgetNumber: number;
  pageNumber: number;
  widgetType: string;
  multiple: boolean;
  validators: Array<T>;
  controlType: string;
  enableWhen: fhir4.QuestionnaireItemEnableWhen[];
  enableBehavior: 'all' | 'any';
  enableWhenFunction: string;
  translations: { [key: string]: string };
  widgetGroup?: WidgetGroupSettings;
  firstInGroup?: boolean;
  renderStandalone?: boolean; // Set at render time
  descriptionText?: string;
  renderDescriptionAsMarkdown?: boolean;
  renderLabelAsMarkdown?: boolean;
  childWidgets: WidgetConfig<unknown>[];
  varInterpolation?: {
    varName: string;
    defaultValue: string;
    curValue?: string;
  };
  setLocalVariable?: {
    varName: string;
    isGlobal: boolean;
  };
  suggestedValueConfig?: {
    value: any;
    valueInput: string;
    valueType: 'variableName' | 'value';
    isDefaultValue: boolean;
    alertMessage: string;
    buttonText: string;
  };
  svgGroupItemElement: string;
  valueBasedHealthTopic?: {
    topic: string;
    subTopic: string;
    isKeyItem: boolean;
  };
  gridGroupConfig?: {
    showOptionsHeaderRow: boolean;
    useOrdinalValuesAsRadioLabel: boolean;
  };
  isCustomSelectionModalEnabled: boolean;
  customSelectionModalConfig?: {
    modalEventIdentifier: string;
    btnText: string;
  };
  metadata?: {
    sessionId: string;
    carePlanId: string;
  };
  fhirItem: fhir4.QuestionnaireItem;
  options?: OptionObject[];
  datetimeWidgetFormat?: 'time' | 'date' | 'date-time';
  invertedImages: boolean;
  constructor(config: {
    linkId?: string;
    linkIdPath?: string;
    linkIdPathArray?: string[];
    name?: string;
    label?: string;
    prefix?: string;
    value?: T;
    defaultValue?: T;
    displayOnly?: boolean;
    shown?: boolean;
    disabled?: boolean;
    required?: boolean;
    order?: number;
    widgetType?: string;
    validators?: Array<T>;
    enableWhen?: fhir4.QuestionnaireItemEnableWhen[];
    enableBehavior?: 'all' | 'any';
    enableWhenFunction?: string;
    translations?: { [key: string]: string };
    controlType?: string;
    widgetNumber?: number;
    pageNumber?: number;
    widgetGroup?: WidgetGroupSettings;
    firstInGroup?: boolean;
    descriptionText?: string;
    renderDescriptionAsMarkdown?: boolean;
    renderLabelAsMarkdown?: boolean;
    childWidgets?: WidgetConfig<unknown>[];
    varInterpolation?: {
      varName: string;
      defaultValue: string;
      curValue?: string;
    };
    setLocalVariable?: {
      varName: string;
      isGlobal: boolean;
    };
    suggestedValueConfig?: {
      value: any;
      valueInput: string;
      valueType: 'variableName' | 'value';
      isDefaultValue: boolean;
      alertMessage: string;
      buttonText: string;
    };
    svgGroupItemElement?: string;
    valueBasedHealthTopic?: {
      topic: string;
      subTopic;
      isKeyItem: boolean;
    };
    gridGroupConfig?: {
      showOptionsHeaderRow: boolean;
      useOrdinalValuesAsRadioLabel: boolean;
    };
    isCustomSelectionModalEnabled?: boolean;
    customSelectionModalConfig?: {
      modalEventIdentifier: string;
      btnText: string;
    };
    metadata?: {
      sessionId: string;
      carePlanId: string;
    };
    fhirItem?: fhir4.QuestionnaireItem;
    options?: OptionObject[];
    renderStandalone?: boolean;
    datetimeWidgetFormat?: 'time' | 'date' | 'date-time';
    invertedImages?: boolean;
  }) {
    this.linkIdPath = config.linkIdPath ?? config.name;
    this.linkIdPathArray = config.linkIdPathArray ?? [config.linkId];
    this.value = config.value;
    this.defaultValue = config.defaultValue ?? null;
    this.displayOnly = config.displayOnly ?? false;
    this.shown = config.shown ?? true;
    this.disabled = config.disabled ?? false;
    this.name = config.name ?? config.linkIdPath;
    this.label = config.label ?? '';
    this.prefix = config.prefix ?? null;
    this.widgetNumber = config.widgetNumber;
    this.pageNumber = config.pageNumber;
    this.required = !!config.required;
    this.translations = config.translations ?? {};
    this.validators = config.validators ?? [];
    this.widgetType = config.widgetType ?? '';
    this.enableWhen = config.enableWhen ?? null;
    this.enableBehavior = config.enableBehavior ?? 'any'; // Default enableWhen operator is OR
    this.enableWhenFunction = config.enableWhenFunction ?? null;
    this.controlType = config.controlType ?? 'FormControl';
    this.widgetGroup = config.widgetGroup ?? null;
    this.firstInGroup = config.firstInGroup ?? null;
    this.descriptionText = config['descriptionText'] ?? null;
    this.renderDescriptionAsMarkdown = config['renderDescriptionAsMarkdown'] ?? false;
    this.renderLabelAsMarkdown = config['renderLabelAsMarkdown'] ?? false;
    this.setLocalVariable = config.setLocalVariable ?? null;
    this.varInterpolation = config.varInterpolation ?? null;
    this.suggestedValueConfig = config.suggestedValueConfig ?? null;
    this.svgGroupItemElement = config.svgGroupItemElement ?? null;
    this.metadata = config.metadata ?? { sessionId: null, carePlanId: null };
    this.childWidgets = config.childWidgets ?? null;
    this.fhirItem = config.fhirItem ?? null;
    this.valueBasedHealthTopic = config.valueBasedHealthTopic ?? null;
    this.gridGroupConfig = config.gridGroupConfig ?? null;
    this.isCustomSelectionModalEnabled = config.isCustomSelectionModalEnabled ?? false;
    this.customSelectionModalConfig = config.customSelectionModalConfig ?? null;
    this.options = config.options || null;
    this.renderStandalone = config.renderStandalone ?? false;
    this.datetimeWidgetFormat = config?.datetimeWidgetFormat;
    this.invertedImages = config?.invertedImages ?? false;
  }

  setTranslatableProperty(props: {
    property: string;
    value: unknown;
    i18nPath: string;
    i18nName: string;
    overwriteProperty: boolean;
  }) {
    if (!(props.property in this)) {
      // Property doesn't exist
      return;
    }
    if (props.overwriteProperty) {
      (this[props.property] as any) = props.value;
    }
    this.translations = {
      ...this.translations,
      [props.property]: `${props.i18nPath}.${props.i18nName}`,
    };
  }

  isShown(
    /** formAnswers as mapped by linkIdPath */
    linkIdPathFormAnswers: Record<string, unknown>,
    enableWhenFunctions: {
      [key: string]: (formAnswers: Record<string, unknown>) => boolean;
    }
  ): boolean {
    /*
     * Multiple answers are treated as "or". E.g. Enable if question 1 = A, C or E.
     * Components not specified in the answer do not need to match. For example, if enableWhen specifies code + system for a Coding, it is ok if the answer has a "display" element. I.e. treat the answer as a 'pattern'.
     *
     * The dataType of this element must be the same as the data type of the question being referenced.
     * https://www.hl7.org/fhir/questionnaire-definitions.html#Questionnaire.item.enableWhen.answer_x_
     */

    /**
     * Start by transforming so we get a formAnwers mapped just by linkId, not by linkIdPath
     * This is required to allow us to find the answers as specified with target question linkIds
     */
    const formAnswers: Record<string, unknown> = Object.entries(linkIdPathFormAnswers).reduce((prev, [key, value]) => {
      const linkId = key.split(LINK_ID_SEPERATOR).slice(-1)[0];
      return { ...prev, [linkId]: value };
    }, {});

    if (this.enableWhenFunction !== null) {
      // enableWhenFunction superseedes regular enableWhen
      if (enableWhenFunctions?.[this.enableWhenFunction]) {
        return enableWhenFunctions[this.enableWhenFunction](formAnswers);
      } // If function not provided, we default to regular enableWhen flow
    }
    if (this.enableWhen === null) {
      return this.shown;
    }
    const results = this.enableWhen.map((condition) => {
      const question = condition.question;
      const curAnswer = formAnswers?.[question]; // TODO: Support nested questions as source of enableWhen
      if (condition.operator === 'exists') {
        return curAnswer !== null;
      } else {
        if (condition.operator !== '=') {
          throw Error(`QuestionnaireItemOperator ${condition.operator} not supported by Hestia yet`);
        }
        const answerType = Object.keys(condition).find((key) => key.startsWith('answer')); // only one of answerString, answerBoolean etc. can be present
        const desiredAnswer = condition[answerType];
        if (Array.isArray(curAnswer)) {
          const curAnswerArray = curAnswer as string[];
          return curAnswerArray.indexOf(desiredAnswer) > -1;
        } else {
          return curAnswer === desiredAnswer;
        }
      }
    });
    // new feature enableBehavior
    // accepted values 'all'|'any' default is any
    if (this.enableBehavior === 'all') {
      return results.every((value) => value === true);
    } else {
      // The default operator for enableWhen is OR
      return results.some((value) => value === true);
    }
  }
}
