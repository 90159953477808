export interface IStorageSyncProvider {
  fetchState(): Promise<void | {}>;
  saveState(state: any, keys: string[]): Promise<any>;
  getNested(obj: any, path: string): any;
  setNested(obj: any, path: string, value: any): any;
}

export class StorageSyncProviderBase {
  // get/setNested inspired by
  // https://github.com/mickhansen/dottie.js
  getNested(obj: any, path: string): any {
    if (obj !== null && path) {
      // Recurse into the object.
      const parts = path.split('.').reverse();
      while (obj != null && parts.length) {
        obj = obj[parts.pop()];
      }
    }
    return obj;
  }

  setNested(obj: any, path: string, value: any): any {
    if (obj != null && path) {
      const pieces = path.split('.');
      const length = pieces.length;
      let current = obj;
      let piece: string;
      let index: number;
      for (index = 0; index < length; index++) {
        piece = pieces[index];
        if (index === length - 1) {
          current[piece] = value;
        } else if (!current[piece]) {
          current[piece] = {};
        }
        current = current[piece];
      }
    }

    return obj;
  }
}
