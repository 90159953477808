<ng-container *transloco="let t; read: 'hestiaPages.ssnEntry'">
  <ion-header [translucent]="true">
    <ion-toolbar color="light">
      <ion-title>{{ t('title') }}</ion-title>
      <ion-buttons slot="end">
        <ion-menu-button color="primary"></ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content [fullscreen]="true" color="light">
    <ion-grid fixed>
      <ion-card>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <ion-item lines="full" counter="true">
            <ion-icon name="id-card-outline" slot="start"></ion-icon>
            <ion-input
              formControlName="ssn"
              name="ssn"
              [placeholder]="t('ssnPlaceholder')"
              [type]="showSSN ? 'text' : 'password'"
              inputmode="numeric"
              autocomplete="off"
            ></ion-input>
            <ion-button fill="clear" slot="end" (click)="showSSN = !showSSN">
              {{ !showSSN ? t('show') : t('hide')}}
            </ion-button>
            <ion-note slot="helper">{{ t('valid') }}</ion-note>
            <ion-note slot="error" *ngIf="form.value.ssn?.length === 10">{{ t('invalid') }}</ion-note>
          </ion-item>

          <ion-row class="ion-padding">
            <ng-container *ngFor="let digit of digits; let i = index">
              <ion-col [size]="4">
                <ion-button expand="block" (click)="addDigit(i + 1)" [disabled]="!form.invalid">{{ i + 1}}</ion-button>
              </ion-col>
            </ng-container>

            <ion-col [size]="4">
              <ion-button expand="block" fill="outline" (click)="form.reset()">
                <ion-icon name="refresh-outline" slot="start"></ion-icon>
                {{ t('reset') }}
              </ion-button>
            </ion-col>

            <ion-col [size]="4">
              <ion-button expand="block" (click)="addDigit(0)" [disabled]="!form.invalid">0</ion-button>
            </ion-col>

            <ion-col [size]="4">
              <ion-button expand="block" fill="outline" (click)="removeDigit()">
                <ion-icon name="backspace-outline" slot="start"></ion-icon>
                {{ t('back') }}
              </ion-button>
            </ion-col>
          </ion-row>

          <ion-row class="ion-padding" *ngIf="!pageData?.autoSubmit">
            <ion-col [size]="12">
              <ion-button expand="block" type="submit" [disabled]="form.invalid">
                <ion-icon name="enter-outline" slot="end"></ion-icon>
                {{ t('submitButton') }}
              </ion-button>
            </ion-col>
          </ion-row>
        </form>
      </ion-card>

      <ion-card *ngIf="pageData?.buttons || pageData?.videoUrl">
        <ion-grid>
          <ion-row>
            <ion-col [size]="6" *ngIf="pageData?.videoUrl">
              <ion-button expand="block" color="light" (click)="presentVideo()">
                <ion-icon slot="start" name="play-outline" />
                Se video
              </ion-button>
            </ion-col>
            <ion-col [size]="6" *ngFor="let button of pageData?.buttons">
              <ion-button expand="block" [color]="button?.color" (click)="button?.handler()">
                <ion-icon slot="start" [name]="button?.icon" *ngIf="button?.icon" />
                {{ t(button.label!) }}
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card>

      <ion-card *ngIf="pageData?.faqs">
        <ion-accordion-group [value]="pageData?.defaultFaq">
          <ion-accordion *ngFor="let faq of pageData?.faqs" [value]="faq.question">
            <ion-item slot="header">
              <ion-label>{{ t(faq.question!) }}</ion-label>
            </ion-item>
            <div class="ion-padding" slot="content">
              <ion-text [innerHTML]="t(faq.answer!)" />
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-card>
    </ion-grid>
  </ion-content>
</ng-container>
