import { Component, Input, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

import { AlertController } from '@ionic/angular';

import { TranslocoService } from '@ngneat/transloco';

import { Store } from '@ngrx/store';

import { WidgetBaseComponent } from '../../../models/widget';
import { Events } from '../../../services/events.service';
import { HestiaQnrFormSessionService } from '../../../services/qnr-form-session.service';
import { createFormGroup } from '../../../services/widget.service';
import { RepeatableGroupWidgetConfig } from './repeatable-group-widget.config';

@Component({
  selector: 'hestia-repeatable-group-widget',
  templateUrl: './repeatable-group-widget.component.html',
  styleUrls: ['./repeatable-group-widget.component.scss'],
})
export class RepeatableGroupWidgetComponent
  extends WidgetBaseComponent
  implements OnInit, OnDestroy
{
  @Input() config: RepeatableGroupWidgetConfig;
  @Input() form: UntypedFormGroup;
  control: UntypedFormArray;
  constructor(
    transloco: TranslocoService,
    questionnaireService: HestiaQnrFormSessionService,
    store: Store,
    element: ElementRef,
    events: Events,
    public alertCtrl: AlertController
  ) {
    super(transloco, questionnaireService, store, element, events);
  }

  ngOnInit() {
    super.ngOnInit();
    this.control = this.form.controls[this.config.name] as UntypedFormArray;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  get isValid() {
    return true;
  }

  getSuggestedValueBtnName() {
    return this.config.suggestedValueConfig?.buttonText
      ? `FHIR.${this.config.metadata.sessionId}.${this.config.name}.QuestionnaireItemSuggestedValue/buttonText`
      : 'IFQ.REPEATABLE_GROUP_WIDGET.INSERT_SUGGESTION_BTN';
  }

  async triggerSuggestedValue(_event: MouseEvent) {
    const translatMessageToken = this.config.suggestedValueConfig?.alertMessage
      ? `FHIR.${this.config.metadata.sessionId}.${this.config.name}.QuestionnaireItemSuggestedValue/alertMessage`
      : 'IFQ.SUGGESTED_VALUE_EXT.DEFAULT_ALERT_MESSAGE';
    const prompt = await this.alertCtrl.create({
      header: this.transloco.translate(
        'IFQ.SUGGESTED_VALUE_EXT.DEFAULT_ALERT_HEADER'
      ),
      message: this.transloco.translate(translatMessageToken),
      buttons: [
        {
          text: this.transloco.translate(
            'IFQ.SUGGESTED_VALUE_EXT.DEFAULT_ALERT_OK_BTN'
          ),
          handler: (data) => {
            const control = this.form.controls[this.config.name] as UntypedFormArray;
            while (control.length !== 0) {
              control.removeAt(0);
            }
            this.config.suggestedValueConfig.value.forEach((valueObj) => {
              control.push(createFormGroup(this.config, valueObj));
            });
          },
        },
        {
          text: this.transloco.translate(
            'IFQ.SUGGESTED_VALUE_EXT.DEFAULT_ALERT_CANCEL_BTN'
          ),
          handler: (data) => {
            //
          },
        },
      ],
    });
    prompt.present();
  }

  addGroup(_event: MouseEvent) {
    // add group to the array
    this.control.push(createFormGroup(this.config, {}));
  }

  removeGroup(_event: MouseEvent, i: number) {
    // remove group from the array
    this.control.removeAt(i);
  }
}
