<hestia-widget-group-header
  [config]="config"
  [i18n]="i18n"
></hestia-widget-group-header>
<ion-item [formGroup]="form" lines="none">
  <ion-label position="stacked" [attr.for]="config.label">
    <hestia-widget-header
      [config]="config"
      [i18n]="i18n"
    ></hestia-widget-header>
  </ion-label>
  <ion-select
    [id]="config.name"
    [formControlName]="config.name"
    [disabled]="config.disabled"
    [multiple]="config.multiple"
  >
    <ion-select-option
      *ngFor="let option of config.options"
      [value]="option.code"
    >
      {{
        i18n({
          translate: option.i18nName,
          fallback: option.display,
          type: 'i18nName'
        })
      }}
    </ion-select-option>
  </ion-select>
</ion-item>
