import { InjectionToken } from '@angular/core';

export interface StorageSyncOptions {
  keys?: string[];
  ignoreActions?: string[];
  hydratedStateKey?: string;
  onSyncError?: (err: any) => void;
}

export const STORAGE_SYNC_MODULE_SETTINGS =
  new InjectionToken<StorageSyncModuleSettings>('Storage Sync Settings');

export interface StorageSyncModuleSettings {
  storageKey: string;
  syncProvider: 'local-forage' | 'capacitor';
  syncSettings: StorageSyncOptions;
}
