import { Component, Inject, Input } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import { IQnrFormSessionModuleConfig } from '@hestia/ngx-fhir-qnr-forms';

import { QNR_CORE_MODULE_CONFIG } from '../../../constants';
import { WidgetConfig } from '../../../models/widget.config';

@Component({
  selector: 'hestia-widget-group-header',
  templateUrl: './widget-group-header.component.html',
  styleUrls: ['./widget-group-header.component.scss'],
})
export class WidgetGroupHeaderComponent {
  @Input() config: WidgetConfig<any>;
  @Input() i18n: (args: {
    translate: string;
    fallback?: string;
    type?: 'configParam' | 'i18nName';
    config?: WidgetConfig<{}>; // Allows us to pass the config of a childWidget
  }) => string;
  constructor(
    public transloco: TranslocoService,
    @Inject(QNR_CORE_MODULE_CONFIG) public coreModuleConfig: IQnrFormSessionModuleConfig
  ) {}
}
