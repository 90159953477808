import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';

import { EffectsModule } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { StoreModule } from '@ngrx/store';

import { HestiaCommonModule } from '@hestia/ngx-common';
import { HestiaFhirResource } from '@hestia/ngx-types';
import { HestiaNgxUserModule } from '@hestia/ngx-user';

import { FhirResourceEffects } from './+state/fhir-resource.effects';
import * as fromFhirResource from './+state/fhir-resource.reducer';
import { FHIR_RESOURCES_INITIAL_STATE } from './constants';
import { HestiaFhirResourcesService } from './services/fhir-resources.service';
import { HestiaFhirUsersService } from './services/fhir-users.service';

const createInitialState = (entities: HestiaFhirResource[]) => {
  const entitiesObj: Dictionary<HestiaFhirResource> = {};
  entities.map((elem) => (entitiesObj[elem.id] = elem));
  const state: fromFhirResource.IFhirResourcesState = {
    ...fromFhirResource.initialState,
    ids: [
      ...(fromFhirResource.initialState.ids as string[]),
      ...entities.map((elem) => elem.id as string),
    ],
    entities: {
      ...fromFhirResource.initialState.entities,
      ...entitiesObj,
    },
  };
  return state;
};

export interface HestiaNgxFhirModuleConfig {
  standardTaskIntentCode?: string;
  yearlyTaskIntentCode?: string;
  oneTimeTaskIntentCode?: string;
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslocoModule,
    HestiaNgxUserModule,
    HestiaCommonModule.forChild(),
    StoreModule.forFeature(
      fromFhirResource.fhirResourcesFeatureKey,
      fromFhirResource.fhirResourcesReducer,
      FHIR_RESOURCES_INITIAL_STATE
    ),
    EffectsModule.forFeature([FhirResourceEffects]),
  ],
  providers: [],
})
export class HestiaNgxFhirModule {
  static forRoot(config?: {
    initialResources?: HestiaFhirResource[];
  }): ModuleWithProviders<HestiaNgxFhirModule> {
    return {
      ngModule: HestiaNgxFhirModule,
      providers: [
        HestiaFhirResourcesService,
        HestiaFhirUsersService,
        {
          provide: FHIR_RESOURCES_INITIAL_STATE,
          useValue: {
            initialState: createInitialState(config?.initialResources ?? []),
          },
        },
      ],
    };
  }
}
