import {
  Component,
  Input,
  ElementRef,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';

import { Store } from '@ngrx/store';

import { WidgetBaseComponent } from '../../../models/widget';
import { Events } from '../../../services/events.service';
import { HestiaQnrFormSessionService } from '../../../services/qnr-form-session.service';
import { TextAreaWidgetConfig } from './textarea-widget.config';

@Component({
  selector: 'hestia-textarea-widget',
  templateUrl: './textarea-widget.component.html',
  styleUrls: ['./textarea-widget.component.scss'],
})
export class TextAreaWidgetComponent
  extends WidgetBaseComponent
  implements OnInit, OnDestroy
{
  @Input() config: TextAreaWidgetConfig;
  @Input() form: UntypedFormGroup;
  constructor(
    transloco: TranslocoService,
    questionnaireService: HestiaQnrFormSessionService,
    store: Store,
    public element: ElementRef,
    events: Events
  ) {
    super(transloco, questionnaireService, store, element, events);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.config.isCustomSelectionModalEnabled) {
      this.events.subscribe(
        `${this.config.customSelectionModalConfig.modalEventIdentifier}:close`,
        (value: string) => this.handleCustomSelectionModalClose(value)
      );
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  updateFormValue(value) {
    this.form.controls[this.config.name].setValue(value);
    const input = this.element.nativeElement.querySelector(
      `ion-textarea#${this.config.name}`
    );
    input.dispatchEvent(new Event('input')); // https://github.com/text-mask/text-mask/issues/696#issuecomment-354887412
  }

  newVirtualKeyboardValue(value) {
    console.log('newVirtualKeyboardValue called');
    if(typeof value === 'string' || value instanceof String){
      this.updateFormValue(value);
    }
    
  }

  customSelectionModalOpen($event) {
    $event.preventDefault();
    const eventName = `${this.config.customSelectionModalConfig.modalEventIdentifier}:open`;
    this.events.publish(eventName);
  }

  handleCustomSelectionModalClose(newValue: string) {
    const existingValue = this.form.controls[this.config.name].value;
    const combinedValue =
      existingValue !== '' && existingValue !== null
        ? existingValue + '\n\n' + newValue
        : newValue;
    this.updateFormValue(combinedValue);
  }

  get isValid() {
    return this.form.controls[this.config.name].valid;
  }
}
