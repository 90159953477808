<div [formGroup]="form">
  <hestia-widget-group-header
    [config]="config"
    [i18n]="i18n"
  ></hestia-widget-group-header>
  <ion-list [id]="config.name" lines="full" text-wrap>
    <ion-list-header>
      <ion-label [attr.for]="config.label">
        <hestia-widget-header
          [config]="config"
          [i18n]="i18n"
        ></hestia-widget-header>
      </ion-label>
    </ion-list-header>

    <ion-radio-group
      [value]="config.value || config.defaultValue"
      [formControlName]="config.name"
    >
      <ng-container *ngFor="let option of config.options">
        <ion-item>
          <ion-label style="white-space: normal">
            <ion-img
              *ngIf="option?.imageUrl === null"
              [src]="'assets/img/' + option?.imageUrl"
            ></ion-img>
            <span
              [innerHTML]="
                i18n({
                  translate: option.i18nName,
                  fallback: option.display,
                  type: 'i18nName'
                })
              "
            ></span>
          </ion-label>
          <ion-radio
            slot="end"
            [value]="option.code"
            [disabled]="config.disabled"
          ></ion-radio>
        </ion-item>
      </ng-container>
    </ion-radio-group>
  </ion-list>
</div>
