/* eslint-disable @typescript-eslint/naming-convention */
export const danish = {
  IFQ: {
    PAGE_OUT_OF_TOTAL: 'Side {{currentPage}} ud af {{totalPages}}',
    PREVIOUS: 'Forrige',
    NEXT: 'Næste',
    SUBMIT: 'Indsend',
    YOU_PICKED: 'Du har valgt',
    STRING_WIDGET: {
      PLACEHOLDER: 'Skriv',
    },
    DATETIME_WIDGET: {
      PLACEHOLDER: 'Klik her for at angive',
    },
    TEXTAREA_WIDGET: {
      PLACEHOLDER: 'Skriv',
    },
    SIGN_WIDGET: {
      ClEAR_BTN: 'Nulstil',
    },
    NUMBER_WIDGET: {
      PLACEHOLDER: 'Tal',
    },
    SELECTION_WIDGET: {
      PLACEHOLDER: 'Vælg',
    },
    SUGGESTED_VALUE_EXT: {
      DEFAULT_BTN_TEXT: 'Forslået værdi',
      DEFAULT_ALERT_HEADER: 'Indsæt værdi?',
      DEFAULT_ALERT_MESSAGE:
        'Skal den foreslåede indtastning indsættes?<br/><br/>Hvis du har indtastet noget allerede vil det blive overskrevet.',
      DEFAULT_ALERT_OK_BTN: 'OK',
      DEFAULT_ALERT_CANCEL_BTN: 'Annullér',
    },
    WANT_TO_PROCEED_ALERT: {
      HEADER: 'Manglende svar',
      MESSAGE:
        "Besvar venligst spørgsmålet før du går videre.<br/><br/>Det er vigtigt at du besvarer alle spørgsmål så vidt muligt, men hvis du ikke ønsker at svare kan du klikke på 'Fortsæt'.",
      OK_BTN: 'Fortsæt',
      CANCEL_BTN: 'Bliv på siden',
    },
    REPEATABLE_GROUP_WIDGET: {
      INSERT_SUGGESTION_BTN: 'Brug forslag',
      ADD_NEW_BTN: 'Tilføj ny',
    },
    SVG_GROUP_WIDGET: {
      ACTION_SHEET: {
        CANCEL: 'Annullér',
        MARK_UNANSWERED_TITLE: 'Marker allé ubesvarede som...',
      },
      LEGEND: {
        HEADER: 'Figur-forklaring',
        NO_VALUE: 'Ingen værdi',
      },
      MARK_UNANSWERED_BTN: {
        LABEL: 'Markér ubesvarede',
      },
    },
  },
};
