export const extractCodeFromCodeableConcept = (
  codeableConcept: fhir4.CodeableConcept
): string => {
  if (codeableConcept.coding.length > 1) {
    console.warn(
      'MORE THAN ONE CODING FOUND IN CODEABLECONCEPT, EXPECTED ONLY ONE: ',
      codeableConcept
    );
  }
  return codeableConcept.coding[0]?.code ?? null;
};
