import { Pipe, PipeTransform } from '@angular/core';

/*
 * Adds a dash between the sixth and seventh digits of the CPR number
 */
@Pipe({ name: 'readableCpr' })
export class ReadableCprPipe implements PipeTransform {
  transform(value: string, hide: boolean = false): string {
    if (value === undefined) {
      return value;
    }
    if (value.length < 10) {
      // if value is less than 10 characters, return it unchanged
      return value;
    }
    return hide ? `${value.substring(0, 6)}-****` : `${value.substring(0, 6)}-${value.substring(6)}`;
  }
}
